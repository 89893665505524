import "./styles.scss";

import moment from "moment";
import classnames from "classnames";

import Pill from "components/Pill";
import List from "./components/List";
import InfoMessage from "./components/InfoMessage";
import StatusSelect from "./components/StatusSelect";
import PrioritySelect from "./components/PrioritySelect";

import task_status_data from "libs/task_status_data.json";

const mainClass = "task-conversation__details";

const Details = ({
  data,
  admin_mode,
  show_time_entries,
  hide_required_action,
  setWebsiteInfoModal,
  updateTask,
}) => {
  const status = task_status_data?.find(
    (item) => item?.value === data?.status
  )?.key;

  return (
    <div
      className={classnames(mainClass, {
        [`${mainClass}--admin-mode`]: !!admin_mode,
      })}
    >
      <div className={`${mainClass}__header`}>
        <h3>Overview</h3>
        <div className={`${mainClass}__header__aside`}>
          {!!admin_mode && !!!hide_required_action ? (
            <>
              <PrioritySelect
                priority={data?.priority}
                updateTask={updateTask}
              />
              <StatusSelect status={data?.status} updateTask={updateTask} />
            </>
          ) : (
            <Pill variant={status} readOnly size="small" />
          )}
        </div>
      </div>
      {data?.status > 1 || !!admin_mode ? (
        <List
          data={data}
          setWebsiteInfoModal={setWebsiteInfoModal}
          show_time_entries={show_time_entries}
          admin_mode={admin_mode}
        />
      ) : (
        <InfoMessage
          text="Your request is being analyzed. You will be informed via email as soon
        as our estimation is ready. The offer will also appear in here for your
        verification and acceptance."
        />
      )}
      <small>Last update: {moment(data?.updated_at)?.format("lll")}</small>
    </div>
  );
};

export default Details;
