import "./styles.scss";

import classnames from "classnames";
import { useTranslation } from "react-i18next";

const mainClass = "textarea";

const Textarea = ({
  type = "text",
  label,
  placeholder,
  name,
  touched,
  error,
  value,
  onChange,
  disabled,
  uppercaseLabel = false,
  maxLength,
  required,
  id,
}) => {
  const { t } = useTranslation();

  const is_error = touched && !!error;

  return (
    <div
      id={id}
      className={classnames(mainClass, {
        [`${mainClass}--error`]: !!is_error,
        [`${mainClass}--uppercase-label`]: !!uppercaseLabel,
      })}
    >
      <label>
        {label}
        {!!required && <span>*</span>}
      </label>
      <div className={`${mainClass}__content`}>
        <textarea
          className={`${mainClass}__field`}
          type={type}
          placeholder={placeholder}
          value={value}
          name={name}
          onChange={onChange}
          disabled={disabled}
          maxLength={maxLength}
        />
      </div>
      {!!is_error && (
        <small className={`${mainClass}__error`}>{t(error)}</small>
      )}
    </div>
  );
};

export default Textarea;
