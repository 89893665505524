import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import DetailsCard from "components/DetailsCard";
import LanguageModal from "./components/LanguageModal";
import DetailsCardList from "components/DetailsCardList";
import AccountRoleModal from "./components/AccountRoleModal";
import ProfilePhotoModal from "./components/ProfilePhotoModal";
import PersonalDataModal from "./components/PersonalDataModal";
import DeleteAccountModal from "./components/DeleteAccountModal";
import ChangePasswordModal from "./components/ChangePasswordModal";
import DashboardContentLayout from "components/layouts/DashboardContentLayout";

import countries from "libs/countries.json";

import { useWebmasterSettings } from "context/admin/webmaster-settings";

const mainClass = "column-layout";

const SettingsContent = () => {
  const { t } = useTranslation();

  const { data, loading } = useWebmasterSettings();

  const [personal_data_modal, setPersonalDataModal] = useState(false);
  const [change_password_modal, setChangePasswordModal] = useState(false);
  const [profile_photo_modal, setProfilePhotoModal] = useState(false);
  const [language_modal, setLanguageModal] = useState(false);
  const [account_role_modal, setAccountRoleModal] = useState(false);
  const [delete_account_modal, setDeleteAccountModal] = useState(false);

  const actions = [
    {
      type: "send_notification",
    },
    {
      label: t("Task list"),
      href: `/webmasters/${data?._id}`,
    },
  ];

  const breadcrumps = [
    {
      label: t("Webmasters"),
      href: "/webmasters",
    },
    {
      label: !!data?.first_name
        ? `${data?.first_name} ${data?.last_name}`
        : null,
      href: `/webmasters/${data?._id}`,
    },
    {
      label: t("Edit webmaster account"),
    },
  ];

  const details_data = [
    {
      title: t("Account role"),
      action: {
        label: t("Change"),
        onclick: () => setAccountRoleModal(true),
      },
      items: [
        {
          label: t("Role"),
          value: data?.type === "webmaster" ? t("Webmaster") : t("Admin"),
        },
      ],
    },
    {
      title: t("Personal data"),
      action: {
        label: t("Edit"),
        onclick: () => setPersonalDataModal(true),
      },
      items: [
        {
          label: t("Full name"),
          value: `${data?.first_name} ${data?.last_name}`,
        },
        {
          type: "link",
          label: t("Email"),
          value: {
            link_label: data?.email,
            link_href: `mailto:${data?.email}`,
          },
        },
        {
          type: "link",
          label: t("Phone"),
          value: {
            link_label: !!data?.phone?.number
              ? `${
                  countries?.find((item) => item?.code === data?.phone?.code)
                    ?.dialling_code || ""
                } ${data?.phone?.number}`
              : "-",
            link_href: `tel:${
              countries?.find((item) => item?.code === data?.phone?.code)
                ?.dialling_code || ""
            } ${data?.phone?.number}`,
          },
        },
      ],
    },
    {
      title: t("Security"),
      action: {
        label: t("Change password"),
        onclick: () => setChangePasswordModal(true),
      },
      items: [
        {
          label: t("Last password change"),
          value: moment(data?.last_password_changed)?.fromNow(),
          tooltip: t(
            "We recommend using a unique password and changing it at least once a year."
          ),
        },
      ],
    },
  ];

  const payment_data = [
    {
      title: t("Panel language"),
      items: [
        {
          type: "language",
          value: data?.language,
        },
      ],
      action: {
        label: t("Change"),
        onclick: () => setLanguageModal(true),
      },
    },
    {
      title: t("Profile photo"),
      items: [
        {
          type: "avatar",
          value: data?.photo?.location,
        },
      ],
      action: {
        label: t("Change"),
        onclick: () => setProfilePhotoModal(true),
      },
    },
    {
      title: t("Delete account"),
      action: {
        label: t("Delete"),
        onclick: () => setDeleteAccountModal(true),
      },
    },
  ];

  return (
    <DashboardContentLayout
      header_label={
        !!data?.first_name ? `${data?.first_name} ${data?.last_name}` : null
      }
      loading={loading}
      async_header
      breadcrumps={breadcrumps}
      actions={actions}
    >
      <div className={mainClass}>
        <div className={`${mainClass}__col`}>
          {details_data?.map((data, index) => (
            <DetailsCard key={index} {...data}>
              <DetailsCardList items={data?.items} view={data?.view} />
            </DetailsCard>
          ))}
        </div>
        <div className={`${mainClass}__col`}>
          {payment_data?.map((data, index) => (
            <DetailsCard key={index} {...data}>
              <DetailsCardList items={data?.items} view={data?.view} />
            </DetailsCard>
          ))}
        </div>
        {!!personal_data_modal && (
          <PersonalDataModal onClose={() => setPersonalDataModal(false)} />
        )}
        {!!change_password_modal && (
          <ChangePasswordModal onClose={() => setChangePasswordModal(false)} />
        )}
        {!!profile_photo_modal && (
          <ProfilePhotoModal onClose={() => setProfilePhotoModal(false)} />
        )}
        {!!language_modal && (
          <LanguageModal onClose={() => setLanguageModal(false)} />
        )}
        {!!account_role_modal && (
          <AccountRoleModal onClose={() => setAccountRoleModal(false)} />
        )}
        {!!delete_account_modal && (
          <DeleteAccountModal
            id={data?._id}
            name={`${data?.first_name} ${data?.last_name}`}
            onClose={() => setDeleteAccountModal(false)}
          />
        )}
      </div>
    </DashboardContentLayout>
  );
};

export default SettingsContent;
