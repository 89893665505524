import "./styles.scss";

import { ReactComponent as StatusIcon } from "assets/icons/status-verification/status.svg";
import { ReactComponent as DotsIcon } from "assets/icons/status-verification/dots.svg";

const mainClass = "status-ok";

const StatusVerificationIcon = () => {
  return (
    <div className={mainClass}>
      <StatusIcon />
      <DotsIcon />
    </div>
  );
};

export default StatusVerificationIcon;
