import { InlineWidget } from "react-calendly";
import { useTranslation } from "react-i18next";

const calendly_link_render = (lang) => {
  switch (lang) {
    case "pl":
      return "https://calendly.com/martin-webinity/spotkanie?hide_gdpr_banner=1&background_color=0f0fff&text_color=ffffff&primary_color=14e0e0";
    default:
      return "https://calendly.com/martin-webinity/30min?hide_gdpr_banner=1&background_color=0f0fff&text_color=ffffff&primary_color=14e0e0";
  }
};

const Calendly = () => {
  const { i18n } = useTranslation();
  const calendly_link = calendly_link_render(i18n?.language);

  return (
    <>
      <InlineWidget
        styles={{
          width: "100%",
          height: 580,
          zIndex: 10,
          position: "relative",
        }}
        url={calendly_link}
      />
    </>
  );
};

export default Calendly;
