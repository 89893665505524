import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import io from "socket.io-client";
import moment from "moment";
import queryString from "query-string";
import { useQueryClient } from "@tanstack/react-query";

import SplashScreen from "components/SplashScreen";

import NotificationsProvider from "./notifications";
import TimeTrackingProvider from "./webmaster/time-tracking";

import api from "libs/api";

import "moment/locale/pl";

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = queryString.parse(search);
  const queryClient = useQueryClient();

  const [user, setUser] = useState(null);
  const [websites, setWebsites] = useState([]);
  const [webmasters, setWebmasters] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [task_tracking, setTaskTracking] = useState(null);
  const [loading, setLoading] = useState(true);
  const [init_loading, setInitLoading] = useState(true);
  const [socket, setSocket] = useState(null);
  const [mobile_sidebar_open, setMobileSidebarOpen] = useState(false);
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    (async function () {
      await getUserData();
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!!!user) return;

    const temp_socket = io.connect(`${process.env.REACT_APP_API}`, {
      reconnection: true,
      forceNew: true,
    });

    temp_socket.emit("join", user._id);
    setSocket(temp_socket);
    // eslint-disable-next-line
  }, [user]);

  const getUserData = async () => {
    try {
      const getMeData = await api.get("/user/me");

      if (getMeData?.user) {
        setUser(getMeData?.user);
        setWebsites(getMeData?.websites);
        setNotifications(getMeData?.notifications);
        setWebmasters(getMeData?.webmasters);
        setAdmins(getMeData?.admins);
        setTaskTracking({
          ...getMeData?.task_tracking,
        });
        setPlans(getMeData?.plans);

        i18n.changeLanguage(getMeData?.user?.language);
        moment.locale(getMeData?.user?.language);

        if (query?.next === "new_website") {
          navigate("/websites/new");
        }
      }
    } catch (err) {
      console.error(err);
    }

    setInitLoading(false);
    setLoading(false);
  };

  const login = ({ email, password }) =>
    new Promise(async (resolve, reject) => {
      try {
        await api.post("/auth/login", {
          email,
          password,
        });
        getUserData();
        resolve();
      } catch (err) {
        reject(err);
      }
    });

  const logout = async () => {
    try {
      await api.get("/auth/logout");

      // setUser(null);
      // setWebsites(null);
      window.location.replace("/login");
      queryClient?.clear();
    } catch (err) {
      console.error(err);
    }
  };

  const value = useMemo(() => {
    return {
      user,
      websites,
      notifications,
      webmasters,
      admins,
      socket,
      login,
      logout,
      plans_list: plans,
      getUserData,
      setUser,
      setWebsites,
      setNotifications,
      task_tracking,
      mobile_sidebar_open,
      setMobileSidebarOpen,
    };
    // eslint-disable-next-line
  }, [
    user,
    websites,
    notifications,
    webmasters,
    admins,
    socket,
    mobile_sidebar_open,
    plans,
  ]);

  if (!!loading) return <SplashScreen loading={!!init_loading} />;

  if (!!user) {
    if (user?.type === "webmaster") {
      return (
        <AppContext.Provider value={value}>
          <NotificationsProvider>
            <TimeTrackingProvider>{children}</TimeTrackingProvider>
          </NotificationsProvider>
        </AppContext.Provider>
      );
    } else {
      return (
        <AppContext.Provider value={value}>
          <NotificationsProvider>{children}</NotificationsProvider>
        </AppContext.Provider>
      );
    }
  } else {
    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
  }
};

const useApp = () => useContext(AppContext);
export { AppContext, useApp };
export default AppProvider;
