import "./styles.scss";

import { useState } from "react";

import Modal from "components/Modal";
import { useTask } from "context/admin/task";

const DeleteTimeModal = ({ id, onClose, editTimeClose }) => {
  const { deleteTaskTime } = useTask();
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    try {
      setLoading(true);

      await deleteTaskTime(id);
      onClose();
      if (!!editTimeClose) {
        editTimeClose();
      }
    } catch (err) {
      console.error(err);
    }

    setLoading(false);
  };

  return (
    <Modal
      title="Delete selected time entry"
      onClose={onClose}
      onSubmit={onSubmit}
      submit_button_label="Yes, delete entry"
      loading={!!loading}
      variant="little-narrow"
    >
      <p>
        Are you sure you would like to delete selected time entry?
        <br />
        <br />
        This action cannot be reversed. Before confirming deletion please make
        sure it doesn't contain any important information.
      </p>
    </Modal>
  );
};

export default DeleteTimeModal;
