import "./styles.scss";
import moment from "moment";
import classnames from "classnames";
import { Link } from "react-router-dom";

import toHoursAndMinutes from "libs/helpers/toHoursAndMinutes";

const mainClass = "task-conversation__details__list";

const List = ({ data, setWebsiteInfoModal, admin_mode, show_time_entries }) => {
  const details_data = [
    {
      label: "Website",
      value: data?.website,
      onclick: !!setWebsiteInfoModal
        ? () => setWebsiteInfoModal(data?.website_id)
        : null,
    },
    {
      label: "Task title",
      value: data?.title,
    },
    {
      label: !!data?.finished_when ? "Task finished when" : "Client message",
      value: data?.finished_when || (
        <div dangerouslySetInnerHTML={{ __html: data?.client_message }} />
      ),
    },
  ];

  const date_details_data = [
    {
      label: "Estimated time",
      value: !!data?.estimated_time?.from
        ? `From ${data?.estimated_time?.from} to ${data?.estimated_time?.to}h`
        : null,
    },
    {
      label: "Due date",
      value: data?.due_date ? moment(data?.due_date)?.format("ll") : null,
    },
  ];

  const time_tracked_data = [
    {
      label: "Time tracked:",
      items: data?.time_entries,
    },
  ];

  return (
    <div className={mainClass}>
      {details_data?.map(({ label, value, onclick }, index) => {
        if (!!!value) return null;

        return (
          <div
            key={index}
            className={classnames(`${mainClass}__item`, {
              [`${mainClass}__item--link`]: !!onclick,
            })}
          >
            <strong>{label}</strong>
            <span onClick={onclick}>{value}</span>
          </div>
        );
      })}
      {(!!data?.estimated_time?.from || !!data?.due_date) && (
        <div className={`${mainClass}__item__group`}>
          {date_details_data?.map(({ label, value }, index) => {
            if (!!!value) return null;

            return (
              <div key={index} className={`${mainClass}__item`}>
                <strong>{label}</strong>
                <span>{value}</span>
              </div>
            );
          })}
        </div>
      )}
      {!!show_time_entries &&
        time_tracked_data?.map(({ label, items }, index) => {
          if (!!!items) return null;

          return (
            <div
              key={index}
              className={`${mainClass}__item ${mainClass}__item--entries`}
            >
              <strong>{label}</strong>

              <div key={index} className={`${mainClass}__item__list`}>
                {items
                  ?.map((item, index) => (
                    <div
                      key={index}
                      className={`${mainClass}__item__list__entry`}
                    >
                      {`${
                        toHoursAndMinutes(
                          parseFloat(item?.time?.$numberDecimal)
                        )?.formatted
                      } on ${moment(item?.created_at)?.format("ll")}`}
                      <span> by </span>
                      {!!admin_mode ? (
                        <Link
                          to={`/webmasters/${item?.webmaster?._id}`}
                        >{`${item?.webmaster?.first_name} ${item?.webmaster?.last_name}`}</Link>
                      ) : (
                        <span>{`${item?.webmaster?.first_name} ${item?.webmaster?.last_name}`}</span>
                      )}
                    </div>
                  ))
                  ?.reverse()}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default List;
