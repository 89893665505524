import "./styles.scss";

import moment from "moment";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import toHoursAndMinutes from "libs/helpers/toHoursAndMinutes";

const mainClass = "dashboard-development-time";

const DevelopmentTime = ({ data }) => {
  const { t } = useTranslation();

  var currentDate = new Date();
  var currentMonth = currentDate.getMonth() + 1;
  var currentYear = currentDate.getFullYear(); // Adding 1 to adjust for zero-indexings
  var lastDayOfMonth = moment().endOf("month").date();

  const time_data_list = [
    {
      label: t("Time available in your plan"),
      value: toHoursAndMinutes(data?.available_in_plan)?.formatted,
    },
    {
      label: t("Time used"),
      value: toHoursAndMinutes(data?.time_used)?.formatted,
    },
    {
      label: t("Time left"),
      value: toHoursAndMinutes(data?.time_left)?.formatted,
    },
    {
      label: t("Time exceeding plan"),
      value: toHoursAndMinutes(data?.time_exceeding_plan)?.formatted,
      extra_link: {
        label: t("Show"),
        href: `/tasks?time_over=true&start_due_date=${currentYear}-${currentMonth}-01&end_due_date=${currentYear}-${currentMonth}-${lastDayOfMonth}`,
      },
    },
  ];

  const time_used = (data?.time_used / data?.available_in_plan) * 100;
  const time_exceeding_plan = !!data?.time_exceeding_plan
    ? (time_used / data?.time_exceeding_plan) * 100
    : 0;

  const formatted_time_exceeding_plan =
    time_exceeding_plan > 70 ? 70 : time_exceeding_plan;

  return (
    <div className={mainClass}>
      <div className={`${mainClass}__progress`}>
        <div
          className={`${mainClass}__progress__part`}
          style={{
            background: `linear-gradient(90deg, rgba(15, 15, 255, 1) 0%, rgba(15, 15, 255, 1) ${time_used}%, rgba(0, 254, 255, 1) ${time_used}%, rgba(0, 254, 255, 1) 100%)`,
            width: `${
              !!formatted_time_exceeding_plan
                ? time_used - formatted_time_exceeding_plan
                : 100
            }%`,
          }}
        />
        <div
          className={`${mainClass}__progress__part`}
          style={{ width: `${formatted_time_exceeding_plan}%` }}
        />
      </div>
      <div className={`${mainClass}__legend`}>
        {time_data_list?.map(({ label, value, extra_link }, index) => (
          <div key={index} className={`${mainClass}__legend__item`}>
            <div key={index} className={`${mainClass}__legend__item__wrapper`}>
              <span>
                {label}: <strong>{value}</strong>
              </span>
              {!!extra_link && (
                <Link to={extra_link?.href}>{extra_link?.label}</Link>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DevelopmentTime;
