import "./styles.scss";

import Modal from "components/Modal";

const mainClass = "cancel-plan-modal";

const RequestPayoutModal = ({ onClose }) => {
  const onSubmit = async () => {
    try {
      // customerPortal("cancel-plan");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Modal
      title="Request payout"
      onClose={onClose}
      onSubmit={onSubmit}
      loading={false}
      variant="standard"
      submit_button_label="Request payout"
    >
      <div className={mainClass}>
        <h2>Would you like to request a payout of your commission?</h2>
        <ul>
          <li>
            We will verify your request and you will receive your commission
            within the next 14 days.
          </li>
          <li>
            You will receive a notification when your payout status changes and
            your commission is on its way to you.
          </li>
          <li>
            You can always check the status of your request by accessing the
            Referral Program tab on your Webinity account.
          </li>
        </ul>
      </div>
    </Modal>
  );
};

export default RequestPayoutModal;
