import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import DetailsCard from "components/DetailsCard";
import DetailsCardList from "components/DetailsCardList";
import OverviewModal from "./components/components/OverviewModal";
import ContactDataModal from "./components/components/ContactDataModal";
import BillingDataModal from "./components/components/BillingDataModal";
import DashboardContentLayout from "components/layouts/DashboardContentLayout";

import countries from "libs/countries.json";
import priceRender from "libs/helpers/priceRender";
import toHoursAndMinutes from "libs/helpers/toHoursAndMinutes";
import getCountryFromCode from "libs/helpers/getCountryFromCode";

import { useClient } from "context/admin/client";

const mainClass = "column-layout";

const ClientDetails = () => {
  const { t } = useTranslation();

  const { data, loading } = useClient();

  const [contact_data_modal, setContactDataModal] = useState(false);
  const [billing_data_modal, setBillingDataModal] = useState(false);
  const [overview_modal, setOverviewModal] = useState(false);

  const breadcrumps = [
    {
      label: t("Clients"),
      href: "/clients",
    },
    {
      label: data?.website,
    },
  ];

  const actions = [
    {
      type: "send_notification",
    },
    {
      type: "create_task",
    },
    {
      label: t("Edit client account"),
      href: `/clients/${data?._id}/account-settings`,
    },
  ];

  let selected_plan = data?.plan?.[0]?.prices?.find(
    (item) => item?.price_id === data?.subscription?.price_id
  );
  let selected_price = selected_plan?.currencies?.find(
    (item) => item?.currency === data?.subscription?.currency
  );

  const details_data = [
    {
      title: t("Overview"),
      action: {
        label: t("Edit"),
        onclick: () => setOverviewModal(true),
      },
      items: [
        {
          type: "link_external",
          label: t("Website URL"),
          value: {
            link_label: data?.website,
            link_href: `https://${data?.website}`,
          },
        },
        {
          type: "status",
          label: t("Status"),
          value: {
            status: data?.status,
          },
        },
        {
          label: t("Last CMS update"),
          value: !!data?.last_cms_update
            ? moment(data?.last_cms_update)?.format("ll")
            : "-",
        },
        {
          label: t("SSL certificate valid until"),
          value: !!data?.ssl_valid_until
            ? moment(data?.ssl_valid_until)?.format("ll")
            : "-",
        },
        {
          label: t("Domain valid until"),
          value: !!data?.domain_valid_until
            ? moment(data?.domain_valid_until)?.format("ll")
            : "-",
        },
        {
          label: t("Time available in your plan"),
          value: toHoursAndMinutes(data?.time_used?.available_in_plan)
            ?.formatted,
        },
        {
          label: t("Time used"),
          value: toHoursAndMinutes(data?.time_used?.time_used)?.formatted,
        },
        {
          label: t("Time left"),
          value: toHoursAndMinutes(data?.time_used?.time_left)?.formatted,
        },
        {
          label: t("Time exceeding plan"),
          value: toHoursAndMinutes(data?.time_used?.time_exceeding_plan)
            ?.formatted,
        },
        {
          type: "link_external",
          active: !!data?.password1_link,
          label: t("Access data"),
          value: {
            link_label: t("Show access data"),
            link_href: data?.password1_link,
          },
        },
      ],
    },
    {
      title: t("Tasks"),
      action: {
        label: t("Show tasks"),
        href: `/clients/${data?._id}/tasks`,
      },
      items: [
        {
          label: t("Awaiting estimation"),
          value: data?.tasks_stats?.awaiting_estimation,
        },

        {
          label: t("In progress"),
          value: data?.tasks_stats?.in_progress,
        },
        {
          label: t("Awaiting verification"),
          value: data?.tasks_stats?.awaiting_verification,
        },
      ],
    },
    {
      title: t("Plan"),
      items: [
        {
          label: t("Type"),
          value: `${data?.plan?.[0]?.name} plan`,
        },
        {
          label: t("Billing cycle"),
          value:
            selected_plan?.contract_period === "monthly"
              ? t("Monthly")
              : t("Yearly"),
        },
        {
          label: t("Amount"),
          value: !!selected_price?.amount
            ? priceRender(
                data?.subscription?.amount / 100,
                data?.subscription?.currency
              )
            : "-",
        },
        {
          label: t("Next charge"),
          value: !!data?.subscription?.next_payment
            ? moment.unix(data?.subscription?.next_payment)?.format("ll")
            : "-",
        },
      ],
    },
  ];

  const payment_data = [
    {
      title: t("Contact data"),
      action: {
        label: t("Edit"),
        onclick: () => setContactDataModal(true),
      },
      items: [
        {
          label: t("Full name"),
          value: `${data?.contact_data?.first_name} ${data?.contact_data?.last_name}`,
        },
        {
          type: "link",
          label: t("Email"),
          value: {
            link_label: data?.contact_data?.email,
            link_href: `mailto:${data?.contact_data?.email}`,
          },
        },
        {
          type: "link",
          label: t("Phone"),
          value: {
            link_label: `${
              countries?.find(
                (item) => item?.code === data?.contact_data?.phone?.code
              )?.dialling_code || ""
            } ${data?.contact_data?.phone?.number}`,
            link_href: `tel:${
              countries?.find(
                (item) => item?.code === data?.contact_data?.phone?.code
              )?.dialling_code || ""
            } ${data?.contact_data?.phone?.number}`,
          },
        },
      ],
    },
    {
      title: t("Payment"),
      view: "active",
      items: [
        {
          type: "card",
          label: t("Method"),
          value: {
            card_type: data?.subscription?.card?.brand,
            status: !!data?.subscription?.card?.expires_soon
              ? 2
              : data?.subscription?.status === "active"
              ? 1
              : 0,
            card_numbers: !!data?.subscription?.card?.last4
              ? `**** **** **** **** ${data?.subscription?.card?.last4}`
              : "-",
          },
        },
        // {
        //   type: "button",
        //   label: "Invoices",
        //   value: {
        //     button_label: "Show",
        //     button_href: `/clients/${data?._id}/invoices`,
        //   },
        // },
      ],
    },
    {
      title: t("Billing data"),
      action: {
        label: t("Edit"),
        onclick: () => setBillingDataModal(true),
      },
      items: [
        {
          label: t("Name"),
          value: data?.billing_data?.name,
        },
        {
          label: t("Tax identification number"),
          value: `${data?.billing_data?.tax?.code} ${data?.billing_data?.tax?.clean_number}`,
        },
        {
          label: t("Address"),
          value: (
            <>
              {data?.billing_data?.street}
              <br />
              {data?.billing_data?.post_code} {data?.billing_data?.city}
              <br />
              {t(getCountryFromCode(data?.billing_data?.country)?.name)}
            </>
          ),
        },
      ],
    },
  ];

  return (
    <DashboardContentLayout
      header_label={data?.website}
      async_header
      loading={loading}
      breadcrumps={breadcrumps}
      actions={actions}
    >
      <div className={mainClass}>
        <div className={`${mainClass}__col`}>
          {details_data?.map((data, index) => (
            <DetailsCard key={index} {...data}>
              <DetailsCardList items={data?.items} view={data?.view} />
            </DetailsCard>
          ))}
        </div>
        <div className={`${mainClass}__col`}>
          {payment_data?.map((data, index) => (
            <DetailsCard key={index} {...data}>
              <DetailsCardList items={data?.items} view={data?.view} />
            </DetailsCard>
          ))}
        </div>
        <div style={{ position: "absolute" }}>
          {!!contact_data_modal && (
            <ContactDataModal onClose={() => setContactDataModal(false)} />
          )}
          {!!billing_data_modal && (
            <BillingDataModal onClose={() => setBillingDataModal(false)} />
          )}
          {!!overview_modal && (
            <OverviewModal onClose={() => setOverviewModal(false)} />
          )}
        </div>
      </div>
    </DashboardContentLayout>
  );
};

export default ClientDetails;
