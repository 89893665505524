import { useState } from "react";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Button from "components/Button";
import FormikInput from "components/formik/FormikInput";

import { useApp } from "context/app";
import loginSchema from "libs/validations/loginSchema";

const mainClass = "auth-content";

const LoginContent = () => {
  const { t, i18n } = useTranslation();

  const { login } = useApp();
  const [error, setError] = useState(false);

  const onSubmit = async (values, setSubmitting, setErrors) => {
    try {
      setError(false);
      await login(values);
    } catch (err) {
      if (!!err?.response?.data?.error) {
        setError(err?.response?.data?.error);
      }

      setErrors({ email: "", password: "" });
      setSubmitting(false);
    }
  };

  return (
    <div className={mainClass}>
      <div className={`${mainClass}__wrapper`}>
        <div className={`${mainClass}__header`}>
          <h1>{`${t("Welcome back")}!`}</h1>
          <span>{t("Start managing your website faster and better")}</span>
        </div>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={loginSchema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            onSubmit(values, setSubmitting, setErrors);
          }}
        >
          {({ isSubmitting }) => (
            <Form className={`${mainClass}__form`} autoComplete="off">
              <div className={`${mainClass}__form__inputs`}>
                <FormikInput
                  name="email"
                  type="email"
                  label={t("Email")}
                  disabled={isSubmitting}
                />
                <FormikInput
                  name="password"
                  label={t("Password")}
                  type="password"
                  disabled={isSubmitting}
                />
              </div>
              <div className={`${mainClass}__form__forget-password`}>
                <Link to="/forget-password">{t("Forgot password?")}</Link>
              </div>
              {!!error && <small className={`form__error`}>{t(error)}</small>}
              <div className={`${mainClass}__form__actions`}>
                <Button type="submit" loading={!!isSubmitting} fullWidth>
                  {t("Log in")}
                </Button>
                <Button
                  loading={!!isSubmitting}
                  fullWidth
                  external_href={true}
                  external_href_blank={false}
                  variant="transparent"
                  href={`https://webinity.com/${
                    i18n?.language === "pl" ? "pl" : ""
                  }`}
                >
                  {t("Back to homepage")}
                </Button>
                <span>
                  {t("Don't have an account yet?")}{" "}
                  <Link
                    to="/register"
                    onClick={() =>
                      window.gtag("event", "register", {
                        click_text: t("Sign up"),
                        click_url: `/register`,
                      })
                    }
                  >
                    {t("Sign up")}
                  </Link>
                </span>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default LoginContent;
