import "./styles.scss";

import { useState, useRef, useEffect } from "react";
import classnames from "classnames";

import useClickOutside from "libs/helpers/useClickOutside";

const mainClass = "dropdown";

const Dropdown = ({
  children,
  button,
  position,
  fullWidthContent,
  onclose = 0,
  offset = 0,
}) => {
  const [dropdown_open, setDropdownOpen] = useState(false);
  const ref = useRef();

  useClickOutside(ref, () => setDropdownOpen(false));

  const openHandler = (e) => {
    if (e.currentTarget !== e.target) setDropdownOpen((prev) => !prev);
  };

  useEffect(() => {
    setDropdownOpen(false);
  }, [onclose]);

  return (
    <div
      ref={ref}
      className={classnames(mainClass, {
        [`${mainClass}--top`]: !!position && position[0] === "top",
        [`${mainClass}--bottom`]: !!position && position[0] === "bottom",
        [`${mainClass}--left`]: !!position && position[1] === "left",
        [`${mainClass}--right`]: !!position && position[1] === "right",
        [`${mainClass}--full-width-content`]: !!fullWidthContent,
      })}
    >
      <div className={`${mainClass}__button`} onClick={openHandler}>
        {button}
      </div>
      {!!dropdown_open && (
        <div
          className={`${mainClass}__content`}
          style={{
            transform: `translateX(${offset}px)`,
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
