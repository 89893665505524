import moment from "moment";
import queryString from "query-string";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import Table from "components/Table";
import Input from "components/form/Input";
import Calendar from "components/Calendar";
import PillGrid from "components/PillGrid";
import Select from "components/form/Select";
import TaskOnboarding from "components/TaskOnboarding";
import PlaceholderPage from "components/PlaceholderPage";
import DashboardContentLayout from "components/layouts/DashboardContentLayout";

import { useApp } from "context/app";
import { useTasks } from "context/client/tasks";

import useResponsive from "libs/helpers/useResponsive";
import task_status_data from "libs/task_status_data.json";
import toHoursAndMinutes from "libs/helpers/toHoursAndMinutes";

const mainClass = "invoices-content";

const TasksContent = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const query = queryString.parse(search);

  const {
    data,
    loading,
    filters,
    filterHandler,
    init_loading,
    filterClearAll,
  } = useTasks();
  const { websites } = useApp();
  const { isMobile } = useResponsive();

  const [task_onboarding, setTaskOnboarding] = useState(false);

  useEffect(() => {
    if (query?.onboarding_finish === "true") {
      setTaskOnboarding(true);
    }
    // eslint-disable-next-line
  }, [query?.onboarding_finish]);

  const onOnboardingClose = () => {
    setTaskOnboarding(false);

    let highlight_element = document.getElementsByClassName("table__row")?.[1];

    if (!!highlight_element) {
      highlight_element.style.zIndex = 1;
      highlight_element.style.position = "relative";
      highlight_element.style.backgroundColor = "transparent";
    }

    window.history.replaceState({}, null, `/tasks`);
  };

  const breadcrumps = [
    {
      label: t("Tasks"),
    },
  ];

  const actions = [
    {
      label: t("Add new website"),
      href: "/websites/new",
      event: () =>
        window.gtag("event", "new_website", {
          click_text: t("Add new website"),
          click_url: `/websites/new`,
        }),
    },
    ...(websites?.length > 0
      ? [
          {
            type: "create_client_task",
            onboarding_button: true,
          },
        ]
      : []),
  ];

  const pills_task_status = [
    {
      label: t("Show all"),
      value: "all",
      onclick: () => filterHandler("status", ""),
      active: !!!filters?.status,
    },
    ...task_status_data
      ?.filter(
        (item) => item?.value !== 6 && item?.value !== 7 && item?.value !== 2
      )
      ?.map(({ key, label, value }) => ({
        label: t(label),
        value,
        onclick: () =>
          filterHandler(
            "status",
            task_status_data?.find((item) => item?.value === value)?.key
          ),
        active: filters?.status === key,
      })),
  ];

  const pills_task_extra_status = [
    {
      label: t("Cancelled"),
      value: 6,
      onclick: () => filterHandler("status", "cancelled"),
      active: filters?.status === "cancelled",
    },
    {
      label: t("Closed"),
      value: 7,
      onclick: () => filterHandler("status", "closed"),
      active: filters?.status === "closed",
    },
  ];

  const tasks_out_of_time = [
    {
      label: t("Show tasks"),
      value: "show_tasks",
      onclick: () =>
        filterHandler("time_over", filters?.time_over === "true" ? "" : "true"),
      active: filters?.time_over === "true",
    },
  ];

  const table_data = {
    keys: [
      {
        sort_key: "status",
        key: "status-pill",
        value: t("Status"),
      },
      {
        sort_key: "title",
        key: "title-full",
        value: t("Title"),
      },
      {
        key: "website",
        value: t("Website"),
      },
      {
        key: "estimated_time",
        value: isMobile ? t("Estimated time mobile") : t("Estimated time"),
      },
      {
        sort_key: "total_time",
        key: "time_tracked",
        value: t("Time tracked"),
      },
      {
        key: !!filters?.time_over ? "time_over" : "due_date",
        value: !!filters?.time_over
          ? t("Time over plan")
          : isMobile
          ? t("Due date mobile")
          : t("Due date"),
      },
    ],
    items: data?.map(
      ({
        _id,
        title,
        website,
        status,
        estimated_time,
        total_time,
        due_date,
        time_over,
      }) => [
        {
          key: "status-pill",
          type: "status-pill",
          value: task_status_data?.find((item) => item?.value === status)?.key,
        },
        {
          key: "title-full",
          type: "link",
          mobile_title: true,
          value: {
            label: title,
            href: !!filters?.time_over
              ? `/tasks/${_id}/time-over`
              : `/tasks/${_id}`,
          },
        },
        {
          key: "website",
          type: "link",
          value: {
            label: website?.name,
            href: `/websites/${website?._id}`,
          },
        },
        {
          key: "estimated_time",
          value: !!estimated_time
            ? toHoursAndMinutes(estimated_time?.$numberDecimal)?.formatted
            : "-",
        },
        {
          key: "time_tracked",
          value: !!total_time?.$numberDecimal
            ? toHoursAndMinutes(total_time?.$numberDecimal)?.formatted
            : "-",
        },
        {
          key: !!filters?.time_over ? "time_over" : "due_date",
          value: !!filters?.time_over
            ? toHoursAndMinutes(time_over)?.formatted
            : !!due_date
            ? moment(due_date)?.format("DD MMM, YYYY")
            : "-",
        },
        ...(isMobile
          ? [
              {
                key: "button",
                type: "button",
                value: {
                  label: t("Show details"),
                  href: `/tasks/${_id}`,
                },
              },
            ]
          : []),
      ]
    ),
  };

  const websites_options = [
    {
      label: t("All websites"),
      value: null,
    },
    ...websites?.map(({ _id, website }) => ({
      label: website,
      value: _id,
    })),
  ];

  return (
    <>
      <DashboardContentLayout
        header_label={t("Tasks")}
        loading={init_loading}
        breadcrumps={breadcrumps}
        actions={actions}
      >
        <div className={mainClass}>
          {websites?.length > 0 ? (
            <Table
              data={table_data}
              loading={!!loading}
              sort={filters?.sort}
              filters_count={Object?.keys(filters)?.length}
              filter_default_open={Object?.keys(filters)?.length > 0}
              setSort={(value) => filterHandler("sort", value)}
              filterClearAll={filterClearAll}
              header={
                <div style={{ display: "flex", gap: 64 }}>
                  <PillGrid
                    header={t("Task status")}
                    items={pills_task_status}
                    size="small"
                  />
                </div>
              }
              filters={
                <>
                  <div className={`${mainClass}__filter-wrap`}>
                    <div className={`${mainClass}__filter-row`}>
                      <Select
                        label={t("Website")}
                        options={websites_options}
                        value={
                          filters?.website || websites?.length === 1
                            ? websites?.[0]?._id
                            : null
                        }
                        disabled={websites?.length === 1}
                        onChange={(e) =>
                          filterHandler("website", e.target.value)
                        }
                      />
                      <Input
                        label={t("Search")}
                        placeholder={t("Task title")}
                        value={filters?.search}
                        onChange={(e) =>
                          filterHandler("search", e.target.value)
                        }
                      />
                      <PillGrid
                        header={t("Show")}
                        items={pills_task_extra_status}
                        size="small"
                      />
                    </div>
                    <div className={`${mainClass}__filter-row`}>
                      <PillGrid
                        header={t("Tasks with time exceeding monthly plan")}
                        items={tasks_out_of_time}
                        size="small"
                      />
                      {!!filters?.time_over && (
                        <>
                          <Calendar
                            label={t("Due date start")}
                            default_value={filters?.start_due_date}
                            onChange={(value) =>
                              filterHandler("start_due_date", value)
                            }
                          />
                          <Calendar
                            label={t("Due date end")}
                            default_value={filters?.end_due_date}
                            onChange={(value) =>
                              filterHandler("end_due_date", value)
                            }
                          />
                        </>
                      )}
                    </div>
                  </div>
                </>
              }
            />
          ) : (
            <PlaceholderPage
              title={t("You have not added any websites yet")}
              text={t(
                "To purchase a plan for your website click the button below."
              )}
              button={{
                label: t("Choose a plan"),
                href: "/websites/new",
              }}
            />
          )}
        </div>
      </DashboardContentLayout>
      {!!task_onboarding && !!!loading && (
        <TaskOnboarding init_step={6} onClose={onOnboardingClose} />
      )}
    </>
  );
};

export default TasksContent;
