import "./styles.scss";

import { useTranslation } from "react-i18next";

import Button from "components/Button";

const mainClass = "dashboard-tasks-action";

const TasksAction = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className={mainClass}>
      {data?.map(({ _id, website, title }) => (
        <div key={_id} className={`${mainClass}__item`}>
          <strong>{title}</strong>
          <span>{website}</span>
          <Button href={`/tasks/${_id}`}>{t("More details")}</Button>
        </div>
      ))}
    </div>
  );
};

export default TasksAction;
