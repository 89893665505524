import "./styles.scss";

import classnames from "classnames";
import { useTranslation } from "react-i18next";

const mainClass = "details-card-status";

const DetailsCardStatus = ({ value }) => {
  const { t } = useTranslation();

  const status_render = (status) => {
    switch (status) {
      case 1:
        return {
          text: t("Everything is ok"),
          class: "good",
        };
      case 2:
        return {
          text: t("Something is wrong"),
          class: "wrong",
        };
      case 3:
        return {
          text: t("Verification in progress"),
          class: "verification",
        };
      case 4:
        return {
          text: t("Website does not respond"),
          class: "wrong",
        };
      default:
        return {
          text: "-",
          class: null,
        };
    }
  };

  const current_status = status_render(value);

  return (
    <div
      className={classnames(
        mainClass,
        `${mainClass}--${current_status?.class}`
      )}
    >
      {current_status?.text}
    </div>
  );
};

export default DetailsCardStatus;
