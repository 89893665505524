import { number, object, string } from "yup";

let yourDataSchema = object({
  first_name: string().required("First name cannot be empty."),
  last_name: string().required("Last name cannot be empty."),
  email: string()
    .required("Email cannot be empty.")
    .email("The email is not a valid email address."),
  phone: object({
    code: string().required("Dialling code connot be empty."),
    number: number().required("Phone connot be empty."),
  }),
  billing: object({
    name: string().required("Name cannot be empty."),
    tax: object({
      code: string().required("Tax identification type cannot be empty."),
      number: string().required("Tax identification number cannot be empty."),
    }),
    street: string().required("Street cannot be empty."),
    city: string().required("City cannot be empty."),
    post_code: string().required("Post code cannot be empty."),
    country: string().required("Country cannot be empty."),
  }),
});

export default yourDataSchema;
