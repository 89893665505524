import "./styles.scss";

import { useState } from "react";
import classnames from "classnames";
import { FileUploader } from "react-drag-drop-files";
import { useTranslation } from "react-i18next";

import Modal from "components/Modal";

import { useClientSettings } from "context/admin/client-settings";

import { ReactComponent as UploadIcon } from "assets/icons/upload.svg";

const mainClass = "profile-photo-modal";

const file_types = ["JPG", "PNG", "JPEG"];
const file_max_size = 5;

const generateBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const ProfilePhotoModal = ({ onClose }) => {
  const { t } = useTranslation();

  const { updatePhoto, photo_progress } = useClientSettings();

  const [photo_preview, setPhotoPreview] = useState(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const onSubmit = async () => {
    try {
      setLoading(true);
      await updatePhoto(file, photo_preview);
      onClose();
    } catch (err) {
      setLoading(false);
    }
  };

  const onChange = async (file) => {
    setError(null);
    const photo = await generateBase64(file[0]);
    setPhotoPreview(photo);
    setFile(file[0]);
  };

  return (
    <Modal
      title={t("Change profile photo")}
      onClose={onClose}
      onSubmit={onSubmit}
      loading={loading}
      variant="narrow"
    >
      <FileUploader
        classes={classnames(mainClass, {
          [`${mainClass}--loading`]: !!loading,
        })}
        multiple={true}
        handleChange={onChange}
        name="file"
        types={file_types}
        maxSize={file_max_size}
        onSizeError={() =>
          setError("The file is too large. Allowed maximum size is 5MB.")
        }
        hoverTitle=" "
      >
        {!!photo_preview ? (
          <img src={photo_preview} alt="" />
        ) : (
          <div className={`${mainClass}__info`}>
            <UploadIcon />
            <span>
              <span>{t("Click to upload")}</span> {t("or drag and drop photo")}
            </span>
            <small>
              {t("Acceptable format: jpg, png.")}
              <br />
              {t("Max size: 5MB each.")}
            </small>
          </div>
        )}
        {!!loading && (
          <div className={`${mainClass}__loader`}>
            <span>{photo_progress}</span>
          </div>
        )}
      </FileUploader>
      {!!error && <small className={`${mainClass}__error`}>{error}</small>}
    </Modal>
  );
};

export default ProfilePhotoModal;
