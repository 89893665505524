import { Route } from "react-router-dom";

import DashboardLayout from "components/layouts/DashboardLayout";

import Dashboard from "pages/client";
import Invoices from "pages/client/invoices";
import Notifications from "pages/shared/notifications";
import Settings from "pages/client/settings";
import Websites from "pages/client/websites";
import NewWebsites from "pages/client/websites/new";
import ChangePlan from "pages/client/websites/change-plan";
import Contact from "pages/client/contact";
import Tasks from "pages/client/tasks";
import NewTasks from "pages/client/tasks/new";
import AccessData from "pages/client/websites/access-data";
import ConversationTasks from "pages/client/tasks/conversation";
import WebsitesPlaceholder from "pages/client/websites/placeholder";
import ReferralProgramDetails from "pages/shared/referral-programs/details";
import TimeOut from "pages/shared/time-out";
import Faq from "pages/shared/faq";
import NewProject from "pages/client/new-project";

const clientRoutes = (is_partner) => {
  return (
    <Route element={<DashboardLayout />}>
      <Route
        index={!!!is_partner}
        path={!!is_partner ? "/dashboard" : "/"}
        element={<Dashboard />}
      />
      <Route path="/invoices" element={<Invoices />} />
      <Route path="/tasks">
        <Route index element={<Tasks />} />
        <Route path=":id" element={<ConversationTasks />} />
        <Route path="new" element={<NewTasks />} />
        <Route path=":id/time-over" element={<TimeOut />} />
      </Route>
      <Route path="/notifications" element={<Notifications />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/websites">
        <Route index element={<WebsitesPlaceholder />} />
        <Route path=":id">
          <Route index element={<Websites />} />
          <Route path="change-plan" element={<ChangePlan />} />
          <Route path="access-data" element={<AccessData />} />
        </Route>
        <Route path="new" element={<NewWebsites />} />
      </Route>
      <Route
        index={!!is_partner}
        path={!!!is_partner ? "/referral-program" : null}
        element={<ReferralProgramDetails />}
      />
      <Route path="/contact" element={<Contact />} />
      <Route path="/new-project" element={<NewProject />} />
      <Route path="/faq" element={<Faq />} />
    </Route>
  );
};

export default clientRoutes;
