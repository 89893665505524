import { object, string } from "yup";

let contactDataSchema = object({
  contact_data: object({
    first_name: string().required("Name cannot be empty."),
    last_name: string().required("Surname cannot be empty."),
    email: string()
      .required("Email cannot be empty.")
      .email("The email is not a valid email address."),
  }),
});

export default contactDataSchema;
