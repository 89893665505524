import "./styles.scss";

import { Link, Outlet } from "react-router-dom";
import { ReactComponent as Logo } from "assets/icons/logo.svg";

const mainClass = "empty-layout";

const EmptyLayout = () => {
  return (
    <div className={mainClass}>
      <div className={`${mainClass}__top`}>
        <Link to="/">
          <Logo />
        </Link>
      </div>
      <div className={`${mainClass}__content`}>
        <Outlet />
      </div>
    </div>
  );
};

export default EmptyLayout;
