import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";

import Button from "components/Button";
import FormikInput from "components/formik/FormikInput";

import forgetPasswordSchema from "libs/validations/forgetPasswordSchema";
import api from "libs/api";

const mainClass = "auth-content";

const ForgetPasswordContent = ({ setEmailConfirmed }) => {
  const { t } = useTranslation();

  const onSubmit = async (values, setSubmitting, setErrors) => {
    try {
      await api.post("/auth/password_reset", values);
      setEmailConfirmed(values?.email);
    } catch (err) {
      setErrors({
        email: t("Incorrect e-mail address"),
      });
      setSubmitting(false);
    }
  };

  return (
    <div className={mainClass}>
      <div className={`${mainClass}__wrapper`}>
        <div className={`${mainClass}__header`}>
          <h1>{t("Forgot your password?")}</h1>
          <span>
            {t(
              "Don't worry! We will send you instructions on how to recover your password."
            )}
          </span>
        </div>
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={forgetPasswordSchema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            onSubmit(values, setSubmitting, setErrors);
          }}
        >
          {({ isSubmitting }) => (
            <Form className={`${mainClass}__form`} autoComplete="off">
              <div className={`${mainClass}__form__inputs`}>
                <FormikInput
                  name="email"
                  type="email"
                  label="Email"
                  disabled={isSubmitting}
                />
              </div>
              <div className={`${mainClass}__form__actions`}>
                <Button type="submit" loading={!!isSubmitting} fullWidth>
                  {t("Reset password")}
                </Button>
                <Button
                  loading={!!isSubmitting}
                  fullWidth
                  variant="transparent"
                  href="/login"
                >
                  {t("Back to login")}
                </Button>
                <span>
                  {t("Do you have an account?")}{" "}
                  <Link to="/register">{t("Sign up")}</Link>
                </span>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ForgetPasswordContent;
