import "./styles.scss";

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import YourData from "./components/steps/YourData";
import ChoosePlan from "./components/steps/ChoosePlan";
import ProgressBarSteps from "components/ProgresBarSteps";
import Confirmation from "./components/steps/Confirmation";
import CheckYourData from "./components/steps/CheckYourData";
import DashboardContentLayout from "components/layouts/DashboardContentLayout";

import useResponsive from "libs/helpers/useResponsive";
import { useNewWebsite } from "context/client/new_website";

const mainClass = "new-website-content";

const form_type_render = (step) => {
  switch (step) {
    case 0:
      return <ChoosePlan />;
    case 1:
      return <YourData />;
    case 2:
      return <CheckYourData />;
    case 4:
      return <Confirmation />;
    default:
      return null;
  }
};

const NewWebsiteContent = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const {
    website,
    step,
    setStep,
    loading: init_loading,
    change_plan_mode,
    step_touched,
    mobile_step,
  } = useNewWebsite();
  const { search } = useLocation();
  const { isMobile } = useResponsive();

  const query = new URLSearchParams(search);

  const breadcrumps = [
    {
      label: t("Websites"),
      href: "/",
    },
    ...(!!change_plan_mode
      ? [
          {
            label: website?.website,
          },
        ]
      : []),
  ];

  useEffect(() => {
    if (query.get("result") === "success") {
      setStep(4);
    }

    window.scroll({
      top: 0,
      behavior: "smooth",
    });

    setLoading(false);
    // eslint-disable-next-line
  }, [step]);

  const progress_data = [
    t("Choose a plan"),
    t("Your data"),
    t("Checkout"),
    t("Payment"),
    t("Confirmation"),
  ];

  const progress_mobile = [
    t("Website"),
    t("Plan type"),
    t("Choose a plan"),
    t("Your data"),
    t("Checkout"),
    t("Payment"),
    t("Confirmation"),
  ];

  if (!!loading) return null;

  return (
    <DashboardContentLayout
      header_label={!!change_plan_mode ? website?.website : t("Add website")}
      loading={init_loading}
      async_header={!!change_plan_mode}
      breadcrumps={breadcrumps}
      no_padding={true}
    >
      <div className={mainClass}>
        <ProgressBarSteps
          data={!!isMobile ? progress_mobile : progress_data}
          step={!!isMobile ? mobile_step + step : step}
          setStep={setStep}
          step_touched={step_touched}
        />
        <div className={`${mainClass}__wrapper`}>{form_type_render(step)}</div>
      </div>
    </DashboardContentLayout>
  );
};

export default NewWebsiteContent;
