import "./styles.scss";

import AvatarImage from "assets/images/avatar-placeholder.png";

const mainClass = "details-card-avatar";

const DetailsCardAvatar = ({ value }) => {
  return (
    <div className={mainClass}>
      <img src={value || AvatarImage} alt="" />
    </div>
  );
};

export default DetailsCardAvatar;
