import { createContext, useContext, useMemo, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import queryString from "query-string";

import api from "libs/api";
import { useApp } from "./app";

const NotificationsContext = createContext();

const NotificationsProvider = ({ children }) => {
  const { socket } = useApp();

  const [notifications, setNotifications] = useState([]);
  const [init_loading, setInitLoading] = useState(true);
  const [show_read, setShowRead] = useState(true);

  const { search } = useLocation();
  const navigate = useNavigate();

  const filters = queryString.parse(search);
  const default_sort = "created_at:desc";

  const { isLoading: loading, refetch } = useQuery(
    ["client_notifications", filters],
    () =>
      api.get(
        `/user/me/notifications?${queryString.stringify({
          ...filters,
          sort: filters?.sort || default_sort,
        })}`
      ),
    {
      onSuccess: (data) => {
        setInitLoading(false);
        setNotifications(data);
      },
    }
  );

  useEffect(() => {
    if (!!!socket) return;

    socket.on("notification", () => {
      refetch();
      // audio.play();
    });
    // eslint-disable-next-line
  }, [socket]);

  const readHandler = (id, status) => {
    try {
      api.post(`/user/read-notifications/${id}`, { status });

      let temp_data = notifications;
      if (id === "all") {
        temp_data = temp_data?.map((item) => ({
          ...item,
          status: 1,
        }));
      } else {
        const index = temp_data?.findIndex((item) => item?._id === id);
        temp_data[index] = { ...temp_data[index], status };
      }

      setNotifications([...temp_data]);
    } catch (err) {
      console.error(err);
    }
  };

  const filterHandler = (name, value) => {
    navigate(
      `/notifications?${queryString.stringify({
        ...filters,
        [name]: value || undefined,
      })}`
    );
  };

  let formatted_notifications = [...notifications];

  if (!!!show_read) {
    formatted_notifications = formatted_notifications?.filter(
      (item) => item?.status === 0
    );
  }

  const value = useMemo(() => {
    return {
      data: formatted_notifications,
      show_read,
      readHandler,
      init_loading,
      loading,
      filters,
      filterHandler,
      setShowRead,
    };
    // eslint-disable-next-line
  }, [formatted_notifications, init_loading, loading, filters, show_read]);

  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  );
};

const useNotifications = () => useContext(NotificationsContext);
export { NotificationsContext, useNotifications };
export default NotificationsProvider;
