import SettingsContent from "page_components/admin/webmasters/Settings";
import WebmasterSettingsProvider from "context/admin/webmaster-settings";

const Settings = () => (
  <WebmasterSettingsProvider>
    <SettingsContent />
  </WebmasterSettingsProvider>
);

export default Settings;
