import TasksProvider from "context/client/tasks";
import TasksContent from "page_components/client/tasks/Content";

const Tasks = () => (
  <TasksProvider>
    <TasksContent />
  </TasksProvider>
);

export default Tasks;
