import "./styles.scss";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { useEffect, useState } from "react";

import UserMessage from "./components/UserMessage";
import StatusMessage from "./components/StatusMessage";
import MessageEditor from "./components/MessageEditor";

import { useApp } from "context/app";

const mainClass = "task-conversation__chat";

const Chat = ({ messages, file_progress, onSubmit, editMessage }) => {
  const { user: user_data } = useApp();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document
      .querySelector(".task-conversation__chat__content__wrapper")
      .scrollIntoView({
        block: "end",
        inline: "nearest",
      });
  }, [messages]);

  const submitHandler = async (content, files) => {
    try {
      await onSubmit(content, files);
    } catch (err) {
      console.error(err);
    }

    setLoading(false);
  };

  let last_status = messages?.filter((item) => !!item?.template);
  last_status = last_status[last_status?.length - 1];

  return (
    <div className={mainClass}>
      <div className={`${mainClass}__content`}>
        <div className={`${mainClass}__content__wrapper`}>
          {[...messages]?.map(
            ({ _id, text, user, template, created_at, files }) =>
              template ? (
                <StatusMessage
                  key={_id}
                  template={template}
                  is_hightlighted={
                    last_status?._id === _id && user_data?.type === "client"
                  }
                />
              ) : (
                <>
                  <UserMessage
                    key={[_id, text]}
                    id={_id}
                    avatar={user?.photo}
                    name={user?.name}
                    type={user?.type}
                    date={created_at}
                    message={text}
                    attachments={files}
                    me={user?._id === user_data?._id}
                    onEditSubmit={editMessage}
                  />
                </>
              )
          )}
        </div>
      </div>
      <div className={`${mainClass}__bottom`}>
        <MessageEditor
          loading={loading}
          file_progress={file_progress}
          onSubmit={submitHandler}
        />
      </div>
    </div>
  );
};

export default Chat;
