import moment from "moment";
import { useTranslation } from "react-i18next";

import SettingsContentShared from "page_components/shared/settings/Content";

import { useSettings } from "context/settings";

import countries from "libs/countries.json";

const SettingsContent = () => {
  const { t } = useTranslation();

  const {
    data,
    updateSettings,
    setPersonalDataModal,
    setChangePasswordModal,
    setProfilePhotoModal,
    setLanguageModal,
  } = useSettings();

  const left_column_data = [
    {
      title: t("Personal data"),
      action: {
        label: t("Edit"),
        onclick: () => setPersonalDataModal(true),
      },
      items: [
        {
          label: t("Full name"),
          value: `${data?.first_name} ${data?.last_name}`,
        },
        {
          label: t("Email"),
          value: data?.email,
        },
        {
          label: t("Phone"),
          value: !!data?.phone?.number
            ? `${
                countries?.find((item) => item?.code === data?.phone?.code)
                  ?.dialling_code || ""
              } ${data?.phone?.number}`
            : "-",
        },
      ],
    },
    {
      title: t("Security"),
      action: {
        label: t("Change password"),
        onclick: () => setChangePasswordModal(true),
      },
      items: [
        {
          label: t("Last password change"),
          value: moment(data?.last_password_changed)?.fromNow(),
          tooltip: t(
            "We recommend using a unique password and changing it at least once a year."
          ),
        },
      ],
    },
    {
      title: t("Email notifications"),
      items: [
        {
          type: "switch",
          label: t("When my card is about to expire"),
          value: data?.settings?.email_notifications?.card_expired,
          onclick: () =>
            updateSettings({
              settings: {
                email_notifications: {
                  card_expired:
                    !data?.settings?.email_notifications?.card_expired,
                },
              },
            }),
        },
        {
          type: "switch",
          label: t("When my payment is declined"),
          value: data?.settings?.email_notifications?.payment_declined,
          onclick: () =>
            updateSettings({
              settings: {
                email_notifications: {
                  payment_declined:
                    !data?.settings?.email_notifications?.payment_declined,
                },
              },
            }),
        },
        {
          type: "switch",
          label: t("When there is a new invoice issued for me"),
          value: data?.settings?.email_notifications?.new_invoice,
          onclick: () =>
            updateSettings({
              settings: {
                email_notifications: {
                  new_invoice:
                    !data?.settings?.email_notifications?.new_invoice,
                },
              },
            }),
        },
      ],
    },
  ];

  const right_column_data = [
    {
      title: t("Panel language"),
      items: [
        {
          type: "language",
          value: data?.language,
        },
      ],
      action: {
        label: t("Change"),
        onclick: () => setLanguageModal(true),
      },
    },
    {
      title: t("Profile photo"),
      items: [
        {
          type: "avatar",
          value: data?.photo,
        },
      ],
      action: {
        label: t("Change"),
        onclick: () => setProfilePhotoModal(true),
      },
    },
  ];

  return (
    <SettingsContentShared
      left_column_data={left_column_data}
      right_column_data={right_column_data}
    />
  );
};

export default SettingsContent;
