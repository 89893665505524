import "./styles.scss";

import moment from "moment";
import { useTranslation } from "react-i18next";
import Button from "components/Button";

import priceRender from "libs/helpers/priceRender";

const mainClass = "dashboard-plan";

const Plan = ({ webiste_id, data }) => {
  const { t } = useTranslation();

  const overview_data = [
    {
      label: t("Type"),
      value: data?.name || "-",
    },
    {
      label: t("Billing cycle"),
      value: !!data?.contract_period ? t(data?.contract_period) : "-",
    },
    {
      label: t("Amount"),
      value: !!data?.amount
        ? priceRender(data?.amount / 100, data?.currency)
        : "-",
    },
    {
      label: t("Next charge"),
      value: !!data?.next_payment
        ? moment.unix(data?.next_payment)?.format("ll")
        : "-",
    },
  ];

  return (
    <div className={mainClass}>
      {data?.status === "active" && !!!data?.next_payment ? (
        <div className={`${mainClass}__warning`}>
          <div className={`${mainClass}__warning__info`}>
            <h3>{t("Subscription ends on")}</h3>
            <span>
              {moment
                ?.unix(data?.start_payment)
                ?.add(1, "months")
                ?.format("ll")}
            </span>
            <Button href={`/websites/${webiste_id}/change-plan`} size="small">
              {t("Renew plan")}
            </Button>
          </div>
          <div className={`${mainClass}__warning__list`}>
            <div className={`${mainClass}__warning__list__wrapper`}>
              {overview_data?.map(({ label, value }, index) => {
                if (label === t("Next charge")) return null;

                return (
                  <div
                    key={index}
                    className={`${mainClass}__warning__list__item`}
                  >
                    <span>{label}</span>
                    <span>{value}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        overview_data?.map(({ label, value, days_left }, index) => (
          <div key={index} className={`${mainClass}__item`}>
            <span>{label}</span>
            <span>
              {value}
              {!!days_left && (
                <>
                  <br />
                  <span>{days_left}</span>
                </>
              )}
            </span>
          </div>
        ))
      )}
    </div>
  );
};

export default Plan;
