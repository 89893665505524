import "./styles.scss";

import classnames from "classnames";

import Menu from "./components/Menu";

import { useApp } from "context/app";

import { ReactComponent as Logo } from "assets/icons/logo.svg";

const mainClass = "dashboard-layout-sidebar";

const Sidebar = () => {
  const { setMobileSidebarOpen } = useApp();

  return (
    <>
      <div
        className={classnames(mainClass, {
          [`${mainClass}--mobile-open`]: false,
        })}
      >
        <Logo />
        <Menu setMobileSidebarOpen={setMobileSidebarOpen} />
      </div>
    </>
  );
};

export default Sidebar;
