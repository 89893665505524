import "./styles.scss";

import { useState } from "react";
import classnames from "classnames";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";

import Modal from "components/Modal";
import Button from "components/Button";
import FormikSelect from "components/formik/FormikSelect";
import FormikTextarea from "components/formik/FormikTextarea";

import { useApp } from "context/app";

import api from "libs/api";
import sendNotificationSchema from "libs/validations/admin/sendNotificationSchema";
import { toast } from "react-toastify";

const mainClass = "send-notification";

const SendNotification = () => {
  const { t } = useTranslation();

  const { user, websites, webmasters, admins } = useApp();
  const [modal_open, setModalOpen] = useState(false);

  const role_data = [
    ...(user?.type === "webmaster"
      ? [
          {
            label: t("Administrator"),
            value: "administrator",
          },
        ]
      : [
          {
            label: t("Client"),
            value: "client",
          },
        ]),
    {
      label: t("Webmaster"),
      value: "webmaster",
    },
  ];

  const formatted_websites = websites?.map(({ _id, website }) => ({
    label: website,
    value: _id,
  }));

  const formatted_webmasters = webmasters?.map(
    ({ _id, first_name, last_name }) => ({
      label: `${first_name} ${last_name}`,
      value: _id,
    })
  );

  const formatted_admins = admins?.map(({ _id, first_name, last_name }) => ({
    label: `${first_name} ${last_name}`,
    value: _id,
  }));

  const onSubmit = async (values, setSubmitting) => {
    try {
      await api.post(`/admin/dashboard/send-notification`, values);
      setModalOpen(false);
      toast.success(t("Notification has been sent."));
    } catch (err) {
      console.error(err);
      setSubmitting(false);
    }
  };

  return (
    <>
      <Button onClick={() => setModalOpen((prev) => !prev)}>
        {t("Send notification")}
      </Button>
      <div className={mainClass}>
        {!!modal_open && (
          <Formik
            initialValues={{
              type: user?.type === "webmaster" ? "administrator" : "client",
              send_to: "",
              website: "",
              message: "",
            }}
            enableReinitialize
            validationSchema={sendNotificationSchema}
            onSubmit={(values, { setSubmitting }) => {
              onSubmit(values, setSubmitting);
            }}
          >
            {({ isSubmitting, values, setFieldValue, submitForm }) => (
              <Form autoComplete="off">
                <Modal
                  title={t("Send notification")}
                  onClose={() => setModalOpen(false)}
                  onSubmit={submitForm}
                  submit_button_label={t("Send")}
                  loading={isSubmitting}
                >
                  {role_data?.map(({ label, value }, index) => (
                    <button
                      key={index}
                      type="button"
                      disabled={isSubmitting}
                      className={classnames(`${mainClass}__item`, {
                        [`${mainClass}__item--active`]: values?.type === value,
                      })}
                      onClick={() => {
                        setFieldValue("type", value);
                        setFieldValue("website", "");
                      }}
                    >
                      {label}
                    </button>
                  ))}
                  {values?.type === "webmaster" && (
                    <FormikSelect
                      name="send_to"
                      label={t("Send to")}
                      placeholder="John Doe"
                      options={formatted_webmasters}
                      disabled={isSubmitting}
                      searcher
                    />
                  )}
                  {values?.type === "administrator" && (
                    <FormikSelect
                      name="send_to"
                      label={t("Send to")}
                      options={formatted_admins}
                      disabled={isSubmitting}
                      placeholder="John Doe"
                      searcher
                    />
                  )}
                  <FormikSelect
                    name="website"
                    label={t("Website")}
                    options={formatted_websites}
                    disabled={isSubmitting}
                    placeholder="www.example.com"
                    searcher
                  />
                  <FormikTextarea
                    name="message"
                    label={t("Message")}
                    placeholder={t("Type a message")}
                    disabled={isSubmitting}
                  />
                </Modal>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </>
  );
};

export default SendNotification;
