import { createContext, useContext, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";

import axios from "axios";

const NewTaskContext = createContext();

const NewTaskProvider = ({ children }) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = queryString.parse(search);

  const [file_progress, setFileProgress] = useState(0);

  const submitForm = (values) =>
    new Promise(async (resolve, reject) => {
      try {
        const formData = new FormData();
        formData.append("title", values?.title);
        formData.append("description", values?.description);
        values?.files?.forEach((item) => {
          formData.append("files", item);
        });

        await axios.post(
          `${process.env.REACT_APP_API}/client/websites/${values?.website}/task`,
          formData,
          {
            withCredentials: true,
            onUploadProgress: ({ loaded, total }) => {
              const percent = Math.floor((loaded * 100) / total);
              setFileProgress(percent);
            },
          }
        );

        resolve();

        navigate(
          `/tasks${
            query?.onboarding === "true" ? "?onboarding_finish=true" : ""
          }`
        );
      } catch (err) {
        reject(err);
      }
    });

  const value = useMemo(() => {
    return {
      file_progress,
      submitForm,
    };
    // eslint-disable-next-line
  }, [file_progress]);

  return (
    <NewTaskContext.Provider value={value}>{children}</NewTaskContext.Provider>
  );
};

const useNewTask = () => useContext(NewTaskContext);
export { NewTaskContext, useNewTask };
export default NewTaskProvider;
