import "./styles.scss";

import moment from "moment";
import Button from "components/Button";

import { ReactComponent as ArrowIcon } from "assets/icons/calendar-arrow.svg";

const mainClass = "calendar-days";

const CalendarDays = ({
  date,
  setDate,
  selected_date,
  onSelect,
  prev_date,
}) => {
  const temp_date = moment(date)?.set("date", 1);

  let all_days = [];
  for (let i = 0; i < temp_date?.daysInMonth(); i++) {
    const d = moment(temp_date).add(i, "days");
    all_days.push({
      date: d.format("ll"),
      day: d.format("DD"),
      disabled: !!prev_date ? moment(prev_date).isAfter(d) : false,
    });
  }

  const onChangeMonth = (type) => {
    if (type === "prev") {
      setDate((prev) => moment(prev).add(-1, "months"));
    } else {
      setDate((prev) => moment(prev).add(1, "months"));
    }
  };

  return (
    <div className={mainClass}>
      <div className={`${mainClass}__header`}>
        <button type="button" onClick={() => onChangeMonth("prev")}>
          <ArrowIcon />
        </button>
        <span>{`${date?.format("MMM")}, ${date?.year()}`}</span>
        <button type="button" onClick={() => onChangeMonth("next")}>
          <ArrowIcon />
        </button>
      </div>
      <div className={`${mainClass}__list`}>
        {all_days?.map((item, index) => (
          <Button
            size="small"
            variant="transparent"
            key={index}
            disabled={item?.disabled}
            onClick={() => onSelect(index + 1)}
            active={item?.date === selected_date?.format("ll")}
          >
            {item?.day}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default CalendarDays;
