import { useField } from "formik";
import TaxInput from "components/form/TaxInput";

const FormikTaxInput = ({ type, name, ...rest }) => {
  const [field, meta] = useField({ name, type });

  return <TaxInput type={type} {...rest} {...field} {...meta} />;
};

export default FormikTaxInput;
