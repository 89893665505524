import { useTranslation } from "react-i18next";

import DetailsCard from "components/DetailsCard";
import LanguageModal from "./components/LanguageModal";
import DetailsCardList from "components/DetailsCardList";
import ProfilePhotoModal from "./components/ProfilePhotoModal";
import PersonalDataModal from "./components/PersonalDataModal";
import ChangePasswordModal from "./components/ChangePasswordModal";
import DashboardContentLayout from "components/layouts/DashboardContentLayout";

import { useSettings } from "context/settings";

const mainClass = "column-layout";

const SettingsContent = ({ left_column_data, right_column_data }) => {
  const { t } = useTranslation();

  const breadcrumps = [
    {
      label: t("Account settings"),
    },
  ];

  const {
    personal_data_modal,
    change_password_modal,
    profile_photo_modal,
    language_modal,
    setPersonalDataModal,
    setChangePasswordModal,
    setProfilePhotoModal,
    setLanguageModal,
  } = useSettings();

  const DetailsCardsLeft = () =>
    left_column_data?.map((data, index) => (
      <DetailsCard key={index} {...data}>
        <DetailsCardList items={data?.items} view={data?.view} />
      </DetailsCard>
    ));

  const DetailsCardsRight = () =>
    right_column_data?.map((data, index) => (
      <DetailsCard key={index} {...data}>
        <DetailsCardList items={data?.items} view={data?.view} />
      </DetailsCard>
    ));

  return (
    <DashboardContentLayout
      header_label={t("Account settings")}
      breadcrumps={breadcrumps}
    >
      <div className={mainClass}>
        <div className={`${mainClass}__col`}>
          <DetailsCardsLeft />
        </div>
        <div className={`${mainClass}__col`}>
          <DetailsCardsRight />
        </div>
        <div style={{ position: "absolute" }}>
          {!!personal_data_modal && (
            <PersonalDataModal onClose={() => setPersonalDataModal(false)} />
          )}
          {!!change_password_modal && (
            <ChangePasswordModal
              onClose={() => setChangePasswordModal(false)}
            />
          )}
          {!!profile_photo_modal && (
            <ProfilePhotoModal onClose={() => setProfilePhotoModal(false)} />
          )}
          {!!language_modal && (
            <LanguageModal onClose={() => setLanguageModal(false)} />
          )}
        </div>
      </div>
    </DashboardContentLayout>
  );
};

export default SettingsContent;
