import "./styles.scss";
import { Link } from "react-router-dom";

const mainClass = "details-card-webmaster";

const DetailsCardWebmaster = ({ value }) => {
  return (
    <div className={mainClass}>
      <div className={`${mainClass}__content`}>
        <div className={`${mainClass}__content__image`}>
          <img alt="" src={value?.photo} />
        </div>
        <Link to={value?.href}>{value?.name}</Link>
      </div>

      <span className={`${mainClass}__extra`}>{value?.extra_text}</span>
    </div>
  );
};

export default DetailsCardWebmaster;
