import "./styles.scss";

import { useTranslation } from "react-i18next";

import { UpcomingTasks } from "./components";
import DetailsCard from "components/DetailsCard";
import DashboardContentLayout from "components/layouts/DashboardContentLayout";

import TasksAction from "page_components/shared/dashboard/TasksAction";

import { useApp } from "context/app";
import { useDashboard } from "context/admin/dashboard";

const mainClass = "dashboard-content";

const actions = [
  {
    type: "send_notification",
  },
  {
    type: "create_task",
  },
];

const DashboardContent = () => {
  const { t } = useTranslation();

  const { user } = useApp();
  const { requiring_action_data, init_loading } = useDashboard();

  const breadcrumps = [
    {
      label: t("Dashboard"),
    },
  ];

  return (
    <DashboardContentLayout
      header_label={`${t("Welcome back")}, ${user?.first_name}`}
      loading={init_loading}
      breadcrumps={breadcrumps}
      actions={actions}
    >
      <div className={mainClass}>
        <UpcomingTasks />
        {requiring_action_data?.length > 0 && (
          <DetailsCard title={t("Tasks requiring your action")}>
            <TasksAction data={requiring_action_data} />
          </DetailsCard>
        )}
      </div>
    </DashboardContentLayout>
  );
};

export default DashboardContent;
