import "./styles.scss";

import React from "react";
import { useTranslation } from "react-i18next";

import Plan from "./components/Plan";
import PillGrid from "components/PillGrid";
import Select from "components/form/Select";
import PageLoader from "components/PageLoader";
import DetailsCard from "components/DetailsCard";
import PlaceholderPage from "components/PlaceholderPage";
import { Status, Overview, DevelopmentTime } from "./components";
import DashboardContentLayout from "components/layouts/DashboardContentLayout";

import { useApp } from "context/app";
import { useDashboard } from "context/client/dashboard";

import useResponsive from "libs/helpers/useResponsive";
import Uptime from "./components/Uptime";

const mainClass = "dashboard-content";

const status_view_render = (status) => {
  switch (status) {
    case 0:
      return "payment_error";
    case 2:
      return "payment_error";
    case 3:
      return "verification";
    default:
      return null;
  }
};

const DashboardContent = () => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  const { user } = useApp();
  const {
    dashboard,
    uptime,
    websites,
    loading,
    uptime_period,
    selected_website,
    setSelectedWebsite,
    setUptimePeriod,
  } = useDashboard();

  const breadcrumps = [
    {
      label: t("Dashboard"),
    },
  ];

  const actions = [
    {
      label: t("Add new website"),
      href: "/websites/new",
      event: () =>
        window.gtag("event", "new_website", {
          click_text: t("Add new website"),
          click_url: `/websites/new`,
        }),
    },
    ...(websites?.length > 0
      ? [
          {
            type: "create_client_task",
            onboarding_button: true,
          },
        ]
      : []),
  ];

  const formatted_websites = websites?.map(({ _id, website }) => ({
    id: _id,
    label: website,
    onclick: () => setSelectedWebsite(_id),
    active: selected_website === _id,
  }));

  const status_view = status_view_render(dashboard?.website_data?.status);

  const tabs_data = [
    {
      label: t("Uptime"),
      // onclick: () => alert("ok"),
      active: true,
    },
  ];

  const period_select_data = [
    {
      label: "Dzisiaj",
      value: "day",
    },
    {
      label: "Tydzień",
      value: "week",
    },
    {
      label: "Miesiąc",
      value: "month",
    },
  ];

  const DetailsCards = () => (
    <>
      <DetailsCard title={t("Status")} view={status_view}>
        <Status status={dashboard?.website_data?.status} />
      </DetailsCard>
      <DetailsCard title={t("Overview")}>
        <Overview data={dashboard?.website_data} uptime={uptime} />
      </DetailsCard>
      <DetailsCard
        title={t("Plan")}
        view={
          dashboard?.website_data?.subscription?.status === "active" &&
          !!!dashboard?.website_data?.subscription?.next_payment
            ? "payment_error"
            : null
        }
      >
        <Plan
          webiste_id={dashboard?.website_data?._id}
          data={dashboard?.website_data?.subscription}
        />
      </DetailsCard>
      <DetailsCard title={t("Development time in current month")}>
        <DevelopmentTime data={dashboard?.website_data?.time_used} />
      </DetailsCard>
      <DetailsCard
        title={t("Uptime monitoring")}
        tabs={tabs_data}
        aside={
          <Select
            options={period_select_data}
            value={uptime_period}
            onChange={(e) => setUptimePeriod(e?.target?.value)}
          />
        }
      >
        <Uptime data={uptime} period={uptime_period} />
      </DetailsCard>
    </>
  );

  // const DetailsCardsBottom = () => (
  //   <>
  //     <DetailsCard title={t("Development time in current month")}>
  //       <DevelopmentTime data={dashboard?.website_data?.time_used} />
  //     </DetailsCard>
  //   </>
  // );

  // const slider_count = DetailsCards()?.props?.children?.length;

  return (
    <DashboardContentLayout
      header_label={`${t("Welcome back")}, ${user?.first_name}`}
      breadcrumps={breadcrumps}
      actions={actions}
    >
      {websites?.length > 0 ? (
        <div className={mainClass}>
          {!!isMobile && websites?.length > 1 ? (
            <Select
              options={formatted_websites?.map((item) => ({
                label: item?.label,
                value: item?.id,
              }))}
              value={selected_website}
              onChange={(e) => setSelectedWebsite(e?.target?.value)}
            />
          ) : (
            <PillGrid header={`${t("Websites")}:`} items={formatted_websites} />
          )}
          {!!!loading ? (
            <>
              <div className={`${mainClass}__row`}>
                <DetailsCards />
              </div>
              {/* <div className={`${mainClass}__row`}>
                <DetailsCardsBottom />
              </div> */}
            </>
          ) : (
            <PageLoader variant="panel" />
          )}
        </div>
      ) : (
        <PlaceholderPage
          title={t("You have not added any websites yet")}
          text={t(
            "To purchase a plan for your website click the button below."
          )}
          button={{
            label: t("Choose a plan"),
            href: "/websites/new",
          }}
          event={() =>
            window.gtag("event", "new_website", {
              click_text: t("Choose a plan"),
              click_url: `/websites/new`,
            })
          }
        />
      )}
    </DashboardContentLayout>
  );
};

export default DashboardContent;
