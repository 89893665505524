import "./styles.scss";

import moment from "moment";
import { useTranslation } from "react-i18next";

import Modal from "components/Modal";

import { useWebsite } from "context/client/website";

const mainClass = "cancel-plan-modal";

const CancelPlanModal = ({ subscription_end, onClose }) => {
  const { t } = useTranslation();
  const { customer_cancel_plan_loading, customerPortal } = useWebsite();

  const onSubmit = async () => {
    try {
      customerPortal("cancel-plan");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Modal
      title={t("Are you done using Webinity?")}
      onClose={onClose}
      onSubmit={onSubmit}
      loading={customer_cancel_plan_loading}
      variant="standard"
      submit_button_label={t("Finish cancellation")}
      close_button_label={t("Go back")}
    >
      <div className={mainClass}>
        <h2>
          {t(
            "If you're sure you'd like to cancel your subscription, finish below"
          )}
        </h2>
        <ul>
          <li>
            {t(
              "Cancellation will be effective at the end of your current billing cycle on"
            )}{" "}
            {moment.unix(subscription_end)?.format("ll")}
          </li>
          <li>{t("Come back anytime you like")}</li>
        </ul>
      </div>
    </Modal>
  );
};

export default CancelPlanModal;
