import { createContext, useContext, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import queryString from "query-string";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import api from "libs/api";

const WebmastersContext = createContext();

const WebmastersProvider = ({ children }) => {
  const { t } = useTranslation();

  const { search } = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [init_loading, setInitLoading] = useState(true);

  const filters = queryString.parse(search);
  const default_sort = "urgent_count:desc";

  const { isLoading: loading, data: webmasters } = useQuery(
    ["webmasters", filters],
    () =>
      api.get(
        `/admin/webmasters?${queryString.stringify({
          ...filters,
          sort: filters?.sort || default_sort,
        })}`
      ),
    {
      onSuccess: () => {
        setInitLoading(false);
      },
    }
  );

  const createAccount = (values) =>
    new Promise(async (resolve, reject) => {
      try {
        const webmaster = await api.post(`/admin/webmasters`, values);

        queryClient.setQueryData(["webmasters", filters], (prev) => [
          ...prev,
          {
            _id: webmaster?._id,
            first_name: values?.first_name,
            last_name: values?.last_name,
            normal_count: 0,
            high_count: 0,
            urgent_count: 0,
          },
        ]);

        toast.success(t("Saved successfully."));
        resolve();
      } catch (err) {
        console.error(err);
        toast.error("Something went wrong.");
        reject(err);
      }
    });

  const filterHandler = (name, value) => {
    navigate(
      `/webmasters?${queryString.stringify({
        ...filters,
        [name]: value || undefined,
      })}`
    );
  };

  const value = useMemo(() => {
    return {
      data: webmasters,
      loading,
      init_loading,
      filters,
      filterHandler,
      createAccount,
    };
    // eslint-disable-next-line
  }, [webmasters, filters, loading, init_loading]);

  return (
    <WebmastersContext.Provider value={value}>
      {children}
    </WebmastersContext.Provider>
  );
};

const useWebmasters = () => useContext(WebmastersContext);
export { WebmastersContext, useWebmasters };
export default WebmastersProvider;
