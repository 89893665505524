import "./styles.scss";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import { useApp } from "context/app";
import { useNewWebsite } from "context/client/new_website";

import { ReactComponent as ArrowIcon } from "assets/icons/long-arrow.svg";

const mainClass = "new-website-content__choose-plan__change-info";

const ChangeInfo = () => {
  const { t } = useTranslation();

  const { plans_list } = useApp();
  const { website } = useNewWebsite();
  const { values } = useFormikContext();

  let current_plan = null;
  plans_list.forEach((item) => {
    item?.prices?.forEach((price) => {
      if (price.id === website?.subscription?.price_id) {
        current_plan = { ...item, ...price };
      }
    });
  });

  let selected_plan = null;
  plans_list.forEach((item) => {
    item?.prices?.forEach((price) => {
      if (price.id === values?.plan) {
        selected_plan = { ...item, ...price };
      }
    });
  });

  return (
    <div className={mainClass}>
      <h3>{t("Subscription plan")}</h3>
      <div className={`${mainClass}__content`}>
        <div className={`${mainClass}__content__plans`}>
          <div className={`${mainClass}__content__plans__item`}>
            <label>{t("Current plan")}</label>
            <span>{current_plan?.name}</span>
            <small>{`${t("Billed monthly")}`}</small>
            <a
              href={t("terms_of_use_link")}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("Terms of Use")}
            </a>
          </div>
          <div className={`${mainClass}__content__plans__item--arrow`}>
            <ArrowIcon />
          </div>
          <div className={`${mainClass}__content__plans__item`}>
            <label>{t("New plan")}</label>
            <span>{selected_plan?.name}</span>
            <small>{`${t("Billed monthly")}`}</small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeInfo;
