import "./styles.scss";

import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import MessageEditor from "../MessageEditor";
import Attachments from "./components/Attachments";

import PlaceholderAvatarImage from "assets/images/avatar-placeholder.png";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import useResponsive from "libs/helpers/useResponsive";

const mainClass = "task-conversation__message";

const UserMessage = ({
  id,
  avatar,
  name,
  type,
  date,
  message,
  attachments = [],
  me,
  onEditSubmit,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  const [edit_mode, setEditMode] = useState(false);

  const onSubmit = async (content) => {
    try {
      await onEditSubmit(id, content);
    } catch (err) {
      console.error(err);
    }
  };

  const user_type_render = (type) => {
    switch (type) {
      case "client":
        return `(${t("Client")})`;
      case "webmaster":
        return "(Webmaster)";
      case "admin":
        return "(Admin)";
      default:
        return null;
    }
  };

  return (
    <div className={mainClass}>
      <div className={`${mainClass}__header`}>
        <div className={`${mainClass}__header__user`}>
          <div className={`${mainClass}__header__user__avatar`}>
            <img src={avatar || PlaceholderAvatarImage} alt="" />
          </div>
          <span>
            {name} {user_type_render(type)}
          </span>
        </div>
        <div className={`${mainClass}__header__meta`}>
          <div className={`${mainClass}__header__meta__actions`}>
            {!!me && (
              <button onClick={() => setEditMode(true)}>
                <EditIcon /> {t("Edit comment")}
              </button>
            )}
          </div>
          <span className={`${!!me ? `${mainClass}__header__meta--date` : ""}`}>
            {moment(date)?.format("lll")}
          </span>
        </div>
      </div>
      <div className={`${mainClass}__content`}>
        {!!isMobile && <span>{moment(date)?.format("lll")}</span>}
        {!!edit_mode && !!me ? (
          <MessageEditor
            content={message}
            setEditMode={setEditMode}
            onSubmit={onSubmit}
          />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: message }} />
        )}
        {attachments?.length > 0 && <Attachments attachments={attachments} />}
      </div>
    </div>
  );
};

export default UserMessage;
