import DashboardContent from "page_components/client/dashboard/Content";
import DashboardProvider from "context/client/dashboard";

const Dashboard = () => (
  <DashboardProvider>
    <DashboardContent />
  </DashboardProvider>
);

export default Dashboard;
