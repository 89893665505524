import "./styles.scss";

import { useState } from "react";
import { toast } from "react-toastify";

import Modal from "components/Modal";
import { useTask } from "context/client/task";

const CancelTaskModal = ({ onClose }) => {
  const { data, updateTask } = useTask();
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    try {
      setLoading(true);

      await updateTask({ status: 7 });
      onClose();
      toast.error("The offer was rejected.");
    } catch (err) {
      console.error(err);
    }

    setLoading(false);
  };

  return (
    <Modal
      title="Cancel order"
      onClose={onClose}
      onSubmit={onSubmit}
      submit_button_label="Yes, close task"
      loading={!!loading}
      variant="little-narrow"
      mode="warning"
    >
      <p>
        Are you sure you would like to cancel the task{" "}
        <strong>{data?.title}</strong>?
        <br />
        <br />
        This action cannot be reversed. Before deleting the task please make
        sure it doesn't contain any important information.
      </p>
    </Modal>
  );
};

export default CancelTaskModal;
