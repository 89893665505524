import { Link, useLocation } from "react-router-dom";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import queryString from "query-string";

import Button from "components/Button";
import FormikInput from "components/formik/FormikInput";
import PasswordValidator from "components/PasswordValidator";

import registerSchema from "libs/validations/registerSchema";
import api from "libs/api";

const mainClass = "auth-content";

const RegisterContent = ({ setEmailConfirmed }) => {
  const { search } = useLocation();
  const query = queryString.parse(search);

  const { t } = useTranslation();

  const onSubmit = async (values, setSubmitting, setErrors) => {
    try {
      let data_to_send = { ...values };

      if (query?.next) {
        data_to_send.next = query?.next;
      }
      if (query?.type === "partner") {
        data_to_send.partner = true;
      }

      await api.post("/auth/register", data_to_send);
      setEmailConfirmed(values?.email);
    } catch (err) {
      if (err?.response?.data?.type === "email_exist") {
        setErrors({ email: err?.response?.data?.error });
      }
      setSubmitting(false);
    }
  };

  return (
    <div className={mainClass}>
      <div className={`${mainClass}__wrapper`}>
        <div className={`${mainClass}__header`}>
          <h1>{t("Welcome!")}</h1>
          <span>
            {query?.type === "partner"
              ? t("Promote Webinity and build passive income")
              : t("Start managing your website faster and better")}
          </span>
        </div>
        <Formik
          initialValues={{
            first_name: "",
            last_name: "",
            email: "",
            password: "",
            password2: "",
          }}
          validationSchema={registerSchema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            onSubmit(values, setSubmitting, setErrors);
          }}
        >
          {({ values, isSubmitting }) => (
            <Form className={`${mainClass}__form`} autoComplete="off">
              <div className={`${mainClass}__form__inputs`}>
                <div className={`${mainClass}__form__group`}>
                  <FormikInput
                    name="first_name"
                    label={t("Name")}
                    disabled={isSubmitting}
                    required
                  />
                  <FormikInput
                    name="last_name"
                    label={t("Surname")}
                    disabled={isSubmitting}
                    required
                  />
                </div>
                <FormikInput
                  name="email"
                  type="email"
                  label={t("Email")}
                  disabled={isSubmitting}
                  required
                />
                <FormikInput
                  name="password"
                  label={t("Password")}
                  type="password"
                  disabled={isSubmitting}
                  required
                />
                <FormikInput
                  name="password2"
                  label={t("Repeat password")}
                  type="password"
                  disabled={isSubmitting}
                  required
                />
              </div>
              <PasswordValidator password={values?.password} />
              <div className={`${mainClass}__form__required-info`}>
                <small>
                  <span>*</span> {t("required field")}
                </small>
              </div>
              <div className={`${mainClass}__form__actions`}>
                <Button type="submit" loading={!!isSubmitting} fullWidth>
                  {t("Sign up")}
                </Button>
                <span>
                  {t("Do you have an account?")}{" "}
                  <Link to="/login">{t("Sign in")}</Link>
                </span>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default RegisterContent;
