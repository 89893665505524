const NotificationRender = (template, context, t) => {
  switch (template) {
    case 1:
      return t("A new invoice is available");
    case 2:
      return t(`Card payment was declined`);
    case 3:
      return t(`Payment method expires in 30 days`);
    case 4:
      return t(`Payment method expires in 14 days`);
    case 5:
      return t(`Payment method expires in 7 days`);
    case 6:
      return t(`Payment method expires in 3 days`);
    case 7:
      return t(`Payment method expires in 2 days`);
    case 8:
      return t(`Payment method expires in 1 day`);
    case 9:
      return t(`Content Management System update completed`);
    case 10:
      return t(`SSL Certificate expires in 7 days`);
    case 11:
      return t(`SSL Certificate expires in 1 day`);
    case 12:
      return t(`Domain expires in 7 days`);
    case 13:
      return t(`Domain expires in 1 day`);
    case 14:
      return t(`Finish Your Webinity Subscription Purchase`);
    case 15:
      return t(`Your subscription has been successfully cancelled`);
    case 16:
      return t(`Subscription has been cancelled`);
    case 17:
      return t(`You have created a new task`);
    case 18:
      return t(`Client created a new task`);
    case 19:
      return t(`New comment`);
    case 20:
      return `${t("Status of the task")} ${context?.task_name} ${t(
        "has been changed to"
      )} ${t(context?.status)}`;
    case 21:
      return t(`Task assigned to you`);
    case 23:
      return t(`Development time available in your plan has been exceeded`);
    case 24:
      return t(`New subscription has been successfully purchased`);
    case 25:
      return t(`Your password has been successfully reset`);
    case 26:
      return t(`Your subscription plan has been changed`);
    case 27:
      return t(`Subscription plan has been changed`);
    case 28:
      return t(`New user registered`);
    default:
      return "";
  }
};

export default NotificationRender;
