import "./styles.scss";
import "react-image-lightbox/style.css";

import { useState } from "react";
import Lightbox from "react-image-lightbox";

const mainClass = "task-conversation__message__content__attachments";

function isImage(url) {
  const imageExtensions = ["jpg", "jpeg", "png", "gif"];

  const extension = url.split(".").pop().toLowerCase();
  if (imageExtensions.includes(extension)) {
    return true;
  } else {
    return false;
  }
}

const Attachments = ({ attachments }) => {
  const [lightbox_open, setLightboxOpen] = useState(null);

  let formatted_attachments = attachments?.map((item) => ({
    ...item,
    is_image: isImage(item?.location),
  }));

  let attachments_photos = formatted_attachments?.filter(
    (item) => item?.is_image
  );
  let attachments_files = formatted_attachments?.filter(
    (item) => !!!item?.is_image
  );

  return (
    <div className={mainClass}>
      {attachments_photos?.length > 0 && (
        <div className={`${mainClass}__gallery`}>
          {attachments_photos?.map(({ _id, location }) => (
            <div
              key={_id}
              className={`${mainClass}__gallery__item`}
              onClick={() => setLightboxOpen(location)}
            >
              <img src={location} alt="" />
            </div>
          ))}
        </div>
      )}

      {attachments_files?.length > 0 && (
        <div className={`${mainClass}__links`}>
          {attachments_files?.map(({ _id, name, location }) => (
            <a
              key={_id}
              target="_blank"
              rel="noopener noreferrer"
              href={location}
            >
              {name}
            </a>
          ))}
        </div>
      )}

      {!!lightbox_open && (
        <Lightbox
          mainSrc={lightbox_open}
          onCloseRequest={() => setLightboxOpen(null)}
        />
      )}
    </div>
  );
};

export default Attachments;
