import { useField } from "formik";
import PhoneInput from "components/form/PhoneInput";

const FormikPhoneInput = ({ type, name, ...rest }) => {
  const [field, meta] = useField({ name, type });

  return <PhoneInput type={type} {...rest} {...field} {...meta} />;
};

export default FormikPhoneInput;
