import { createContext, useContext, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import queryString from "query-string";

import api from "libs/api";
import task_status_data from "libs/task_status_data.json";
import task_priority_data from "libs/task_priority_data.json";

const TasksContext = createContext();

const TasksProvider = ({ children }) => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const filters = queryString.parse(search);
  const default_sort = "due_date:desc";

  const [init_loading, setInitLoading] = useState(true);

  const {
    isLoading: tasks_loading,
    data,
    refetch: refetchTasks,
  } = useQuery(
    ["webmaster-tasks", filters],
    () =>
      api.get(
        `/webmaster/tasks?${queryString.stringify({
          ...filters,
          type:
            task_priority_data?.find((item) => item?.key === filters?.type)
              ?.value || undefined,
          status:
            task_status_data?.find((item) => item?.key === filters?.status)
              ?.value || undefined,
          sort: filters?.sort || default_sort,
        })}`
      ),
    {
      onSuccess: () => {
        setInitLoading(false);
      },
    }
  );

  const { isLoading: websites_loading, data: websites } = useQuery(
    ["webmaster-websites"],
    () => api.get(`/webmaster/websites`)
  );

  const filterHandler = (name, value) => {
    navigate(
      `/tasks?${queryString.stringify({
        ...filters,
        [name]: value || undefined,
      })}`
    );
  };

  const loading = !!tasks_loading || !!websites_loading;

  const value = useMemo(() => {
    return {
      data,
      websites: websites || [],
      loading,
      init_loading,
      filters,
      filterHandler,
      refetchTasks,
    };
    // eslint-disable-next-line
  }, [data, loading, init_loading, filters, websites]);

  return (
    <TasksContext.Provider value={value}>{children}</TasksContext.Provider>
  );
};

const useTasks = () => useContext(TasksContext);
export { TasksContext, useTasks };
export default TasksProvider;
