import "./styles.scss";

import { useTranslation } from "react-i18next";

import Modal from "components/Modal";

const mainClass = "custom-notification-modal";

const CustomNotificationModal = ({ text, onClose }) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal
        onClose={onClose}
        submit_button_label="Send"
        hide_submit_button
        title={t("Message")}
        // variant="extra-large"
      >
        <div className={mainClass}>
          <p>{text}</p>
        </div>
      </Modal>
    </>
  );
};

export default CustomNotificationModal;
