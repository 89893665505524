import "./styles.scss";

import classnames from "classnames";

import task_priority_data from "libs/task_priority_data.json";

import { ReactComponent as PriorityIcon } from "assets/icons/priority-flag.svg";

const mainClass = "priority-flag";

const PriorityFlag = ({ priority }) => {
  return (
    <div
      className={classnames(
        mainClass,
        `${mainClass}--${
          task_priority_data?.find((item) => item?.value === priority)?.key
        }`
      )}
    >
      <PriorityIcon />
    </div>
  );
};

export default PriorityFlag;
