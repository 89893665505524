import "./styles.scss";

import classnames from "classnames";
import { Link } from "react-router-dom";

import Button from "components/Button";
import Tooltip from "components/ToolTip";
import DetailsCardCard from "./components/Card";
import DetailsCardStatus from "./components/Status";
import DetailsCardSwitch from "./components/Switch";
import DetailsCardAvatar from "./components/Avatar";
import PaymentError from "./components/PaymentError";
import DetailsCardLanguage from "./components/Language";
import DetailsCardWebmaster from "./components/Webmaster";
import ChangePlanPreview from "./components/ChangePlanPreview";
import DetailsCardWebmasterTime from "./components/WebmasterTime";

import { ReactComponent as InformationIcon } from "assets/icons/information.svg";

const mainClass = "details-card";

const details_value_type = (value, type, onclick) => {
  switch (type) {
    case "status":
      return <DetailsCardStatus value={value?.status} />;
    case "card":
      return <DetailsCardCard value={value} />;
    case "switch":
      return <DetailsCardSwitch onClick={onclick} value={value} />;
    case "change_plan_preview":
      return <ChangePlanPreview value={value} />;
    case "button":
      return (
        <Button
          size="small"
          variant={value?.variant || "transparent"}
          href={value?.button_href}
          external_href={value?.external_href}
        >
          {value?.button_label}
        </Button>
      );
    case "link":
      return <Link to={value?.link_href}>{value?.link_label}</Link>;
    case "link_external":
      return (
        <a target="_blank" rel="noopener noreferrer" href={value?.link_href}>
          {value?.link_label}
        </a>
      );
    case "avatar":
      return <DetailsCardAvatar value={value} />;
    case "webmaster":
      return !!value?.name ? <DetailsCardWebmaster value={value} /> : null;
    case "webmaster_time":
      return <DetailsCardWebmasterTime value={value} onClick={onclick} />;
    case "language":
      return <DetailsCardLanguage value={value} />;
    default:
      return value;
  }
};

const DetailsCardList = ({ items, view }) => {
  return view === "payment_error" ? (
    <PaymentError />
  ) : items?.length > 0 ? (
    <div className={`${mainClass}__list`}>
      {items?.map(
        ({ label, value, type, tooltip, onclick, active = "true" }, index) => {
          if (!!!active) return null;

          return (
            <div
              key={index}
              className={classnames(`${mainClass}__list__item`, {
                "extra-padding": type === "switch",
                "full-width": type === "switch",
                "no-value": !!!value && value,
              })}
            >
              {!!label && <label>{label}</label>}
              <span
                className={classnames({
                  "flex-end": type === "switch",
                })}
              >
                {details_value_type(value, type, onclick)}
                {!!tooltip && (
                  <Tooltip text={tooltip}>
                    <InformationIcon />
                  </Tooltip>
                )}
              </span>
            </div>
          );
        }
      )}
    </div>
  ) : null;
};

export default DetailsCardList;
