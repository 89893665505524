import "./styles.scss";

import { useEffect } from "react";
import classnames from "classnames";
import Flag from "react-world-flags";
import { useTranslation } from "react-i18next";

import FormikSelect from "components/formik/FormikSelect";

import countries from "libs/countries.json";

const mainClass = "phone-input";

const PhoneInput = ({
  type = "text",
  label,
  placeholder,
  name,
  touched,
  error,
  value,
  onChange,
  disabled,
  uppercaseLabel = false,
  maxLength,
  required,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    const handleResize = () => {
      const phone_input = document.getElementsByClassName("phone-input")?.[0];
      const select = phone_input.getElementsByClassName("select")?.[0];
      const input =
        phone_input.getElementsByClassName("phone-input__field")?.[0];

      input.style.paddingLeft = `${select.offsetWidth + 10}px`;
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const is_error = touched && (!!error?.number || !!error?.code);

  const select_options = countries?.map(({ dialling_code, code }) => ({
    icon: <Flag code={code} />,
    label: dialling_code,
    value: code,
  }));

  return (
    <div
      className={classnames(mainClass, {
        [`${mainClass}--error`]: !!is_error,
        [`${mainClass}--uppercase-label`]: !!uppercaseLabel,
      })}
    >
      <label>
        {label}
        {!!required && <span>*</span>}
      </label>
      <div className={`${mainClass}__content`}>
        <input
          className={`${mainClass}__field`}
          type={type}
          placeholder={placeholder}
          value={value?.number}
          name={`${name}.number`}
          onChange={onChange}
          disabled={disabled}
          maxLength={maxLength}
        />
        <FormikSelect
          options={select_options}
          selected={value?.code}
          disabled={disabled}
          searcher={true}
          required
          name={`${name}.code`}
          placeholder={t("Code")}
          hideError
        />
      </div>
      {!!is_error && (
        <small className={`${mainClass}__error`}>
          {!!error?.number ? t(error?.number) : t(error?.code)}
        </small>
      )}
    </div>
  );
};

export default PhoneInput;
