import TimeOverContent from "page_components/shared/time-over/Content";
import DashboardContentLayout from "components/layouts/DashboardContentLayout";

import { useApp } from "context/app";
import TaskTimeOverProvider, {
  useTaskTimeOver,
} from "context/admin/task_time_over";

const TaskTimeOverContent = () => {
  const { user } = useApp();
  const { data, loading } = useTaskTimeOver();

  const breadcrumps = [
    {
      label: "Tasks",
      href: "/tasks",
    },
    {
      label: data?.website,
      href:
        user?.type === "client"
          ? `/websites/${data?.website_id}`
          : `/clients/${data?.website_id}`,
    },
    {
      label: data?.title,
      href: `/tasks/${data?._id}`,
    },
    {
      label: "Time exceeding monthly plan",
    },
  ];

  return (
    <DashboardContentLayout
      header_label="Time exceeding monthly plan"
      breadcrumps={breadcrumps}
      loading={loading}
    >
      <TimeOverContent data={data} />
    </DashboardContentLayout>
  );
};

const TaskTimeOver = () => (
  <TaskTimeOverProvider>
    <TaskTimeOverContent />
  </TaskTimeOverProvider>
);

export default TaskTimeOver;
