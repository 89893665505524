import "./styles.scss";

import { useTranslation } from "react-i18next";

import Table from "components/Table";
import DashboardContentLayout from "components/layouts/DashboardContentLayout";

import countries from "libs/countries.json";

import { useUsers } from "context/admin/users";

const mainClass = "users-content";

const actions = [
  {
    type: "send_notification",
  },
];

const UsersContent = () => {
  const { t } = useTranslation();

  const { data, loading, init_loading, filters, filterHandler } = useUsers();

  const breadcrumps = [
    {
      label: t("Registered users"),
    },
  ];

  const table_data = {
    keys: [
      {
        sort_key: "first_name",
        key: "first_name",
        value: t("Name and surname"),
      },
      {
        key: "email",
        value: t("Email"),
      },
      {
        key: "phone",
        value: t("Phone"),
      },
      {
        sort_key: "websites",
        key: "website",
        value: t("Website"),
      },
      {
        sort_key: "type",
        key: "account_type",
        value: t("Account type"),
      },
    ],
    items: data?.map(
      ({ first_name, last_name, email, phone, websites, type }) => [
        {
          key: "first_name",
          value: `${first_name} ${last_name}`,
        },
        {
          key: "email",
          value: email,
        },
        {
          key: "phone",
          value: !!phone?.number
            ? `${
                countries?.find((item) => item?.code === phone?.code)
                  ?.dialling_code || ""
              } ${phone?.number}`
            : "-",
        },
        {
          key: "website",
          type: "website_items",
          value: websites?.map((item) => ({
            label: item?.website,
            href: `/clients/${item?._id}`,
          })),
        },
        {
          key: "account_type",
          value: t(type),
        },
      ]
    ),
  };

  return (
    <DashboardContentLayout
      header_label={t("Registered users")}
      loading={init_loading}
      breadcrumps={breadcrumps}
      actions={actions}
    >
      <div className={mainClass}>
        <Table
          data={table_data}
          loading={!!loading}
          sort={filters?.sort}
          setSort={(value) => filterHandler("sort", value)}
        />
      </div>
    </DashboardContentLayout>
  );
};

export default UsersContent;
