import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { createContext, useContext, useMemo } from "react";

import api from "libs/api";

const WebsiteAccessDataContext = createContext();

const WebsiteAccessDataProvider = ({ children }) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const { isLoading: loading, data } = useQuery(
    ["websites-access-data", id],
    () => api.get(`/client/websites/${id}/access-data`)
  );

  const updateData = (values) =>
    new Promise(async (resolve, reject) => {
      try {
        await api.put(`/client/websites/${id}/access-data`, values);

        toast.success(t("Saved successfully."));
        resolve();
      } catch (err) {
        toast.error("Something went wrong.");
        reject(err);
      }
    });

  const value = useMemo(() => {
    return {
      data,
      loading,
      website_id: id,
      updateData,
    };
    // eslint-disable-next-line
  }, [data, id, loading]);

  return (
    <WebsiteAccessDataContext.Provider value={value}>
      {children}
    </WebsiteAccessDataContext.Provider>
  );
};

const useWebsiteAccessData = () => useContext(WebsiteAccessDataContext);
export { WebsiteAccessDataContext, useWebsiteAccessData };
export default WebsiteAccessDataProvider;
