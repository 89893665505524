import "./styles.scss";

const mainClass = "async-header";

const AsyncHeader = ({ text }) => {
  return (
    <div className={mainClass}>
      {!!text ? (
        <h1>{text}</h1>
      ) : (
        <div className={`${mainClass}__skeleton`}></div>
      )}
    </div>
  );
};

export default AsyncHeader;
