import "./styles.scss";

import Select from "../Select";
import IconButton from "components/IconButton";

import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";

const mainClass = "webmaster-select";

const WebmasterSelect = ({
  value,
  options = [],
  onChange,
  error,
  touched,
  multi = true,
}) => {
  const onChangeHandler = (e) => {
    if (!!multi) {
      onChange("webmaster", [...value, e?.target?.value]);
    } else {
      onChange("webmaster", [e?.target?.value]);
    }
  };

  const onDeleteHandler = (index) => {
    let temp_value = [...value];
    temp_value?.splice(index, 1);

    onChange("webmaster", [...temp_value]);
  };

  const formatted_webmasters = options?.filter(
    (item) => !value.includes(item?.value)
  );

  return (
    <div className={mainClass}>
      <Select
        label="Webmaster"
        options={formatted_webmasters}
        value={null}
        onChange={onChangeHandler}
        error={error}
        touched={Object.keys(touched).length !== 0}
        placeholder="Webmaster"
      />
      <div className={`${mainClass}__list`}>
        {value?.map((item, index) => {
          const selected_webmaster = options?.find(
            (element) => element?.value === item
          );

          return (
            <div key={index} className={`${mainClass}__list__item`}>
              <div className={`${mainClass}__list__item__name`}>
                {selected_webmaster?.label}
              </div>
              {!!multi && (
                <IconButton
                  size="small"
                  onClick={() => onDeleteHandler(index)}
                  icon={<TrashIcon />}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WebmasterSelect;
