import "./styles.scss";

import { useTranslation } from "react-i18next";

import { ReactComponent as EnglishIcon } from "assets/icons/flags/en.svg";
import { ReactComponent as PolishIcon } from "assets/icons/flags/pl.svg";

const mainClass = "details-card-language";

const DetailsCardLanguage = ({ value }) => {
  const { t } = useTranslation();

  const current_language_render = (lang) => {
    switch (lang) {
      case "pl":
        return {
          icon: <PolishIcon />,
          label: t("Polish"),
          value: "pl",
        };
      case "en":
        return {
          icon: <EnglishIcon />,
          label: t("English"),
          value: "en",
        };
      default:
        return null;
    }
  };

  const language = current_language_render(value);

  return (
    <div className={mainClass}>
      <div className={`${mainClass}__icon`}>{language?.icon}</div>
      <span>{language?.label}</span>
    </div>
  );
};

export default DetailsCardLanguage;
