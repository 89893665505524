import "./styles.scss";

import { useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "components/Button";
import PageLoader from "components/PageLoader";
import DetailsCard from "components/DetailsCard";
import RequestPayoutModal from "./components/RequestPayoutModal";
import DashboardContentLayout from "components/layouts/DashboardContentLayout";

import { useReferralProgram } from "context/referral-program";

import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import { ReactComponent as GrowIcon } from "assets/icons/referral/grow.svg";
import { ReactComponent as SearchIcon } from "assets/icons/referral/search.svg";
import { ReactComponent as LinkIcon } from "assets/icons/referral/link.svg";
import { ReactComponent as HistoryIcon } from "assets/icons/referral/history.svg";
import priceRender from "libs/helpers/priceRender";

const mainClass = "referral-programs__details";

const ReferralProgramsDetails = () => {
  const { t } = useTranslation();

  const { data, rewardfulLinkGenerate, link_loading, loading } =
    useReferralProgram();

  const [copied, setCopied] = useState(false);
  const [request_payout_modal, setRequestPayoutModal] = useState(false);

  const breadcrumps = [
    {
      label: t("Referral program"),
    },
  ];

  const dashboard_data = [
    {
      icon: <GrowIcon />,
      label: t("Check your conversion rates"),
    },
    {
      icon: <SearchIcon />,
      label: t("See more details about your leads"),
    },
    {
      icon: <LinkIcon />,
      label: t("Customize/edit your referral link"),
    },
    {
      icon: <HistoryIcon />,
      label: t("View history of your payouts"),
    },
  ];

  const copyHandler = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <DashboardContentLayout
      header_label={t("Referral program")}
      async_header
      loading={loading}
      breadcrumps={breadcrumps}
    >
      {!!!loading ? (
        <div className={mainClass}>
          <div className={`${mainClass}__wrapper`}>
            <div className={`${mainClass}__col`}>
              <DetailsCard title={t("Wallet")}>
                <div className={`${mainClass}__wallet`}>
                  <span>
                    {`${t(
                      "Your commission earned in the partnership program"
                    )}:`}
                  </span>
                  <h1>
                    {priceRender(
                      data?.commission_stats?.currencies?.USD?.unpaid?.cents?.toFixed(
                        2
                      ),
                      data?.commission_stats?.currencies?.USD?.unpaid?.currency_iso?.toLowerCase()
                    )}
                  </h1>
                </div>
              </DetailsCard>
            </div>
            <div className={`${mainClass}__col`}>
              <DetailsCard title={t("Referral link")}>
                <div className={`${mainClass}__referral-link`}>
                  <div className={`${mainClass}__referral-link__code`}>
                    <input type="text" value={data?.links?.[0]?.url} disabled />
                    <Button
                      variant={copied ? "success" : ""}
                      onClick={() => copyHandler(data?.links?.[0]?.url)}
                    >
                      <CopyIcon />
                    </Button>
                  </div>
                  <span>
                    {t(
                      "Your referral link. Recommend Webinity's services and build passive income with our partnership program."
                    )}
                  </span>
                </div>
              </DetailsCard>
            </div>
          </div>
          <div className={`${mainClass}__referral-dashboard`}>
            <DetailsCard title={t("Partner dashboard")} no_border>
              <div className={`${mainClass}__referral-dashboard__items`}>
                {dashboard_data?.map(({ icon, label }, index) => (
                  <div
                    key={index}
                    className={`${mainClass}__referral-dashboard__items__item`}
                  >
                    {icon}
                    <span>{label}</span>
                  </div>
                ))}
              </div>
            </DetailsCard>
            <div className={`${mainClass}__referral-dashboard__button`}>
              <Button onClick={rewardfulLinkGenerate} loading={!!link_loading}>
                {t("Open dashboard")}
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <PageLoader variant="panel" />
      )}
      {!!request_payout_modal && (
        <RequestPayoutModal onClose={() => setRequestPayoutModal(false)} />
      )}
    </DashboardContentLayout>
  );
};

export default ReferralProgramsDetails;
