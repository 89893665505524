import ReferralProgramsProvider from "context/referral-programs";
import ReferralProgramsContent from "page_components/shared/referral-programs/Content";

const ReferralPrograms = () => (
  <ReferralProgramsProvider>
    <ReferralProgramsContent />
  </ReferralProgramsProvider>
);

export default ReferralPrograms;
