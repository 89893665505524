import "./styles.scss";

import Modal from "components/Modal";

const mainClass = "faq-content-modal";

const FaqContentModal = ({ data, onClose, title }) => {
  return (
    <>
      <Modal
        onClose={onClose}
        submit_button_label="Send"
        hide_submit_button
        variant="standard"
        title={title}
      >
        <div className={mainClass}>
          {!!!data?.title && <small>{data?.section}</small>}
          <h3>{data?.title}</h3>
          <p dangerouslySetInnerHTML={{ __html: data?.content }} />
        </div>
      </Modal>
    </>
  );
};

export default FaqContentModal;
