import "./styles.scss";

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import Button from "components/Button";

const CreateClientTask = ({ onboarding }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <Button
      href={
        !!onboarding
          ? `/tasks/new?onboarding=true&referrer=${pathname}`
          : "/tasks/new"
      }
    >
      {t("Create new task")}
    </Button>
  );
};

export default CreateClientTask;
