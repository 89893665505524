import "./styles.scss";

import { useState } from "react";
import { useTranslation } from "react-i18next";

import Modal from "components/Modal";
import { useClientSettings } from "context/admin/client-settings";

const DeleteAccountModal = ({ id, name, email, onClose }) => {
  const { t } = useTranslation();

  const { deleteAccount } = useClientSettings();
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    try {
      setLoading(true);

      await deleteAccount(id);
      onClose();
    } catch (err) {
      console.error(err);
    }

    setLoading(false);
  };

  return (
    <Modal
      title={t("Delete account")}
      onClose={onClose}
      onSubmit={onSubmit}
      submit_button_label={t("Yes, delete account")}
      loading={!!loading}
      variant="little-narrow"
      mode="warning"
    >
      <p>
        {t("Are you sure you would like to delete the account of")}{" "}
        <strong>{name}</strong> {t("connected with")} <strong>{email}</strong>{" "}
        {t(`email address?`)}
        <br />
        <br />
        {t(
          `This action cannot be reversed. Before deleting the account you should remove all subscriptions for domains connected to it.`
        )}
      </p>
    </Modal>
  );
};

export default DeleteAccountModal;
