import "./styles.scss";

import Avatar from "./components/Avatar";
import IconButton from "components/IconButton";
import MobileMenu from "./components/MobileMenu";
import AsyncHeader from "./components/AsyncHeader";
import Notifications from "./components/Notifications";
import MobileHeaderMenu from "./components/MobileHeaderMenu";

import { useApp } from "context/app";

import { ReactComponent as HamburgerIcon } from "assets/icons/hamburger.svg";

const mainClass = "dashboard-layout-top";

const Top = ({ header_label, async_header, buttons }) => {
  const { mobile_sidebar_open, setMobileSidebarOpen } = useApp();

  return (
    <>
      <div className={mainClass}>
        <div className={`${mainClass}__wrapper`}>
          <div className={`${mainClass}__header`}>
            <IconButton
              onClick={() => setMobileSidebarOpen(true)}
              size="large"
              icon={<HamburgerIcon />}
            />
            {!!async_header ? (
              <AsyncHeader text={header_label} />
            ) : (
              <h1>{header_label}</h1>
            )}
            {!!header_label && (
              <div className={`${mainClass}__header__buttons`}>{buttons}</div>
            )}
          </div>
          <div className={`${mainClass}__aside`}>
            <div className={`${mainClass}__aside__buttons`}>
              <MobileHeaderMenu content={buttons} />
              <Notifications />
            </div>
            <Avatar />
          </div>
        </div>
      </div>
      {!!mobile_sidebar_open && <MobileMenu />}
    </>
  );
};

export default Top;
