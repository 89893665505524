import "./styles.scss";

import { useState } from "react";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";

import Button from "components/Button";
import AccessDataMobile from "./MobileContent";
import FormikInput from "components/formik/FormikInput";
import PlaceholderPage from "components/PlaceholderPage";
import FormikTextarea from "components/formik/FormikTextarea";
import DashboardContentLayout from "components/layouts/DashboardContentLayout";

import useResponsive from "libs/helpers/useResponsive";
import { useWebsiteAccessData } from "context/client/website_access_data";

const mainClass = "new-website-content__your-data";

const AccessDataContent = () => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  const { data, loading, website_id, updateData } = useWebsiteAccessData();
  const [confirmation, setConfirmation] = useState(false);
  const [mobile_save_index, setMobileSaveIndex] = useState(0);

  const onSubmit = async (values, setSubmitting) => {
    try {
      await updateData(values);
      if (!!!isMobile) {
        setConfirmation(true);
      }
      setMobileSaveIndex((prev) => prev + 1);
    } catch (err) {
      console.error(err);
      setMobileSaveIndex((prev) => prev + 1);
    }

    setSubmitting(false);
  };

  return (
    <DashboardContentLayout header_label={t("Access data")} loading={loading}>
      {!!confirmation ? (
        <div className={`${mainClass}__confirmation`}>
          <PlaceholderPage
            title={t("Your access data has been added successfully!")}
            text={t(
              "Thank you for sending us the access data to your website. We will verify if the data is correct and if we can carry out our services properly within the next 24 hours. In case the data is incorrect we will contact you directly."
            )}
            button={{
              label: t("Manage your website"),
              href: `/websites/${website_id}`,
            }}
          />
        </div>
      ) : (
        <Formik
          initialValues={{
            wordpress: {
              url: data?.wordpress?.url || "",
              login: data?.wordpress?.login || "",
              password: data?.wordpress?.password || "",
            },
            server: {
              host: data?.server?.host || "",
              username: data?.server?.username || "",
              password: data?.server?.password || "",
            },
            database: {
              name: data?.database?.name || "",
              username: data?.database?.username || "",
              password: data?.database?.password || "",
              host: data?.database?.host || "",
            },
            notes: data?.notes || "",
          }}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(values, setSubmitting);
          }}
        >
          {({ isSubmitting }) => (
            <Form className={mainClass} autoComplete="off">
              {!!isMobile ? (
                <AccessDataMobile index={mobile_save_index} />
              ) : (
                <>
                  <div className={`${mainClass}__wrapper`}>
                    <div className={`${mainClass}__form form`}>
                      <div className={`${mainClass}__form__header`}>
                        <h3>{t("CMS WordPress")}</h3>
                      </div>
                      <FormikInput
                        name="wordpress.url"
                        label={t("Login URL")}
                        disabled={isSubmitting}
                      />
                      <FormikInput
                        name="wordpress.login"
                        label={t("Login")}
                        disabled={isSubmitting}
                      />
                      <FormikInput
                        name="wordpress.password"
                        label={t("Password")}
                        disabled={isSubmitting}
                      />
                    </div>
                    <div className={`${mainClass}__form form`}>
                      <div className={`${mainClass}__form__header`}>
                        <h3>{t("Server / FTP")}</h3>
                      </div>
                      <FormikInput
                        name="server.host"
                        label={t("Host")}
                        disabled={isSubmitting}
                      />
                      <FormikInput
                        name="server.username"
                        label={t("Username")}
                        disabled={isSubmitting}
                      />
                      <FormikInput
                        name="server.password"
                        label={t("Password")}
                        disabled={isSubmitting}
                      />
                    </div>
                    <div className={`${mainClass}__form form`}>
                      <div className={`${mainClass}__form__header`}>
                        <h3>{t("Database")}</h3>
                      </div>
                      <FormikInput
                        name="database.name"
                        label={t("Database name")}
                        disabled={isSubmitting}
                      />
                      <FormikInput
                        name="database.username"
                        label={t("Username")}
                        disabled={isSubmitting}
                      />
                      <FormikInput
                        name="database.password"
                        label={t("Password")}
                        disabled={isSubmitting}
                      />
                      <FormikInput
                        name="database.host"
                        label={t("Host/URL")}
                        disabled={isSubmitting}
                      />
                    </div>
                  </div>
                  <div className={`${mainClass}__wrapper--extra`}>
                    <div className={`${mainClass}__form form`}>
                      <div className={`${mainClass}__form__header`}>
                        <h3>{t("Additional access data")}</h3>
                      </div>
                      <FormikTextarea
                        name="notes"
                        label={t("Github, Bitbucket etc.")}
                        placeholder={t(
                          "Type in any additional access data you would like to add."
                        )}
                      />
                    </div>
                    <div className={`${mainClass}__form form`}></div>
                  </div>

                  <div className={`${mainClass}__bottom`}>
                    <Button
                      variant="transparent"
                      href={`/websites/${website_id}`}
                    >
                      {t("Cancel")}
                    </Button>
                    <Button type="submit" loading={isSubmitting}>
                      {t("Save")}
                    </Button>
                  </div>
                </>
              )}
            </Form>
          )}
        </Formik>
      )}
    </DashboardContentLayout>
  );
};

export default AccessDataContent;
