import "./styles.scss";

import classnames from "classnames";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Button from "components/Button";

import { useApp } from "context/app";

const mainClass = "mobile-menu__websites";

const status_class_render = (status) => {
  switch (status) {
    case 1:
      return "good";
    case 2:
      return "wrong";
    case 3:
      return "verification";
    default:
      return {
        text: "-",
        class: null,
      };
  }
};

const WebsiteMenuMobile = () => {
  const { t } = useTranslation();
  const { websites, setMobileSidebarOpen } = useApp();

  return (
    <div className={mainClass}>
      <span>{t("Websites")}</span>
      <div className={`${mainClass}__items`}>
        {websites?.map((item, index) => {
          const status_class = status_class_render(item?.status);
          return (
            <NavLink
              key={index}
              to={`/websites/${item?._id}`}
              onClick={() => setMobileSidebarOpen(false)}
              className={({ isActive }) =>
                classnames(`${mainClass}__items__item`, {
                  [`${mainClass}__menu__list--active`]: !!isActive,
                  [status_class]: !!status_class,
                  disabled: item?.subscription?.status !== "active",
                })
              }
            >
              <span>{item?.website}</span>
            </NavLink>
          );
        })}
      </div>
      <Button
        fullWidth
        href="/websites/new"
        onClick={() => {
          setMobileSidebarOpen(false);
          window.gtag("event", "new_website", {
            click_text: t("Add new website"),
            click_url: `/websites/new`,
          });
        }}
      >
        {t("Add new website")}
      </Button>
    </div>
  );
};

export default WebsiteMenuMobile;
