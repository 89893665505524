import "./styles.scss";

import { useState } from "react";
import { useTranslation } from "react-i18next";

import Dropdown from "components/Dropdown";
import DropdownMenu from "components/DropdownMenu";

import { useApp } from "context/app";

import AvatarImage from "assets/images/avatar-placeholder.png";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { ReactComponent as LogoutIcon } from "assets/icons/logout.svg";

const mainClass = "avatar";

const Avatar = () => {
  const { t } = useTranslation();

  const { user, logout } = useApp();
  const [dropdown_key, setDropdownKey] = useState(0);

  const basics_menu_items = [
    {
      icon: <SettingsIcon />,
      label: t("Account settings"),
      href: "/settings",
    },
    {
      icon: <LogoutIcon />,
      label: t("Sign out"),
      action: logout,
    },
  ];

  return (
    <Dropdown
      // offset={-90}
      button={
        <div className={mainClass}>
          <div className={`${mainClass}__photo`}>
            <img src={user?.photo || AvatarImage} alt="" />
          </div>
          <span>
            {user?.first_name}
            <br />
            {user?.last_name}
          </span>
        </div>
      }
      onclose={dropdown_key}
    >
      <DropdownMenu
        items={basics_menu_items}
        variant="small"
        color="dark"
        icon_type="colorful"
        closeHandler={() => setDropdownKey((prev) => prev + 1)}
      />
    </Dropdown>
  );
};

export default Avatar;
