import "./styles.scss";

import { useState } from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import Pill from "components/Pill";
import Tooltip from "components/ToolTip";
import Dropdown from "components/Dropdown";
import DropdownCard from "components/DropdownCard";

import task_priority_data from "libs/task_priority_data.json";

import { ReactComponent as PriorityIcon } from "assets/icons/priority-flag.svg";

const mainClass = "task-conversation__details__priority-select";

const PrioritySelect = ({ priority, updateTask, readOnly }) => {
  const { t } = useTranslation();

  const [dropdown_key, setDropdownKey] = useState(0);

  const priority_key = task_priority_data?.find(
    (item) => item?.value === priority
  )?.key;

  const onClickHandler = (priority) => {
    updateTask({ priority });
    setDropdownKey((prev) => prev + 1);
  };

  return (
    <div style={!!readOnly ? { pointerEvents: "none" } : {}}>
      <Dropdown
        position={["bottom", "right"]}
        button={
          <Tooltip text={t("Change Priority")} variant="info">
            <button
              className={classnames(
                `${mainClass}__button`,
                `${mainClass}__button--${priority_key}`
              )}
            >
              <PriorityIcon />
            </button>
          </Tooltip>
        }
        onclose={dropdown_key}
        offset={8}
      >
        <DropdownCard position="right">
          <div className={mainClass}>
            {task_priority_data?.map(({ key, label, value }) => {
              if (value === priority) return null;

              return (
                <Pill
                  key={key}
                  variant={
                    task_priority_data?.find((item) => item?.value === value)
                      ?.key
                  }
                  size="small"
                  label={
                    <>
                      <PriorityIcon />
                      {t(label)}
                    </>
                  }
                  onclick={() => onClickHandler(value)}
                />
              );
            })}
          </div>
        </DropdownCard>
      </Dropdown>
    </div>
  );
};

export default PrioritySelect;
