import { createContext, useContext, useMemo, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import queryString from "query-string";

import task_status_data from "libs/task_status_data.json";
import task_priority_data from "libs/task_priority_data.json";

import api from "libs/api";

const WebmasterContext = createContext();

const WebmasterProvider = ({ children }) => {
  const { id } = useParams();
  const { search } = useLocation();
  const navigate = useNavigate();

  const filters = queryString.parse(search);

  const [init_loading, setInitLoading] = useState(true);

  const { isLoading: loading, data: webmaster } = useQuery(
    ["webmaster", id, filters],
    () =>
      api.get(
        `/admin/webmasters/${id}/tasks?${queryString.stringify({
          ...filters,
          type:
            task_priority_data?.find((item) => item?.key === filters?.type)
              ?.value || undefined,
          status: filters?.status
            ? task_status_data?.find((item) => item?.key === filters?.status)
                ?.value
            : undefined,
          sort: filters?.sort || "tasks",
        })}`
      ),
    {
      onSuccess: () => {
        setInitLoading(false);
      },
    }
  );

  const filterHandler = (name, value) => {
    navigate(
      `/webmasters/${id}?${queryString.stringify({
        ...filters,
        [name]: value || undefined,
      })}`
    );
  };

  const value = useMemo(() => {
    return {
      data: webmaster,
      webmaster_id: id,
      loading,
      init_loading,
      filters,
      filterHandler,
    };
    // eslint-disable-next-line
  }, [webmaster, loading, init_loading, filters]);

  return (
    <WebmasterContext.Provider value={value}>
      {children}
    </WebmasterContext.Provider>
  );
};

const useWebmaster = () => useContext(WebmasterContext);
export { WebmasterContext, useWebmaster };
export default WebmasterProvider;
