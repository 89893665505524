import "./styles.scss";

import Top from "./components/Top";
import Actions from "./components/Actions";
import PageLoader from "components/PageLoader";
import MobileBar from "./components/MobileBar";
import Breadcrumps from "components/Breadcrumps";
import AsyncHeader from "./components/Top/components/AsyncHeader";

const DashboardContentLayout = ({
  header_label,
  async_header,
  children,
  loading,
  buttons,
  breadcrumps,
  actions,
  no_padding,
  less_padding,
}) => {
  return (
    <div className="dashboard-layout__content">
      <Top
        async_header={async_header}
        header_label={header_label}
        buttons={buttons}
      />
      <div className="dashboard-layout__content__mobile-header">
        {!!async_header ? (
          <AsyncHeader text={header_label} />
        ) : (
          <h1>{header_label}</h1>
        )}
      </div>
      <div
        className={`dashboard-layout__content__wrapper ${
          (no_padding ? "no-padding" : "", less_padding ? "less-padding" : "")
        }`}
      >
        <div className="dashboard-layout__content__wrapper__breadcrumps">
          <Breadcrumps data={breadcrumps} extra_padding={!!less_padding} />
        </div>
        {!!actions && <Actions data={actions} />}
        {!!loading ? <PageLoader variant="panel" /> : children}
        <MobileBar />
      </div>
    </div>
  );
};

export default DashboardContentLayout;
