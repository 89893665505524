import { createContext, useContext, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import api from "libs/api";
import { useApp } from "context/app";

const DashboardContext = createContext();

const DashboardProvider = ({ children }) => {
  const { websites } = useApp();
  const [selected_website, setSelectedWebsite] = useState(
    websites?.[0]?._id || null
  );
  const [uptime_period, setUptimePeriod] = useState("day");

  const { isLoading: loading, data: dashboard } = useQuery(
    ["client_dashboard", selected_website],
    () => api.get(`/client/dashboard?website=${selected_website}`)
  );

  const { isLoading: uptime_loading, data: uptime } = useQuery(
    ["client_dashboard_uptime", selected_website, uptime_period],
    () =>
      api.get(
        `/client/dashboard/uptime?website=${selected_website}&period=${uptime_period}`
      )
  );

  const value = useMemo(() => {
    return {
      websites,
      uptime,
      dashboard,
      uptime_period,
      loading,
      uptime_loading,
      selected_website,
      setSelectedWebsite,
      setUptimePeriod,
    };
    // eslint-disable-next-line
  }, [
    dashboard,
    uptime,
    selected_website,
    uptime_period,
    loading,
    uptime_loading,
  ]);

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
};

const useDashboard = () => useContext(DashboardContext);
export { DashboardContext, useDashboard };
export default DashboardProvider;
