import { useTranslation } from "react-i18next";

import FaqContent from "page_components/client/faq/Content";
import DashboardContentLayout from "components/layouts/DashboardContentLayout";

import { useApp } from "context/app";
import { useState } from "react";

const Faq = () => {
  const { t } = useTranslation();

  const { user, websites } = useApp();
  const [mobile_header, setMobileHeader] = useState(null);

  const breadcrumps = [
    {
      label: t("FAQ"),
    },
  ];

  const actions = [
    {
      label: t("Add new website"),
      href: "/websites/new",
      event: () =>
        window.gtag("event", "new_website", {
          click_text: t("Add new website"),
          click_url: `/websites/new`,
        }),
    },
    ...(websites?.length > 0
      ? [
          {
            type: "create_client_task",
            onboarding_button: true,
          },
        ]
      : []),
  ];

  return (
    <DashboardContentLayout
      header_label={`${t("FAQ")} ${mobile_header ? `- ${mobile_header}` : ""}`}
      actions={user?.type === "client" ? actions : null}
      breadcrumps={breadcrumps}
    >
      <FaqContent setMobileHeader={setMobileHeader} />
    </DashboardContentLayout>
  );
};

export default Faq;
