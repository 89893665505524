import "./styles.scss";

import { useTranslation } from "react-i18next";
import MobileModalOptions from "components/MobileModalOptions";
import List from "../Details/components/List";
import Chat from "../Chat";
import Button from "components/Button";

const mainClass = "task-conversation__details-mobile";

const ConversationContentMobile = ({
  messages,
  onSubmit,
  editMessage,
  file_progress,
  last_updated,
  status,
  overview_data,
  action_buttons,
}) => {
  const { t } = useTranslation();

  const UploadedList = () => (
    <>
      {action_buttons?.length > 0 ? (
        <div title={t("Actions")}>
          <List data={overview_data} show_time_entries={false} />
          <div className={`${mainClass}__actions`}>
            {action_buttons?.map((item, index) => (
              <Button
                fullWidth
                key={index}
                variant={item?.variant}
                onClick={item?.onclick}
              >
                {item?.label}
              </Button>
            ))}
          </div>
        </div>
      ) : null}
      <div title={t("Overview")}>
        <List data={overview_data} show_time_entries={false} />
      </div>
      <div title={t("Conversation")} full_height={true}>
        <Chat
          messages={messages}
          onSubmit={onSubmit}
          editMessage={editMessage}
          file_progress={file_progress}
          last_updated={last_updated}
          status={status}
        />
      </div>
      <div title={t("Time tracked")}>
        <List
          data={overview_data}
          show_time_entries={true}
          only_time_tracked={true}
        />
      </div>
    </>
  );

  return (
    <div className={mainClass}>
      <MobileModalOptions
        components={UploadedList}
        // components={DetailsCards}
        // onClick={setSelectedTopic}
      />
    </div>
  );
};

export default ConversationContentMobile;
