import "./styles.scss";

import MobileModalOptions from "components/MobileModalOptions";
import { ReactComponent as CrossIcon } from "assets/icons/cross.svg";

const mainClass = "file-uploader__list-mobile";

const FileListMobile = ({ files, onDelete }) => {
  const UploadedList = () => (
    <div
      className="mobile-uploaded-files"
      title="List of files"
      sub_title={`${files?.length} files added`}
    >
      <div className="mobile-uploaded-files__content">
        {files?.map((item, index) => (
          <div key={index} className="mobile-uploaded-files__content__item">
            <span>{item?.name}</span>
            <button type="button" onClick={() => onDelete(index)}>
              <CrossIcon />
            </button>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className={mainClass}>
      <MobileModalOptions components={UploadedList} />
    </div>
  );
};

export default FileListMobile;
