import { createContext, useContext, useMemo, useState } from "react";
import api from "libs/api";

import { useQuery } from "@tanstack/react-query";

const ReferralProgramsContext = createContext();

const ReferralProgramsProvider = ({ children }) => {
  const [link_loading, setLinkLoading] = useState(false);

  const { isLoading: loading, data } = useQuery(["referral_programs"], () =>
    api.get(`/admin/referral`)
  );

  const rewardfulLinkGenerate = async (id) => {
    try {
      setLinkLoading(id);
      const data = await api.get(`/admin/referral/rewardful-link/${id}`);
      window.open(data?.sso?.url, "_blank");
    } catch (err) {
      console.error(err);
    }

    setLinkLoading(false);
  };

  const value = useMemo(() => {
    return {
      data,
      loading,
      init_loading: false,
      link_loading,
      rewardfulLinkGenerate,
    };
    // eslint-disable-next-line
  }, [loading, link_loading]);

  return (
    <ReferralProgramsContext.Provider value={value}>
      {children}
    </ReferralProgramsContext.Provider>
  );
};

const useReferralPrograms = () => useContext(ReferralProgramsContext);
export { ReferralProgramsContext, useReferralPrograms };
export default ReferralProgramsProvider;
