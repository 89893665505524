import "./styles.scss";

import { useState } from "react";

import Modal from "components/Modal";
import { useTask } from "context/admin/task";

const ChangeStatusModal = ({ onClose }) => {
  const { updateTask } = useTask();
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    try {
      setLoading(true);

      await updateTask({ status: 4 });
      onClose();
    } catch (err) {
      console.error(err);
    }

    setLoading(false);
  };

  return (
    <Modal
      title="Change status"
      onClose={onClose}
      onSubmit={onSubmit}
      submit_button_label="Yes, change status"
      loading={!!loading}
      variant="little-narrow"
    >
      <p>
        Are you sure you would like to change the status of this task from{" "}
        <strong>AWAITING ESTIMATION to IN PROGRESS</strong>?
        <br />
        <br />
        Notification about this change will be automatically sent to the Client
        and the task will be assigned to the webmaster.
      </p>
    </Modal>
  );
};

export default ChangeStatusModal;
