import "./styles.scss";

import React from "react";
import classnames from "classnames";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

const mainClass = "formik-mobile-radio";

const FormikMobileRadio = () => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext();

  const radio_data = [
    {
      label: t("Monthly"),
      sub_text: t("billed monthly"),
      value: "monthly",
    },
    {
      label: t("Yearly"),
      sub_text: t("billed monthly"),
      value: "yearly",
      discount: `10% ${t("discount")}`,
    },
  ];

  return (
    <div className={mainClass}>
      {radio_data?.map((item, index) => (
        <button
          key={index}
          type="button"
          className={classnames(`${mainClass}__item`, {
            [`${mainClass}__item--selected`]:
              values?.billed_type === item?.value,
          })}
          onClick={() => setFieldValue("billed_type", item?.value)}
        >
          <div className={`${mainClass}__item__text`}>
            <span>{item?.label}</span>
            <span>{item?.sub_text}</span>
            {!!item?.discount && <span>{item?.discount}</span>}
          </div>

          {/* <div className={`${mainClass}__item__radio`}></div> */}
        </button>
      ))}
    </div>
  );
};

export default FormikMobileRadio;
