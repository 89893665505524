import WebsiteProvider from "context/client/website";
import WebsitesContent from "page_components/client/websites/Content";

const Websites = () => (
  <WebsiteProvider>
    <WebsitesContent />
  </WebsiteProvider>
);

export default Websites;
