import "./styles.scss";

import { useState } from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import Modal from "components/Modal";
import { useWebmasterSettings } from "context/admin/webmaster-settings";

const mainClass = "account-role-modal";

const role_data = [
  {
    label: "Webmaster",
    value: "webmaster",
  },
  {
    label: "Admin",
    value: "admin",
  },
];

const AccountRoleModal = ({ onClose }) => {
  const { t } = useTranslation();

  const { data, updateSettings } = useWebmasterSettings();

  const [selected, setSelected] = useState(data?.type);
  const [confirmation_modal, setConfirmationModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    try {
      if (selected === data?.type) {
        onClose();
      } else {
        if (!!!confirmation_modal) {
          setConfirmationModal(true);
        } else {
          setLoading(true);

          await updateSettings({ type: selected });
          onClose();
        }
      }
    } catch (err) {
      console.error(err);
    }

    setLoading(false);
  };

  return (
    <Modal
      title={!!confirmation_modal ? t("Change role") : t("Account role")}
      onClose={
        !!confirmation_modal ? () => setConfirmationModal(false) : onClose
      }
      onSubmit={onSubmit}
      submit_button_label={!!confirmation_modal ? t("Yes, do it") : t("Save")}
      loading={loading}
      variant={!!confirmation_modal ? "little-narrow" : "narrow"}
    >
      {!!!confirmation_modal ? (
        <div className={mainClass}>
          {role_data?.map(({ label, value }, index) => (
            <button
              key={index}
              className={classnames(`${mainClass}__item`, {
                [`${mainClass}__item--active`]: selected === value,
              })}
              onClick={() => setSelected(value)}
            >
              {label}
            </button>
          ))}
        </div>
      ) : (
        <ConfirmationModal
          name={`${data?.first_name} ${data?.last_name}`}
          role_before={
            role_data?.find((item) => item?.value === data?.type)?.label
          }
          role_after={
            role_data?.find((item) => item?.value === selected)?.label
          }
        />
      )}
    </Modal>
  );
};

const ConfirmationModal = ({ name, role_before, role_after }) => {
  return (
    <div className={`${mainClass}__confirmation`}>
      <p>
        Are you sure you would like to change the role of the account of{" "}
        <strong>{name}</strong> from <strong>"{role_before}"</strong> to{" "}
        <strong>"{role_after}"</strong>?
        <br />
        <br />
        This action may affect data safety.
      </p>
    </div>
  );
};

export default AccountRoleModal;
