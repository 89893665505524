import "./styles.scss";

import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";

import Modal from "components/Modal";
import FormikInput from "components/formik/FormikInput";
import FormikPhoneInput from "components/formik/FormikPhoneInput";

import { useSettings } from "context/settings";
import contactDataSchema from "libs/validations/contactDataSchema";

const PersonalDataModal = ({ onClose }) => {
  const { t } = useTranslation();

  const { data, updateSettings } = useSettings();

  const onSubmit = async (values, setSubmitting, setErrors) => {
    try {
      await updateSettings(values?.contact_data);
      onClose();
    } catch (err) {
      if (err?.response?.data?.type === "email_exist") {
        setErrors({ contact_data: { email: err?.response?.data?.error } });
      }

      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        contact_data: {
          first_name: data?.first_name,
          last_name: data?.last_name,
          email: data?.email,
          phone: {
            code: data?.phone?.code,
            number: data?.phone?.number,
          },
        },
      }}
      validationSchema={contactDataSchema}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        onSubmit(values, setSubmitting, setErrors);
      }}
    >
      {({ isSubmitting, submitForm }) => (
        <Form autoComplete="off">
          <Modal
            title={t("Change personal data")}
            onClose={onClose}
            onSubmit={submitForm}
            loading={isSubmitting}
          >
            <div className="form">
              <FormikInput
                name="contact_data.first_name"
                label={t("Name")}
                placeholder="John"
                disabled={isSubmitting}
                required
              />
              <FormikInput
                name="contact_data.last_name"
                label={t("Surname")}
                placeholder="Doe"
                disabled={isSubmitting}
                required
              />
              <FormikInput
                name="contact_data.email"
                label={t("Email")}
                disabled={isSubmitting}
                required
                placeholder="john.doe@adream.com"
              />
              <FormikPhoneInput
                label={t("Phone")}
                name="contact_data.phone"
                placeholder="123 456 123"
              />
              <div className="form__required-info">
                <small>
                  <span>*</span> {t("required field")}
                </small>
              </div>
            </div>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default PersonalDataModal;
