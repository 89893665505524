import ClientsProvider from "context/admin/clients";
import ClientsContent from "page_components/admin/clients/Content";

const Clients = () => (
  <ClientsProvider>
    <ClientsContent />
  </ClientsProvider>
);

export default Clients;
