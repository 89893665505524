import { useLocation, useNavigate } from "react-router-dom";
import { createContext, useContext, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import queryString from "query-string";

import api from "libs/api";

const DashboardContext = createContext();

const DashboardProvider = ({ children }) => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const filters = queryString.parse(search);

  const [init_loading, setInitLoading] = useState(true);
  const [requiring_action_data, setRequiringActionData] = useState([]);

  const { isLoading: loading, data } = useQuery(
    ["admin_dashboard", filters],
    () =>
      api.get(
        `/admin/dashboard?${queryString.stringify({
          ...filters,
          sort: filters?.sort || "due_date",
        })}`
      ),
    {
      onSuccess: (data) => {
        setInitLoading(false);
        setRequiringActionData(data?.requiring_action_data);
      },
    }
  );

  const filterHandler = (name, value) => {
    navigate(
      `?${queryString.stringify({
        ...filters,
        [name]: value || undefined,
      })}`
    );
  };

  const value = useMemo(() => {
    return {
      data,
      loading,
      filters,
      filterHandler,
      requiring_action_data,
      init_loading,
    };
    // eslint-disable-next-line
  }, [data, loading, init_loading, filters]);

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
};

const useDashboard = () => useContext(DashboardContext);
export { DashboardContext, useDashboard };
export default DashboardProvider;
