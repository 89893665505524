import "./styles.scss";

import { Formik, Form } from "formik";

import Modal from "components/Modal";
import FormikInput from "components/formik/FormikInput";
import FormikSelect from "components/formik/FormikSelect";

import createTaskSchema from "libs/validations/admin/createTaskSchema";

import { useTasks } from "context/admin/tasks";

const CreateTaskModal = ({ onClose }) => {
  const { websites, createTask } = useTasks();

  const formatted_websites = websites?.map(({ _id, website }) => ({
    label: website,
    value: _id,
  }));

  const onSubmit = async (values, setSubmitting) => {
    try {
      await createTask(values);
      onClose();
    } catch (err) {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        title: "",
        website: "",
      }}
      validationSchema={createTaskSchema}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({ isSubmitting, submitForm }) => (
        <Form autoComplete="off">
          <Modal
            title="Create task"
            onClose={onClose}
            onSubmit={submitForm}
            loading={isSubmitting}
            submit_button_label="Create"
          >
            <div className="form">
              <FormikInput
                name="title"
                label="Title"
                disabled={isSubmitting}
                required
              />
              <FormikSelect
                name="website"
                label="Website"
                options={formatted_websites}
                disabled={isSubmitting}
                searcher
                required
              />
              <div className="form__required-info">
                <small>
                  <span>*</span> required field
                </small>
              </div>
            </div>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default CreateTaskModal;
