import "./styles.scss";

import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";

import Modal from "components/Modal";
import FormikInput from "components/formik/FormikInput";
import FormikSelect from "components/formik/FormikSelect";
import FormikTaxInput from "components/formik/FormikTaxInput";

import countries from "libs/countries.json";
import billingDataSchema from "libs/validations/billingDataSchema";

import { useClient } from "context/admin/client";

const BillingDataModal = ({ onClose }) => {
  const { t, i18n } = useTranslation();

  const { data, updateData } = useClient();

  const onSubmit = async (values, setSubmitting) => {
    try {
      await updateData(values);
      onClose();
    } catch (err) {
      setSubmitting(false);
    }
  };

  const countries_options = countries?.map(({ name, name_pl, code }) => ({
    label: i18n?.language === "pl" ? name_pl || name : name,
    value: code,
  }));

  return (
    <Formik
      initialValues={{
        billing_data: {
          name: data?.billing_data?.name,
          tax: {
            code: data?.billing_data?.tax?.code,
            number: data?.billing_data?.tax?.clean_number,
          },
          street: data?.billing_data?.street,
          post_code: data?.billing_data?.post_code,
          city: data?.billing_data?.city,
          country: data?.billing_data?.country,
        },
      }}
      validationSchema={billingDataSchema}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        onSubmit(values, setSubmitting, setErrors);
      }}
    >
      {({ values, isSubmitting, submitForm }) => (
        <Form autoComplete="off">
          <Modal
            title={t("Change billing data")}
            onClose={onClose}
            onSubmit={submitForm}
            loading={isSubmitting}
            variant="wide"
          >
            <div className="form">
              <div className="form__group">
                <FormikInput
                  name="billing_data.name"
                  label={t("Name")}
                  disabled={isSubmitting}
                  placeholder="Acme Corporation"
                />
                <FormikTaxInput
                  label={t("Tax identification number")}
                  name="billing_data.tax"
                  disabled={isSubmitting}
                />
              </div>
              <div className="form__group">
                <FormikInput
                  name="billing_data.street"
                  label={t("Street")}
                  disabled={isSubmitting}
                  placeholder="Main Street 13A"
                />
                <FormikInput
                  name="billing_data.post_code"
                  label={t("Post code")}
                  disabled={isSubmitting}
                  placeholder="30-123"
                />
              </div>
              <div className="form__group">
                <FormikInput
                  name="billing_data.city"
                  label={t("City")}
                  disabled={isSubmitting}
                  placeholder="London"
                />
                <FormikSelect
                  name="billing_data.country"
                  label={t("Country")}
                  placeholder="United Kingdom"
                  options={countries_options}
                  selected={values?.billing_data?.country}
                  disabled={isSubmitting}
                  searcher={true}
                />
              </div>
            </div>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default BillingDataModal;
