import WebmasterContent from "page_components/webmaster/webmaster/Content";
import WebmasterSettingsProvider from "context/admin/webmaster-settings";

const WebmasterDetail = () => (
  <WebmasterSettingsProvider>
    <WebmasterContent />
  </WebmasterSettingsProvider>
);

export default WebmasterDetail;
