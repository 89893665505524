import "./styles.scss";

import classnames from "classnames";

const mainClass = "dropdown-card";

const DropdownCard = ({ children, position }) => {
  return (
    <div
      className={classnames(mainClass, {
        [`${mainClass}--${position}`]: !!position,
      })}
    >
      <div className={`${mainClass}__wrapper`}>{children}</div>
    </div>
  );
};

export default DropdownCard;
