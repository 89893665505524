import "./styles.scss";

import React, { useEffect, useState } from "react";
import classnames from "classnames";

import Modal from "components/Modal";

import { ReactComponent as ArrowIcon } from "assets/icons/arrow-mobile-options.svg";

const mainClass = "mobile-modal-options";

const MobileModalOptions = ({ index, components, onClick }) => {
  const [selected, setSelected] = useState(null);

  const component_list = !!components ? components() : null;

  useEffect(() => {
    setSelected(null);
  }, [index]);

  const list_data = !!Array.isArray(component_list?.props?.children)
    ? component_list?.props?.children?.map((item) => ({
        label: item?.props?.title,
        component: item?.props?.children,
        sub_title: item?.props?.sub_title,
        variant: item?.props?.variant,
        checked: item?.props?.checked,
        error: item?.props?.error,
        full_height: item?.props?.full_height,
      }))
    : [
        {
          label: component_list?.props?.title,
          component: component_list?.props?.children,
          sub_title: component_list?.props?.sub_title,
          variant: component_list?.props?.variant,
          checked: component_list?.props?.checked,
          error: component_list?.props?.error,
          full_height: component_list?.props?.full_height,
        },
      ];

  return (
    <>
      <div className={mainClass}>
        {list_data?.map((item, index) => {
          if (!!!item?.label) return null;

          return (
            <button
              type="button"
              key={index}
              onClick={() =>
                !!onClick ? onClick(item?.component) : setSelected(index)
              }
              className={classnames(`${mainClass}__item`, {
                [`${mainClass}__item--with-subtitle`]: !!item?.sub_title,
                [`${mainClass}__item--radio`]: item?.variant === "radio",
                [`${mainClass}__item--radio--checked`]: !!item?.checked,
                [`${mainClass}__item--error`]: !!item?.error,
              })}
            >
              <div className={`${mainClass}__item__text`}>
                <span>{item?.label}</span>
                {!!item?.sub_title && <small>{item?.sub_title}</small>}
              </div>
              <ArrowIcon />
            </button>
          );
        })}
      </div>
      {selected !== null && (
        <Modal
          title={list_data?.[selected]?.label}
          onClose={() => setSelected(null)}
          fullHeight={list_data?.[selected]?.full_height}
          hide_submit_button
        >
          {list_data?.[selected]?.component}
        </Modal>
      )}
    </>
  );
};

export default MobileModalOptions;
