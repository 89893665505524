import "./styles.scss";

import classnames from "classnames";
import { Link } from "react-router-dom";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

import Pill from "components/Pill";
import Modal from "components/Modal";
import Button from "components/Button";
import Loader from "components/Loader";
import MobileSlider from "./MobileSlider";
import PriorityFlag from "components/PriorityFlag";
import TrackerButton from "components/TrackerButton";

import useResponsive from "libs/helpers/useResponsive";
import website_status_data from "libs/website_status_data.json";

import { ReactComponent as NoDataIcon } from "assets/icons/no-data.svg";
import { ReactComponent as ArrowIcon } from "assets/icons/list-arrow.svg";
import { ReactComponent as CrossIcon } from "assets/icons/cross.svg";

const mainClass = "table";

function active_label(liczba) {
  if (liczba === 1) {
    return "active-1";
  } else if (liczba > 1 && liczba < 5) {
    return "active-2";
  } else {
    return "active-3";
  }
}

const Table = ({
  data,
  header,
  header_aside,
  filters,
  filter_default_open = false,
  loading,
  sort,
  setSort,
  filters_always_show,
  filters_count,
  filterClearAll,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  const [filters_show, setFiltersShow] = useState(filter_default_open);
  const [mobile_filters, setMobileFilters] = useState(false);

  const current_sort = sort?.split(":");

  const table_value_type = (type, value) => {
    switch (type) {
      case "button":
        return (
          <Button
            external_href={!!value?.external}
            href={value?.href}
            onClick={value?.onclick}
            loading={value?.loading}
            size="small"
          >
            {value?.label}
          </Button>
        );
      case "status":
        return (
          <span
            className={classnames(`${mainClass}__status`, {
              [`${mainClass}__status--active`]: !!value,
            })}
          ></span>
        );
      case "status-pill":
        return <Pill variant={value} label={value} readOnly size="small" />;
      case "priority":
        return <PriorityFlag priority={value} />;
      case "link":
        return (
          <Link className={`${mainClass}__link`} to={value?.href}>
            {value?.label}
          </Link>
        );
      case "clickable_text":
        return (
          <span
            onClick={value?.onclick}
            className={`${mainClass}__clickable_text`}
          >
            {value?.label}
          </span>
        );
      case "website_status":
        const website_status = website_status_data?.find(
          (item) => item?.value === value
        );
        return (
          <span
            className={classnames(`${mainClass}__website_status`, {
              [`${mainClass}__website_status--${website_status?.key}`]: !!value,
            })}
          >
            {t(website_status?.label)}
          </span>
        );
      case "website_items":
        return value?.length > 0 ? (
          <div className={`${mainClass}__website_items`}>
            {value?.map((item, index) => (
              <div key={index} className={`${mainClass}__website_items__item`}>
                <span />
                <Link to={item?.href}>{item?.label}</Link>
              </div>
            ))}
          </div>
        ) : (
          "-"
        );
      case "webmaster_tracker":
        return (
          <span className={classnames(`${mainClass}__webmaster_tracker`)}>
            <TrackerButton
              active={value?.active}
              time={value?.label}
              onClick={value?.onclick}
              start_time_tracked={value?.start_time_tracked}
            />
          </span>
        );

      default:
        return <span>{value}</span>;
    }
  };

  const sortHandler = (key) => {
    let method;
    let new_key = key;

    if (current_sort?.[1] === "asc") {
      method = "desc";
    } else {
      method = "asc";
    }

    setSort(`${new_key}:${method}`);
  };

  return (
    <div className={mainClass}>
      {(!!header || !!header_aside || !!filters) && (
        <>
          <div className={`${mainClass}__header`}>
            <div className={`${mainClass}__header__content`}>
              {header}
              {(!!filters_show || filters_always_show) && (
                <div className={`${mainClass}__header__content__filters`}>
                  {filters}
                </div>
              )}
            </div>
            {!!header_aside && (
              <div className={`${mainClass}__header__aside`}>
                {header_aside}
              </div>
            )}
            {!!filters && !filters_always_show && (
              <div className={`${mainClass}__header__aside`}>
                <Button
                  size="small"
                  variant="transparent"
                  onClick={() => setFiltersShow((prev) => !prev)}
                >
                  {!!filters_show ? t("Less filters") : t("More filters")}
                </Button>
              </div>
            )}
          </div>
          <div className={`${mainClass}__header__mobile`}>
            <div className={`${mainClass}__header__mobile__wrapper`}>
              <Pill
                label={t("Filters")}
                onclick={() => setMobileFilters(true)}
              />
              {!!filters_count && (
                <div
                  className={`${mainClass}__header__mobile__wrapper__active`}
                >
                  <span>
                    {filters_count} {t(active_label(filters_count))}
                  </span>
                  <button onClick={filterClearAll}>
                    <CrossIcon />
                  </button>
                </div>
              )}
            </div>
            {!!mobile_filters && (
              <Modal
                title={t("Filters")}
                onSubmit={() => setMobileFilters(false)}
                onClose={() => setMobileFilters(false)}
              >
                <div className={`${mainClass}__header__mobile__modal`}>
                  {header}
                  {filters}
                </div>
                {/* <Button fullWidth>Zastosuj</Button> */}
              </Modal>
            )}
          </div>
        </>
      )}
      <div className={`${mainClass}__content`}>
        <div className={`${mainClass}__content__wrapper`}>
          <div
            className={classnames(
              `${mainClass}__row`,
              `${mainClass}__row--header`
            )}
          >
            {data?.keys?.map(
              ({ key, sort_key: table_sort_key, value }, index) => {
                const sort_key = table_sort_key || key;

                return (
                  <div
                    key={index}
                    onClick={
                      value && !!setSort ? () => sortHandler(sort_key) : null
                    }
                    className={classnames(
                      `${mainClass}__col`,
                      `${mainClass}__col--${key}`,
                      {
                        [`${mainClass}__col--${current_sort?.[1]}`]:
                          sort_key === current_sort?.[0],
                      }
                    )}
                  >
                    <span>{value}</span>
                    {!!value && !!setSort && (
                      <ArrowIcon className={`${mainClass}__col__arrow`} />
                    )}
                  </div>
                );
              }
            )}
          </div>
          {!!loading ? (
            <div className="table-loader">
              <Loader color="blue" center size="large" />
            </div>
          ) : data?.items?.length ? (
            isMobile ? (
              <MobileSlider data={data} />
            ) : (
              data?.items?.map((elements, index) => {
                const is_disabled = elements?.find(
                  (item) => item?.key === "notification_status"
                )
                  ? !!!elements?.find(
                      (item) => item?.key === "notification_status"
                    )?.value
                  : false;

                return (
                  <div
                    key={index}
                    className={classnames(`${mainClass}__row`, {
                      [`${mainClass}__row--disabled`]: !!is_disabled,
                    })}
                  >
                    {elements?.map(({ key, value, type }, index) => (
                      <Fragment key={index}>
                        <div
                          className={classnames(
                            `${mainClass}__col`,
                            `${mainClass}__col--${key}`
                          )}
                        >
                          {table_value_type(type, value)}
                        </div>
                      </Fragment>
                    ))}
                  </div>
                );
              })
            )
          ) : (
            <div className="table-empty">
              <NoDataIcon />
              <span>{t("No data")}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Table;
