import "./styles.scss";

import moment from "moment";
import { useTranslation } from "react-i18next";

import Table from "components/Table";
import PillGrid from "components/PillGrid";
import Calendar from "components/Calendar";
import Breadcrumps from "components/Breadcrumps";
import DashboardContentLayout from "components/layouts/DashboardContentLayout";

import { useWebmaster } from "context/admin/webmaster";

import task_status_data from "libs/task_status_data.json";
import task_priority_data from "libs/task_priority_data.json";
import toHoursAndMinutes from "libs/helpers/toHoursAndMinutes";
import Input from "components/form/Input";

const WebmasterDetails = () => {
  const { t } = useTranslation();

  const { data, webmaster_id, loading, init_loading, filters, filterHandler } =
    useWebmaster();

  const breadcrumps = [
    {
      label: t("Webmasters"),
      href: "/webmasters",
    },
    {
      label: data?.webmaster?.first_name
        ? `${data?.webmaster?.first_name} ${data?.webmaster?.last_name}`
        : null,
    },
  ];

  const actions = [
    {
      type: "send_notification",
    },
    {
      label: t("Edit webmaster account"),
      href: `/webmasters/${webmaster_id}/account-settings`,
    },
  ];

  const pills_data = [
    {
      label: t("Show all"),
      value: "all",
      href: `/webmasters/${webmaster_id}`,
      active: !!!filters?.type,
    },
    ...task_priority_data?.map(({ key, label, value }) => ({
      label: t(label),
      value,
      href: `/webmasters/${webmaster_id}?type=${
        task_priority_data?.find((item) => item?.value === value)?.key
      }`,
      active: filters?.type === key,
    })),
  ];

  const pills_task_status = [
    {
      label: t("Show all"),
      value: "all",
      onclick: () => filterHandler("status", ""),
      active: !!!filters?.status,
    },
    ...task_status_data
      ?.filter(
        (item) =>
          item?.value !== 1 &&
          item?.value !== 2 &&
          item?.value !== 6 &&
          item?.value !== 7
      )
      ?.map(({ key, label, value }) => ({
        label: t(label),
        value,
        onclick: () =>
          filterHandler(
            "status",
            task_status_data?.find((item) => item?.value === value)?.key
          ),
        active: filters?.status === key,
      })),
  ];

  const table_data = {
    keys: [
      {
        key: "priority",
        value: t("Priority"),
      },
      {
        sort_key: "status",
        key: "status-pill",
        value: t("Status"),
      },
      {
        key: "title",
        value: t("Title"),
      },
      {
        key: "website",
        value: t("Website"),
      },
      {
        key: "estimated_time",
        value: t("Estimated time"),
      },
      {
        sort_key: "total_time",
        key: "time_tracked",
        value: t("Time tracked"),
      },
      {
        key: "date",
        value: t("Due date"),
      },
    ],
    items: data?.tasks?.map(
      ({
        _id,
        priority,
        title,
        website,
        website_id,
        estimated_time,
        total_time,
        due_date,
        status,
      }) => [
        {
          key: "priority",
          value: priority,
          type: "priority",
        },
        {
          key: "status-pill",
          type: "status-pill",
          value: task_status_data?.find((item) => item?.value === status)?.key,
        },
        {
          key: "title",
          type: "link",
          value: {
            label: title,
            href: `/tasks/${_id}`,
          },
        },
        {
          key: "website",

          type: "link",
          value: {
            label: website,
            href: `/clients/${website_id}`,
          },
        },
        {
          key: "estimated_time",
          value: !!estimated_time
            ? toHoursAndMinutes(estimated_time?.$numberDecimal)?.formatted
            : "-",
        },
        {
          key: "time_tracked",
          value: !!total_time?.$numberDecimal
            ? toHoursAndMinutes(total_time?.$numberDecimal)?.formatted
            : "-",
        },
        {
          key: "date",
          value: !!due_date ? moment(due_date)?.format("DD MMM, YYYY") : "-",
        },
      ]
    ),
  };

  return (
    <DashboardContentLayout
      header_label={
        !!data?.webmaster?.first_name
          ? `${data?.webmaster?.first_name} ${data?.webmaster?.last_name}`
          : null
      }
      loading={init_loading}
      async_header
      actions={actions}
      breadcrumps={breadcrumps}
    >
      <Breadcrumps />
      <Table
        data={table_data}
        loading={!!loading}
        header={
          <div style={{ display: "flex", gap: 64 }}>
            <PillGrid
              header={t("Task priority")}
              items={pills_data}
              size="small"
            />
            <PillGrid
              header={t("Task status")}
              items={pills_task_status}
              size="small"
            />
          </div>
        }
        filter_default_open={Object?.keys(filters)?.length > 0}
        filters={
          <>
            <Input
              label={t("Search")}
              placeholder={t("Task title")}
              value={filters?.search}
              onChange={(e) => filterHandler("search", e.target.value)}
            />
            <Calendar
              label={t("Last update start date")}
              default_value={filters?.start_updated_at}
              onChange={(value) => filterHandler("start_updated_at", value)}
            />
            <Calendar
              label={t("Last update end date")}
              default_value={filters?.end_updated_at}
              onChange={(value) => filterHandler("end_updated_at", value)}
            />
          </>
        }
        sort={filters?.sort}
        setSort={(value) => filterHandler("sort", value)}
      />
    </DashboardContentLayout>
  );
};

export default WebmasterDetails;
