import { useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import DetailsCard from "components/DetailsCard";
import DetailsCardList from "components/DetailsCardList";
import ContactDataModal from "./components/ContactDataModal";
import BillingDataModal from "./components/BillingDataModal";
import CancelPlanModal from "./components/CancelPlanModal";
import DashboardContentLayout from "components/layouts/DashboardContentLayout";

import { useWebsite } from "context/client/website";

import countries from "libs/countries.json";
import toHoursAndMinutes from "libs/helpers/toHoursAndMinutes";
import getCountryFromCode from "libs/helpers/getCountryFromCode";
import { useApp } from "context/app";
import priceRender from "libs/helpers/priceRender";
import useResponsive from "libs/helpers/useResponsive";

const mainClass = "column-layout";

const WebsitesContent = () => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  const { plans_list, websites } = useApp();
  const { data, loading, customerPortal, customer_portal_loading } =
    useWebsite();

  const [contact_data_modal, setContactDataModal] = useState(false);
  const [billing_data_modal, setBillingDataModal] = useState(false);
  const [cancel_plan_modal, setCancelPlanModal] = useState(false);

  let subscription_data = null;
  plans_list.forEach((item) => {
    item?.prices?.forEach((price) => {
      if (price.id === data?.subscription?.price_id) {
        subscription_data = { ...item, ...price };
      }
    });
  });

  const status_tooltip_render = (status) => {
    switch (status) {
      case 1:
        return t(
          "Your website is safe and up to date. Your payment method is active."
        );
      case 2:
        return t(
          "We have detected a problem with your website. We will verify it and fix it, or get back to you with further information"
        );
      case 3:
        return t(
          "It may take up to 24h for us to verify if everything is ok with your website"
        );
      case 4:
        return t(
          "There may be a problem with your website or its server. We have received an automatic notification of the issue and are working to resolve it."
        );
      default:
        return "-";
    }
  };

  const actions = [
    {
      label: t("Add new website"),
      href: "/websites/new",
      event: () =>
        window.gtag("event", "new_website", {
          click_text: t("Add new website"),
          click_url: `/websites/new`,
        }),
    },
    ...(websites?.length > 0
      ? [
          {
            type: "create_client_task",
            onboarding_button: true,
          },
        ]
      : []),
  ];

  const breadcrumps = [
    {
      label: t("Websites"),
      href: "/",
    },
    {
      label: data?.website,
    },
  ];

  const details_data = [
    {
      title: t("Overview"),
      items: [
        {
          label: t("Website URL"),
          value: data?.website,
        },
        {
          type: "status",
          label: t("Status"),
          value: {
            status: data?.status,
          },
          tooltip: status_tooltip_render(data?.status),
        },
        {
          label: t("Last CMS update"),
          value: data?.last_cms_update
            ? moment(data?.last_cms_update).format("ll")
            : "-",
        },
        {
          label: t("SSL certificate valid until"),
          value: data?.ssl_valid_until
            ? moment(data?.ssl_valid_until).format("ll")
            : "-",
        },
        {
          label: t("Domain valid until"),
          value: data?.domain_valid_until
            ? moment(data?.domain_valid_until).format("ll")
            : "-",
        },
        {
          label: t("Time available in your plan"),
          value: toHoursAndMinutes(data?.time_used?.available_in_plan)
            ?.formatted,
        },
        {
          label: t("Time used"),
          value: toHoursAndMinutes(data?.time_used?.time_used)?.formatted,
        },
        {
          label: t("Time left"),
          value: toHoursAndMinutes(data?.time_used?.time_left)?.formatted,
        },
        {
          label: t("Time exceeding plan"),
          value: toHoursAndMinutes(data?.time_used?.time_exceeding_plan)
            ?.formatted,
        },
      ],
    },
    {
      title: t("Tasks"),
      action: {
        label: t("Show tasks"),
        href: "/tasks",
      },
      items: [
        {
          label: t("Awaiting estimation"),
          value: data?.tasks_stats?.awaiting_estimation,
        },

        {
          label: t("In progress"),
          value: data?.tasks_stats?.in_progress,
        },
        {
          label: t("Awaiting verification"),
          value: data?.tasks_stats?.awaiting_verification,
        },
      ],
    },
    {
      title: t("Plan"),
      subscription_end:
        !!!data?.subscription?.next_payment &&
        !!data?.subscription?.start_payment
          ? moment?.unix(data?.subscription?.start_payment)?.add(1, "months")
          : null,
      action: [
        ...(!!data?.subscription?.next_payment
          ? [
              {
                label: t("Change plan"),
                href: "change-plan",
              },
              {
                label: t("Cancel plan"),
                variant: "no-border-grey",
                onclick: () => setCancelPlanModal(true),
              },
            ]
          : [
              {
                label: t("Change plan"),
                href: "change-plan",
              },
            ]),
      ],
      items: [
        {
          label: t("Type"),
          value: `${subscription_data?.name} plan`,
        },
        {
          label: t("Billing cycle"),
          value:
            subscription_data?.contract_period === "monthly"
              ? t("Monthly")
              : t("Yearly"),
        },
        {
          label: t("Amount"),
          value: priceRender(
            data?.subscription?.amount / 100,
            data?.subscription?.currency
          ),
        },
        {
          label: t("Next charge"),
          value: !!data?.subscription?.next_payment
            ? moment.unix(data?.subscription?.next_payment)?.format("ll")
            : "-",
        },
      ],
    },
  ];

  const payment_data = [
    {
      title: t("Contact data"),
      action: {
        label: t("Edit"),
        onclick: () => setContactDataModal(true),
      },
      items: [
        {
          label: t("Full name"),
          value: `${data?.contact_data?.first_name} ${data?.contact_data?.last_name}`,
        },
        {
          label: t("Email"),
          value: data?.contact_data?.email,
        },
        {
          label: t("Phone"),
          value: `${
            countries?.find(
              (item) => item?.code === data?.contact_data?.phone?.code
            )?.dialling_code || ""
          } ${data?.contact_data?.phone?.number}`,
        },
      ],
    },
    {
      title: t("Payment"),
      view: data?.subscription?.status !== "active" ? "payment_error" : null,
      action: {
        label: t("Edit"),
        half_margin_mobile: true,
        onclick: customerPortal,
        loading: customer_portal_loading,
      },
      items: [
        {
          type: "card",
          label: t("Method"),
          value: {
            card_type: data?.subscription?.card?.brand,
            status: !!data?.subscription?.card?.expires_soon
              ? 2
              : data?.subscription?.status === "active"
              ? 1
              : 0,
            card_numbers: !!data?.subscription?.card?.last4
              ? `**** **** **** **** ${data?.subscription?.card?.last4}`
              : data?.subscription?.status === "active"
              ? "Link"
              : "-",
          },
        },
        {
          type: "button",
          label: t("Invoices"),
          value: {
            button_label: t("Show"),
            button_href: `/invoices?website=${data?._id}`,
          },
        },
      ],
    },
    {
      title: t("Billing data"),
      action: {
        label: t("Edit"),
        onclick: () => setBillingDataModal(true),
      },
      items: [
        {
          label: t("Name"),
          value: data?.billing_data?.name,
        },
        {
          label: t("Tax identification number"),
          value: `${data?.billing_data?.tax?.code} ${data?.billing_data?.tax?.clean_number}`,
        },
        {
          label: t("Address"),
          value: (
            <>
              {data?.billing_data?.street}
              <br />
              {data?.billing_data?.post_code} {data?.billing_data?.city}
              <br />
              {t(getCountryFromCode(data?.billing_data?.country)?.name)}
            </>
          ),
        },
      ],
    },
    {
      title: t("Website access data"),
      action: {
        label: t("View / Edit"),
        href: "access-data",
      },
      items: [
        {
          value: t(
            "Here you can update access data to your website. The data includes access to FTP server, database and CMS WordPress."
          ),
        },
      ],
    },
  ];

  const DetailsCardsData = () =>
    details_data?.map((data, index) => (
      <DetailsCard key={index} {...data}>
        <DetailsCardList items={data?.items} view={data?.view} />
      </DetailsCard>
    ));

  const DetailsCardsPayment = () =>
    payment_data?.map((data, index) => (
      <DetailsCard key={index} {...data}>
        <DetailsCardList items={data?.items} view={data?.view} />
      </DetailsCard>
    ));

  return (
    <DashboardContentLayout
      header_label={data?.website}
      breadcrumps={breadcrumps}
      actions={actions}
      loading={loading}
      async_header={!!isMobile ? null : true}
    >
      <div className={mainClass}>
        <div className={`${mainClass}__col`}>
          <DetailsCardsData />
        </div>
        <div className={`${mainClass}__col`}>
          <DetailsCardsPayment />
        </div>

        <div style={{ position: "absolute" }}>
          {!!contact_data_modal && (
            <ContactDataModal onClose={() => setContactDataModal(false)} />
          )}
          {!!billing_data_modal && (
            <BillingDataModal onClose={() => setBillingDataModal(false)} />
          )}
          {!!cancel_plan_modal && (
            <CancelPlanModal
              subscription_end={data?.subscription?.next_payment}
              onClose={() => setCancelPlanModal(false)}
            />
          )}
        </div>
      </div>
    </DashboardContentLayout>
  );
};

export default WebsitesContent;
