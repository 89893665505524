import { useState } from "react";
import { useTranslation } from "react-i18next";

import PlaceholderPage from "components/PlaceholderPage";

import Conversation from "page_components/shared/tasks/Conversation";
import DashboardContentLayout from "components/layouts/DashboardContentLayout";
import AcceptTaskModal from "page_components/shared/tasks/Conversation/components/Details/components/AcceptTaskModal";
import CancelTaskModal from "page_components/shared/tasks/Conversation/components/Details/components/CancelTaskModal";
import ClientCommentsModal from "page_components/shared/tasks/Conversation/components/Details/components/ClientCommentsModal";

import useResponsive from "libs/helpers/useResponsive";
import TaskProvider, { useTask } from "context/client/task";

const ConversationTasksContent = () => {
  const { t } = useTranslation();

  const {
    data,
    file_progress,
    sendMessage,
    editMessage,
    loading,
    updateTask,
    task_close_confirmation,
  } = useTask();
  const [comments_modal, setCommentsModal] = useState(false);
  const [accept_modal, setAcceptModal] = useState(false);
  const [close_modal, setCloseModal] = useState(false);

  const { isMobile } = useResponsive();

  const breadcrumps = [
    {
      label: t("Tasks"),
      href: "/tasks",
    },
    {
      label: data?.website,
      href: `/websites/${data?.website_id}`,
    },
    {
      label: data?.title,
    },
  ];

  const required_action_buttons = () => {
    switch (data?.status) {
      case 2:
        return [
          {
            label: t("Accept terms"),
            variant: "success",
            onclick: () => updateTask({ status: 3 }),
          },
          {
            label: t("Cancel order"),
            onclick: () => setCloseModal(true),
            variant: "error",
          },
        ];
      case 5:
        return [
          {
            label: t("Accept and close task"),
            variant: "success",
            onclick: () => setAcceptModal(true),
          },
          {
            label: t("Request a correction"),
            onclick: () => setCommentsModal(true),
          },
        ];
      default:
        return null;
    }
  };

  const action_buttons = required_action_buttons();

  return (
    <TaskProvider>
      <DashboardContentLayout
        header_label={!!!isMobile ? t("Conversation") : data?.title}
        breadcrumps={breadcrumps}
        loading={loading}
        actions={action_buttons}
      >
        {!!!task_close_confirmation ? (
          <>
            <Conversation
              data={data}
              file_progress={file_progress}
              sendMessage={sendMessage}
              editMessage={editMessage}
              action_buttons={action_buttons}
            />
            {!!comments_modal && (
              <ClientCommentsModal onClose={() => setCommentsModal(false)} />
            )}
            {!!accept_modal && (
              <AcceptTaskModal
                title={data?.title}
                onClose={() => setAcceptModal(false)}
              />
            )}
            {!!close_modal && (
              <CancelTaskModal onClose={() => setCloseModal(false)} />
            )}
          </>
        ) : (
          <PlaceholderPage
            title={t("The task has been finished!")}
            text={t(
              "We are glad we could help and would like to thank you for fruitful cooperation :)"
            )}
            button={{
              label: t("Manage your website"),
              href: "/websites",
            }}
          />
        )}
      </DashboardContentLayout>
    </TaskProvider>
  );
};

const ConversationTasks = () => (
  <TaskProvider>
    <ConversationTasksContent />
  </TaskProvider>
);

export default ConversationTasks;
