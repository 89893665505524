import "./styles.scss";

import { useState } from "react";
import { useTranslation } from "react-i18next";

import DetailsCard from "components/DetailsCard";
import FaqContentModal from "./components/FaqContentModal";

import FaqData from "libs/faq_data";
import useResponsive from "libs/helpers/useResponsive";
import FaqContentMobile from "./Mobile/FaqContentModal";

const mainClass = "faq-content";

const FaqContent = ({ setMobileHeader }) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const [selected_faq, setSelectedFaq] = useState(null);

  const faq_data_render = FaqData();

  return (
    <>
      <div className={mainClass}>
        {!!isMobile ? (
          <FaqContentMobile
            data={faq_data_render}
            setMobileHeader={setMobileHeader}
          />
        ) : (
          faq_data_render?.map((item, index) => (
            <DetailsCard key={index} title={t(item?.title)}>
              {item?.items?.map(({ title, content }, index) => (
                <button
                  key={index}
                  className={`${mainClass}__item`}
                  onClick={() =>
                    setSelectedFaq({ section: item?.title, title, content })
                  }
                >
                  <span>{title}</span>
                </button>
              ))}
            </DetailsCard>
          ))
        )}
      </div>
      {!!selected_faq && (
        <FaqContentModal
          data={selected_faq}
          onClose={() => setSelectedFaq(null)}
        />
      )}
    </>
  );
};

export default FaqContent;
