import "./styles.scss";

import moment from "moment";
import { useTranslation } from "react-i18next";

const mainClass = "dashboard-overview";

const Overview = ({ data, uptime }) => {
  const { t } = useTranslation();

  const ssl_valid_days = !!data?.ssl_valid_until
    ? moment(data?.ssl_valid_until).diff(moment(), "days")
    : null;

  const uptime_status =
    uptime?.items?.[uptime?.items?.length - 1]?.status === 404
      ? "Offline"
      : "Online";

  const overview_data = [
    {
      label: t("Uptime monitoring"),
      value: t(uptime_status),
    },
    {
      label: t("Last CMS update"),
      value: !!data?.last_cms_update
        ? moment(data?.last_cms_update).format("ll")
        : "-",
    },
    {
      label: t("SSL certificate valid until"),
      value: !!data?.ssl_valid_until
        ? moment(data?.ssl_valid_until).format("ll")
        : "-",
      days_left: !!ssl_valid_days
        ? ssl_valid_days > 0
          ? `${t("Expires in")} ${ssl_valid_days} ${t("days")}.`
          : `${t("Expired")} ${moment(data?.ssl_valid_until)?.fromNow()}`
        : null,
    },
    {
      label: t("Domain valid until"),
      value: !!data?.domain_valid_until
        ? moment(data?.domain_valid_until).format("ll")
        : "-",
    },
  ];

  return (
    <div className={mainClass}>
      {overview_data?.map(({ label, value, days_left }, index) => (
        <div key={index} className={`${mainClass}__item`}>
          <span>{label}</span>
          <span>
            {value}
            {!!days_left && (
              <>
                <br />
                <span>{days_left}</span>
              </>
            )}
          </span>
        </div>
      ))}
    </div>
  );
};

export default Overview;
