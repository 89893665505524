import { createContext, useContext, useMemo, useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import api from "libs/api";

const TaskTimeOverContext = createContext();

const TaskTimeOverProvider = ({ children }) => {
  const { id } = useParams();

  const [task, setTask] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function () {
      await getTaskConversationData();
    })();
    // eslint-disable-next-line
  }, []);

  const getTaskConversationData = async () => {
    try {
      setLoading(true);
      const task = await api.get(`/admin/tasks/${id}/time-over`);
      setTask(task);
    } catch (err) {}

    setLoading(false);
  };

  const value = useMemo(() => {
    return {
      data: task,
      loading,
    };
    // eslint-disable-next-line
  }, [task, loading]);

  return (
    <TaskTimeOverContext.Provider value={value}>
      {children}
    </TaskTimeOverContext.Provider>
  );
};

const useTaskTimeOver = () => useContext(TaskTimeOverContext);
export { TaskTimeOverContext, useTaskTimeOver };
export default TaskTimeOverProvider;
