import "./styles.scss";

import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import moment from "moment";
import { Line } from "react-chartjs-2";

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const Uptime = ({ data, period }) => {
  const uptime_data = {
    labels: data?.items?.map((item) =>
      moment(item?.created_at).format(period === "month" ? "MMM D" : "hhA")
    ),
    datasets: [
      {
        data: data?.items?.map((item) => item?.response_time),
        fill: {
          target: "origin", // 3. Set the fill options
          above: "rgba(15,15,255, 0.1)",
        },
        backgroundColor: "#0f0fff",
        borderColor: "rgba(15,15,255, 0.3)",
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return value + "ms";
          },
        },
      },
    },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return <Line data={uptime_data} options={options} />;
};

export default Uptime;
