import { array, object, string, number } from "yup";

let createTaskSchema = object({
  title: string().required("Title cannot be empty."),
  website: string().required("Website cannot be empty."),
  finished_when: string().required("Task finished when cannot be empty."),
  priority: number().required("Priority cannot be empty."),
  status: number().required("Status cannot be empty."),
  // estimated_time: object({
  //   hours: number()
  //     .required("Hours cannot be empty.")
  //     .typeError("Must be a number"),
  //   minutes: number()
  //     .required("Minutes cannot be empty.")
  //     .typeError("Must be a number"),
  // }),
  due_date: string().required("Due date cannot be empty."),
  webmaster: array().min(1, "Webmaster cannot be empty."),
});

export default createTaskSchema;
