import "./styles.scss";

import { useState } from "react";
import { Formik, Form } from "formik";

import ChooseProject from "./components/ChooseProject";
import SummaryProject from "./components/SummaryProject";
import ConfirmationProject from "./components/ConfirmationProject";

import api from "libs/api";

const mainClass = "new-project-content";

const view_render = (type, setStep) => {
  switch (type) {
    case 0:
      return <ChooseProject setStep={setStep} />;
    case 1:
      return <SummaryProject setStep={setStep} />;
    default:
      return null;
  }
};

const NewProjectContent = () => {
  const [step, setStep] = useState(0);

  const onSubmit = async (values, setSubmitting) => {
    try {
      await api.post("/client/websites/new-project-form", values);
      setStep(2);
    } catch (err) {
      setSubmitting(false);
    }
  };

  return (
    <div className={mainClass}>
      {step === 2 ? (
        <ConfirmationProject setStep={setStep} />
      ) : (
        <Formik
          initialValues={{
            options: [],
            message: "",
          }}
          enableReinitialize
          // validationSchema={sendNotificationSchema}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(values, setSubmitting);
          }}
        >
          <Form autoComplete="off">{view_render(step, setStep)}</Form>
        </Formik>
      )}
    </div>
  );
};

export default NewProjectContent;
