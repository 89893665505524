import { createContext, useContext, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import queryString from "query-string";

import api from "libs/api";
import task_status_data from "libs/task_status_data.json";

const TasksContext = createContext();

const TasksProvider = ({ children }) => {
  const { search } = useLocation();
  const navigate = useNavigate();

  let filters = queryString.parse(search);
  delete filters["onboarding_finish"];
  delete filters["onboarding"];

  const default_sort = "updated_at:desc";

  const [init_loading, setInitLoading] = useState(true);
  const [init_data_length, setInitDataLength] = useState(0);

  const { isLoading: loading, data: tasks } = useQuery(
    ["tasks", filters],
    () =>
      api.get(
        `/client/tasks?${queryString.stringify({
          ...filters,
          type:
            task_status_data?.find((item) => item?.key === filters?.type)
              ?.value || undefined,
          status:
            task_status_data?.find((item) => item?.key === filters?.status)
              ?.value || undefined,
          sort: filters?.sort || default_sort,
        })}`
      ),
    {
      onSuccess: (data) => {
        setInitDataLength((prev) => (!!init_loading ? data?.length : prev));
        setInitLoading(false);
      },
    }
  );

  const filterClearAll = () => {
    navigate(`/tasks`);
  };

  const filterHandler = (name, value) => {
    navigate(
      `/tasks?${queryString.stringify({
        ...filters,
        [name]: value || undefined,
      })}`
    );
  };

  const value = useMemo(() => {
    return {
      data: tasks,
      init_data_length,
      loading,
      init_loading,
      filters,
      filterHandler,
      filterClearAll,
    };
    // eslint-disable-next-line
  }, [tasks, loading, init_data_length, filters, init_loading]);

  return (
    <TasksContext.Provider value={value}>{children}</TasksContext.Provider>
  );
};

const useTasks = () => useContext(TasksContext);
export { TasksContext, useTasks };
export default TasksProvider;
