import "./styles.scss";

import classnames from "classnames";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "components/Button";
import Loader from "components/Loader";
import IconButton from "components/IconButton";

import useResponsive from "libs/helpers/useResponsive";
import { ReactComponent as CrossIcon } from "assets/icons/modal-cross.svg";
import { ReactComponent as ArrowIcon } from "assets/icons/arrow-mobile-options.svg";

const mainClass = "modal";

const Modal = ({
  title,
  variant,
  onClose,
  children,
  onSubmit,
  loading,
  hide_submit_button,
  submit_button_label = "Save",
  close_button_label = "Close",
  data_loading,
  mode,
  onBackAction,
  fullHeight,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(true);
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const onCloseHandler = () => {
    setOpen(false);
    setTimeout(() => {
      onClose();
    }, 300);
  };

  return (
    <div
      className={classnames(mainClass, {
        [`${mainClass}--${variant}`]: !!variant,
        [`${mainClass}--${mode}`]: !!mode,
        [`${mainClass}--open`]: !!open,
        [`${mainClass}--full_height`]: !!fullHeight,
      })}
    >
      <div className={`${mainClass}__container`}>
        <div className={`${mainClass}__wrapper`}>
          {!!title && (
            <div className={`${mainClass}__header`}>
              <h3 onClick={onBackAction}>
                {!!onBackAction && <ArrowIcon />}
                {title}
              </h3>
              <IconButton onClick={onCloseHandler} icon={<CrossIcon />} />
            </div>
          )}
          <div className={`${mainClass}__content`}>
            {!!!data_loading ? children : <Loader color="blue" size="large" />}
          </div>
          {!!!hide_submit_button && (
            <div className={`${mainClass}__action`}>
              <Button size="small" variant="transparent" onClick={onClose}>
                {t(close_button_label)}
              </Button>
              {!!!hide_submit_button && (
                <Button size="small" onClick={onSubmit} loading={loading}>
                  {t(submit_button_label)}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
      <div
        className={`${mainClass}__backdrop`}
        onClick={!!isMobile ? onCloseHandler : onClose}
      ></div>
    </div>
  );
};

export default Modal;
