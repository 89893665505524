import "./styles.scss";

import classnames from "classnames";
import Button from "components/Button";

const mainClass = "placeholder-page";

const PlaceholderPage = ({ title, text, button, variant, event }) => {
  return (
    <div
      className={classnames(mainClass, {
        [`${mainClass}--auth`]: !!variant,
      })}
    >
      <div className={`${mainClass}__wrapper`}>
        <h1>{title}</h1>
        <p>{text}</p>
        {!!Array.isArray(button) ? (
          <div className={`${mainClass}__wrapper__buttons`}>
            {button?.map(({ label, href, variant }, index) => (
              <Button key={index} size="large" href={href} variant={variant}>
                {label}
              </Button>
            ))}
          </div>
        ) : (
          !!button?.label && (
            <Button
              size="large"
              href={button?.href}
              onClick={!!event ? event : null}
            >
              {button?.label}
            </Button>
          )
        )}
      </div>
    </div>
  );
};

export default PlaceholderPage;
