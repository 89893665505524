import { useState } from "react";
import { useTranslation } from "react-i18next";

import PlaceholderPage from "components/PlaceholderPage";
import RegisterContent from "page_components/auth/register/Content";

const Register = () => {
  const { t } = useTranslation();

  const [email_confirmed, setEmailConfirmed] = useState(null);

  return !!!email_confirmed ? (
    <RegisterContent setEmailConfirmed={setEmailConfirmed} />
  ) : (
    <PlaceholderPage
      variant="auth"
      title={t("Thank you for registering with us!")}
      text={
        <>
          {t("We have sent you an email to")} <strong>{email_confirmed}</strong>{" "}
          {t(
            "with instructions on how you can confirm your email address for your Webinity account. Make sure to check your spam box as well in case you can't see it in your inbox."
          )}
        </>
      }
      button={{
        label: t("Back to login"),
        href: "/",
      }}
    />
  );
};

export default Register;
