import "./styles.scss";

import { useState } from "react";
import { useTranslation } from "react-i18next";

import Modal from "components/Modal";
import { useTask } from "context/admin/task";

const DeleteTaskModal = ({ title, onClose }) => {
  const { t } = useTranslation();

  const { deleteTask } = useTask();
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    try {
      setLoading(true);

      await deleteTask();
      onClose();
    } catch (err) {
      console.error(err);
    }

    setLoading(false);
  };

  return (
    <Modal
      title={t("Delete task")}
      onClose={onClose}
      onSubmit={onSubmit}
      submit_button_label={t("Yes, delete task")}
      loading={!!loading}
      variant="little-narrow"
    >
      <p>
        {t("Are you sure you would like to delete the task")}{" "}
        <strong>{title}</strong>?
        <br />
        <br />
        {t(
          `This action cannot be reversed. Before deleting the task please make sure it doesn't contain any important information.`
        )}
      </p>
    </Modal>
  );
};

export default DeleteTaskModal;
