import "./styles.scss";

import { useState } from "react";

import Modal from "components/Modal";
import { useTask } from "context/admin/task";

const DeleteTaskModal = ({ title, onClose }) => {
  const { deleteTask } = useTask();
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    try {
      setLoading(true);

      await deleteTask();
      onClose();
    } catch (err) {
      console.error(err);
    }

    setLoading(false);
  };

  return (
    <Modal
      title="Delete task"
      onClose={onClose}
      onSubmit={onSubmit}
      submit_button_label="Yes, delete task"
      loading={!!loading}
      variant="little-narrow"
    >
      <p>
        Are you sure you would like to delete the task <strong>{title}</strong>?
        <br />
        <br />
        This action cannot be reversed. Before deleting the task please make
        sure it doesn't contain any important information.
      </p>
    </Modal>
  );
};

export default DeleteTaskModal;
