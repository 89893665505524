import ClientsTasksContent from "page_components/admin/clients/Tasks";
import ClientTasksProvider from "context/admin/client_tasks";

const ClientTasks = () => (
  <ClientTasksProvider>
    <ClientsTasksContent />
  </ClientTasksProvider>
);

export default ClientTasks;
