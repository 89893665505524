import { createContext, useContext, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import queryString from "query-string";

import api from "libs/api";

const InvoicesContext = createContext();

const InvoicesProvider = ({ children }) => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const filters = queryString.parse(search);
  const default_sort = "created_at:desc";

  const [init_loading, setInitLoading] = useState(true);
  const [init_data_length, setInitDataLength] = useState(0);

  const { isLoading: loading, data: invoices } = useQuery(
    ["invoices", filters],
    () =>
      api.get(
        `/user/invoices?${queryString.stringify({
          ...filters,
          sort: filters?.sort || default_sort,
        })}`
      ),
    {
      onSuccess: (data) => {
        setInitDataLength((prev) => (!!init_loading ? data?.length : prev));
        setInitLoading(false);
      },
    }
  );

  const clearAllFilter = (name, value) => {
    navigate(`/invoices`);
  };

  const filterHandler = (name, value) => {
    navigate(
      `/invoices?${queryString.stringify({
        ...filters,
        [name]: value || undefined,
      })}`
    );
  };

  const showInvoice = async (id) => {
    try {
      const invoice_link = await api.get(`/user/invoices/${id}`);
      window.open(invoice_link, "_blank").focus();
    } catch (err) {
      console.error(err);
    }
  };

  const value = useMemo(() => {
    return {
      data: invoices,
      init_data_length,
      loading,
      init_loading,
      filters,
      filterHandler,
      clearAllFilter,
      showInvoice,
    };
    // eslint-disable-next-line
  }, [invoices, loading, init_loading, init_data_length, filters]);

  return (
    <InvoicesContext.Provider value={value}>
      {children}
    </InvoicesContext.Provider>
  );
};

const useInvoices = () => useContext(InvoicesContext);
export { InvoicesContext, useInvoices };
export default InvoicesProvider;
