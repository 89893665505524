import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import PageLoader from "components/PageLoader";
import PlaceholderPage from "components/PlaceholderPage";

import api from "libs/api";

const VerifyAccount = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const navigate = useNavigate();

  const query = new URLSearchParams(search);

  const [email_confirmed, setEmailConfirmed] = useState(false);

  useEffect(() => {
    const token = query.get("token");
    (async () => {
      try {
        await api.post("/auth/activation", {
          token,
        });
        setEmailConfirmed(true);
      } catch (err) {
        navigate("/");
      }
    })();
    // eslint-disable-next-line
  }, []);

  return !!!email_confirmed ? (
    <PageLoader />
  ) : (
    <PlaceholderPage
      variant="auth"
      title={t("Your account was successfully activated")}
      text={t("Log in to your account and start managing your website today.")}
      button={{
        label: t("Back to login"),
        href:
          query?.get("next") === "new_website"
            ? "/login?next=new_website"
            : "/",
      }}
    />
  );
};

export default VerifyAccount;
