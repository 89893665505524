import NewWebsiteContent from "page_components/client/websites/NewWebsiteContent";
import NewWebsiteProvider from "context/client/new_website";

const NewWebsites = () => (
  <NewWebsiteProvider>
    <NewWebsiteContent />
  </NewWebsiteProvider>
);

export default NewWebsites;
