import "./styles.scss";

import { useState } from "react";
import classnames from "classnames";

import TimeTracked from "./components/TimeTracked";
import Button from "components/Button";
import Details from "./components/Details";

const mainClass = "time-over-content";

const TimeOverContent = ({
  admin_mode,
  hide_required_action,
  data,
  setWebsiteInfoModal,
}) => {
  const [mobile_view, setMobileView] = useState("overview");

  return (
    <div className={classnames(mainClass, `${mainClass}--${mobile_view}`)}>
      <div className={`${mainClass}__mobile_menu`}>
        <Button
          variant={mobile_view === "overview" ? "" : "transparent"}
          onClick={() => setMobileView("overview")}
        >
          Overview
        </Button>
        <Button
          variant={mobile_view === "chat" ? "" : "transparent"}
          onClick={() => setMobileView("chat")}
        >
          Chat
        </Button>
      </div>
      <div className={`${mainClass}__content`}>
        <div className={`${mainClass}__content__details`}>
          <Details
            data={data}
            admin_mode={admin_mode}
            show_time_entries={false}
            hide_required_action={hide_required_action}
            setWebsiteInfoModal={setWebsiteInfoModal}
          />
          {!!admin_mode && <Button href={`/tasks/${data?._id}`}>Back</Button>}
        </div>
        <TimeTracked data={data} />
      </div>
    </div>
  );
};

export default TimeOverContent;
