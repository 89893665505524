import { object, string, ref } from "yup";

let registerSchema = object({
  first_name: string().required("Name cannot be empty."),
  last_name: string().required("Surname cannot be empty."),
  email: string()
    .required("Email cannot be empty.")
    .email("The email is not a valid email address."),
  password: string()
    .required("Password cannot be empty.")
    .min(10, "Password must be at least 10 characters.")
    .matches(/[0-9]/, "Password must have at least one number.")
    .matches(/[A-Z]/, "Password must have one upper case letter.")
    .matches(/[a-z]/, "Password must have one lower case letter."),
  password2: string()
    .required("Repeat password cannot be empty.")
    .oneOf([ref("password"), null], "Passwords must be the same."),
});

export default registerSchema;
