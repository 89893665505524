import "./styles.scss";

import moment from "moment";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import Button from "components/Button";
import PillGrid from "components/PillGrid";

const mainClass = "details-card";

const DetailsCard = ({
  title,
  action,
  view,
  children,
  no_border,
  aside,
  subscription_end,
  tabs,
  zIndex,
  onMouseLeave,
}) => {
  const { t } = useTranslation();

  const ActionComponent = () =>
    !!action &&
    view !== "payment_error" &&
    (!!Array.isArray(action) ? (
      <div className={`${mainClass}__header__buttons`}>
        {action?.map(
          (
            { href, onclick, loading, label, variant = "transparent" },
            index
          ) => (
            <Button
              key={index}
              size="small"
              variant={variant}
              href={href}
              onClick={onclick}
              loading={loading}
            >
              {label}
            </Button>
          )
        )}
      </div>
    ) : (
      <div className={`${mainClass}__header__buttons`}>
        <Button
          size="small"
          class_name={action?.half_margin_mobile ? "half-margin" : null}
          variant="transparent"
          href={action?.href}
          onClick={action?.onclick}
          loading={action?.loading}
        >
          {action?.label}
        </Button>
      </div>
    ));

  return (
    <div
      style={!!zIndex ? { zIndex } : {}}
      onMouseLeave={onMouseLeave || null}
      className={classnames(mainClass, {
        [`${mainClass}--error`]: view === "payment_error",
        [`${mainClass}--${view}`]: !!view,
        [`${mainClass}--no_border`]: !!no_border,
      })}
    >
      {!!subscription_end && (
        <div className={`${mainClass}__subscription_end`}>
          <span>{t("Subscription ends on")}</span>
          <div>
            <span>{moment(subscription_end)?.format("ll")}</span>
            <Button size="small" href="change-plan">
              {t("Renew plan")}
            </Button>
          </div>
        </div>
      )}
      {!!tabs && (
        <div className={`${mainClass}__tabs`}>
          <PillGrid items={tabs} size="small" />
        </div>
      )}

      <div className={`${mainClass}__header`}>
        <div className={`${mainClass}__header__wrapper`}>
          <h3>{title}</h3>
          {aside}
        </div>
        <ActionComponent />
      </div>
      <div className={`${mainClass}__content`}>
        {children}
        {!!action && (
          <div
            className={classnames(`${mainClass}__content__actions-mobile`, {
              [`${mainClass}__content__actions-mobile--no-margin`]:
                !!action?.half_margin_mobile,
            })}
          >
            <ActionComponent />
          </div>
        )}
      </div>
      {/* <div className={`${mainClass}__content__mobile-bottom`}>
        <div className={`${mainClass}__content__mobile-bottom__wrapper`}>
          <span>Scroll down</span>
        </div>
      </div> */}
    </div>
  );
};

export default DetailsCard;
