import "./styles.scss";

import { createPortal } from "react-dom";
import { Link } from "react-router-dom";

import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as TasksIcon } from "assets/icons/tasks.svg";
import { ReactComponent as DashboardIcon } from "assets/icons/dashboard.svg";
import { ReactComponent as NotificationIcon } from "assets/icons/notification.svg";
import { useNotifications } from "context/notifications";

const mainClass = "mobile-bar";

const MobileBar = () => {
  const { data } = useNotifications();
  const unreaded_count = data?.reduce((a, b) => a + (!!!b?.status ? 1 : 0), 0);

  return createPortal(
    <div className={mainClass}>
      <div className={`${mainClass}__items`}>
        <Link to="/">
          <DashboardIcon />
        </Link>
        <Link to="/tasks">
          <TasksIcon />
        </Link>
        <Link to="/notifications">
          <NotificationIcon />
          {!!unreaded_count && <span>{unreaded_count}</span>}
        </Link>
      </div>
      <Link to="/tasks/new">
        <PlusIcon />
      </Link>
    </div>,
    document.body
  );
};

export default MobileBar;
