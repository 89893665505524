import "./styles.scss";

import moment from "moment";
import { useTranslation } from "react-i18next";

import Table from "components/Table";
import Calendar from "components/Calendar";
import PillGrid from "components/PillGrid";
import Input from "components/form/Input";
import DashboardContentLayout from "components/layouts/DashboardContentLayout";

import { useClientTasks } from "context/admin/client_tasks";

import task_status_data from "libs/task_status_data.json";
import task_priority_data from "libs/task_priority_data.json";
import toHoursAndMinutes from "libs/helpers/toHoursAndMinutes";

const mainClass = "notifications-content";

const ClientTasksContent = () => {
  const { t } = useTranslation();

  const { data, loading, init_loading, filters, filterHandler } =
    useClientTasks();

  const breadcrumps = [
    {
      label: t("Clients"),
      href: "/clients",
    },
    {
      label: "adream.pl",
      href: `/clients/${data?.website_id}`,
    },
    {
      label: t("Tasks"),
    },
  ];

  const actions = [
    {
      type: "send_notification",
    },
    {
      type: "create_task",
    },
  ];

  const pills_task_priority = [
    {
      label: t("Show all"),
      value: "all",
      onclick: () => filterHandler("type", ""),
      active: !!!filters?.type,
    },
    ...task_priority_data?.map(({ key, label, value }) => ({
      label: t(label),
      value,
      onclick: () =>
        filterHandler(
          "type",
          task_priority_data?.find((item) => item?.value === value)?.key
        ),
      active: filters?.type === key,
    })),
  ];

  const pills_data = [
    {
      label: t("Show all"),
      value: "all",
      onclick: () => filterHandler("status", ""),
      active: !!!filters?.status,
    },
    ...task_status_data
      ?.filter(
        (item) => item?.value !== 6 && item?.value !== 7 && item?.value !== 2
      )
      ?.map(({ key, label, value }) => ({
        label: t(label),
        value,
        onclick: () =>
          filterHandler(
            "status",
            task_status_data?.find((item) => item?.value === value)?.key
          ),
        active: filters?.status === key,
      })),
  ];

  const pills_task_extra_status = [
    {
      label: t("Cancelled"),
      value: 6,
      onclick: () => filterHandler("status", "cancelled"),
      active: filters?.status === "cancelled",
    },
    {
      label: t("Closed"),
      value: 7,
      onclick: () => filterHandler("status", "closed"),
      active: filters?.status === "closed",
    },
  ];

  const table_data = {
    keys: [
      {
        key: "priority",
        value: t("Priority"),
      },
      {
        sort_key: "status",
        key: "status-pill",
        value: t("Status"),
      },
      {
        key: "title",
        value: t("Title"),
      },
      {
        key: "website",
        value: t("Website"),
      },
      {
        key: "estimated_time",
        value: t("Estimated time"),
      },
      {
        sort_key: "total_time",
        key: "time_tracked",
        value: t("Time tracked"),
      },
      {
        key: "due_date",
        value: t("Due date"),
      },
    ],
    items: data?.tasks?.map(
      ({
        _id,
        priority,
        title,
        website,
        status,
        estimated_time,
        total_time,
        due_date,
      }) => [
        {
          key: "priority",
          type: "priority",
          value: priority,
        },
        {
          key: "status-pill",
          type: "status-pill",
          value: task_status_data?.find((item) => item?.value === status)?.key,
        },
        {
          key: "title",
          type: "link",
          value: {
            label: title,
            href: `/tasks/${_id}`,
          },
        },
        {
          key: "website",
          type: "link",
          value: {
            label: website?.name,
            href: `/clients/${website?._id}`,
          },
        },
        {
          key: "estimated_time",
          value: !!estimated_time
            ? toHoursAndMinutes(estimated_time?.$numberDecimal)?.formatted
            : "-",
        },
        {
          key: "time_tracked",
          value: !!total_time?.$numberDecimal
            ? toHoursAndMinutes(total_time?.$numberDecimal)?.formatted
            : "-",
        },
        {
          key: "due_date",
          value: !!due_date ? moment(due_date)?.format("DD MMM, YYYY") : "-",
        },
      ]
    ),
  };

  return (
    <DashboardContentLayout
      header_label={data?.website}
      async_header
      loading={init_loading}
      breadcrumps={breadcrumps}
      actions={actions}
    >
      <div className={mainClass}>
        <Table
          data={table_data}
          loading={!!loading}
          header={
            <div style={{ display: "flex", gap: 64 }}>
              <PillGrid
                header={t("Task priority")}
                items={pills_task_priority}
                size="small"
              />
              <PillGrid
                header={t("Task status")}
                items={pills_data}
                size="small"
              />
            </div>
          }
          filter_default_open={Object?.keys(filters)?.length > 0}
          sort={filters?.sort}
          setSort={(value) => filterHandler("sort", value)}
          filters={
            <>
              <Input
                label={t("Search")}
                placeholder={t("Task title")}
                value={filters?.search}
                onChange={(e) => filterHandler("search", e.target.value)}
              />
              <Calendar
                label={t("Last update start date")}
                default_value={filters?.start_updated_at}
                onChange={(value) => filterHandler("start_updated_at", value)}
              />
              <Calendar
                label={t("Last update end date")}
                default_value={filters?.end_updated_at}
                onChange={(value) => filterHandler("end_updated_at", value)}
              />
              <PillGrid
                header={t("Show")}
                items={pills_task_extra_status}
                size="small"
              />
            </>
          }
        />
      </div>
    </DashboardContentLayout>
  );
};

export default ClientTasksContent;
