import SettingsContent from "page_components/admin/clients/Settings";
import ClientSettingsProvider from "context/admin/client-settings";

const Settings = () => (
  <ClientSettingsProvider>
    <SettingsContent />
  </ClientSettingsProvider>
);

export default Settings;
