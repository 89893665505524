import "./styles.scss";

import moment from "moment";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Modal from "components/Modal";
import Calendar from "components/Calendar";
import IconButton from "components/IconButton";
import FormikInput from "components/formik/FormikInput";
import WebmasterSelect from "components/form/WebmasterSelect";

import { useTask } from "context/admin/task";
import { useTasks } from "context/admin/tasks";

import toHoursAndMinutes from "libs/helpers/toHoursAndMinutes";

import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import DeleteTimeModal from "./components/DeleteTimeModal";
import { useState } from "react";

const mainClass = "edit-task-modal";

const EditTimeModal = ({ id, onClose }) => {
  const { t } = useTranslation();

  const { data, updateTaskTime } = useTask();
  const { webmasters } = useTasks();

  const [delete_time_modal, setDeleteTimeModal] = useState(false);

  const selected_item = data?.time_entries?.find((item) => item?._id === id);

  const formatted_webmasters = webmasters?.map(
    ({ _id, first_name, last_name, photo }) => ({
      label: (
        <>
          <img
            alt=""
            src={photo}
            style={{ height: 24, width: 24, objectFit: "cover" }}
          />
          &nbsp;&nbsp;{first_name} {last_name}
        </>
      ),
      value: _id,
    })
  );

  const onSubmit = async (values, setSubmitting) => {
    try {
      await updateTaskTime(id, values);
      onClose();
    } catch (err) {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          new_time: {
            hours: null,
            minutes: null,
          },
          created_at: "",
          webmaster: [selected_item?.webmaster?._id],
        }}
        // validationSchema={editTaskSchema}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          onSubmit(values, setSubmitting, setErrors);
        }}
      >
        {({
          values,
          isSubmitting,
          submitForm,
          setFieldValue,
          errors,
          touched,
        }) => (
          <Form autoComplete="off">
            <Modal
              title={t("Edit time")}
              onClose={onClose}
              onSubmit={submitForm}
              loading={isSubmitting}
              variant="little-wide"
            >
              <div className={mainClass}>
                <div className={`${mainClass}__info`}>
                  <span>{t("Current time")}</span>
                  <div className={`${mainClass}__info__item`}>
                    <span>
                      {`${
                        toHoursAndMinutes(
                          parseFloat(selected_item?.time?.$numberDecimal)
                        )?.formatted
                      } ${t("on")}
                    ${moment(selected_item?.created_at)?.format("ll")}`}
                      <span> {t("by")} </span>
                      <Link
                        to={`/webmasters/${selected_item?.webmaster?._id}`}
                      >{`${selected_item?.webmaster?.first_name} ${selected_item?.webmaster?.last_name}`}</Link>
                    </span>
                    <IconButton
                      size="small"
                      variant="error"
                      onClick={() => setDeleteTimeModal(true)}
                      icon={<TrashIcon />}
                    />
                  </div>
                </div>
                <div className={`${mainClass}__content`}>
                  <div className={`${mainClass}__col`}>
                    <div className="form__input-group">
                      <span>{t("New time")}</span>
                      <div className="form__input-group__inputs">
                        <FormikInput
                          name="new_time.hours"
                          placeholder={t("Hours")}
                          disabled={isSubmitting}
                        />
                        <span>-</span>
                        <FormikInput
                          name="new_time.minutes"
                          placeholder={t("Minutes")}
                          disabled={isSubmitting}
                        />
                      </div>
                    </div>
                    <Calendar
                      label={t("New date")}
                      error={errors?.created_at}
                      onChange={(value) => setFieldValue("created_at", value)}
                    />
                  </div>
                  <div className={`${mainClass}__col`}>
                    <WebmasterSelect
                      value={values?.webmaster}
                      options={formatted_webmasters}
                      onChange={setFieldValue}
                      error={errors?.webmaster}
                      touched={touched}
                      multi={false}
                    />
                  </div>
                </div>
              </div>
            </Modal>
          </Form>
        )}
      </Formik>
      {!!delete_time_modal && (
        <DeleteTimeModal
          id={id}
          onClose={() => setDeleteTimeModal(false)}
          editTimeClose={onClose}
        />
      )}
    </>
  );
};

export default EditTimeModal;
