import "./styles.scss";

import classnames from "classnames";

import { ReactComponent as MastercardIcon } from "assets/icons/mastercard.svg";
import { ReactComponent as VisaIcon } from "assets/icons/visa.svg";
import { useTranslation } from "react-i18next";

const mainClass = "details-card-credit-card";

const card_type_render = (status) => {
  switch (status) {
    case "mastercard":
      return (
        <>
          <MastercardIcon />
          <span>Credit Card</span>
        </>
      );
    case "visa":
      return (
        <>
          <VisaIcon />
          <span>Credit Card</span>
        </>
      );
    default:
      return "";
  }
};

const DetailsCardCard = ({ value }) => {
  const { t } = useTranslation();

  return (
    <div
      className={classnames(
        mainClass,
        `${mainClass}--${!!value ? "good" : "wrong"}`
      )}
    >
      <div className={`${mainClass}__name`}>
        {card_type_render(value?.card_type)}
        <div
          className={classnames(
            `${mainClass}__name__status`,
            `${mainClass}__name__status--${
              value.status === 2
                ? "warning"
                : !!value.status
                ? "active"
                : "unactive"
            }`
          )}
        >
          {value.status === 2
            ? t("Expires soon")
            : !!value?.status
            ? t("Active")
            : t("Inactive")}
        </div>
      </div>
      <span>{value?.card_numbers}</span>
    </div>
  );
};

export default DetailsCardCard;
