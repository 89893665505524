import "./styles.scss";

import classnames from "classnames";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Pill from "components/Pill";
import Button from "components/Button";
import PriorityFlag from "components/PriorityFlag";
import TrackerButton from "components/TrackerButton";

import useResponsive from "libs/helpers/useResponsive";
import website_status_data from "libs/website_status_data.json";

const mainClass = "table";

const MobileSlider = ({ data }) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  const table_value_type = (type, value) => {
    switch (type) {
      case "button":
        return (
          <Button
            external_href={!!value?.external}
            href={value?.href}
            onClick={value?.onclick}
            loading={value?.loading}
            size="small"
          >
            {value?.label}
          </Button>
        );
      case "status":
        return (
          <span
            className={classnames(`${mainClass}__status`, {
              [`${mainClass}__status--active`]: !!value,
            })}
          ></span>
        );
      case "status-pill":
        return <Pill variant={value} label={value} readOnly size="small" />;
      case "priority":
        return <PriorityFlag priority={value} />;
      case "link":
        return (
          <Link className={`${mainClass}__link`} to={value?.href}>
            {value?.label}
          </Link>
        );
      case "clickable_text":
        return (
          <span
            onClick={value?.onclick}
            className={`${mainClass}__clickable_text`}
          >
            {value?.label}
          </span>
        );
      case "website_status":
        const website_status = website_status_data?.find(
          (item) => item?.value === value
        );
        return (
          <span
            className={classnames(`${mainClass}__website_status`, {
              [`${mainClass}__website_status--${website_status?.key}`]: !!value,
            })}
          >
            {t(website_status?.label)}
          </span>
        );
      case "website_items":
        return value?.length > 0 ? (
          <div className={`${mainClass}__website_items`}>
            {value?.map((item, index) => (
              <div key={index} className={`${mainClass}__website_items__item`}>
                <span />
                <Link to={item?.href}>{item?.label}</Link>
              </div>
            ))}
          </div>
        ) : (
          "-"
        );
      case "webmaster_tracker":
        return (
          <span className={classnames(`${mainClass}__webmaster_tracker`)}>
            <TrackerButton
              active={value?.active}
              time={value?.label}
              onClick={value?.onclick}
              start_time_tracked={value?.start_time_tracked}
            />
          </span>
        );

      default:
        return <span>{value}</span>;
    }
  };

  return (
    <div className="mobile-table-list">
      {data?.items?.map((elements, index) => {
        const is_disabled = elements?.find(
          (item) => item?.key === "notification_status"
        )
          ? !!!elements?.find((item) => item?.key === "notification_status")
              ?.value
          : false;

        const formatted_elements = !!isMobile
          ? elements?.filter((item) => item?.key !== "notification_status")
          : elements;

        let title = elements?.find((item) => !!item?.mobile_title);

        return (
          <div
            key={index}
            className={classnames(`details-card`, {
              [`mobile-table-list__row--disabled`]: !!is_disabled,
            })}
          >
            {!!!title?.hidden && (
              <div className="details-card__header">
                <div className="details-card__header__wrapper">
                  <h3>{title?.value?.label}</h3>
                </div>
              </div>
            )}
            <div className="details-card__content">
              <div className="details-card__list">
                {formatted_elements?.map(
                  ({ key, value, type, hidden }, index) => {
                    if (title?.key === key) return null;

                    return (
                      <div key={index} className="details-card__list__item">
                        <label>
                          {data?.keys?.find((item) => item?.key === key)?.value}
                        </label>
                        <span>{table_value_type(type, value)}</span>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MobileSlider;
