import { createContext, useContext, useMemo } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import api from "libs/api";

const TaskContext = createContext();

const TaskProvider = ({ children }) => {
  const { t } = useTranslation();

  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { isLoading: loading, data: task } = useQuery(["task", id], () =>
    api.get(`/admin/tasks/${id}`)
  );

  const { data: webmasters } = useQuery(["webmasters"], () =>
    api.get("/admin/webmasters")
  );

  const updateTask = (values) =>
    new Promise(async (resolve, reject) => {
      try {
        const updated = await api.put(`/admin/tasks/${id}`, values);

        queryClient.setQueryData(["task", id], (prev) => ({
          ...prev,
          ...updated,
          updated_at: new Date(),
        }));

        if (!(!!!values?.status || !!!values?.priority)) {
          toast.success(t("Saved successfully."));
        }
        resolve();
      } catch (err) {
        console.error(err);
        toast.error("Something went wrong.");
        reject(err);
      }
    });

  const updateTaskTime = (time_id, values) =>
    new Promise(async (resolve, reject) => {
      try {
        const updated = await api.put(
          `/admin/tasks/${id}/time/${time_id}`,
          values
        );

        let temp_data = { ...task };

        const index = temp_data?.time_entries?.findIndex(
          (item) => item?._id === updated?._id
        );

        temp_data.time_entries[index] = {
          ...updated,
        };

        queryClient.setQueryData(["task", id], (prev) => ({
          ...prev,
          ...temp_data,
          updated_at: new Date(),
        }));

        toast.success(t("Saved successfully."));

        resolve();
      } catch (err) {
        console.error(err);
        toast.error("Something went wrong.");
        reject(err);
      }
    });

  const deleteTaskTime = (time_id) =>
    new Promise(async (resolve, reject) => {
      try {
        await api.delete(`/admin/tasks/${id}/time/${time_id}`);

        let temp_data = { ...task };

        const index = temp_data?.time_entries?.findIndex(
          (item) => item?._id === time_id
        );

        temp_data.time_entries?.splice(index, 1);

        queryClient.setQueryData(["task", id], (prev) => ({
          ...prev,
          ...temp_data,
          updated_at: new Date(),
        }));

        toast.success("Deleted successfully.");

        resolve();
      } catch (err) {
        console.error(err);
        toast.error("Something went wrong.");
        reject(err);
      }
    });

  const deleteTask = () =>
    new Promise(async (resolve, reject) => {
      try {
        await api.delete(`/admin/tasks/${id}`);

        navigate("/tasks");

        toast.success("Task deleted.");
        resolve();
      } catch (err) {
        toast.error("Something went wrong.");
        reject(err);
      }
    });

  const value = useMemo(() => {
    return {
      data: task,
      webmasters,
      updateTask,
      deleteTask,
      deleteTaskTime,
      updateTaskTime,
      loading,
    };
    // eslint-disable-next-line
  }, [task, webmasters, loading]);

  return <TaskContext.Provider value={value}>{children}</TaskContext.Provider>;
};

const useTask = () => useContext(TaskContext);
export { TaskContext, useTask };
export default TaskProvider;
