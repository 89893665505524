import "./styles.scss";

import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";

import Modal from "components/Modal";
import Calendar from "components/Calendar";
import FormikInput from "components/formik/FormikInput";
import FormikSelect from "components/formik/FormikSelect";

import { useClient } from "context/admin/client";

import website_status_data from "libs/website_status_data.json";
import overviewSchema from "libs/validations/admin/overviewSchema";

import { ReactComponent as GreenStatusIcon } from "assets/icons/green-status.svg";
import { ReactComponent as RedStatusIcon } from "assets/icons/red-status.svg";
import { ReactComponent as BlueStatusIcon } from "assets/icons/blue-status.svg";

const status_icon_render = (status) => {
  switch (status) {
    case 1:
      return <GreenStatusIcon />;
    case 2:
      return <RedStatusIcon />;
    case 3:
      return <BlueStatusIcon />;
    default:
      return null;
  }
};

const OverviewModal = ({ onClose }) => {
  const { t } = useTranslation();

  const { data, updateData } = useClient();

  const status_options = website_status_data?.map(({ label, value }) => ({
    icon: status_icon_render(value),
    label: t(label),
    value,
  }));

  const onSubmit = async (values, setSubmitting) => {
    try {
      await updateData(values);
      onClose();
    } catch (err) {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        website: data?.website,
        status: data?.status,
        last_cms_update: data?.last_cms_update,
        domain_valid_until: data?.domain_valid_until,
        password1_link: data?.password1_link,
      }}
      validationSchema={overviewSchema}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({ isSubmitting, submitForm, values, setFieldValue }) => (
        <Form autoComplete="off">
          <Modal
            title={t("Change overview")}
            onClose={onClose}
            onSubmit={submitForm}
            loading={isSubmitting}
          >
            <div className="form">
              <FormikInput
                name="website"
                label={t("Website URL")}
                disabled={isSubmitting}
                required
              />
              <FormikSelect
                name="status"
                label={t("Status")}
                options={status_options}
                disabled={isSubmitting}
                required
              />
              <Calendar
                anchor="top"
                label={t("Last CMS update")}
                default_value={values?.last_cms_update}
                onChange={(value) => setFieldValue("last_cms_update", value)}
              />
              <Calendar
                anchor="top"
                label={t("Domain valid until")}
                default_value={values?.domain_valid_until}
                onChange={(value) => setFieldValue("domain_valid_until", value)}
              />
              <FormikInput
                name="password1_link"
                label={t("Access data link")}
                disabled={isSubmitting}
                required
              />
              <div className="form__required-info">
                <small>
                  <span>*</span> {t("required field")}
                </small>
              </div>
            </div>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default OverviewModal;
