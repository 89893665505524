import WebmastersContent from "page_components/admin/webmasters/Content";
import WebmastersProvider from "context/admin/webmasters";

const Webmasters = () => (
  <WebmastersProvider>
    <WebmastersContent />
  </WebmastersProvider>
);

export default Webmasters;
