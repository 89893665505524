import { createContext, useContext, useMemo, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import { useApp } from "../app";

import api from "libs/api";
import notificationSound from "assets/sounds/notification.mp3";
import { toast } from "react-toastify";

let audio = new Audio(notificationSound);

const TaskContext = createContext();

const TaskProvider = ({ children }) => {
  const { user, socket } = useApp();
  const { id } = useParams();

  const [task, setTask] = useState(null);
  const [loading, setLoading] = useState(true);
  const [file_progress, setFileProgress] = useState(0);
  const [task_close_confirmation, setTaskCloseConfirmation] = useState(false);

  useEffect(() => {
    (async function () {
      await getTaskData();
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!!!socket) return;

    socket.emit("join_task", id);
    socket.on("task_update", (task) => {
      const last_user_id =
        task?.messages?.[task?.messages?.length - 1]?.user?._id;

      setTask({ ...task });

      if (user?._id !== last_user_id) audio.play();
    });

    return () => {
      if (!!!socket) return;

      socket.emit("task_leave", id);
    };

    // eslint-disable-next-line
  }, [socket]);

  const getTaskData = async () => {
    try {
      setLoading(true);
      const task = await api.get(`/client/tasks/${id}`);
      setTask(task);
    } catch (err) {}

    setLoading(false);
  };

  const updateTask = (values) =>
    new Promise(async (resolve, reject) => {
      try {
        await api.put(`/client/tasks/${id}`, values);

        resolve();
      } catch (err) {
        console.error(err);
        toast.error("Something went wrong.");
        reject(err);
      }
    });

  const sendMessage = async (text, files) => {
    const formData = new FormData();
    formData.append("text", text);
    files?.forEach((item) => {
      formData.append("files", item);
    });

    try {
      await axios.post(
        `${process.env.REACT_APP_API}/client/tasks/${id}/send-message`,
        formData,
        {
          withCredentials: true,
          onUploadProgress: ({ loaded, total }) => {
            const percent = Math.floor((loaded * 100) / total);
            setFileProgress(percent);
          },
        }
      );
    } catch (err) {
      console.error(err);
    }

    setFileProgress(0);
  };

  const editMessage = async (message_id, text) => {
    try {
      await api.put(`/client/tasks/${id}/edit-message/${message_id}`, {
        text,
      });

      let temp_task = { ...task };

      const index = temp_task?.messages?.findIndex(
        (item) => item?._id === message_id
      );
      temp_task.messages[index] = {
        ...temp_task.messages[index],
        text,
      };

      setTask({ ...temp_task });
    } catch (err) {
      console.error(err);
    }

    setFileProgress(0);
  };

  const value = useMemo(() => {
    return {
      data: task,
      file_progress,
      sendMessage,
      editMessage,
      loading,
      updateTask,
      task_close_confirmation,
      setTaskCloseConfirmation,
    };
    // eslint-disable-next-line
  }, [task, file_progress, loading, task_close_confirmation]);

  return <TaskContext.Provider value={value}>{children}</TaskContext.Provider>;
};

const useTask = () => useContext(TaskContext);
export { TaskContext, useTask };
export default TaskProvider;
