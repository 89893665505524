import TasksContent from "page_components/admin/tasks/Content";
import TasksProvider from "context/admin/tasks";

const Tasks = () => (
  <TasksProvider>
    <TasksContent />
  </TasksProvider>
);

export default Tasks;
