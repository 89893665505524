import "./styles.scss";

import { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useTranslation } from "react-i18next";

import Button from "components/Button";

import { useWebsite } from "context/client/website";
import api from "libs/api";

const mainClass = "details-card__payment-error";
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PK}`);

const PaymentError = () => {
  const { t } = useTranslation();

  const { data, customerPortal } = useWebsite();
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    try {
      setLoading(true);

      if (!!data?.subscription?.status) {
        customerPortal();
      } else {
        const continuePayment = await api.post(
          `/client/websites/${data?._id}/continue-payment`
        );
        if (continuePayment) {
          stripePromise.then((c) => {
            const { error } = c.redirectToCheckout({
              sessionId: continuePayment?.id,
            });
            console.error(error);
          });
        }
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  return (
    <div className={mainClass}>
      <span>
        {t(
          "There was a problem processing your payment. Check your payment information and try again"
        )}
      </span>
      <Button type="button" onClick={onSubmit} loading={loading}>
        {t("Pay now")}
      </Button>
    </div>
  );
};

export default PaymentError;
