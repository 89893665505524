import { useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "components/Button";
import Conversation from "page_components/shared/tasks/Conversation";
import DashboardContentLayout from "components/layouts/DashboardContentLayout";
import WebmasterCompletedModal from "page_components/shared/tasks/Conversation/components/Details/components/WebmasterCompletedModal";

import TaskConversationProvider from "context/webmaster/task_conversation";
import WebsiteInfoModal from "components/WebsiteInfoModal";
import { useTaskConversation } from "context/webmaster/task_conversation";

const TaskConversationContent = () => {
  const { t } = useTranslation();

  const { data, file_progress, sendMessage, editMessage, loading } =
    useTaskConversation();
  const [completed_modal, setCompletedModal] = useState(false);
  const [website_info_modal, setWebsiteInfoModal] = useState(null);

  const required_action_buttons = () => {
    switch (data?.status) {
      case 4:
        return (
          <>
            <Button onClick={() => setCompletedModal(true)}>
              {t("Mark task as completed")}
            </Button>
          </>
        );
      default:
        return null;
    }
  };

  const breadcrumps = [
    {
      label: t("Tasks"),
      href: "/tasks",
    },
    {
      label: data?.website,
      href: `/tasks?website=${data?.website_id}`,
    },
    {
      label: data?.title,
      href: `/tasks/${data?._id}`,
    },
    {
      label: t("Conversation"),
    },
  ];

  return (
    <DashboardContentLayout
      header_label={t("Conversation")}
      loading={loading}
      breadcrumps={breadcrumps}
    >
      <Conversation
        data={data}
        file_progress={file_progress}
        sendMessage={sendMessage}
        editMessage={editMessage}
        required_action_buttons={required_action_buttons()}
        setWebsiteInfoModal={setWebsiteInfoModal}
      />
      {!!completed_modal && (
        <WebmasterCompletedModal onClose={() => setCompletedModal(false)} />
      )}
      {!!website_info_modal && (
        <WebsiteInfoModal
          id={website_info_modal}
          onClose={() => setWebsiteInfoModal(null)}
        />
      )}
    </DashboardContentLayout>
  );
};

const TaskConversation = () => (
  <TaskConversationProvider>
    <TaskConversationContent />
  </TaskConversationProvider>
);

export default TaskConversation;
