import WebsiteAccessDataProvider from "context/client/website_access_data";
import AccessDataContent from "page_components/client/websites/AccessData";

const AccessData = () => (
  <WebsiteAccessDataProvider>
    <AccessDataContent />
  </WebsiteAccessDataProvider>
);

export default AccessData;
