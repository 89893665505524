import "./styles.scss";

import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";

import Button from "components/Button";
import ChangeInfo from "./components/ChangeInfo";
import FormikInput from "components/formik/FormikInput";
import SubscriptionPlan from "./components/SubscriptionPlan";

import choosePlanSchema from "libs/validations/new_website_form/choosePlanSchema";

import { useNewWebsite } from "context/client/new_website";
import useResponsive from "libs/helpers/useResponsive";

const mainClass = "new-website-content__choose-plan";

const ChoosePlan = () => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  const {
    website,
    change_plan_mode,
    form_data,
    nextStep,
    mobile_step,
    setMobileStep,
  } = useNewWebsite();

  const onSubmit = (values) => {
    nextStep(values);
  };

  const onMobileStepHandler = async (type, validate, setErrors) => {
    if (type === "next") {
      if (mobile_step === 0) {
        const errors = await validate("website");
        if (!!errors?.website) return;
      }
    }

    setMobileStep((prev) => (type === "next" ? prev + 1 : prev - 1));
  };

  return (
    <Formik
      initialValues={{
        website: !!change_plan_mode
          ? website?.website
          : form_data?.website || "",
        plan: form_data?.plan || null,
        billed_type: form_data?.billed_type || "monthly",
      }}
      validationSchema={choosePlanSchema}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({ values, isSubmitting, setFieldTouched, setErrors }) => (
        <Form className={mainClass} autoComplete="off">
          <div className={`${mainClass}__wrapper`}>
            <div className={`${mainClass}__form form`}>
              {(!isMobile || mobile_step === 0) && (
                <div className={`${mainClass}__form__top`}>
                  <h3>{t("Website data")}</h3>
                  <FormikInput
                    name="website"
                    label={t("Website address")}
                    placeholder="www.example.com"
                    disabled={isSubmitting || !!change_plan_mode}
                    required
                  />
                  <div className={`${mainClass}__form__required-info`}>
                    <small>
                      <span>*</span> {t("required field")}
                    </small>
                  </div>
                </div>
              )}
              {!!change_plan_mode && values?.plan ? (
                <ChangeInfo />
              ) : (
                <div className={`${mainClass}__form__bottom`}>
                  {!!values?.plan && (
                    <div className={`${mainClass}__form__bottom__terms`}>
                      <a
                        href={t("terms_of_use_link")}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t("Terms of Use")}
                      </a>
                    </div>
                  )}
                </div>
              )}
            </div>
            {(!isMobile || mobile_step > 0) && <SubscriptionPlan />}
          </div>
          {!isMobile ? (
            <div className={`${mainClass}__bottom`}>
              <Button type="submit" variant="transparent" href="/">
                {t("Back")}
              </Button>
              <Button type="submit">{t("Next step")}</Button>
            </div>
          ) : (
            <div className={`${mainClass}__bottom--mobile`}>
              {mobile_step === 0 ? (
                <Button
                  key={mobile_step}
                  variant="transparent"
                  onClick={() =>
                    onMobileStepHandler("next", setFieldTouched, setErrors)
                  }
                >
                  {t("Next step")}
                </Button>
              ) : (
                <>
                  <Button
                    variant="transparent"
                    onClick={() => setMobileStep((prev) => prev - 1)}
                  >
                    {t("Back")}
                  </Button>
                  <Button
                    type={mobile_step === 2 ? "submit" : "button"}
                    onClick={
                      mobile_step === 2
                        ? null
                        : () => setMobileStep((prev) => prev + 1)
                    }
                  >
                    {t("Next step")}
                  </Button>
                </>
              )}
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default ChoosePlan;
