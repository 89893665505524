import "./styles.scss";

import Switch from "components/form/Switch";

const mainClass = "details-card-switch";

const DetailsCardSwitch = ({ value, onClick }) => {
  return (
    <div className={mainClass}>
      <Switch onChange={onClick} checked={value} />
    </div>
  );
};

export default DetailsCardSwitch;
