import "./styles.scss";

import { NavLink } from "react-router-dom";
import { Collapse } from "react-collapse";
import { useTranslation } from "react-i18next";
import classnames from "classnames";

import adminMenu from "./components/admin";
import clientMenu from "./components/client";
import webmasterMenu from "./components/webmaster";

import { useApp } from "context/app";
import { useNotifications } from "context/notifications";

import useResponsive from "libs/helpers/useResponsive";

import { ReactComponent as ArrowIcon } from "assets/icons/list-arrow.svg";
import { ReactComponent as MessageIcon } from "assets/icons/message.svg";
import { ReactComponent as LogoutIcon } from "assets/icons/logout.svg";
import { ReactComponent as FaqIcon } from "assets/icons/faq.svg";
import { ReactComponent as ArrowItemIcon } from "assets/icons/arrow-mobile-options.svg";

const mainClass = "dashboard-layout-sidebar";

const Menu = ({ setMobileSidebarOpen, setWebisteMobileOpen }) => {
  const { t } = useTranslation();
  const { user, logout } = useApp();
  const { data } = useNotifications();
  const { isMobile } = useResponsive();

  const menu_type_render = (user_type, unreaded_count) => {
    switch (user_type) {
      case "client":
        return clientMenu(unreaded_count);
      case "admin":
        return adminMenu(unreaded_count);
      case "webmaster":
        return webmasterMenu(unreaded_count);
      default:
        return null;
    }
  };

  const status_class_render = (status) => {
    switch (status) {
      case 1:
        return "good";
      case 2:
      case 4:
        return "wrong";
      case 3:
        return "verification";
      default:
        return {
          text: "-",
          class: null,
        };
    }
  };

  const unreaded_count = data?.reduce((a, b) => a + (!!!b?.status ? 1 : 0), 0);
  const menu_items = menu_type_render(user?.type, unreaded_count);

  return (
    <div className={`${mainClass}__menu`}>
      <div className={`${mainClass}__menu__list`}>
        {menu_items?.map(
          (
            { icon, label, href, extended, onclick, extended_items, count },
            index
          ) =>
            !!href ? (
              <NavLink
                key={index}
                to={href}
                onClick={() => setMobileSidebarOpen(false)}
                className={({ isActive }) =>
                  isActive ? `${mainClass}__menu__list--active` : ""
                }
              >
                <span>
                  {icon}
                  {label}
                </span>
                {!!count && (
                  <small className={`${mainClass}__menu__list__count`}>
                    {count}
                  </small>
                )}
              </NavLink>
            ) : isMobile ? (
              <button
                key={index}
                type="button"
                onClick={() => setWebisteMobileOpen(true)}
                className={({ isActive }) =>
                  isActive ? `${mainClass}__menu__list--active` : ""
                }
              >
                <span>
                  {icon}
                  {label}
                </span>
                <ArrowItemIcon height={15} />
              </button>
            ) : (
              <div
                key={index}
                className={classnames(`${mainClass}__menu__list__extend`, {
                  [`${mainClass}__menu__list__extend--open`]: !!extended,
                })}
              >
                <button onClick={onclick}>
                  <span>
                    {icon}
                    {label}
                  </span>
                  {!!extended_items && <ArrowIcon />}
                </button>
                <Collapse
                  theme={{
                    collapse: `${mainClass}__menu__list__extend__items`,
                  }}
                  isOpened={!!extended}
                >
                  {extended_items?.map(
                    ({ label, href, status, disabled }, index) => {
                      const status_class = status_class_render(status);

                      return (
                        <NavLink
                          key={index}
                          to={href}
                          onClick={() => setMobileSidebarOpen(false)}
                          className={({ isActive }) =>
                            classnames({
                              [`${mainClass}__menu__list--active`]: !!isActive,
                              [status_class]: !!status_class,
                              disabled: !!disabled,
                            })
                          }
                        >
                          <span>{label}</span>
                        </NavLink>
                      );
                    }
                  )}
                </Collapse>
              </div>
            )
        )}
      </div>
      <div className={`${mainClass}__menu__list`}>
        <NavLink
          to={"/faq"}
          onClick={() => setMobileSidebarOpen(false)}
          className={({ isActive }) =>
            isActive ? `${mainClass}__menu__list--active` : ""
          }
        >
          <span>
            <FaqIcon />
            {t("FAQ")}
          </span>
        </NavLink>
        {user?.type === "client" && (
          <NavLink
            to={"/contact"}
            onClick={() => setMobileSidebarOpen(false)}
            className={({ isActive }) =>
              isActive ? `${mainClass}__menu__list--active` : ""
            }
          >
            <span>
              <MessageIcon />
              {t("Contact us")}
            </span>
          </NavLink>
        )}
        <button onClick={logout}>
          <span>
            <LogoutIcon />
            {t("Sign out")}
          </span>
        </button>
      </div>
    </div>
  );
};

export default Menu;
