import "./styles.scss";
import StatusErrorIcon from "./components/StatusErrorIcon";
import StatusOkIcon from "./components/StatusOkIcon";
import StatusVerificationIcon from "./components/StatusVerificationIcon";

import { useTranslation } from "react-i18next";

// import { ReactComponent as StatusOkIcon } from "assets/icons/status-ok.svg";

const mainClass = "dashboard-status";

const Status = ({ status }) => {
  const { t } = useTranslation();

  const status_data = [
    {
      id: 0,
      icon: <StatusErrorIcon />,
      label: t("Payment problem"),
      text: t(
        "There was a problem paying for your subscription. Check your payment method details and make sure they are up to date"
      ),
    },
    {
      id: 1,
      icon: <StatusOkIcon />,
      label: t("Everything is ok"),
      text: t(
        "Your website is safe and up to date. Your payment method is active."
      ),
    },
    {
      id: 2,
      icon: <StatusErrorIcon />,
      label: t("Payment problem"),
      text: t(
        "There was a problem paying for your subscription. Check your payment method details and make sure they are up to date"
      ),
    },
    {
      id: 3,
      icon: <StatusVerificationIcon />,
      label: t("Verification in progress"),
      text: t(
        "It may take up to 24h for us to verify if everything is ok with your website"
      ),
    },
    {
      id: 4,
      icon: <StatusErrorIcon />,
      label: t("Website does not respond"),
      text: t(
        "There may be a problem with your website or its server. We have received an automatic notification of the issue and are working to resolve it."
      ),
    },
  ];

  const current_status = status_data?.find((item) => item?.id === status);

  return (
    <div className={mainClass}>
      {current_status?.icon}
      <h3>{current_status?.label}</h3>
      <p>{current_status?.text}</p>
    </div>
  );
};

export default Status;
