import "./styles.scss";

import classnames from "classnames";

import { ReactComponent as InformationIcon } from "assets/icons/information.svg";

const mainClass = "radio-button";

const RadioButton = ({
  label,
  type = "radio",
  onClick,
  name,
  input_value,
  icon,
  disabled,
  onHintClick,
  checked,
}) => {
  return (
    <div
      className={classnames(mainClass, {
        [`${mainClass}--active`]: !!checked,
        [`${mainClass}--icon`]: !!icon,
      })}
    >
      <label>
        {icon}
        {label}
        <input
          type={type}
          name={name}
          onClick={onClick}
          checked={checked}
          value={input_value}
          disabled={disabled}
        />
        <span className="checkmark" />
      </label>
      <button className={`${mainClass}__hint`} onClick={onHintClick}>
        <InformationIcon />
      </button>
    </div>
  );
};

export default RadioButton;
