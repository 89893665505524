import { useTranslation } from "react-i18next";

import { ReactComponent as InvoicesIcon } from "assets/icons/invoices.svg";
import { ReactComponent as TasksIcon } from "assets/icons/tasks.svg";
import { ReactComponent as NotificationIcon } from "assets/icons/notification.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";

const WebmasterMenu = (unreaded_count) => {
  const { t } = useTranslation();

  const menu_items = [
    {
      icon: <InvoicesIcon />,
      label: t("Dashboard"),
      href: "/",
    },
    {
      icon: <TasksIcon />,
      label: t("Tasks"),
      href: "/tasks",
    },
    {
      icon: <NotificationIcon />,
      label: t("Notifications"),
      href: "/notifications",
      count: unreaded_count,
    },
    {
      icon: <SettingsIcon />,
      label: t("Account settings"),
      href: "/settings",
    },
  ];

  return menu_items;
};

export default WebmasterMenu;
