import "./styles.scss";

import classnames from "classnames";
import { useTranslation } from "react-i18next";

const mainClass = "task-conversation__status";

const StatusMessage = ({ template, is_hightlighted }) => {
  const { t } = useTranslation();

  const status_text_render = (template) => {
    switch (template) {
      case 1:
        return `${t("Task status changed to")} "${t("Awaiting estimation")}"`;
      case 2:
        return `${t("Task status changed to")} "${t("Awaiting your action")}"`;
      case 3:
        return `${t("Task status changed to")} "${t(
          "Accepted to realization"
        )}"`;
      case 4:
        return `${t("Task status changed to")} "${t("In progress")}"`;
      case 5:
        return `${t("Task status changed to")} "${t("Awaiting verification")}"`;
      case 6:
        return `${t("Task status changed to")} "${t("Closed")}"`;
      case 7:
        return `${t("Task status changed to")} "${t("Cancelled")}"`;
      default:
        return "";
    }
  };

  return (
    <div className={mainClass}>
      <div
        className={classnames(`${mainClass}__content`, {
          [`${mainClass}__content--hightlighted`]: !!is_hightlighted,
        })}
      >
        <p>{status_text_render(template)}</p>
      </div>
    </div>
  );
};

export default StatusMessage;
