import "./styles.scss";

import { ReactComponent as ArrowIcon } from "assets/icons/long-arrow.svg";

const mainClass = "details-change-plan-preview";

const ChangePlanPreview = ({ value }) => {
  return (
    <div className={mainClass}>
      {value?.current_plan}
      <ArrowIcon />
      {value?.selected_plan}
    </div>
  );
};

export default ChangePlanPreview;
