import "./styles.scss";

import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";

import Modal from "components/Modal";
import FormikInput from "components/formik/FormikInput";
import PasswordValidator from "components/PasswordValidator";

import changePasswordSchema from "libs/validations/changePasswordSchema";
import { useSettings } from "context/settings";

const ChangePasswordModal = ({ onClose }) => {
  const { t } = useTranslation();

  const { changePassword } = useSettings();

  const onSubmit = async (values, setSubmitting, setErrors) => {
    try {
      await changePassword(values);
      onClose();
    } catch (err) {
      if (err?.response?.data?.type === "wrong_password") {
        setErrors({ old_password: err?.response?.data?.error });
      }
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        old_password: "",
        new_password: "",
        new_password2: "",
      }}
      validationSchema={changePasswordSchema}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        onSubmit(values, setSubmitting, setErrors);
      }}
    >
      {({ values, isSubmitting, submitForm }) => (
        <Form autoComplete="off">
          <Modal
            title={t("Change password")}
            onClose={onClose}
            onSubmit={submitForm}
            loading={isSubmitting}
          >
            <div className="form">
              <FormikInput
                name="old_password"
                label={t("Old password")}
                type="password"
                placeholder="****************"
                disabled={isSubmitting}
              />
              <FormikInput
                name="new_password"
                label={t("New password")}
                type="password"
                placeholder="****************"
                disabled={isSubmitting}
              />
              <FormikInput
                name="new_password2"
                label={t("Repeat new password")}
                type="password"
                placeholder="****************"
                disabled={isSubmitting}
              />
              <PasswordValidator password={values?.new_password} />
            </div>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default ChangePasswordModal;
