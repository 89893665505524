import "./styles.scss";

import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";

import Modal from "components/Modal";
import FormikTextarea from "components/formik/FormikTextarea";

import { useTask } from "context/client/task";
import taskCommentSchema from "libs/validations/taskCommentSchema";

const ClientCommentsModal = ({ onClose }) => {
  const { t } = useTranslation();

  const { sendMessage, updateTask } = useTask();

  const onSubmit = async (values, setSubmitting) => {
    try {
      await sendMessage(values?.message);
      await updateTask({ status: 4 });
      onClose();
    } catch (err) {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        message: "",
      }}
      validationSchema={taskCommentSchema}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({ isSubmitting, submitForm }) => (
        <Form autoComplete="off">
          <Modal
            title={t("Request a correction")}
            onClose={onClose}
            onSubmit={submitForm}
            loading={isSubmitting}
            submit_button_label="Send"
          >
            <div className="form">
              <FormikTextarea
                name="message"
                label={t("Description")}
                disabled={isSubmitting}
                placeholder={t(
                  "Let us know if we have made any mistakes that need to be corrected."
                )}
              />
            </div>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default ClientCommentsModal;
