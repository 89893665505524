import { object, string } from "yup";

let billingDataSchema = object({
  billing_data: object({
    name: string().required("Name cannot be empty."),
    tax: object({
      // code: string().required("Tax identification number cannot be empty."),
      number: string().required("Tax identification number cannot be empty."),
    }),
    street: string().required("Street cannot be empty."),
    post_code: string().required("Post code cannot be empty."),
    city: string().required("City cannot be empty."),
    country: string().required("Country cannot be empty."),
  }),
});

export default billingDataSchema;
