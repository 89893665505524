import "./styles.scss";

import { Link } from "react-router-dom";
import { useState } from "react";
import moment from "moment";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import Dropdown from "components/Dropdown";
import IconButton from "components/IconButton";
import DropdownCard from "components/DropdownCard";
import Button from "components/Button";

import notification_render from "libs/notification_render";
import { useNotifications } from "context/notifications";

import { ReactComponent as NotificationIcon } from "assets/icons/notification.svg";
import { ReactComponent as LogoIcon } from "assets/icons/logo-notification.svg";

const mainClass = "notification-dropdown";

const Notifications = () => {
  const { t } = useTranslation();

  const { data, readHandler } = useNotifications();
  const [dropdown_key, setDropdownKey] = useState(0);

  const unreaded_count = data?.reduce((a, b) => a + (!!!b?.status ? 1 : 0), 0);
  const unreaded_notifications = data?.filter((item) => item?.status === 0);

  const closeHandler = () => {
    setDropdownKey((prev) => prev + 1);
  };

  return (
    <Dropdown
      button={
        <IconButton
          icon={<NotificationIcon />}
          badgeContent={unreaded_count}
          variant={!!!unreaded_count ? "gray" : null}
        />
      }
      onclose={dropdown_key}
      offset={12}
    >
      <DropdownCard>
        <div
          className={classnames(mainClass, {
            [`${mainClass}--empty`]: unreaded_notifications?.length === 0,
          })}
        >
          <div className={`${mainClass}__header`}>
            <h2>{t("Notifications")}</h2>
            {unreaded_notifications?.length > 0 && (
              <Button
                size="small"
                variant="transparent"
                onClick={() => readHandler("all")}
              >
                {t("Mark all as read")}
              </Button>
            )}
          </div>
          <div className={`${mainClass}__content`}>
            {unreaded_notifications?.length > 0 ? (
              unreaded_notifications?.map(
                (
                  {
                    _id,
                    template,
                    href,
                    created_at,
                    website,
                    status,
                    custom_text,
                    context,
                  },
                  index
                ) => (
                  <Link
                    to={href}
                    key={index}
                    onClick={() => {
                      closeHandler();
                      readHandler(_id, 1);
                    }}
                    className={classnames(`${mainClass}__content__item`, {
                      [`${mainClass}__content__item--disabled`]: !!status,
                    })}
                  >
                    <div className={`${mainClass}__content__item__wrapper`}>
                      <div className={`${mainClass}__content__item__image`}>
                        <LogoIcon />
                      </div>
                      <div className={`${mainClass}__content__item__info`}>
                        <span>
                          {!!template
                            ? notification_render(template, context, t)
                            : custom_text}
                        </span>
                        <small>
                          {`${moment(created_at)?.format("ll")} ${
                            !!website?.website ? `for ${website?.website}` : ""
                          }`}
                        </small>
                      </div>
                      {!!!status && (
                        <div
                          className={`${mainClass}__content__item__status`}
                        />
                      )}
                    </div>
                  </Link>
                )
              )
            ) : (
              <div className={`${mainClass}__content__placeholder`}>
                <h3>{t("You haven't got any notifications")}</h3>
                <span>{t("You are up to date.")}</span>
              </div>
            )}
          </div>
          {!!unreaded_notifications?.length > 0 && (
            <div className={`${mainClass}__bottom`}>
              <Button href="/notifications" onClick={closeHandler}>
                {t("Show all notifications")}
              </Button>
            </div>
          )}
        </div>
      </DropdownCard>
    </Dropdown>
  );
};

export default Notifications;
