import "./styles.scss";

import classnames from "classnames";
import Flag from "react-world-flags";
import { useTranslation } from "react-i18next";

import FormikSelect from "components/formik/FormikSelect";

import tax_data from "libs/tax_data.json";

const mainClass = "tax-input";

const TaxInput = ({
  type = "text",
  label,
  name,
  touched,
  error,
  value,
  onChange,
  disabled,
  uppercaseLabel = false,
  maxLength,
  required,
  invalid_tax,
}) => {
  const { t } = useTranslation();

  const is_error = touched ? !!error?.number || !!error?.code : !!invalid_tax;

  const select_options = tax_data?.map(({ iso_code, code }) => ({
    icon: <Flag code={iso_code} />,
    label: code,
    value: code,
  }));

  const current_placeholder = tax_data?.find(
    (item) => value?.code === item?.code
  )?.example;

  return (
    <div
      className={classnames(mainClass, {
        [`${mainClass}--error`]: !!is_error,
        [`${mainClass}--uppercase-label`]: !!uppercaseLabel,
      })}
    >
      <label>
        {label}
        {!!required && <span>*</span>}
      </label>
      <div className={`${mainClass}__content`}>
        <input
          className={`${mainClass}__field`}
          type={type}
          placeholder={current_placeholder}
          value={value?.number}
          name={`${name}.number`}
          onChange={onChange}
          disabled={disabled}
          maxLength={maxLength}
        />
        <FormikSelect
          options={select_options}
          selected={value?.code}
          disabled={disabled}
          searcher={true}
          required
          placeholder={t("Tax type")}
          name={`${name}.code`}
          hideError
        />
      </div>
      {!!is_error && (
        <small className={`${mainClass}__error`}>
          {!!invalid_tax
            ? t("Invalid tax identification number")
            : !!error?.number
            ? t(error?.number)
            : t(error?.code)}
        </small>
      )}
    </div>
  );
};

export default TaxInput;
