import { useTranslation } from "react-i18next";

import { ReactComponent as InvoicesIcon } from "assets/icons/invoices.svg";
import { ReactComponent as TasksIcon } from "assets/icons/tasks.svg";
import { ReactComponent as ClientsIcon } from "assets/icons/clients.svg";
import { ReactComponent as WebmastersIcon } from "assets/icons/webmasters.svg";
import { ReactComponent as UsersIcon } from "assets/icons/users.svg";
import { ReactComponent as ReferralProgramIcon } from "assets/icons/referral-program.svg";
import { ReactComponent as NotificationIcon } from "assets/icons/notification.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";

const AdminMenu = (unreaded_count) => {
  const { t } = useTranslation();

  const menu_items = [
    {
      icon: <InvoicesIcon />,
      label: t("Dashboard"),
      href: "/",
    },
    {
      icon: <TasksIcon />,
      label: t("Tasks"),
      href: "/tasks",
    },
    {
      icon: <ClientsIcon />,
      label: t("Clients"),
      href: "/clients",
    },
    {
      icon: <WebmastersIcon />,
      label: t("Webmasters"),
      href: "/webmasters",
    },
    {
      icon: <UsersIcon />,
      label: t("Registered users"),
      href: "/users",
    },
    {
      icon: <ReferralProgramIcon />,
      label: t("Referral program"),
      href: "/referral-program",
    },
    {
      icon: <NotificationIcon />,
      label: t("Notifications"),
      href: "/notifications",
      count: unreaded_count,
    },
    {
      icon: <SettingsIcon />,
      label: t("Account settings"),
      href: "/settings",
    },
  ];

  return menu_items;
};

export default AdminMenu;
