import "./styles.scss";

import moment from "moment";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";

import Modal from "components/Modal";
import DetailsCard from "components/DetailsCard";
import DetailsCardList from "components/DetailsCardList";

import OverviewModal from "page_components/webmaster/tasks/Content/components/OverviewModal";

import api from "libs/api";
import countries from "libs/countries.json";
import toHoursAndMinutes from "libs/helpers/toHoursAndMinutes";
import { useTranslation } from "react-i18next";

const mainClass = "website-info-modal";

const WebsiteInfoModal = ({ id, onClose }) => {
  const { t } = useTranslation();

  const { isLoading: loading, data } = useQuery(["client-modal", id], () =>
    api.get(`/admin/clients/${id}`)
  );

  const [change_overview_modal, setChangeOverviewModal] = useState(false);

  const details_data = [
    {
      title: "Overview",
      action: {
        label: "Change",
        onclick: () => setChangeOverviewModal(true),
      },
      items: [
        {
          type: "link",
          label: "Website URL",
          value: {
            link_label: data?.website,
            link_href: `/clients/${data?._id}`,
          },
        },
        {
          type: "status",
          label: "Status",
          value: {
            status: data?.status,
          },
          tooltip:
            "Your website is safe and up to date. Your payment method is active.",
        },
        {
          label: "Last CMS update",
          value: data?.last_cms_update
            ? moment(data?.last_cms_update).format("ll")
            : "-",
        },
        {
          label: "SSL certificate valid until",
          value: data?.ssl_valid_until
            ? moment(data?.ssl_valid_until).format("ll")
            : "-",
        },
        {
          label: "Domain valid until",
          value: data?.domain_valid_until
            ? moment(data?.domain_valid_until).format("ll")
            : "-",
        },
        {
          label: (
            <>
              Time available in
              <br />
              your plan
            </>
          ),
          value: toHoursAndMinutes(data?.time_used?.available_in_plan)
            ?.formatted,
        },
        {
          label: "Time used",
          value: toHoursAndMinutes(data?.time_used?.time_used?.$numberDecimal)
            ?.formatted,
        },
        {
          label: "Time left",
          value: toHoursAndMinutes(data?.time_used?.time_left)?.formatted,
        },
        {
          label: "Time exceeding plan",
          value: toHoursAndMinutes(data?.time_used?.time_exceeding_plan)
            ?.formatted,
        },
      ],
    },
    ...(!!data?.password1_link
      ? [
          {
            title: "Website access data",
            items: [
              {
                type: "button",
                value: {
                  button_label: "Show access data",
                  variant: "normal",
                  external_href: true,
                  button_href: data?.password1_link,
                },
              },
            ],
          },
        ]
      : []),
  ];

  const payment_data = [
    {
      title: "Plan",
      items: [
        {
          label: "Type",
          value: `${data?.plan?.[0]?.name} plan`,
        },
      ],
    },
    {
      title: t("Contact data"),
      items: [
        {
          label: "Full name",
          value: `${data?.contact_data?.first_name} ${data?.contact_data?.last_name}`,
        },
        {
          type: "link",
          label: "Email",
          value: {
            link_label: data?.contact_data?.email,
            link_href: `mailto:${data?.contact_data?.email}`,
          },
        },
        {
          type: "link",
          label: "Phone",
          value: {
            link_label: `${
              countries?.find(
                (item) => item?.code === data?.contact_data?.phone?.code
              )?.dialling_code || ""
            } ${data?.contact_data?.phone?.number}`,
            link_href: `tel:${
              countries?.find(
                (item) => item?.code === data?.contact_data?.phone?.code
              )?.dialling_code || ""
            } ${data?.contact_data?.phone?.number}`,
          },
        },
      ],
    },
  ];

  return (
    <>
      <Modal
        onClose={onClose}
        submit_button_label="Send"
        hide_submit_button
        variant="extra-large"
        data_loading={!!loading}
      >
        <div className={mainClass}>
          <div className={`${mainClass}__col`}>
            {details_data?.map((data, index) => (
              <DetailsCard key={index} {...data}>
                <DetailsCardList items={data?.items} view={data?.view} />
              </DetailsCard>
            ))}
          </div>
          <div className={`${mainClass}__col`}>
            {payment_data?.map((data, index) => (
              <DetailsCard key={index} {...data}>
                <DetailsCardList items={data?.items} view={data?.view} />
              </DetailsCard>
            ))}
          </div>
        </div>
      </Modal>
      {!!change_overview_modal && (
        <OverviewModal
          data={data}
          onClose={() => setChangeOverviewModal(false)}
        />
      )}
    </>
  );
};

export default WebsiteInfoModal;
