import TasksDetails from "page_components/admin/tasks/Details";
import TaskProvider from "context/admin/task";
import TasksProvider from "context/admin/tasks";

const TaskDetails = () => {
  return (
    <TasksProvider>
      <TaskProvider>
        <TasksDetails />
      </TaskProvider>
    </TasksProvider>
  );
};

export default TaskDetails;
