import { createContext, useContext, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import api from "libs/api";
import { useParams } from "react-router-dom";

const ReferralProgramContext = createContext();

const ReferralProgramProvider = ({ children }) => {
  const { id } = useParams();

  const [link_loading, setLinkLoading] = useState(false);

  const { isLoading: loading, data } = useQuery(["referral_program", id], () =>
    api.get(!!id ? `/admin/referral/${id}` : `/client/referral`)
  );

  const rewardfulLinkGenerate = async () => {
    try {
      setLinkLoading(true);
      const data = await api.get(`/client/referral/rewardful-link`);
      window.open(data?.sso?.url, "_blank");
    } catch (err) {
      console.error(err);
    }

    setLinkLoading(false);
  };

  const value = useMemo(() => {
    return {
      data,
      loading,
      init_loading: false,
      rewardfulLinkGenerate,
      link_loading,
      setLinkLoading,
    };
    // eslint-disable-next-line
  }, [data, loading, link_loading]);

  return (
    <ReferralProgramContext.Provider value={value}>
      {children}
    </ReferralProgramContext.Provider>
  );
};

const useReferralProgram = () => useContext(ReferralProgramContext);
export { ReferralProgramContext, useReferralProgram };
export default ReferralProgramProvider;
