import { createContext, useContext, useMemo } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import api from "libs/api";
import tax_data from "libs/tax_data.json";

const ClientContext = createContext();

const ClientProvider = ({ children }) => {
  const { t } = useTranslation();

  const { id } = useParams();
  const queryClient = useQueryClient();

  const { isLoading: loading, data: client } = useQuery(["client", id], () =>
    api.get(`admin/clients/${id}`)
  );

  const updateData = (values) =>
    new Promise(async (resolve, reject) => {
      let data_to_send = values;

      if (data_to_send?.billing_data?.tax) {
        const tax = tax_data?.find(
          (item) => data_to_send?.billing_data?.tax?.code === item?.code
        );

        data_to_send.billing_data.tax = {
          ...data_to_send.billing_data.tax,
          prefix: tax?.prefix,
          clean_number: data_to_send.billing_data.tax?.number,
          number: `${tax?.prefix}${data_to_send.billing_data.tax?.number}`,
          id: tax?.tax_id,
        };
      }

      try {
        await api.put(`/admin/clients/${id}`, data_to_send);

        queryClient.setQueryData(["client", id], (prev) => ({
          ...prev,
          ...values,
        }));

        toast.success(t("Saved successfully."));
        resolve();
      } catch (err) {
        toast.error("Something went wrong.");
        reject(err);
      }
    });

  const value = useMemo(() => {
    return {
      data: client,
      updateData,
      loading,
    };
    // eslint-disable-next-line
  }, [client, loading]);

  return (
    <ClientContext.Provider value={value}>{children}</ClientContext.Provider>
  );
};

const useClient = () => useContext(ClientContext);
export { ClientContext, useClient };
export default ClientProvider;
