import "./styles.scss";

import { useState } from "react";
import classnames from "classnames";
import queryString from "query-string";
import { Link, useLocation } from "react-router-dom";

import useTaskOnboardingInit from "./components/Init";
import useTaskOnboardingTitle from "./components/Title";
import useTaskOnboardingCreate from "./components/Create";
import useTaskOnboardingFinish from "./components/Finish";
import useTaskOnboardingWebsite from "./components/Website";
import useTaskOnboardingDescription from "./components/Description";
import useTaskOnboardingAttachments from "./components/Attachments";
import { useTranslation } from "react-i18next";

const mainClass = "task-onboarding";

const TaskOnboarding = ({ init_step, onClose, validate, submitForm }) => {
  const { t } = useTranslation();
  const { pathname, search } = useLocation();
  const query = queryString.parse(search);

  const [step, setStep] = useState(init_step || 0);

  const task_onboarding_render = (
    step,
    setStep,
    onClose,
    init_referrer,
    current_referrer,
    validate,
    submitForm
  ) => {
    switch (step) {
      case 0:
        return {
          text: t(
            "We will guide you through the process creating a task related to your website. First, click on 'CREATE NEW TASK'."
          ),
          buttons: [
            {
              label: t("Close guide"),
              onClick: onClose,
            },
            {
              label: t("Next step"),
              href: `/tasks/new?onboarding=true&referrer=${init_referrer}`,
            },
          ],
          component: useTaskOnboardingInit,
        };
      case 1:
        return {
          text: t(
            "Select a website for which you would like to create a new task, if you have multiple websites using Webinity. If you have a single website then it will be already automatically pre-selected."
          ),
          buttons: [
            {
              label: t("Previous step"),
              href: `${current_referrer}?onboarding=true`,
            },
            {
              label: t("Next step"),
              onClick: async () => {
                const errors = await validate("website");

                if (!!!errors?.website) {
                  setStep((prev) => prev + 1);
                }
              },
            },
          ],
          component: useTaskOnboardingWebsite,
        };
      case 2:
        return {
          text: t(
            "Add a title for the task you are creating. This will help identify your task in our system. We recommend creating a title related to the work required e.g. 'Add new photos to the image gallery'"
          ),
          buttons: [
            {
              label: t("Previous step"),
              onClick: () => setStep((prev) => prev - 1),
            },
            {
              label: t("Next step"),
              onClick: async () => {
                const errors = await validate("title");

                if (!!!errors?.title) {
                  setStep((prev) => prev + 1);
                }
              },
            },
          ],
          component: useTaskOnboardingTitle,
        };
      case 3:
        return {
          text: t(
            "Add a description for your task. Let us know what you need to be done on your website. Please tell us as many details as possible."
          ),
          buttons: [
            {
              label: t("Previous step"),
              onClick: () => setStep((prev) => prev - 1),
            },
            {
              label: t("Next step"),
              onClick: async () => {
                const errors = await validate("description");

                if (!!!errors?.description) {
                  setStep((prev) => prev + 1);
                }
              },
            },
          ],
          component: useTaskOnboardingDescription,
        };
      case 4:
        return {
          text: t(
            "Here, you can optionally add files related to the task. You can always attach screenshots or documents that can help us with carrying out the task you created."
          ),
          buttons: [
            {
              label: t("Previous step"),
              onClick: () => setStep((prev) => prev - 1),
            },
            {
              label: t("Next step"),
              onClick: () => setStep((prev) => prev + 1),
            },
          ],
          component: useTaskOnboardingAttachments,
        };
      case 5:
        return {
          text: t(
            "Click on 'CREATE TASK' button to create a new task if you have completed filling out all the details."
          ),
          buttons: [
            {
              label: t("Previous step"),
              onClick: () => setStep((prev) => prev - 1),
            },
            {
              label: t("Next step"),
              onClick: submitForm,
            },
          ],
          component: useTaskOnboardingCreate,
        };
      case 6:
        return {
          text: t(
            "Congratulations! You have successfully created a new task. It is now awaiting our time estimation. We will get back to you with time estimation of implementation as soon as possible."
          ),
          hint: (
            <>
              {t(
                "If the task is simple it will just require your acceptance and it will be included in the time available in your plan. If we estimate that the task you have created is advanced and exceeds time available in your plan, you will receive an estimation, and you will be able to accept or reject it. Any work hours exceeding the time available in your plan will be billed monthly at an hourly rate as agreed in our"
              )}{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={t("terms_of_use_link")}
              >
                {t("Hours Terms of Use")}
              </a>
              .
              <strong>
                {" "}
                {t(
                  "This only applies after your acceptance, we will never carry out tasks exceeding time available in your monthly plan without your acceptance."
                )}
              </strong>
            </>
          ),
          buttons: [
            {
              label: t("Close guide"),
              onClick: onClose,
            },
          ],
          component: useTaskOnboardingFinish,
        };
      default:
        return null;
    }
  };

  const current_step = task_onboarding_render(
    step,
    setStep,
    onClose,
    pathname,
    query?.referrer,
    validate,
    submitForm
  );
  let current_component = current_step?.component;

  current_component({ step });

  return (
    <div className={mainClass}>
      <div className={`${mainClass}__tooltip`}>
        <div className={`${mainClass}__tooltip__pointer`}></div>
        <div className={`${mainClass}__tooltip__content`}>
          <p>{current_step?.text}</p>
          {!!current_step?.hint && <span>{current_step?.hint}</span>}
          <div className={`${mainClass}__tooltip__content__actions`}>
            {current_step?.buttons?.map((item, index) =>
              item?.onClick ? (
                <button key={index} onClick={item?.onClick}>
                  {item?.label}
                </button>
              ) : (
                <Link key={index} to={item?.href}>
                  {item?.label}
                </Link>
              )
            )}
          </div>
          <div
            className={classnames(
              `${mainClass}__tooltip__content__pagination`,
              {
                [`${mainClass}__tooltip__content__pagination--hidden`]:
                  step === 0 || step === 6,
              }
            )}
          >
            <button onClick={onClose}>{t("Close guide")}</button>
            <span>{step + 1}/7</span>
          </div>
        </div>
      </div>
      <div className={`${mainClass}__backdrop`}></div>
    </div>
  );
};

export default TaskOnboarding;
