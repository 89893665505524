import SettingsContent from "page_components/webmaster/settings/Content";
import SettingsProvider from "context/settings";

const Settings = () => (
  <SettingsProvider>
    <SettingsContent />
  </SettingsProvider>
);

export default Settings;
