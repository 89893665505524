import "./styles.scss";
import { useState } from "react";

import Modal from "components/Modal";
import WebsiteMenuMobile from "./components/WebsiteMenuMobile";
import Menu from "components/layouts/DashboardLayout/components/Sidebar/components/Menu";

import { useApp } from "context/app";

const mainClass = "mobile-menu";

const MobileMenu = () => {
  const { setMobileSidebarOpen } = useApp();

  const [webiste_mobile_open, setWebisteMobileOpen] = useState(false);

  return (
    <Modal
      hide_submit_button
      title="Menu"
      onBackAction={
        !!webiste_mobile_open ? () => setWebisteMobileOpen(false) : null
      }
      onClose={() => setMobileSidebarOpen(false)}
    >
      <div className={mainClass}>
        {!!!webiste_mobile_open ? (
          <Menu
            setMobileSidebarOpen={setMobileSidebarOpen}
            setWebisteMobileOpen={setWebisteMobileOpen}
          />
        ) : (
          <WebsiteMenuMobile />
        )}
      </div>
    </Modal>
  );
};

export default MobileMenu;
