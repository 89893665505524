import DetailsCard from "components/DetailsCard";
import DetailsCardList from "components/DetailsCardList";
import DashboardContentLayout from "components/layouts/DashboardContentLayout";

import countries from "libs/countries.json";

import { useWebmasterSettings } from "context/admin/webmaster-settings";

const mainClass = "column-layout";

const WebmasterContent = () => {
  const { data, loading } = useWebmasterSettings();

  const actions = [
    {
      type: "send_notification",
    },
  ];

  const breadcrumps = [
    {
      label: "Webmasters",
      href: "/webmasters",
    },
    {
      label: !!data?.first_name
        ? `${data?.first_name} ${data?.last_name}`
        : null,
    },
  ];

  const details_data = [
    {
      title: "Account role",
      items: [
        {
          label: "Role",
          value: data?.type === "webmaster" ? "Webmaster" : "Admin",
        },
      ],
    },
    {
      title: "Personal data",
      items: [
        {
          label: "Full name",
          value: `${data?.first_name} ${data?.last_name}`,
        },
        {
          type: "link",
          label: "Email",
          value: {
            link_label: data?.email,
            link_href: `mailto:${data?.email}`,
          },
        },
        {
          type: "link",
          label: "Phone",
          value: {
            link_label: !!data?.phone?.number
              ? `${
                  countries?.find((item) => item?.code === data?.phone?.code)
                    ?.dialling_code || ""
                } ${data?.phone?.number}`
              : "-",
            link_href: `tel:${
              countries?.find((item) => item?.code === data?.phone?.code)
                ?.dialling_code || ""
            } ${data?.phone?.number}`,
          },
        },
      ],
    },
  ];

  return (
    <DashboardContentLayout
      header_label={
        !!data?.first_name ? `${data?.first_name} ${data?.last_name}` : null
      }
      loading={loading}
      async_header
      breadcrumps={breadcrumps}
      actions={actions}
    >
      <div className={mainClass}>
        <div className={`${mainClass}__col`}>
          {details_data?.map((data, index) => (
            <DetailsCard key={index} {...data}>
              <DetailsCardList items={data?.items} view={data?.view} />
            </DetailsCard>
          ))}
        </div>
      </div>
    </DashboardContentLayout>
  );
};

export default WebmasterContent;
