import moment from "moment";
import { useTranslation } from "react-i18next";

import Table from "components/Table";
import { useDashboard } from "context/webmaster/dashboard";
import { useState } from "react";
import WebsiteInfoModal from "components/WebsiteInfoModal";

const UpcomingTasks = () => {
  const { t } = useTranslation();

  const { data, loading, filters, filterHandler } = useDashboard();
  const [website_info_modal, setWebsiteInfoModal] = useState(null);

  const table_data = {
    keys: [
      {
        sort_key: "title",
        key: "title-full",
        value: t("Title"),
      },
      {
        key: "website",
        value: t("Website"),
      },
      {
        key: "due_date",
        value: t("Due date"),
      },
    ],
    items: data?.tasks_upcoming_date?.map(
      ({ _id, title, website_id, website, due_date }) => [
        {
          key: "title-full",
          type: "link",
          value: {
            label: title,
            href: `/tasks/${_id}`,
          },
        },
        {
          key: "website",
          type: "clickable_text",
          value: {
            label: website,
            onclick: () => setWebsiteInfoModal(website_id),
          },
        },
        {
          key: "due_date",
          value: !!due_date ? moment(due_date)?.format("DD MMM, YYYY") : "-",
        },
      ]
    ),
  };

  return (
    <>
      <Table
        data={table_data}
        loading={!!loading}
        header={<h3>{t("Tasks with upcoming due date")}</h3>}
        sort={filters?.sort}
        setSort={(value) => filterHandler("sort", value, "upcoming_tasks")}
      />
      {!!website_info_modal && (
        <WebsiteInfoModal
          id={website_info_modal}
          onClose={() => setWebsiteInfoModal(null)}
        />
      )}
    </>
  );
};

export default UpcomingTasks;
