import UsersContent from "page_components/admin/users/Content";
import UsersProvider from "context/admin/users";

const Users = () => (
  <UsersProvider>
    <UsersContent />
  </UsersProvider>
);

export default Users;
