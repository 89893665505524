import InvoicesContent from "page_components/client/invoices/Content";
import InvoicesProvider from "context/client/invoices";

const Invoices = () => (
  <InvoicesProvider>
    <InvoicesContent />
  </InvoicesProvider>
);

export default Invoices;
