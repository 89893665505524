import "./styles.scss";

import React, { useEffect } from "react";
import classnames from "classnames";

import { ReactComponent as LogoIcon } from "assets/icons/logo-sign.svg";

const mainClass = "splash-screen";

const SplashScreen = ({ loading }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [loading]);

  return (
    <div
      className={classnames(mainClass, {
        [`${mainClass}--fade-out`]: !!!loading,
      })}
    >
      <LogoIcon />
    </div>
  );
};

export default SplashScreen;
