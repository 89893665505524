import { createContext, useContext, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import queryString from "query-string";

import api from "libs/api";

const DashboardContext = createContext();

const DashboardProvider = ({ children }) => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const filters = queryString.parse(search);

  const { isLoading: loading, data: dashboard } = useQuery(
    ["webmaster_dashboard", filters],
    () =>
      api.get(
        `/webmaster/dashboard?${queryString.stringify({
          ...filters,
          sort: filters?.sort || "due_date",
        })}`
      )
  );

  const filterHandler = (name, value, type) => {
    navigate(
      `?${queryString.stringify({
        ...filters,
        [name]: value || undefined,
        type: !!value ? type : undefined,
      })}`
    );
  };

  const value = useMemo(() => {
    return {
      data: dashboard,
      loading,
      filters,
      filterHandler,
    };
    // eslint-disable-next-line
  }, [dashboard, filters, loading]);

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
};

const useDashboard = () => useContext(DashboardContext);
export { DashboardContext, useDashboard };
export default DashboardProvider;
