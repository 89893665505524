import "./styles.scss";

import { useState } from "react";
import classnames from "classnames";

import Modal from "components/Modal";

import { useTask } from "context/admin/task";

const mainClass = "webmaster-modal";

const WebmasterModal = ({ onClose }) => {
  const { data, webmasters, updateTask } = useTask();
  const [selected, setSelected] = useState(data?.webmaster?._id);
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    try {
      setLoading(true);

      await updateTask({ webmaster: selected });
      onClose();
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Edit webmaster"
      onClose={onClose}
      onSubmit={onSubmit}
      loading={!!loading}
      variant="narrow"
    >
      <div className={mainClass}>
        {webmasters?.map(({ _id, photo, first_name, last_name }) => (
          <button
            key={_id}
            onClick={() => setSelected(_id)}
            className={classnames(`${mainClass}__item`, {
              [`${mainClass}__item--active`]: _id === selected,
            })}
          >
            <div className={`${mainClass}__item__image`}>
              <img alt="" src={photo} />
            </div>
            <span>{`${first_name} ${last_name}`}</span>
          </button>
        ))}
      </div>
    </Modal>
  );
};

export default WebmasterModal;
