import "./styles.scss";

import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";

import Pill from "components/Pill";
import Modal from "components/Modal";
import Calendar from "components/Calendar";
import PriorityFlag from "components/PriorityFlag";
import FormikInput from "components/formik/FormikInput";
import FormikSelect from "components/formik/FormikSelect";
import FormikTextarea from "components/formik/FormikTextarea";
import WebmasterSelect from "components/form/WebmasterSelect";

import { useTasks } from "context/admin/tasks";

import task_status_data from "libs/task_status_data.json";
import task_priority_data from "libs/task_priority_data.json";
import createTaskSchema from "libs/validations/admin/createTaskSchema";
import toHoursAndMinutes from "libs/helpers/toHoursAndMinutes";

import AvatarImage from "assets/images/avatar-placeholder.png";

const mainClass = "create-task-modal";

const CreateTaskModal = ({ data, update, onClose }) => {
  const { t } = useTranslation();

  const { websites, webmasters, createTask } = useTasks();

  const formatted_websites = websites?.map(({ _id, website }) => ({
    label: website,
    value: _id,
  }));

  const formatted_webmasters = webmasters?.map(
    ({ _id, first_name, last_name, photo }) => ({
      label: (
        <>
          <img
            alt=""
            src={photo || AvatarImage}
            style={{ height: 24, width: 24, objectFit: "cover" }}
          />
          {first_name} {last_name}
        </>
      ),
      value: _id,
    })
  );

  const formatted_priority = task_priority_data?.map((item) => ({
    ...item,
    label: t(item?.label),
    icon: <PriorityFlag priority={item?.value} />,
  }));

  const formatted_status = task_status_data?.map((item) => ({
    ...item,
    label: t(item?.label),
    icon: <Pill variant={item?.key} label={item?.label} readOnly size="icon" />,
  }));

  const onSubmit = async (values, setSubmitting) => {
    try {
      if (!!update) {
        await update(values);
      } else {
        await createTask(values);
      }
      onClose();
    } catch (err) {
      setSubmitting(false);
    }
  };

  const estimated_time = toHoursAndMinutes(
    data?.estimated_time?.$numberDecimal
  );

  const today = new Date();

  return (
    <Formik
      initialValues={{
        website: data?.website_id || "",
        title: data?.title || "",
        finished_when: data?.finished_when || data?.client_message,
        priority: data?.priority || 1,
        status: data?.status || 1,
        estimated_time: {
          hours: estimated_time?.hours,
          minutes: estimated_time?.minutes,
        },
        due_date: data?.due_date,
        webmaster:
          data?.webmaster?.length > 0
            ? data?.webmaster?.map((item) => item?._id)
            : [],
      }}
      validationSchema={createTaskSchema}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        submitForm,
        errors,
        values,
        setFieldValue,
        touched,
      }) => (
        <div className={mainClass}>
          <Form autoComplete="off">
            <Modal
              title={!!update ? t("Edit task") : t("Create task")}
              onClose={onClose}
              onSubmit={submitForm}
              loading={isSubmitting}
              submit_button_label={!!update ? t("Save") : t("Create")}
              variant="large"
            >
              <div className="form">
                <div className="form__group">
                  <div className="form__input-group">
                    <FormikSelect
                      name="website"
                      label={t("Website")}
                      options={formatted_websites}
                      disabled={isSubmitting || !!update}
                      placeholder="www.example.com"
                      searcher
                    />
                    <FormikInput
                      name="title"
                      label={t("Task title")}
                      disabled={isSubmitting}
                    />
                    <FormikTextarea
                      name="finished_when"
                      label={t("Task finished when")}
                      disabled={isSubmitting}
                      placeholder={t(
                        "List your conditions for task completion"
                      )}
                    />
                  </div>
                  <div className="form__input-group">
                    <div className="form__input-group">
                      <span>{t("Estimated time")}</span>
                      <div className="form__input-group__inputs">
                        <FormikInput
                          name="estimated_time.hours"
                          placeholder={t("Hours")}
                          disabled={isSubmitting}
                        />
                        <span>-</span>
                        <FormikInput
                          name="estimated_time.minutes"
                          placeholder={t("Minutes")}
                          disabled={isSubmitting}
                        />
                      </div>
                    </div>
                    <Calendar
                      label={t("Due date")}
                      error={errors?.due_date}
                      default_value={values?.due_date}
                      onChange={(value) => setFieldValue("due_date", value)}
                      prev_date={today}
                      touched={touched}
                    />
                    <FormikSelect
                      name="priority"
                      label={t("Priority")}
                      options={formatted_priority}
                      disabled={isSubmitting}
                    />
                    <FormikSelect
                      name="status"
                      label={t("Status")}
                      options={formatted_status}
                      disabled={isSubmitting}
                    />
                  </div>
                  <div className="form__input-group">
                    <WebmasterSelect
                      value={values?.webmaster}
                      options={formatted_webmasters}
                      onChange={setFieldValue}
                      error={errors?.webmaster}
                      touched={touched}
                    />
                  </div>
                </div>
              </div>
            </Modal>
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default CreateTaskModal;
