import ReferralProgramsDetails from "page_components/shared/referral-programs/Details";
import ReferralProgramProvider from "context/referral-program";

const ReferralProgramDetails = () => (
  <ReferralProgramProvider>
    <ReferralProgramsDetails />
  </ReferralProgramProvider>
);

export default ReferralProgramDetails;
