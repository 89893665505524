import "./styles.scss";

import { useState } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import Button from "components/Button";
import DetailsCard from "components/DetailsCard";
import RadioButton from "components/form/RadioButton";

import { ReactComponent as AuditIcon } from "assets/icons/new-project-audit.svg";
import { ReactComponent as WebsiteIcon } from "assets/icons/new-project-website.svg";
import { ReactComponent as GraphicsIcon } from "assets/icons/new-project-graphics.svg";

const mainClass = "new-project-content__choose";

const ChooseProject = ({ setStep }) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext();

  const [description_open, setDescriptionOpen] = useState(null);
  const [is_error, setIsError] = useState(false);

  const choose_project_data = [
    {
      title: t("Website/e-commerce"),
      icon: <WebsiteIcon />,
      options: [
        {
          label: t("Website"),
          description: t(
            `Design and implementation of a comprehensive website. The technology stack we recommend is a proven solution used by global brands, like  4F, Techland, Hiddenfjord, and Airly, which are our regular customers.`
          ),
        },
        {
          label: t("Landing Page"),
          description: t(
            `A website designed for a specific purpose, most often related to marketing and sales campaigns. Its main task is to encourage the user to take a specific action, such as signing up for a newsletter, filling out a contact form, downloading an e-book, or making a purchase. The landing page is usually devoid of additional navigation to focus the user's attention on one specific offer or message. The key elements of such a website are: an attractive headline, a clear call to action (CTA), convincing content, and often social evidence, such as customer opinions or numbers proving the quality of the offer.`
          ),
        },
        {
          label: t("One Page"),
          description: t(
            `A website that contains all the necessary information in one view (does not contain subpages). It is characterized by scrolling down, where subsequent sections of the page contain various pieces of content, such as company information, offer, photo gallery, references, contact details, etc. One Page is often used to present simple information websites, portfolios, event pages or marketing campaigns . The key advantage of this type of website is its simplicity and transparency, which makes it easier to navigate and quickly find the information you need.`
          ),
        },
        {
          label: t("E-Commerce"),
          description: t(
            `E-commerce platforms enable businesses and consumers to complete transactions online, eliminating the need for physical contact. Thanks to e-commerce, users can browse products, make purchases, pay for them, and track shipments in a convenient and safe way online.`
          ),
        },
      ],
    },
    {
      title: t("Web design"),
      icon: <GraphicsIcon />,
      options: [
        {
          label: t("Web design"),
          description: t(
            `Do you need to design something new for your website? A new page, an ad banner, or a new section? Just let us know.`
          ),
        },
        {
          label: t("Logo"),
          description: t(
            `We present projects in the form of a clear presentation, which we discuss together with you. A Brand Card (shortened version of a Brand Book) is prepared for each project, containing the most important information on how to properly use the designed logo.`
          ),
        },
        {
          label: t("Key Visual"),
          description: t(
            `The brand's graphic leitmotif, which ensures its consistency at the visual level, and makes us recognize the brand even before we see its name or logo.`
          ),
        },
        {
          label: t("Banner Ads"),
          description: t(
            `A set of advertising banners necessary for promotion in advertising networks, such as Google Ads, Meta (Facebook) Ads, LinkedIn, or Instagram`
          ),
        },
      ],
    },
    {
      title: t("UX Audits"),
      icon: <AuditIcon />,
      options: [
        {
          label: t("UX Tests"),
          description: t(
            `Isolation of the most important website errors that prevent the users from achieving their goals:<br/><br/>- Classification and prioritization of page errors<br/>- Errors report<br/>- Assessment on a scale of the overall usability of the website<br/>- Conclusions and recommendations<br/><br/>We can perform this version of the audit when we plan on making changes to the website or when difficulties with navigating the website are observed.<br/><br/>As a result of such an audit you will learn what are the most important errors on the website that should be focused on, in order  to improve the website's intuitiveness and increase conversion.`
          ),
        },
        {
          label: t("Competition Analysis"),
          description: t(
            `- Competition awareness<br/>- Comparison of the website with the competition's websites<br/>- Solutions used by competitors<br/><br/>We can perform this version of the audit when we want to find out what our website looks like compared to the competition, and what is there to improve.<br/><br/>As a result, we will learn what solutions work (in this business) and how you compare to the competition (in terms of functionality and design).`
          ),
        },
        {
          label: t("Traffic Analysis on Your Website"),
          description: t(
            `How does the website work in practice? How do users use it?<br/><br/>- Isolation of problematic areas for users<br/>- Errors report<br/>- Conclusions and recommendations<br/><br/>We can perform this version of the audit when we notice that users only fill out part of the forms on our website or stop their registration or purchase process.<br/><br/>As a result, we will find out, which elements on the website are problematic for the users, which ones  they use most often, and which ones they do not use at all.`
          ),
        },
      ],
    },
  ];

  const onRadioClickHandler = (value) => {
    setIsError(false);

    const index = values?.options?.findIndex((item) => item === value);
    if (index > -1) {
      let temp = [...values?.options];
      temp.splice(index, 1);

      setFieldValue("options", [...temp]);
    } else {
      setFieldValue("options", [...values?.options, value]);
    }
  };

  const onStepChange = () => {
    setIsError(false);
    if (values?.options?.length === 0) {
      setIsError(true);
      return;
    }
    setStep(1);
  };

  return (
    <div className={mainClass}>
      <div className={`${mainClass}__items`}>
        {choose_project_data?.map((item, index) => (
          <DetailsCard
            view={!!is_error ? "error" : null}
            key={index}
            title={item?.title}
            zIndex={description_open?.index === index ? 1001 : 999}
          >
            {item?.icon}
            {description_open?.index === index ? (
              <div className={`${mainClass}__description`}>
                <h3>{description_open?.title}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: description_open?.description,
                  }}
                ></p>
                <Button
                  variant="transparent"
                  onClick={() => setDescriptionOpen(null)}
                >
                  {t("Close")}
                </Button>
              </div>
            ) : (
              <>
                <span className={`${mainClass}__header`}>
                  {t(`Choose the project you need`)}
                </span>
                <div className={`${mainClass}__buttons`}>
                  {item?.options?.map((item, option_index) => (
                    <RadioButton
                      key={option_index}
                      label={item?.label}
                      value={item?.label}
                      checked={!!values?.options?.includes(item?.label)}
                      onClick={() => onRadioClickHandler(item?.label)}
                      onHintClick={() =>
                        setDescriptionOpen({
                          index,
                          title: item?.label,
                          description: item?.description,
                        })
                      }
                    />
                  ))}
                </div>
              </>
            )}
          </DetailsCard>
        ))}
      </div>
      <div className={`${mainClass}__bottom`}>
        <Button onClick={onStepChange}>{t("Next step")}</Button>
      </div>
      {!!description_open && (
        <div
          className={`${mainClass}__backdrop`}
          onClick={() => setDescriptionOpen(null)}
        />
      )}
    </div>
  );
};

export default ChooseProject;
