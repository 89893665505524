import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useApp } from "context/app";

import { ReactComponent as TasksIcon } from "assets/icons/tasks.svg";
import { ReactComponent as RocketIcon } from "assets/icons/rocket.svg";
import { ReactComponent as WebsiteIcon } from "assets/icons/website.svg";
import { ReactComponent as InvoicesIcon } from "assets/icons/invoices.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { ReactComponent as DashboardIcon } from "assets/icons/dashboard.svg";
import { ReactComponent as NotificationIcon } from "assets/icons/notification.svg";
import { ReactComponent as ReferralProgramIcon } from "assets/icons/referral-program.svg";

const ClientMenu = (unreaded_count) => {
  const { user, websites } = useApp();
  const { t } = useTranslation();

  const [website_extended, setWebsiteExtended] = useState(true);

  let active = websites?.filter(
    (item) => item?.subscription?.status === "active"
  );
  let unactive = websites?.filter(
    (item) => item?.subscription?.status !== "active"
  );

  const formatted_website = [...active, ...unactive];

  const menu_items = [
    ...(!!user?.is_partner
      ? [
          {
            icon: <ReferralProgramIcon />,
            label: t("Referral program"),
            href: "/",
          },
        ]
      : []),
    {
      icon: <DashboardIcon />,
      label: t("Dashboard"),
      href: !!user?.is_partner ? "/dashboard" : "/",
    },
    {
      icon: <WebsiteIcon />,
      label: t("Websites"),
      extended: website_extended,
      onclick: () => setWebsiteExtended((prev) => !prev),
      href: websites?.length === 0 ? "/websites" : null,
      extended_items: formatted_website?.map(
        ({ _id, website, status, subscription }) => ({
          label: website,
          href: `/websites/${_id}`,
          disabled: subscription?.status !== "active",
          status,
        })
      ),
    },
    {
      icon: <TasksIcon />,
      label: t("Tasks"),
      href: "/tasks",
    },
    {
      icon: <InvoicesIcon />,
      label: t("Invoices"),
      href: "/invoices",
    },
    ...(!!!user?.is_partner
      ? [
          {
            icon: <ReferralProgramIcon />,
            label: t("Referral program"),
            href: "/referral-program",
          },
        ]
      : []),
    {
      icon: <NotificationIcon />,
      label: t("Notifications"),
      href: "/notifications",
      count: unreaded_count,
    },
    {
      icon: <SettingsIcon />,
      label: t("Account settings"),
      href: "/settings",
    },
    {
      icon: <RocketIcon />,
      label: t("Need a new project?"),
      href: "/new-project",
    },
  ];

  return menu_items;
};

export default ClientMenu;
