import "./styles.scss";

import { useState } from "react";

import Dropdown from "components/Dropdown";
import IconButton from "components/IconButton";
import DropdownMenu from "components/DropdownMenu";

import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";

const mainClass = "mobile-header-menu";

const MobileHeaderMenu = ({ content }) => {
  const [dropdown_key, setDropdownKey] = useState(0);

  const basics_menu_items = !!content?.props?.children?.[0]
    ? content?.props?.children?.map(({ props }) => ({
        label: props?.children,
        href: props?.href,
        action: props?.onClick,
      }))
    : !!content?.props?.children?.props?.children
    ? [
        {
          label: content?.props?.children?.props?.children,
          href: content?.props?.children?.props?.href,
          action: content?.props?.children?.props?.onClick,
        },
      ]
    : [];

  if (basics_menu_items?.length === 0) return null;

  return (
    <div className={mainClass}>
      <Dropdown
        button={<IconButton icon={<PlusIcon />} />}
        onclose={dropdown_key}
        offset={15}
      >
        <DropdownMenu
          items={basics_menu_items}
          variant="small"
          color="dark"
          icon_type="colorful"
          closeHandler={() => setDropdownKey((prev) => prev + 1)}
        />
      </Dropdown>
    </div>
  );
};

export default MobileHeaderMenu;
