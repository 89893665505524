import "./styles.scss";

import { useState } from "react";
import { useFormikContext } from "formik";
import { FileUploader } from "react-drag-drop-files";
import { useTranslation } from "react-i18next";

import { ReactComponent as UploadIcon } from "assets/icons/upload.svg";
import useResponsive from "libs/helpers/useResponsive";
import FileListMobile from "../FileListMobile";

const mainClass = "file-uploader";

const file_max_size = 5;
const file_types = ["JPG", "PNG", "JPEG", "DOC", "DOCX", "PDF"];

const FileUploadComponent = ({ progress }) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  const [error, setError] = useState(null);
  const { values, setFieldValue, isSubmitting } = useFormikContext();

  const onChange = async (file) => {
    setError(null);
    setFieldValue("files", [...values?.files, ...file]);
  };

  const deleteHandler = (index) => {
    const temp_data = [...values?.files];

    temp_data?.splice(index, 1);
    setFieldValue("files", [...temp_data]);
  };

  return (
    <div className={mainClass}>
      <span>{t("Files")}</span>
      <FileUploader
        classes={`${mainClass}__input`}
        multiple={true}
        handleChange={onChange}
        name="file"
        types={file_types}
        maxSize={file_max_size}
        onSizeError={() =>
          setError("The file is too large. Allowed maximum size is 5MB.")
        }
        hoverTitle=" "
      >
        <UploadIcon />
        <span>
          <span>{t("Click to upload")}</span> {t("or drag and drop photo")}
        </span>
        <small>
          {t("Acceptable format")}: doc, docx, pdf, jpg, png.{" "}
          {t("Max size: 5MB each.")}
        </small>
      </FileUploader>
      {!!error && <small className={`${mainClass}__error`}>{error}</small>}
      {!!isSubmitting && values?.files?.length > 0 && (
        <div className={`${mainClass}__progress`}>
          <div style={{ width: `${progress}%` }} />
        </div>
      )}
      {isMobile ? (
        !!values?.files?.length > 0 ? (
          <FileListMobile files={values?.files} onDelete={deleteHandler} />
        ) : null
      ) : (
        <div className={`${mainClass}__list`}>
          {values?.files?.map(({ name }, index) => (
            <div key={index} className={`${mainClass}__list__item`}>
              <span>{name}</span>
              <button type="button" onClick={() => deleteHandler(index)}>
                {t("Delete file")}
              </button>
            </div>
          ))}
        </div>
      )}

      <small>
        {t(
          "If the description or implementation of your task requires sending additional information in the form of files, add them above."
        )}
      </small>
    </div>
  );
};

export default FileUploadComponent;
