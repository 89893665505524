import "./styles.scss";

import moment from "moment";
import { useTranslation } from "react-i18next";

import Table from "components/Table";
import Calendar from "components/Calendar";
import Select from "components/form/Select";
import PlaceholderPage from "components/PlaceholderPage";
import DashboardContentLayout from "components/layouts/DashboardContentLayout";

import { useApp } from "context/app";
import { useInvoices } from "context/client/invoices";

import priceRender from "libs/helpers/priceRender";

const mainClass = "invoices-content";

const InvoicesContent = () => {
  const { t } = useTranslation();

  const {
    data,
    loading,
    init_loading,
    init_data_length,
    filters,
    filterHandler,
    clearAllFilter,
    showInvoice,
  } = useInvoices();

  const { websites } = useApp();

  const breadcrumps = [
    {
      label: t("Invoices"),
    },
  ];

  const actions = [
    {
      label: t("Add new website"),
      href: "/websites/new",
      event: () =>
        window.gtag("event", "new_website", {
          click_text: t("Add new website"),
          click_url: `/websites/new`,
        }),
    },
    ...(websites?.length > 0
      ? [
          {
            type: "create_client_task",
            onboarding_button: true,
          },
        ]
      : []),
  ];

  const table_data = {
    keys: [
      {
        key: "website-full",
        value: t("Website"),
      },
      {
        key: "created_at",
        value: t("Date"),
      },
      {
        sort_key: "price_id",
        key: "subscription_plan",
        value: t("Subscription plan"),
      },
      {
        key: "amount",
        value: t("Amount"),
      },
      {
        key: "show_invoice",
        value: "",
      },
    ],
    items: data?.map(
      ({
        name,
        website,
        website_id,
        created_at,
        amount,
        currency,
        invoice_id,
      }) => [
        {
          key: "website-full",
          type: "link",
          mobile_title: true,
          value: {
            label: website,
            href: `/websites/${website_id}`,
          },
        },
        {
          key: "created_at",
          value: moment(created_at)?.format("ll"),
        },
        {
          key: "subscription_plan",
          value: name,
        },
        {
          key: "amount",
          value: priceRender((amount / 100)?.toFixed(2), currency),
        },
        {
          key: "show_invoice",
          type: "button",
          value: {
            label: t("Show invoice"),
            onclick: () => showInvoice(invoice_id),
          },
        },
      ]
    ),
  };

  const websites_options = [
    {
      label: t("All websites"),
      value: null,
    },
    ...websites?.map(({ _id, website }) => ({
      label: website,
      value: _id,
    })),
  ];

  return (
    <DashboardContentLayout
      header_label={t("Invoices")}
      loading={init_loading}
      actions={actions}
      breadcrumps={breadcrumps}
    >
      <div className={mainClass}>
        {init_data_length > 0 ? (
          <Table
            data={table_data}
            loading={!!loading}
            sort={filters?.sort}
            setSort={(value) => filterHandler("sort", value)}
            filters_count={Object?.keys(filters)?.length}
            filter_default_open={Object?.keys(filters)?.length > 0}
            filters_always_show={true}
            filterClearAll={clearAllFilter}
            filters={
              <>
                <Select
                  label={t("Website")}
                  options={websites_options}
                  value={
                    websites?.length === 1
                      ? websites?.[0]?._id
                      : filters?.website || null
                  }
                  disabled={websites?.length === 1}
                  onChange={(e) => filterHandler("website", e.target.value)}
                />
                <Calendar
                  label={t("Start date")}
                  default_value={filters?.start_date}
                  onChange={(value) => {
                    filterHandler("start_date", value);
                  }}
                />
                <Calendar
                  label={t("End date")}
                  default_value={filters?.end_date}
                  prev_date={filters?.start_date}
                  onChange={(value) => filterHandler("end_date", value)}
                />
              </>
            }
          />
        ) : (
          <PlaceholderPage
            title={t("You have not added any websites yet")}
            text={t(
              "To purchase a plan for your website click the button below."
            )}
            button={{
              label: t("Choose a plan"),
              href: "/websites/new",
            }}
          />
        )}
      </div>
    </DashboardContentLayout>
  );
};

export default InvoicesContent;
