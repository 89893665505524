import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";

import Button from "components/Button";
import FormikInput from "components/formik/FormikInput";
import PasswordValidator from "components/PasswordValidator";

import resetPasswordSchema from "libs/validations/resetPasswordSchema";
import api from "libs/api";

const mainClass = "auth-content";

const ResetPasswordContent = ({ setConfirmed }) => {
  const { t } = useTranslation();

  const { search } = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const query = new URLSearchParams(search);
  const token = query.get("token");

  useEffect(() => {
    (async () => {
      try {
        await api.get(`/auth/password_reset?token=${token}`);
        setLoading(false);
      } catch (err) {
        navigate("/");
      }
    })();
    // eslint-disable-next-line
  }, []);

  const onSubmit = async (values, setSubmitting) => {
    try {
      await api.post("/auth/password_reset_confirm", {
        ...values,
        token,
      });
      setConfirmed(true);
    } catch (err) {
      setSubmitting(false);
    }
  };

  if (!!loading) return null;

  return (
    <div className={mainClass}>
      <div className={`${mainClass}__wrapper`}>
        <div className={`${mainClass}__header`}>
          <h1>{t("Set new password")}</h1>
          <span>
            {t("Use a strong and unique password for increased security.")}
          </span>
        </div>
        <Formik
          initialValues={{
            password: "",
            password2: "",
          }}
          validationSchema={resetPasswordSchema}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(values, setSubmitting);
          }}
        >
          {({ values, isSubmitting }) => (
            <Form className={`${mainClass}__form`} autoComplete="off">
              <div className={`${mainClass}__form__inputs`}>
                <FormikInput
                  name="password"
                  label={t("Password")}
                  type="password"
                  disabled={isSubmitting}
                />
                <FormikInput
                  name="password2"
                  label={t("Repeat password")}
                  type="password"
                  disabled={isSubmitting}
                />
              </div>
              <PasswordValidator password={values?.password} />
              <div className={`${mainClass}__form__actions`}>
                <Button type="submit" loading={!!isSubmitting} fullWidth>
                  {t("Set password")}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ResetPasswordContent;
