import NewWebsiteContent from "page_components/client/websites/NewWebsiteContent";
import NewWebsiteProvider from "context/client/new_website";

const ChangePlan = () => (
  <NewWebsiteProvider change_plan_mode>
    <NewWebsiteContent />
  </NewWebsiteProvider>
);

export default ChangePlan;
