import "./styles.scss";
import DropdownCard from "components/DropdownCard";

import { Link } from "react-router-dom";

const mainClass = "dropdown-menu";

const DropdownMenu = ({ items, closeHandler }) => {
  return (
    <DropdownCard>
      <div className={mainClass}>
        <div className={`${mainClass}__wrapper`}>
          {items?.map(({ icon, label, href, action }, index) => {
            const content = (
              <>
                {icon}
                {label}
              </>
            );

            const classname = `${mainClass}__item`;

            return !!href ? (
              <Link
                key={index}
                to={href}
                className={classname}
                onClick={closeHandler}
              >
                {content}
              </Link>
            ) : (
              <button
                key={index}
                onClick={() => {
                  action();
                  closeHandler();
                }}
                className={classname}
                type="button"
              >
                {content}
              </button>
            );
          })}
        </div>
      </div>
    </DropdownCard>
  );
};

export default DropdownMenu;
