import "./styles.scss";

import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import FormikInput from "components/formik/FormikInput";
import FormikTaxInput from "components/formik/FormikTaxInput";
import MobileModalOptions from "components/MobileModalOptions";
import FormikPhoneInput from "components/formik/FormikPhoneInput";

import { useNewWebsite } from "context/client/new_website";

const mainClass = "new-website-content__your-data__mobile";

const YourDataMobile = () => {
  const { t } = useTranslation();
  const { values, isSubmitting, touched } = useFormikContext();

  const { change_plan_mode, form_data_error } = useNewWebsite();

  const contact_data_complete =
    !!values?.first_name &&
    !!values?.last_name &&
    !!values?.email &&
    !!values?.phone;

  const billing_data_complete =
    !!values?.billing?.name &&
    !!values?.billing?.tax &&
    !!values?.billing.street &&
    !!values?.billing.city;

  const is_touched = Object.keys(touched).length;
  const contact_data_error = !!is_touched && !!!contact_data_complete;
  const billing_data_error = !!is_touched && !!!billing_data_complete;

  const MobileYourDataModals = () => (
    <>
      <div
        title={t("Contact data")}
        variant="radio"
        error={contact_data_error}
        checked={contact_data_complete}
      >
        <div className="form">
          <FormikInput
            name="first_name"
            label={t("Name")}
            placeholder={t("John")}
            disabled={isSubmitting || !!change_plan_mode}
            required
          />
          <FormikInput
            name="last_name"
            label={t("Surname")}
            placeholder={t("Doe")}
            disabled={isSubmitting || !!change_plan_mode}
            required
          />
          <FormikInput
            name="email"
            type="email"
            label={t("Email")}
            placeholder={t("john.doe@adream.com")}
            disabled={isSubmitting || !!change_plan_mode}
            required
          />
          <FormikPhoneInput
            label={t("Phone")}
            name="phone"
            placeholder="123 456 789"
            required
            disabled={isSubmitting || !!change_plan_mode}
          />
          <div className="form__required-info">
            <small>
              <span>*</span> {t("required field")}
            </small>
          </div>
          {/* <Button fullWidth>{t("Save")}</Button> */}
        </div>
      </div>
      <div
        title={t("Billing data")}
        variant="radio"
        error={billing_data_error}
        checked={billing_data_complete}
      >
        <div className="form">
          <FormikInput
            name="billing.name"
            label={t("Company name")}
            disabled={isSubmitting || !!change_plan_mode}
            placeholder={t("Acme Corporation")}
            required
          />
          <FormikTaxInput
            label={t("Tax identification number")}
            name="billing.tax"
            disabled={isSubmitting || !!change_plan_mode}
            invalid_tax={form_data_error === "tax_id_invalid" ? true : false}
            required
          />
          <FormikInput
            name="billing.street"
            label={t("Street")}
            disabled={isSubmitting || !!change_plan_mode}
            placeholder={t("Main Street 13A")}
            required
          />
          <FormikInput
            name="billing.city"
            label={t("City")}
            placeholder={t("London")}
            disabled={isSubmitting || !!change_plan_mode}
            required
          />
          <div className="form__required-info">
            <small>
              <span>*</span> {t("required field")}
            </small>
          </div>
          {/* <Button fullWidth>{t("Save")}</Button> */}
        </div>
      </div>
    </>
  );

  return (
    <div className={mainClass}>
      <MobileModalOptions components={MobileYourDataModals} />
    </div>
  );
};

export default YourDataMobile;
