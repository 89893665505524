import { useState } from "react";
import { useTranslation } from "react-i18next";

import PlaceholderPage from "components/PlaceholderPage";
import ForgetPasswordContent from "page_components/auth/forget-password/Content";

const ForgetPassword = () => {
  const { t } = useTranslation();

  const [email_confirmed, setEmailConfirmed] = useState(null);

  return !!!email_confirmed ? (
    <ForgetPasswordContent setEmailConfirmed={setEmailConfirmed} />
  ) : (
    <PlaceholderPage
      variant="auth"
      title={t("Message was sent")}
      text={
        <>
          {t(
            "We have sent instructions on how to reset your password onto your e-mail"
          )}{" "}
          (<strong>{email_confirmed}</strong>).{" "}
          {t(
            "Make sure to check your spam box as well in case you can't see it in your inbox."
          )}
        </>
      }
      button={{
        label: t("Back to login"),
        href: "/",
      }}
    />
  );
};

export default ForgetPassword;
