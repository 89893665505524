import "./styles.scss";

import { useTranslation } from "react-i18next";

import Chat from "./components/Chat";
import Button from "components/Button";
import Details from "./components/Details";
import ConversationContentMobile from "./components/ConversationContentMobile";

import useResponsive from "libs/helpers/useResponsive";

const mainClass = "task-conversation";

const Conversation = ({
  admin_mode,
  hide_required_action,
  data,
  file_progress,
  sendMessage,
  editMessage,
  setWebsiteInfoModal,
  action_buttons,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  return (
    <div className={mainClass}>
      {!!isMobile ? (
        <ConversationContentMobile
          overview_data={data}
          messages={data?.messages}
          onSubmit={sendMessage}
          editMessage={editMessage}
          file_progress={file_progress}
          last_updated={data?.last_updated}
          status={data?.status}
          action_buttons={action_buttons}
        />
      ) : (
        <div className={`${mainClass}__content`}>
          <div className={`${mainClass}__content__details`}>
            <Details
              data={data}
              admin_mode={admin_mode}
              show_time_entries={true}
              hide_required_action={hide_required_action}
              setWebsiteInfoModal={setWebsiteInfoModal}
            />
            {!!admin_mode && (
              <Button href={`/tasks/${data?._id}`}>{t("Back")}</Button>
            )}
          </div>
          <Chat
            messages={data?.messages}
            onSubmit={sendMessage}
            editMessage={editMessage}
            file_progress={file_progress}
            last_updated={data?.last_updated}
            status={data?.status}
          />
        </div>
      )}
    </div>
  );
};

export default Conversation;
