import "./styles.scss";

import { useState } from "react";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";

import Modal from "components/Modal";
import FormikInput from "components/formik/FormikInput";
import PasswordValidator from "components/PasswordValidator";

import { useWebmasters } from "context/admin/webmasters";
import createAccountSchema from "libs/validations/admin/createAccountSchema";
import CreateAccountConfirmationModal from "../CreateAccountConfirmationModal";

const CreateAccountModal = ({ onClose }) => {
  const { t } = useTranslation();

  const { createAccount } = useWebmasters();
  const [create_confirmation_modal, setCreateConfirmationModal] =
    useState(null);

  const onSubmit = async (values, setSubmitting, setErrors) => {
    try {
      await createAccount(values);
      setCreateConfirmationModal(values?.email);
    } catch (err) {
      if (err?.response?.data?.type === "email_exist") {
        setErrors({ email: err?.response?.data?.error });
      }

      setSubmitting(false);
    }
  };

  return !!!create_confirmation_modal ? (
    <Formik
      initialValues={{
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        password2: "",
      }}
      validationSchema={createAccountSchema}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        onSubmit(values, setSubmitting, setErrors);
      }}
    >
      {({ values, isSubmitting, submitForm }) => (
        <Form autoComplete="off">
          <Modal
            title={t("Create account")}
            onClose={onClose}
            onSubmit={submitForm}
            loading={isSubmitting}
            variant="wide"
          >
            <div className="form">
              <div className="column-layout">
                <div className="column-layout__col">
                  <FormikInput
                    name="first_name"
                    label={t("Name")}
                    disabled={isSubmitting}
                  />
                  <FormikInput
                    name="last_name"
                    label={t("Surname")}
                    disabled={isSubmitting}
                  />
                  <FormikInput
                    name="email"
                    label={t("Email")}
                    type="email"
                    disabled={isSubmitting}
                  />
                </div>
                <div className="column-layout__col">
                  <FormikInput
                    name="password"
                    type="password"
                    label={t("New password")}
                  />
                  <FormikInput
                    name="password2"
                    type="password"
                    label={t("Repeat new password")}
                  />
                  <PasswordValidator password={values?.password} />
                </div>
              </div>
            </div>
          </Modal>
        </Form>
      )}
    </Formik>
  ) : (
    <CreateAccountConfirmationModal
      email={create_confirmation_modal}
      onClose={onClose}
    />
  );
};

export default CreateAccountModal;
