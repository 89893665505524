import { useState } from "react";
import { useTranslation } from "react-i18next";

import PlaceholderPage from "components/PlaceholderPage";
import ResetPasswordContent from "page_components/auth/forget-password/reset/Content";

const ResetPassword = () => {
  const { t } = useTranslation();
  const [confirmed, setConfirmed] = useState(null);

  return !!!confirmed ? (
    <ResetPasswordContent setConfirmed={setConfirmed} />
  ) : (
    <PlaceholderPage
      variant="auth"
      title={t("Your password has been changed")}
      text={t("You can now login using your new password.")}
      button={{
        label: t("Back to login"),
        href: "/",
      }}
    />
  );
};

export default ResetPassword;
