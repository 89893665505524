import { createContext, useContext, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

import api from "libs/api";
import task_status_data from "libs/task_status_data.json";
import task_priority_data from "libs/task_priority_data.json";

import { useApp } from "context/app";

const TasksContext = createContext();

const TasksProvider = ({ children }) => {
  const { t } = useTranslation();

  const { websites } = useApp();
  const { search } = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const filters = queryString.parse(search);
  const default_sort = "created_at:desc";

  const [init_loading, setInitLoading] = useState(true);

  const { isLoading: loading, data } = useQuery(
    ["tasks", filters],
    () =>
      api.get(
        `/admin/tasks?${queryString.stringify({
          ...filters,
          type:
            task_priority_data?.find((item) => item?.key === filters?.type)
              ?.value || undefined,
          status:
            filters?.status === "accepted_and_unasigned"
              ? filters?.status
              : task_status_data?.find((item) => item?.key === filters?.status)
                  ?.value || undefined,
          sort: filters?.sort || default_sort,
        })}`
      ),
    {
      onSuccess: () => {
        setInitLoading(false);
      },
    }
  );

  const { data: webmasters } = useQuery(["webmasters"], () =>
    api.get("/admin/webmasters")
  );

  const createTask = (values) =>
    new Promise(async (resolve, reject) => {
      try {
        const task = await api.post(`/admin/tasks`, values);

        queryClient.setQueryData(["tasks", filters], (prev) => ({
          ...prev,
          tasks: [task, ...prev?.tasks],
        }));

        toast.success(t("Saved successfully."));
        resolve();
      } catch (err) {
        console.error(err);
        toast.error("Something went wrong.");
        reject(err);
      }
    });

  const filterHandler = (name, value) => {
    navigate(
      `/tasks?${queryString.stringify({
        ...filters,
        [name]: value || undefined,
      })}`
    );
  };

  const value = useMemo(() => {
    return {
      data: data?.tasks,
      websites: data?.websites,
      webmasters,
      loading,
      init_loading,
      filters,
      filterHandler,
      createTask,
    };
    // eslint-disable-next-line
  }, [data, websites, webmasters, loading, init_loading, filters]);

  return (
    <TasksContext.Provider value={value}>{children}</TasksContext.Provider>
  );
};

const useTasks = () => useContext(TasksContext);
export { TasksContext, useTasks };
export default TasksProvider;
