import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import PlaceholderPage from "components/PlaceholderPage";
import PageLoader from "components/PageLoader";

import api from "libs/api";
import { useApp } from "context/app";

const ChangeEmail = () => {
  const { user, setUser } = useApp();
  const { search } = useLocation();
  const navigate = useNavigate();

  const query = new URLSearchParams(search);

  const [email_confirmed, setEmailConfirmed] = useState(true);

  useEffect(() => {
    const token = query.get("token");
    (async () => {
      try {
        const data = await api.put("/user/settings/change-email", {
          token,
        });
        setEmailConfirmed(true);

        if (!!user) {
          setUser((prev) => ({ ...prev, email: data?.email }));
        }
      } catch (err) {
        console.error(err);
        navigate("/");
      }
    })();
    // eslint-disable-next-line
  }, []);

  return !!!email_confirmed ? (
    <PageLoader />
  ) : (
    <PlaceholderPage
      title="Your email was successfully changed."
      text="You can now use your new email to log in"
      button={{
        label: !!user ? "Go to dashboard" : "Log in",
        href: "/",
      }}
    />
  );
};

export default ChangeEmail;
