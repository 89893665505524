import { useTranslation } from "react-i18next";

import Conversation from "page_components/shared/tasks/Conversation";
import DashboardContentLayout from "components/layouts/DashboardContentLayout";

import TaskConversationProvider, {
  useTaskConversation,
} from "context/admin/task_conversation";

const TaskConversationContent = () => {
  const { t } = useTranslation();

  const { data, file_progress, sendMessage, editMessage, loading } =
    useTaskConversation();

  const breadcrumps = [
    {
      label: t("Tasks"),
      href: "/tasks",
    },
    {
      label: data?.website,
      href: `/websites/${data?.website_id}`,
    },
    {
      label: data?.title,
    },
  ];

  return (
    <DashboardContentLayout
      breadcrumps={breadcrumps}
      header_label={t("Conversation")}
      loading={loading}
    >
      <Conversation
        data={data}
        file_progress={file_progress}
        sendMessage={sendMessage}
        editMessage={editMessage}
        hide_required_action
        admin_mode
      />
    </DashboardContentLayout>
  );
};

const TaskConversation = () => (
  <TaskConversationProvider>
    <TaskConversationContent />
  </TaskConversationProvider>
);

export default TaskConversation;
