import "./styles.scss";

import { useState } from "react";
import { useTranslation } from "react-i18next";

import Modal from "components/Modal";
import { useTask } from "context/client/task";

const AcceptTaskModal = ({ title, onClose }) => {
  const { t } = useTranslation();

  const { updateTask } = useTask();
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    try {
      setLoading(true);

      await updateTask({ status: 6 });
      onClose();
    } catch (err) {
      console.error(err);
    }

    setLoading(false);
  };

  return (
    <Modal
      title={t("Accept and close task")}
      onClose={onClose}
      onSubmit={onSubmit}
      submit_button_label="Yes, accept task"
      loading={!!loading}
      variant="little-narrow"
      mode="success"
    >
      <p>
        {t("Are you sure you would like to accept and close the task")}{" "}
        <strong>{title}</strong>?
        <br />
        <br />
        {t(
          "This action cannot be reversed. Before closing the task make sure that everything you requested has been completed."
        )}
      </p>
    </Modal>
  );
};

export default AcceptTaskModal;
