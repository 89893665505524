import moment from "moment";
import { useTranslation } from "react-i18next";

import Table from "components/Table";

import { useDashboard } from "context/admin/dashboard";
import toHoursAndMinutes from "libs/helpers/toHoursAndMinutes";

const UpcomingTasks = () => {
  const { t } = useTranslation();

  const { data, loading, filters, filterHandler } = useDashboard();

  const table_data = {
    keys: [
      {
        sort_key: "title",
        key: "title-full",
        value: t("Title"),
      },
      {
        key: "website",
        value: t("Website"),
      },
      {
        key: "estimated_time",
        value: t("Estimated time"),
      },
      {
        key: "time_tracked",
        value: t("Time tracked"),
      },
      {
        key: "due_date",
        value: t("Due date"),
      },
    ],
    items: data?.tasks_upcoming_date?.map(
      ({
        _id,
        title,
        website_id,
        website,
        estimated_time,
        time_tracked,
        due_date,
      }) => [
        {
          key: "title-full",
          type: "link",
          value: {
            label: title,
            href: `/tasks/${_id}`,
          },
        },
        {
          key: "website",
          type: "link",
          value: {
            label: website,
            href: `/clients/${website_id}`,
          },
        },
        {
          key: "estimated_time",
          value: !!estimated_time
            ? toHoursAndMinutes(estimated_time?.$numberDecimal)?.formatted
            : "-",
        },
        {
          key: "time_tracked",
          value: time_tracked?.time
            ? toHoursAndMinutes(time_tracked?.time?.$numberDecimal)?.formatted
            : "-",
        },
        {
          key: "due_date",
          value: !!due_date ? moment(due_date)?.format("DD MMM, YYYY") : "-",
        },
      ]
    ),
  };

  return (
    <Table
      data={table_data}
      loading={!!loading}
      header={<h3>{t("Tasks with upcoming due date")}</h3>}
      sort={filters?.sort}
      setSort={(value) => filterHandler("sort", value)}
    />
  );
};

export default UpcomingTasks;
