import "./styles.scss";

import { useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import Modal from "components/Modal";
import { useTask } from "context/admin/task";

const MarkTaskAsComplete = ({ onClose }) => {
  const { t } = useTranslation();

  const { updateTask } = useTask();
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    try {
      setLoading(true);

      await updateTask({ status: 6 });
      toast.success(t("Task was marked as completed."));
      onClose();
    } catch (err) {
      console.error(err);
    }

    setLoading(false);
  };

  return (
    <Modal
      title={t("Mark task as completed")}
      onClose={onClose}
      onSubmit={onSubmit}
      submit_button_label={t("Yes, all done")}
      loading={!!loading}
      variant="little-narrow"
    >
      <p>
        {t(
          "Have you checked if all the requirements for the task have been completed?"
        )}
        <br />
        <br />
        {t(
          "Are you sure you want to complete this task? This action can only be reversed by the administrator"
        )}
      </p>
    </Modal>
  );
};

export default MarkTaskAsComplete;
