import "./styles.scss";
import IconButton from "components/IconButton";

import { ReactComponent as EditIcon } from "assets/icons/pencil.svg";

const mainClass = "details-card-webmaster-time";

const DetailsCardWebmasterTime = ({ value, onClick }) => {
  return (
    <div className={mainClass}>
      <span className={`${mainClass}__extra`}>{value}</span>
      {!!onClick && (
        <IconButton icon={<EditIcon />} onClick={onClick} size="small" />
      )}
    </div>
  );
};

export default DetailsCardWebmasterTime;
