import "./styles.scss";

import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";

import Modal from "components/Modal";
import FormikInput from "components/formik/FormikInput";
import FormikPhoneInput from "components/formik/FormikPhoneInput";

import { useWebsite } from "context/client/website";
import contactDataSchema from "libs/validations/contactDataSchema";

const ContactDataModal = ({ onClose }) => {
  const { t } = useTranslation();
  const { data, updateData } = useWebsite();

  const onSubmit = async (values, setSubmitting) => {
    try {
      await updateData(values);
      onClose();
    } catch (err) {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        contact_data: {
          first_name: data?.contact_data?.first_name,
          last_name: data?.contact_data?.last_name,
          email: data?.contact_data?.email,
          phone: {
            code: data?.contact_data?.phone?.code,
            number: data?.contact_data?.phone?.number,
          },
        },
      }}
      validationSchema={contactDataSchema}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({ isSubmitting, submitForm }) => (
        <Form autoComplete="off">
          <Modal
            title={t("Change contact data")}
            onClose={onClose}
            onSubmit={submitForm}
            loading={isSubmitting}
          >
            <div className="form">
              <FormikInput
                name="contact_data.first_name"
                label={t("Name")}
                disabled={isSubmitting}
                required
                placeholder="John"
              />
              <FormikInput
                name="contact_data.last_name"
                label={t("Surname")}
                disabled={isSubmitting}
                required
                placeholder="Doe"
              />
              <FormikInput
                name="contact_data.email"
                label={t("Email")}
                disabled={isSubmitting}
                required
                placeholder="john.doe@adream.com"
              />
              <FormikPhoneInput
                label={t("Phone")}
                name="contact_data.phone"
                placeholder="123 456 789"
                disabled={isSubmitting}
              />
              <div className="form__required-info">
                <small>
                  <span>*</span> {t("required field")}
                </small>
              </div>
            </div>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default ContactDataModal;
