import NewTaskContent from "page_components/client/tasks/NewTaskContent";
import NewTaskProvider from "context/client/new_task";

const NewTasks = () => (
  <NewTaskProvider>
    <NewTaskContent />
  </NewTaskProvider>
);

export default NewTasks;
