import { useTranslation } from "react-i18next";

const FaqData = () => {
  const { t } = useTranslation();

  const data = [
    {
      title: t("Tasks"),
      items: [
        {
          title: t("faq_tasks_question_1"),
          content: t("faq_tasks_answer_1"),
        },
        {
          title: t("faq_tasks_question_2"),
          content: t("faq_tasks_answer_2"),
        },
        {
          title: t("faq_tasks_question_3"),
          content: t("faq_tasks_answer_3"),
        },
        {
          title: t("faq_tasks_question_4"),
          content: t("faq_tasks_answer_4"),
        },
        {
          title: t("faq_tasks_question_5"),
          content: t("faq_tasks_answer_5"),
        },
        {
          title: t("faq_tasks_question_6"),
          content: t("faq_tasks_answer_6"),
        },
        {
          title: t("faq_tasks_question_7"),
          content: t("faq_tasks_answer_7"),
        },
        {
          title: t("faq_tasks_question_8"),
          content: t("faq_tasks_answer_8"),
        },
        {
          title: t("faq_tasks_question_9"),
          content: t("faq_tasks_answer_9"),
        },
      ],
    },
    {
      title: t("Payments & Subscriptions"),
      items: [
        {
          title: t("faq_payments_question_1"),
          content: t("faq_payments_answer_1"),
        },
        {
          title: t("faq_tasks_question_2"),
          content: t("faq_payments_answer_2"),
        },
        {
          title: t("faq_payments_question_3"),
          content: t("faq_payments_answer_3"),
        },
        {
          title: t("faq_payments_question_4"),
          content: t("faq_payments_answer_4"),
        },
        {
          title: t("faq_payments_question_5"),
          content: t("faq_payments_answer_5"),
        },
        {
          title: t("faq_payments_question_6"),
          content: t("faq_payments_answer_6"),
        },
        {
          title: t("faq_payments_question_7"),
          content: t("faq_payments_answer_7"),
        },
        {
          title: t("faq_payments_question_8"),
          content: t("faq_payments_answer_8"),
        },
        {
          title: t("faq_payments_question_9"),
          content: t("faq_payments_answer_9"),
        },
        {
          title: t("faq_payments_question_10"),
          content: t("faq_payments_answer_10"),
        },
        {
          title: t("faq_payments_question_11"),
          content: t("faq_payments_answer_11"),
        },
        {
          title: t("faq_payments_question_12"),
          content: t("faq_payments_answer_12"),
        },
      ],
    },
    {
      title: t("Referral program"),
      items: [
        {
          title: t("faq_referral_question_1"),
          content: t("faq_referral_answer_1"),
        },
        {
          title: t("faq_referral_question_2"),
          content: t("faq_referral_answer_2"),
        },
        {
          title: t("faq_referral_question_3"),
          content: t("faq_referral_answer_3"),
        },
        {
          title: t("faq_referral_question_4"),
          content: t("faq_referral_answer_4"),
        },
        {
          title: t("faq_referral_question_5"),
          content: t("faq_referral_answer_5"),
        },
        {
          title: t("faq_referral_question_6"),
          content: t("faq_referral_answer_6"),
        },
        {
          title: t("faq_referral_question_7"),
          content: t("faq_referral_answer_7"),
        },
        {
          title: t("faq_referral_question_8"),
          content: t("faq_referral_answer_8"),
        },
        {
          title: t("faq_referral_question_9"),
          content: t("faq_referral_answer_9"),
        },
        {
          title: t("faq_referral_question_10"),
          content: t("faq_referral_answer_10"),
        },
      ],
    },
  ];

  return data;
};

export default FaqData;
