import "./styles.scss";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Table from "components/Table";
import Button from "components/Button";
import DetailsCard from "components/DetailsCard";
import FaqContentModal from "../../components/FaqContentModal";
import MobileModalOptions from "components/MobileModalOptions";

import useResponsive from "libs/helpers/useResponsive";

const mainClass = "faq-content-mobile";

const FaqContentMobile = ({ data, setMobileHeader }) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const [selected_topic, setSelectedTopic] = useState(false);
  const [selected_question, setSelectedQuestion] = useState(null);

  useEffect(() => {
    setMobileHeader(selected_topic?.props?.data?.items?.[0]?.[0]?.value?.label);
    // eslint-disable-next-line
  }, [selected_topic]);

  const DetailsCards = () => (
    <>
      {data?.map((item, index) => {
        const table_data = {
          keys: [
            {
              sort_key: "website",
              key: "website-full",
              value: t("Website"),
              hidden: true,
            },
            {
              key: "created_at",
              value: t("Date"),
            },
            {
              key: "template",
              value: "",
            },
          ],
          items: item?.items?.map(({ title, content }) => [
            {
              key: "website-fulld",
              mobile_title: true,
              hidden: true,
              value: {
                label: item?.title,
              },
            },
            {
              key: "template",
              value: title,
            },
            ...(isMobile
              ? [
                  {
                    key: "button",
                    type: "button",
                    value: {
                      label: t("Show information"),
                      onclick: () =>
                        setSelectedQuestion({
                          section: item?.title,
                          title,
                          content,
                        }),
                    },
                  },
                ]
              : []),
          ]),
        };

        return (
          <DetailsCard key={index} title={t(item?.title)}>
            <Table data={table_data} />
          </DetailsCard>
        );
      })}
    </>
  );

  return (
    <>
      {!!selected_topic ? (
        <div className={mainClass}>
          <Button
            size="mobile-small"
            variant="mobile-transparent"
            onClick={() => setSelectedTopic(null)}
          >
            {t("Show categories")}
          </Button>
          {selected_topic}
        </div>
      ) : (
        <MobileModalOptions
          components={DetailsCards}
          onClick={setSelectedTopic}
        />
      )}
      {selected_question !== null && (
        <FaqContentModal
          title={selected_question?.section}
          data={selected_question}
          onClose={() => setSelectedQuestion(null)}
        />
      )}
    </>
  );
};

export default FaqContentMobile;
