import "./styles.scss";

import { Formik, Form } from "formik";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import Button from "components/Button";
import FileUploader from "./components/FileUploader";
import TaskOnboarding from "components/TaskOnboarding";
import FormikInput from "components/formik/FormikInput";
import FormikSelect from "components/formik/FormikSelect";
import ProgressBarSteps from "components/ProgresBarSteps";
import FormikTextarea from "components/formik/FormikTextarea";
import DashboardContentLayout from "components/layouts/DashboardContentLayout";

import { useApp } from "context/app";
import { useNewTask } from "context/client/new_task";

import useResponsive from "libs/helpers/useResponsive";
import newTaskFormSchema from "libs/validations/new_task_form";

const mainClass = "new-task-content";

const NewTaskContent = () => {
  const { t } = useTranslation();
  const { search, pathname } = useLocation();
  const query = queryString.parse(search);
  const { isMobile } = useResponsive();

  const { websites } = useApp();
  const { file_progress, submitForm } = useNewTask();
  const [task_onboarding, setTaskOnboarding] = useState(false);
  const [mobile_step, setMobileStep] = useState(0);

  useEffect(() => {
    if (query?.onboarding === "true") {
      setTaskOnboarding(true);
    }
    // eslint-disable-next-line
  }, [query?.onboarding]);

  const formatted_websites = websites?.map(({ _id, website }) => ({
    label: website,
    value: _id,
  }));

  const onSubmit = async (values, setSubmitting) => {
    try {
      await submitForm(values);
    } catch (err) {
      setSubmitting(false);
    }
  };

  const breadcrumps = [
    {
      label: t("Tasks"),
      href: "/tasks",
    },
    {
      label: t("Create new task"),
    },
  ];

  const onOnboardingClose = () => {
    const container_element = document.getElementsByClassName(
      "dashboard-layout__content__wrapper"
    )?.[0];
    const website_element = document?.getElementById("website_select");
    const title_element = document.getElementById("title_select");
    const description_element = document.getElementById("description_select");
    const attachments_element = document.getElementById("file_uploader");

    container_element.style.position = "relative";
    website_element.style.zIndex = 1;
    website_element.style.backgroundColor = "transparent";
    title_element.style.zIndex = 1;
    title_element.style.backgroundColor = "transparent";
    description_element.style.zIndex = 1;
    description_element.style.backgroundColor = "transparent";
    attachments_element.style.zIndex = 1;
    attachments_element.style.backgroundColor = "transparent";

    window.history.replaceState({}, null, pathname);
    setTaskOnboarding(false);
  };

  const progress_data = [
    t("Website"),
    t("Title"),
    t("Description"),
    t("Files"),
  ];

  const onMobileStepHandler = async (type, validate, setErrors) => {
    // setErrors(null);
    // let errors;

    if (type === "next") {
      if (mobile_step === 0) {
        const errors = await validate("website");
        if (!!errors?.website) return;
      }
      if (mobile_step === 1) {
        const errors = await validate("title");
        if (!!errors?.title) return;
      }
      if (mobile_step === 2) {
        const errors = await validate("description");
        if (!!errors?.description) return;
      }
    }

    setMobileStep((prev) => (type === "next" ? prev + 1 : prev - 1));
  };

  return (
    <>
      <DashboardContentLayout
        header_label={t("Create new task")}
        breadcrumps={breadcrumps}
        less_padding
      >
        <div className={mainClass}>
          <div className={`${mainClass}__wrapper`}>
            {!!isMobile && (
              <ProgressBarSteps
                data={progress_data}
                step={mobile_step}
                setStep={() => alert("ok")}
                step_touched={false}
              />
            )}
            <Formik
              initialValues={{
                website:
                  formatted_websites?.length === 1
                    ? formatted_websites?.[0]?.value
                    : null,
                title: "",
                description: "",
                files: [],
              }}
              validationSchema={newTaskFormSchema}
              onSubmit={(values, { setSubmitting }) => {
                onSubmit(values, setSubmitting);
              }}
            >
              {({
                values,
                isSubmitting,
                setFieldTouched,
                submitForm,
                setErrors,
              }) => (
                <>
                  <Form
                    className={`${mainClass}__form__content`}
                    autoComplete="off"
                  >
                    <div className={`${mainClass}__form__content__wrapper`}>
                      {(!isMobile || mobile_step === 0) && (
                        <div
                          className={`${mainClass}__form__content__form form`}
                        >
                          <div
                            id="website_select"
                            className={`${mainClass}__form__content__form__onboarding__wrapper`}
                          >
                            <div
                              className={`${mainClass}__form__content__form__header`}
                            >
                              <h3>{t("Choose website")}</h3>
                            </div>
                            <FormikSelect
                              name="website"
                              label={t("Website")}
                              options={formatted_websites}
                              selected={values?.billing?.country}
                              disabled={isSubmitting}
                              placeholder="www.example.com"
                              searcher={false}
                              required
                            />
                          </div>
                          <div className="form__required-info">
                            <small>
                              <span>*</span> {t("required field")}
                            </small>
                          </div>
                        </div>
                      )}
                      {(!isMobile ||
                        mobile_step === 1 ||
                        mobile_step === 2) && (
                        <div className={`${mainClass}__form__content__form`}>
                          <div
                            id="title_select"
                            className={`${mainClass}__form__content__form__onboarding__wrapper`}
                          >
                            <div
                              className={`${mainClass}__form__content__form__header`}
                            >
                              <h3>
                                {" "}
                                {!isMobile
                                  ? t("Task description")
                                  : mobile_step === 1
                                  ? t("Add title")
                                  : mobile_step === 2
                                  ? t("Add description")
                                  : null}
                              </h3>
                            </div>
                            {(!isMobile || mobile_step === 1) && (
                              <FormikInput
                                name="title"
                                label={t("Title")}
                                disabled={isSubmitting}
                                placeholder={t("Add task title")}
                                required
                              />
                            )}
                          </div>
                          {(!isMobile || mobile_step === 2) && (
                            <div
                              id="description_select"
                              className={`${mainClass}__form__content__form__onboarding__wrapper`}
                            >
                              <FormikTextarea
                                label={t("Description")}
                                name="description"
                                disabled={isSubmitting}
                                placeholder={t("Add task description")}
                                required
                              />
                            </div>
                          )}
                        </div>
                      )}
                      {(!isMobile || mobile_step === 3) && (
                        <div
                          className={`${mainClass}__form__content__form form`}
                        >
                          <div
                            id="file_uploader"
                            className={`${mainClass}__form__content__form__onboarding__wrapper`}
                          >
                            <div
                              className={`${mainClass}__form__content__form__header`}
                            >
                              <h3>
                                {!isMobile
                                  ? t("Additional information")
                                  : t("Add files")}
                              </h3>
                            </div>
                            <FileUploader progress={file_progress} />
                          </div>
                        </div>
                      )}
                    </div>
                    {!!!isMobile ? (
                      <div className={`${mainClass}__form__content__bottom`}>
                        <Button variant="transparent" href="/tasks">
                          {t("Back to tasks")}
                        </Button>
                        <Button
                          id="create_task_button"
                          type="submit"
                          loading={!!isSubmitting}
                        >
                          {t("Create task")}
                        </Button>
                      </div>
                    ) : (
                      <div
                        className={`${mainClass}__form__content__bottom--mobile`}
                      >
                        {mobile_step !== 0 && (
                          <Button
                            variant="transparent"
                            onClick={() =>
                              onMobileStepHandler("prev", null, setErrors)
                            }
                          >
                            {t("Previous step")}
                          </Button>
                        )}
                        <Button
                          key={mobile_step}
                          id="create_task_button"
                          type={mobile_step === 3 ? "submit" : "button"}
                          onClick={
                            mobile_step !== 3
                              ? () =>
                                  onMobileStepHandler(
                                    "next",
                                    setFieldTouched,
                                    setErrors
                                  )
                              : null
                          }
                          loading={!!isSubmitting}
                        >
                          {mobile_step === 3
                            ? t("Create task")
                            : t("Next step")}
                        </Button>
                      </div>
                    )}
                  </Form>
                  {!!task_onboarding && (
                    <TaskOnboarding
                      init_step={1}
                      onClose={onOnboardingClose}
                      validate={setFieldTouched}
                      submitForm={submitForm}
                    />
                  )}
                </>
              )}
            </Formik>
          </div>
        </div>
      </DashboardContentLayout>
    </>
  );
};

export default NewTaskContent;
