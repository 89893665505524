import "./styles.scss";

import { useState } from "react";
import { useTranslation } from "react-i18next";

import Table from "components/Table";
import PriorityFlag from "components/PriorityFlag";
import CreateAccountModal from "./components/CreateAccountModal";
import DashboardContentLayout from "components/layouts/DashboardContentLayout";

import { useWebmasters } from "context/admin/webmasters";

const WebmastersContent = () => {
  const { t } = useTranslation();

  const { data, filters, loading, init_loading, filterHandler } =
    useWebmasters();

  const breadcrumps = [
    {
      label: t("Webmasters"),
    },
  ];

  const [create_account_modal, setCreateAccountModal] = useState(false);

  const actions = [
    {
      type: "send_notification",
    },
    {
      label: t("Create account"),
      onclick: () => setCreateAccountModal((prev) => !prev),
    },
  ];

  const table_data = {
    keys: [
      {
        key: "first_name",
        value: t("Full name"),
      },
      {
        key: "urgent_count",
        value: (
          <>
            {t("Urgent tasks")} <PriorityFlag priority={3} />
          </>
        ),
      },
      {
        key: "high_count",
        value: (
          <>
            {t("High tasks")} <PriorityFlag priority={2} />
          </>
        ),
      },
      {
        key: "normal_count",
        value: (
          <>
            {t("Normal tasks")} <PriorityFlag priority={1} />
          </>
        ),
      },
    ],
    items: data?.map(
      ({
        _id,
        first_name,
        last_name,
        normal_count,
        high_count,
        urgent_count,
      }) => [
        {
          key: "first_name",
          type: "link",
          value: {
            label: `${first_name} ${last_name}`,
            href: `/webmasters/${_id}`,
          },
        },
        {
          key: "urgent_count",
          value: urgent_count,
        },
        {
          key: "high_count",
          value: high_count,
        },
        {
          key: "normal_count",
          value: normal_count,
        },
      ]
    ),
  };

  return (
    <DashboardContentLayout
      header_label={t("Webmasters")}
      loading={init_loading}
      breadcrumps={breadcrumps}
      actions={actions}
    >
      <Table
        data={table_data}
        sort={filters?.sort}
        loading={!!loading}
        setSort={(value) => filterHandler("sort", value)}
      />
      {!!create_account_modal && (
        <CreateAccountModal onClose={() => setCreateAccountModal(false)} />
      )}
    </DashboardContentLayout>
  );
};

export default WebmastersContent;
