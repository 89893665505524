import { createContext, useContext, useMemo, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { useTranslation } from "react-i18next";

import api from "libs/api";

const ClientSettingsContext = createContext();

const ClientSettingsProvider = ({ children }) => {
  const { t } = useTranslation();

  const { id } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [photo_progress, setPhotoProgress] = useState("0%");

  const { isLoading: loading, data } = useQuery(["client-settings", id], () =>
    api.get(`/admin/clients/${id}/client-settings`)
  );

  const updateSettings = (values) =>
    new Promise(async (resolve, reject) => {
      try {
        let data_to_send = { ...values };

        if (data_to_send?.settings?.email_notifications) {
          data_to_send = {
            settings: {
              email_notifications: {
                ...data?.settings?.email_notifications,
                ...values?.settings?.email_notifications,
              },
            },
          };
        }

        await api.put(`/admin/clients/${id}/client-settings`, data_to_send);

        queryClient.setQueryData(["client-settings", id], (prev) => ({
          ...prev,
          ...data_to_send,
        }));

        toast.success(t("Saved successfully."));
        resolve();
      } catch (err) {
        toast.error("Something went wrong.");
        reject(err);
      }
    });

  const changePassword = (values) =>
    new Promise(async (resolve, reject) => {
      try {
        await api.put(`/admin/clients/${id}/change-password`, values);

        queryClient.setQueryData(["client-settings", id], (prev) => ({
          ...prev,
          last_password_changed: new Date(),
        }));

        toast.success(t("Saved successfully."));
        resolve();
      } catch (err) {
        toast.error("Something went wrong.");
        reject(err);
      }
    });

  const updatePhoto = (file, photo_preview) =>
    new Promise(async (resolve, reject) => {
      try {
        const formData = new FormData();
        formData.append("photo", file);

        await axios.put(
          `${process.env.REACT_APP_API}/admin/clients/${id}/client-settings`,
          formData,
          {
            withCredentials: true,
            onUploadProgress: ({ loaded, total }) => {
              const percent = Math.floor((loaded * 100) / total);
              setPhotoProgress(`${percent}%`);
            },
          }
        );

        queryClient.setQueryData(["client-settings", id], (prev) => ({
          ...prev,
          photo: photo_preview,
        }));
        // setUser((prev) => ({ ...prev, photo: photo_preview }));

        toast.success(t("Saved successfully."));
        resolve();
        setPhotoProgress("0%");
      } catch (err) {
        toast.error("Something went wrong.");
        reject(err);
      }
    });

  const deleteAccount = (id) =>
    new Promise(async (resolve, reject) => {
      try {
        await api.delete(`/admin/clients/${id}`);

        navigate("/clients");

        toast.success("Deleted successfully.");
        resolve();
      } catch (err) {
        toast.error("Something went wrong.");
        reject(err);
      }
    });

  const value = useMemo(() => {
    return {
      data,
      loading,
      photo_progress,
      updateSettings,
      changePassword,
      updatePhoto,
      deleteAccount,
    };
    // eslint-disable-next-line
  }, [data, loading, photo_progress]);

  return (
    <ClientSettingsContext.Provider value={value}>
      {children}
    </ClientSettingsContext.Provider>
  );
};

const useClientSettings = () => useContext(ClientSettingsContext);
export { ClientSettingsContext, useClientSettings };
export default ClientSettingsProvider;
