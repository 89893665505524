import { useEffect } from "react";

const useTaskOnboardingDescription = ({ step }) => {
  useEffect(() => {
    //reset last styles
    const prev_highlight_element = document.getElementById("title_select");
    prev_highlight_element.style.zIndex = 1;
    prev_highlight_element.style.backgroundColor = "transparent";

    const next_highlight_element = document.getElementById("file_uploader");
    next_highlight_element.style.zIndex = 1;
    next_highlight_element.style.backgroundColor = "transparent";

    const handleResize = () => {
      const pointer_element = document.getElementsByClassName(
        "task-onboarding__tooltip"
      )?.[0];
      const highlight_element = document.getElementById("description_select");
      const hightlight_position = highlight_element?.getBoundingClientRect();
      const hightlight_position_x = hightlight_position?.left;
      const hightlight_position_y = hightlight_position?.top;
      highlight_element.style.zIndex = 1101;
      highlight_element.style.backgroundColor = `#E1E6EB`;

      pointer_element.style.flexDirection = "row-reverse";

      const pointer_position_top = hightlight_position_y + 20;
      const pointer_position_left =
        hightlight_position_x - pointer_element?.offsetWidth;

      pointer_element.style.top = `${pointer_position_top}px`;
      pointer_element.style.left = `${pointer_position_left}px`;
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [step]);

  return null;
};

export default useTaskOnboardingDescription;
