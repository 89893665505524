import "./styles.scss";

import { Link } from "react-router-dom";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import task_status_data from "libs/task_status_data.json";

const mainClass = "pill";

const Pill = ({ label, href, onclick, active, readOnly, variant, size }) => {
  const { t } = useTranslation();

  const classes = classnames(mainClass, {
    [`${mainClass}--active`]: !!active,
    [`${mainClass}--read-only`]: !!readOnly,
    [`${mainClass}--${variant}`]: !!variant,
    [`${mainClass}--${size}`]: !!size,
  });

  const formatted_label =
    t(task_status_data?.find((item) => item?.key === variant)?.label) || label;

  return !!readOnly ? (
    <div className={classes}>{size !== "icon" ? formatted_label : null}</div>
  ) : href ? (
    <Link to={href} className={classes}>
      {formatted_label}
    </Link>
  ) : (
    <button onClick={onclick} className={classes}>
      {size !== "icon" ? formatted_label : null}
    </button>
  );
};

export default Pill;
