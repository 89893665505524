import WebmasterDetails from "page_components/admin/webmasters/Details";
import WebmasterProvider from "context/admin/webmaster";

const WebmastersDetails = () => (
  <WebmasterProvider>
    <WebmasterDetails />
  </WebmasterProvider>
);

export default WebmastersDetails;
