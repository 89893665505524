import { useTranslation } from "react-i18next";

import Table from "components/Table";
import DashboardContentLayout from "components/layouts/DashboardContentLayout";

import { useReferralPrograms } from "context/referral-programs";
import priceRender from "libs/helpers/priceRender";

const actions = [
  {
    type: "send_notification",
  },
];

const ReferralProgramsContent = () => {
  const { t } = useTranslation();

  const { data, loading, init_loading } = useReferralPrograms();

  const breadcrumps = [
    {
      label: t("Referral program"),
    },
  ];

  const table_data = {
    keys: [
      {
        key: "first_name",
        value: t("Full name"),
      },
      {
        key: "active_plans",
        value: t("Leads"),
      },
      {
        key: "wallet",
        value: t("Wallet"),
      },
    ],
    items: data?.map(
      ({ id, first_name, last_name, leads, commission_stats }) => [
        {
          key: "first_name",
          type: "link",
          value: {
            label: `${first_name} ${last_name}`,
            href: `/referral-program/${id}`,
          },
        },
        {
          key: "active_plans",
          value: leads,
        },
        {
          key: "wallet",
          value: priceRender(
            commission_stats?.currencies?.USD?.unpaid?.cents?.toFixed(2),
            commission_stats?.currencies?.USD?.unpaid?.currency_iso?.toLowerCase()
          ),
        },
      ]
    ),
  };

  return (
    <DashboardContentLayout
      header_label={t("Referral program")}
      loading={init_loading}
      breadcrumps={breadcrumps}
      actions={actions}
    >
      <Table data={table_data} loading={!!loading} />
    </DashboardContentLayout>
  );
};

export default ReferralProgramsContent;
