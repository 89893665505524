import { object, string } from "yup";

let sendNotificationSchema = object({
  website: string().required("Website cannot be empty."),
  message: string().required("Message cannot be empty."),
  send_to: string().when("type", {
    is: (type) => type === "webmaster",
    then: () => string().required("Send to cannot be empty."),
  }),
});

export default sendNotificationSchema;
