import "./styles.scss";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import moment from "moment";

import DetailsCard from "components/DetailsCard";
import toHoursAndMinutes from "libs/helpers/toHoursAndMinutes";

const mainClass = "time-over__tracked";

const TimeTracked = ({ data }) => {
  let time_over = data?.time_over;
  let plan_time = data?.total_time - time_over;

  let accumulatedTime = 0;

  const formatted_entries = data?.time_entries?.map((item) => {
    const time = parseFloat(item?.time?.$numberDecimal);
    let total_over = time_over - time;

    accumulatedTime = accumulatedTime + time;

    if (accumulatedTime > plan_time) {
      total_over = accumulatedTime - plan_time;
      accumulatedTime = accumulatedTime - total_over;
    } else {
      total_over = 0;
    }

    return {
      ...item,
      time_over: total_over,
    };
  });

  return (
    <div className={mainClass}>
      <div className={`${mainClass}__card`}>
        <DetailsCard title="Time tracked">
          <div className={`${mainClass}__time-summary`}>
            <div className={`${mainClass}__time-summary__col`}>
              <span>Total time</span>
              <span>{toHoursAndMinutes(data?.total_time)?.formatted}</span>
            </div>
            <div className={`${mainClass}__time-summary__col`}>
              <span>Time over plan</span>
              <span>{toHoursAndMinutes(data?.time_over)?.formatted}</span>
            </div>
          </div>

          <div className={`${mainClass}__time-entries`}>
            {formatted_entries?.map?.((item, index) => (
              <div key={index} className={`${mainClass}__time-entries__item`}>
                <div>
                  {`${
                    toHoursAndMinutes(parseFloat(item?.time?.$numberDecimal))
                      ?.formatted
                  } on ${moment(item?.created_at)?.format("ll")}`}
                  <span> by </span>
                  <span>{`${item?.webmaster?.first_name} ${item?.webmaster?.last_name}`}</span>
                </div>
                {!!item?.time_over && (
                  <span
                    className={
                      !!item?.time_over
                        ? `${mainClass}__time-entries__item--over`
                        : ""
                    }
                  >
                    {toHoursAndMinutes(parseFloat(item?.time_over))?.formatted}{" "}
                    exceeding monthly plan
                  </span>
                )}
              </div>
            ))}
          </div>
        </DetailsCard>
      </div>
    </div>
  );
};

export default TimeTracked;
