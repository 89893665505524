import "./styles.scss";

import React from "react";
import classnames from "classnames";

import Loader from "components/Loader";

const mainClass = "page-loader";

const PageLoader = ({ variant }) => {
  return (
    <div
      className={classnames(mainClass, {
        [`${mainClass}--panel`]: variant === "panel",
      })}
    >
      <Loader size="large" color="blue" />
    </div>
  );
};

export default PageLoader;
