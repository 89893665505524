import { useState } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import DetailsCard from "components/DetailsCard";
import LanguageModal from "./components/LanguageModal";
import DetailsCardList from "components/DetailsCardList";
import ProfilePhotoModal from "./components/ProfilePhotoModal";
import PersonalDataModal from "./components/PersonalDataModal";
import DeleteAccountModal from "./components/DeleteAccountModal";
import ChangePasswordModal from "./components/ChangePasswordModal";
import DashboardContentLayout from "components/layouts/DashboardContentLayout";

import countries from "libs/countries.json";
import { useClientSettings } from "context/admin/client-settings";

const mainClass = "column-layout";

const actions = [
  {
    type: "send_notification",
  },
];

const SettingsContent = () => {
  const { t } = useTranslation();

  const { id } = useParams();
  const { data, updateSettings, loading } = useClientSettings();

  const [personal_data_modal, setPersonalDataModal] = useState(false);
  const [change_password_modal, setChangePasswordModal] = useState(false);
  const [profile_photo_modal, setProfilePhotoModal] = useState(false);
  const [language_modal, setLanguageModal] = useState(false);
  const [delete_account_modal, setDeleteAccountModal] = useState(false);

  const breadcrumps = [
    {
      label: t("Clients"),
      href: "/clients",
    },
    {
      label: data?.websites?.find((item) => item?._id === id)?.website,
      href: `/clients/${data?.websites?.find((item) => item?._id === id)?._id}`,
    },
    {
      label: t("Edit client account"),
    },
  ];

  const details_data = [
    {
      title: t("Websites list"),
      items: data?.websites?.map(({ _id, website }) => ({
        type: "link",
        label: t("Domain name"),
        value: {
          link_label: website,
          link_href: `/clients/${_id}`,
        },
      })),
    },
    {
      title: t("Personal data"),
      action: {
        label: t("Edit"),
        onclick: () => setPersonalDataModal(true),
      },
      items: [
        {
          label: t("Full name"),
          value: `${data?.first_name} ${data?.last_name}`,
        },
        {
          label: t("Email"),
          value: data?.email,
        },
        {
          type: "link",
          label: t("Phone"),
          value: {
            link_label: `${
              countries?.find(
                (item) => item?.code === data?.contact_data?.phone?.code
              )?.dialling_code || ""
            } ${data?.contact_data?.phone?.number}`,
            link_href: `tel:${
              countries?.find(
                (item) => item?.code === data?.contact_data?.phone?.code
              )?.dialling_code || ""
            } ${data?.contact_data?.phone?.number}`,
          },
        },
      ],
    },
    {
      title: t("Security"),
      action: {
        label: t("Change password"),
        onclick: () => setChangePasswordModal(true),
      },
      items: [
        {
          label: t("Last password change"),
          value: moment(data?.last_password_changed)?.fromNow(),
          tooltip: t(
            "We recommend using a unique password and changing it at least once a year."
          ),
        },
      ],
    },
  ];

  const payment_data = [
    {
      title: t("Email notifications"),
      items: [
        {
          type: "switch",
          label: t("When my card is about to expire"),
          value: data?.settings?.email_notifications?.card_expired,
          onclick: () =>
            updateSettings({
              settings: {
                email_notifications: {
                  card_expired:
                    !data?.settings?.email_notifications?.card_expired,
                },
              },
            }),
        },
        {
          type: "switch",
          label: t("When my payment is declined"),
          value: data?.settings?.email_notifications?.payment_declined,
          onclick: () =>
            updateSettings({
              settings: {
                email_notifications: {
                  payment_declined:
                    !data?.settings?.email_notifications?.payment_declined,
                },
              },
            }),
        },
        {
          type: "switch",
          label: t("When there is a new invoice issued for me"),
          value: data?.settings?.email_notifications?.new_invoice,
          onclick: () =>
            updateSettings({
              settings: {
                email_notifications: {
                  new_invoice:
                    !data?.settings?.email_notifications?.new_invoice,
                },
              },
            }),
        },
      ],
    },
    {
      title: t("Panel language"),
      items: [
        {
          type: "language",
          value: data?.language,
        },
      ],
      action: {
        label: t("Change"),
        onclick: () => setLanguageModal(true),
      },
    },
    {
      title: t("Profile photo"),
      items: [
        {
          type: "avatar",
          value: data?.photo?.location,
        },
      ],
      action: {
        label: t("Change"),
        onclick: () => setProfilePhotoModal(true),
      },
    },
    {
      title: t("Delete account"),
      action: {
        label: t("Delete"),
        onclick: () => setDeleteAccountModal(true),
      },
    },
  ];

  return (
    <DashboardContentLayout
      header_label={
        !!data?.first_name ? `${data?.first_name} ${data?.last_name}` : null
      }
      loading={loading}
      async_header
      breadcrumps={breadcrumps}
      actions={actions}
    >
      <div className={mainClass}>
        <div className={`${mainClass}__col`}>
          {details_data?.map((data, index) => (
            <DetailsCard key={index} {...data}>
              <DetailsCardList items={data?.items} view={data?.view} />
            </DetailsCard>
          ))}
        </div>
        <div className={`${mainClass}__col`}>
          {payment_data?.map((data, index) => (
            <DetailsCard key={index} {...data}>
              <DetailsCardList items={data?.items} view={data?.view} />
            </DetailsCard>
          ))}
        </div>
        {!!personal_data_modal && (
          <PersonalDataModal onClose={() => setPersonalDataModal(false)} />
        )}
        {!!change_password_modal && (
          <ChangePasswordModal onClose={() => setChangePasswordModal(false)} />
        )}
        {!!profile_photo_modal && (
          <ProfilePhotoModal onClose={() => setProfilePhotoModal(false)} />
        )}
        {!!language_modal && (
          <LanguageModal onClose={() => setLanguageModal(false)} />
        )}
        {!!delete_account_modal && (
          <DeleteAccountModal
            id={data?._id}
            email={data?.email}
            name={`${data?.first_name} ${data?.last_name}`}
            onClose={() => setDeleteAccountModal(false)}
          />
        )}
      </div>
    </DashboardContentLayout>
  );
};

export default SettingsContent;
