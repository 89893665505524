import ClientDetails from "page_components/admin/clients/Details";
import ClientProvider from "context/admin/client";

const Client = () => (
  <ClientProvider>
    <ClientDetails />
  </ClientProvider>
);

export default Client;
