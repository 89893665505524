import "./styles.scss";

import { ReactComponent as StatusIcon } from "assets/icons/status-ok/status.svg";
import { ReactComponent as DotsIcon } from "assets/icons/status-ok/dots.svg";

const mainClass = "status-ok";

const StatusOkIcon = () => {
  return (
    <div className={mainClass}>
      <StatusIcon />
      <DotsIcon />
    </div>
  );
};

export default StatusOkIcon;
