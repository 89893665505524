import moment from "moment";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import DetailsCard from "components/DetailsCard";
import EditTimeModal from "./components/EditTimeModal";
import DetailsCardList from "components/DetailsCardList";
import WebmasterModal from "./components/WebmasterModal";
import DeleteTaskModal from "./components/DeleteTaskModal";
import AllEntriesModal from "./components/AllEntriesModal";
import WebsiteInfoModal from "components/WebsiteInfoModal";
import TimeTrackedModal from "./components/TimeTrackedModal";
import MarkTaskAsComplete from "./components/MarkTaskAsComplete";
import CreateTaskModal from "../Content/components/CreateTaskModal";
import DashboardContentLayout from "components/layouts/DashboardContentLayout";
import Details from "page_components/shared/tasks/Conversation/components/Details";

import { useTask } from "context/admin/task";

import countries from "libs/countries.json";
import toHoursAndMinutes from "libs/helpers/toHoursAndMinutes";

import AvatarImage from "assets/images/avatar-placeholder.png";

const mainClass = "column-layout";

const TasksDetails = () => {
  const { t } = useTranslation();

  const { data, loading, updateTask } = useTask();

  const [edit_task_modal, setEditTaskModal] = useState(false);
  const [edit_time_tracked_modal, setEditTimeTrackedModal] = useState(false);
  const [edit_webmaster_modal, setEditWebmasterModal] = useState(false);
  const [delete_task_modal, setDeleteTaskModal] = useState(false);
  const [mark_task_as_complate_modal, setMarkTaskAsComplate] = useState(false);
  const [website_info_modal, setWebsiteInfoModal] = useState(null);
  const [all_entries_modal, setAllEntriesModal] = useState(false);
  const [edit_time_modal, setEditTimeModal] = useState(false);

  let time_over = data?.time_over;
  let plan_time = data?.total_time - time_over;

  let accumulatedTime = 0;

  const formatted_entries = data?.time_entries?.map((item) => {
    const time = parseFloat(item?.time?.$numberDecimal);
    let total_over = time_over - time;

    accumulatedTime = accumulatedTime + time;

    if (accumulatedTime > plan_time) {
      total_over = accumulatedTime - plan_time;
      accumulatedTime = accumulatedTime - total_over;
    } else {
      total_over = 0;
    }

    return {
      ...item,
      time_over: total_over,
    };
  });

  const actions = [
    ...(!!!loading && data?.status !== 7 && data?.status !== 6
      ? [
          {
            label: t("Mark task as complate"),
            onclick: () => setMarkTaskAsComplate(true),
            variant: "success",
          },
        ]
      : []),
    {
      label: t("View conversation"),
      href: `/tasks/${data?._id}/conversation`,
    },
    {
      type: "send_notification",
    },
    {
      label: t("Edit task"),
      onclick: () => setEditTaskModal(true),
    },
    {
      label: t("Delete task"),
      onclick: () => setDeleteTaskModal(data?.title),
      variant: "error",
    },
  ];

  const breadcrumps = [
    {
      label: t("Tasks"),
      href: "/tasks",
    },
    {
      label: data?.website,
      href: `/clients/${data?.website_id}`,
    },
    {
      label: data?.title,
    },
  ];

  const more_details = [
    {
      title: t("Time tracked"),
      action: {
        label: t("Show all entries"),
        onclick: () => setAllEntriesModal(true),
      },
      items: formatted_entries
        ?.reverse()
        ?.slice(0, 3)
        ?.map((item) => ({
          type: "webmaster_time",
          value: (
            <span
              className={
                !!item?.time_over ? `details-card-webmaster-time--over` : ""
              }
            >
              {`${
                toHoursAndMinutes(parseFloat(item?.time?.$numberDecimal))
                  ?.formatted
              } ${t("on")}
            ${moment(item?.created_at)?.format("ll")}`}
              <span> {t("by")} </span>
              <Link
                to={`/webmasters/${item?.webmaster?._id}`}
              >{`${item?.webmaster?.first_name} ${item?.webmaster?.last_name}`}</Link>
            </span>
          ),
          onclick: () => setEditTimeModal(item?._id),
        })),
    },
    {
      title: t("Webmaster"),
      items: data?.webmaster?.map((item) => {
        const minutes = data?.time_entries?.reduce(
          (a, b) =>
            b?.webmaster?._id === item?._id
              ? a + parseFloat(b?.time?.$numberDecimal)
              : a,
          0
        );

        return {
          type: "webmaster",
          value: {
            name: `${item?.first_name} ${item?.last_name}`,
            photo: item?.photo || AvatarImage,
            href: `/webmasters/${item?._id}`,
            extra_text: `${t("Total time in task")}: ${
              toHoursAndMinutes(minutes)?.formatted
            }`,
          },
        };
      }),
    },
    {
      title: t("Contact data"),
      items: [
        {
          label: t("Full name"),
          value: `${data?.contact_data?.first_name} ${data?.contact_data?.last_name}`,
        },
        {
          label: t("Email"),
          value: data?.contact_data?.email,
        },
        {
          label: t("Phone"),
          value: `${
            countries?.find(
              (item) => item?.code === data?.contact_data?.phone?.code
            )?.dialling_code || ""
          } ${data?.contact_data?.phone?.number}`,
        },
      ],
    },
  ];

  return (
    <DashboardContentLayout
      header_label={data?.title}
      async_header
      loading={loading}
      breadcrumps={breadcrumps}
      actions={actions}
    >
      <div className={mainClass}>
        <div className={`${mainClass}__col`}>
          <Details
            admin_mode={true}
            data={{ ...data }}
            updateTask={updateTask}
            setWebsiteInfoModal={setWebsiteInfoModal}
          />
        </div>
        <div className={`${mainClass}__col`}>
          {more_details?.map((data, index) => (
            <DetailsCard key={index} {...data}>
              <DetailsCardList items={data?.items} view={data?.view} />
            </DetailsCard>
          ))}
        </div>
        <div style={{ position: "absolute" }}>
          {!!edit_time_tracked_modal && (
            <TimeTrackedModal onClose={() => setEditTimeTrackedModal(false)} />
          )}
          {!!edit_webmaster_modal && (
            <WebmasterModal onClose={() => setEditWebmasterModal(false)} />
          )}
          {!!delete_task_modal && (
            <DeleteTaskModal
              title={delete_task_modal}
              onClose={() => setDeleteTaskModal(null)}
            />
          )}
          {!!mark_task_as_complate_modal && (
            <MarkTaskAsComplete onClose={() => setMarkTaskAsComplate(false)} />
          )}

          {!!website_info_modal && (
            <WebsiteInfoModal
              id={website_info_modal}
              onClose={() => setWebsiteInfoModal(null)}
            />
          )}
          {!!edit_task_modal && (
            <CreateTaskModal
              data={data}
              update={updateTask}
              onClose={() => setEditTaskModal(false)}
            />
          )}
          {!!all_entries_modal && (
            <AllEntriesModal
              setEditTimeModal={setEditTimeModal}
              onClose={() => setAllEntriesModal(false)}
            />
          )}
          {!!edit_time_modal && (
            <EditTimeModal
              id={edit_time_modal}
              onClose={() => setEditTimeModal(false)}
            />
          )}
        </div>
      </div>
    </DashboardContentLayout>
  );
};

export default TasksDetails;
