import "./styles.scss";

import moment from "moment";
import Button from "components/Button";

import { ReactComponent as ArrowIcon } from "assets/icons/calendar-arrow.svg";

const mainClass = "calendar-months";

const CalendarMonths = ({ date, setDate, selected_date, onSelect }) => {
  const all_months = moment.monthsShort();
  // const current_month = parseInt(moment()?.format("M"));

  let formatted_months = all_months?.map((item, index) => {
    return {
      name: item,
      disabled: false,
    };
  });

  const selected = !!selected_date
    ? moment(selected_date)?.year() === moment(date)?.year()
      ? moment(selected_date).month()
      : null
    : null;

  const onChangeYear = (type) => {
    if (type === "prev") {
      setDate((prev) => moment(prev).add(-1, "years"));
    } else {
      setDate((prev) => moment(prev).add(1, "years"));
    }
  };

  return (
    <div className={mainClass}>
      <div className={`${mainClass}__header`}>
        <button type="button" onClick={() => onChangeYear("prev")}>
          <ArrowIcon />
        </button>
        <span>{date?.year()}</span>
        <button type="button" onClick={() => onChangeYear("next")}>
          <ArrowIcon />
        </button>
      </div>
      <div className={`${mainClass}__list`}>
        {formatted_months?.map((item, index) => (
          <Button
            size="small"
            variant="transparent"
            key={index}
            disabled={item?.disabled}
            onClick={() => onSelect(index)}
            active={index === selected}
          >
            {item?.name}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default CalendarMonths;
