import "./styles.scss";

import { ReactComponent as StatusIcon } from "assets/icons/status-error/status.svg";
import { ReactComponent as DotsIcon } from "assets/icons/status-error/dots.svg";

const mainClass = "status-ok";

const StatusErrorIcon = () => {
  return (
    <div className={mainClass}>
      <StatusIcon />
      <DotsIcon />
    </div>
  );
};

export default StatusErrorIcon;
