import "./styles/globals.scss";

import ReactGA from "react-ga4";
import { hotjar } from "react-hotjar";
import ReactDOM from "react-dom/client";
import TagManager from "react-gtm-module";
import React, { StrictMode } from "react";
import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import App from "./App";
import AppProvider from "context/app";

import "./i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();

TagManager.initialize({
  gtmId: "GTM-M46LPGVM",
});
ReactGA.initialize("G-FMBTZ2ZVZD");
ReactGA.initialize("AW-16644263375");

hotjar.initialize({ id: "5073667", sv: 6 });

root.render(
  <StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <CookiesProvider defaultSetOptions={{ path: "/" }}>
          <AppProvider>
            <App />
          </AppProvider>
        </CookiesProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </StrictMode>
);
