import "./styles.scss";

import { Formik, Form } from "formik";

import Modal from "components/Modal";
import FormikInput from "components/formik/FormikInput";

import { useTask } from "context/admin/task";

import toHoursAndMinutes from "libs/helpers/toHoursAndMinutes";

const TimeTrackedModal = ({ onClose }) => {
  const { data, updateTask } = useTask();

  const onSubmit = async (values, setSubmitting) => {
    try {
      await updateTask(values);
      onClose();
    } catch (err) {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        time_tracked: {
          hours: "",
          minutes: "",
        },
      }}
      // validationSchema={contactDataSchema}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        onSubmit(values, setSubmitting, setErrors);
      }}
    >
      {({ isSubmitting, submitForm }) => (
        <Form autoComplete="off">
          <Modal
            title="Edit time tracked"
            onClose={onClose}
            onSubmit={submitForm}
            loading={isSubmitting}
          >
            <div className="form">
              <div className="form__group">
                <div className="form__input-group">
                  <span>Time tracked</span>
                  <p>
                    {
                      toHoursAndMinutes(
                        data?.time_tracked?.time?.$numberDecimal
                      )?.formatted
                    }
                  </p>
                </div>
              </div>
              <div className="form__group">
                <div className="form__group">
                  <div className="form__input-group">
                    <span>New time tracked</span>
                    <div className="form__input-group__inputs">
                      <FormikInput
                        name="time_tracked.hours"
                        placeholder="Hours"
                        disabled={isSubmitting}
                      />
                      <span>-</span>
                      <FormikInput
                        name="time_tracked.minutes"
                        placeholder="Minutes"
                        disabled={isSubmitting}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default TimeTrackedModal;
