import DashboardContent from "page_components/webmaster/dashboard/Content";
import DashboardProvider from "context/webmaster/dashboard";

const Dashboard = () => {
  return (
    <DashboardProvider>
      <DashboardContent />
    </DashboardProvider>
  );
};

export default Dashboard;
