import { Routes, Route, Navigate } from "react-router-dom";

import authRoutes from "./auth";
import adminRoutes from "./admin";
import clientRoutes from "./client";
import webmasterRoutes from "./webmaster";

import ChangeEmail from "pages/auth/change-email";

import { useApp } from "context/app";

const routes_type_render = (user_type, is_partner) => {
  switch (user_type) {
    case "client":
      return clientRoutes(is_partner);
    case "admin":
      return adminRoutes();
    case "webmaster":
      return webmasterRoutes();
    default:
      return authRoutes();
  }
};

const AppRoutes = () => {
  const { user } = useApp();

  return (
    <Routes>
      <Route path="/change-email" element={<ChangeEmail />} />
      {routes_type_render(user?.type, user?.is_partner)}
      <Route
        path="*"
        element={<Navigate to={!!!user?.type ? "/login" : `/`} replace />}
      />
    </Routes>
  );
};

export default AppRoutes;
