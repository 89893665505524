import "./styles.scss";

import { useTranslation } from "react-i18next";

import PlaceholderPage from "components/PlaceholderPage";

const mainClass = "new-project-content__confirmation";

const ConfirmationProject = () => {
  const { t } = useTranslation();

  return (
    <div className={mainClass}>
      <div>
        <PlaceholderPage
          title={t("Thank you for your message")}
          text={t(
            "We got your message. Our team will contact you as soon as possible."
          )}
          button={[
            {
              label: t("Manage your website"),
              href: `/websites`,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default ConfirmationProject;
