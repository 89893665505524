import { useState } from "react";
import { useTranslation } from "react-i18next";

import PlaceholderPage from "components/PlaceholderPage";
import ContactContent from "page_components/client/contact/Content";
import DashboardContentLayout from "components/layouts/DashboardContentLayout";
import { useApp } from "context/app";

const Contact = () => {
  const { t } = useTranslation();

  const { websites } = useApp();
  const [form_sent, setFormSent] = useState(false);

  const breadcrumps = [
    {
      label: t("Contact us"),
    },
  ];

  const actions = [
    {
      label: t("Add new website"),
      href: "/websites/new",
      event: () =>
        window.gtag("event", "new_website", {
          click_text: t("Add new website"),
          click_url: `/websites/new`,
        }),
    },
    ...(websites?.length > 0
      ? [
          {
            type: "create_client_task",
            onboarding_button: true,
          },
        ]
      : []),
  ];

  return (
    <DashboardContentLayout
      header_label={t("Contact us")}
      actions={!!form_sent ? null : actions}
      breadcrumps={breadcrumps}
    >
      {!!form_sent ? (
        <PlaceholderPage
          title={t("Thank you for your message.")}
          text={t(
            "We got your message. Our team will contact you as soon as possible."
          )}
          button={{
            label: t("Menage your website"),
            href: "/",
          }}
        />
      ) : (
        <ContactContent setFormSent={setFormSent} />
      )}
    </DashboardContentLayout>
  );
};

export default Contact;
