import "./styles.scss";

import { useState } from "react";
import { useTranslation } from "react-i18next";

import Modal from "components/Modal";
import { useWebmasterSettings } from "context/admin/webmaster-settings";

const DeleteAccountModal = ({ id, name, onClose }) => {
  const { t } = useTranslation();

  const { deleteAccount } = useWebmasterSettings();
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    try {
      setLoading(true);

      await deleteAccount();
      onClose();
    } catch (err) {
      console.error(err);
    }

    setLoading(false);
  };

  return (
    <Modal
      title={t("Delete account")}
      onClose={onClose}
      onSubmit={onSubmit}
      submit_button_label={t("Yes, delete account")}
      loading={!!loading}
      variant="little-narrow"
    >
      <p>
        {t("Are you sure you would like to delete the Webmaster account of")}{" "}
        <strong>{name}</strong>?
        <br />
        <br />
        {t(
          `This action cannot be reversed. Before deleting the account you should reassign all tasks currently assigned to this account.`
        )}
      </p>
    </Modal>
  );
};

export default DeleteAccountModal;
