import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { createContext, useContext, useMemo, useState } from "react";

import api from "libs/api";
import tax_data from "libs/tax_data.json";

const WebsiteContext = createContext();

const WebsiteProvider = ({ children }) => {
  const { t } = useTranslation();

  const { id } = useParams();
  const queryClient = useQueryClient();

  const [customer_portal_loading, setCustomerPortalLoading] = useState(false);
  const [customer_cancel_plan_loading, setCustomerCancelPlanLoading] =
    useState(false);

  const { isLoading: loading, data: website } = useQuery(["websites", id], () =>
    api.get(`/client/websites/${id}`)
  );

  const customerPortal = async (type) => {
    try {
      if (type === "cancel-plan") {
        setCustomerCancelPlanLoading(true);
      } else {
        setCustomerPortalLoading(true);
      }
      const portal = await api.post("/client/stripe/customer-portal", {
        website_id: id,
        return_url: window.location.href,
      });

      if (portal?.url) {
        window.location.replace(portal?.url);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const updateData = (values) =>
    new Promise(async (resolve, reject) => {
      let data_to_send = values;

      if (data_to_send?.billing_data?.tax) {
        const tax = tax_data?.find(
          (item) => data_to_send?.billing_data?.tax?.code === item?.code
        );

        data_to_send.billing_data.tax = {
          ...data_to_send.billing_data.tax,
          prefix: tax?.prefix,
          clean_number: data_to_send.billing_data.tax?.number,
          number: `${tax?.prefix}${data_to_send.billing_data.tax?.number}`,
          id: tax?.tax_id,
        };
      }

      try {
        await api.put(`/client/websites/${id}`, data_to_send);

        queryClient.setQueryData(["websites", id], (prev) => ({
          ...prev,
          ...values,
        }));

        toast.success(t("Saved successfully."));
        resolve();
      } catch (err) {
        toast.error("Something went wrong.");
        reject(err);
      }
    });

  const value = useMemo(() => {
    return {
      data: website,
      loading,
      customerPortal,
      updateData,
      customer_portal_loading,
      customer_cancel_plan_loading,
    };
    // eslint-disable-next-line
  }, [website, customer_portal_loading, customer_cancel_plan_loading, loading]);

  return (
    <WebsiteContext.Provider value={value}>{children}</WebsiteContext.Provider>
  );
};

const useWebsite = () => useContext(WebsiteContext);
export { WebsiteContext, useWebsite };
export default WebsiteProvider;
