import { createContext, useContext, useMemo, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

import { useApp } from "./app";
import api from "libs/api";
import { toast } from "react-toastify";

const SettingsContext = createContext();

const SettingsProvider = ({ children }) => {
  const { t } = useTranslation();

  const { user, setUser } = useApp();

  const [photo_progress, setPhotoProgress] = useState("0%");
  const [personal_data_modal, setPersonalDataModal] = useState(false);
  const [change_password_modal, setChangePasswordModal] = useState(false);
  const [profile_photo_modal, setProfilePhotoModal] = useState(false);
  const [language_modal, setLanguageModal] = useState(false);

  const updateSettings = (values) =>
    new Promise(async (resolve, reject) => {
      try {
        let data_to_send = { ...values };

        if (data_to_send?.settings?.email_notifications) {
          data_to_send = {
            settings: {
              email_notifications: {
                ...user?.settings?.email_notifications,
                ...values?.settings?.email_notifications,
              },
            },
          };
        }

        await api.put(`/user/settings`, data_to_send);
        setUser((prev) => ({ ...prev, ...data_to_send, email: prev?.email }));
        toast.success(t("Saved successfully."));
        resolve();
      } catch (err) {
        toast.error("Something went wrong.");
        reject(err);
      }
    });

  const changePassword = (values) =>
    new Promise(async (resolve, reject) => {
      try {
        await api.put(`/user/settings/change-password`, values);
        setUser((prev) => ({ ...prev, last_password_changed: new Date() }));

        toast.success(t("Saved successfully."));
        resolve();
      } catch (err) {
        toast.error("Something went wrong.");
        reject(err);
      }
    });

  const updatePhoto = (file, photo_preview) =>
    new Promise(async (resolve, reject) => {
      try {
        const formData = new FormData();
        formData.append("photo", file);

        await axios.put(
          `${process.env.REACT_APP_API}/user/settings`,
          formData,
          {
            withCredentials: true,
            onUploadProgress: ({ loaded, total }) => {
              const percent = Math.floor((loaded * 100) / total);
              setPhotoProgress(`${percent}%`);
            },
          }
        );
        setUser((prev) => ({ ...prev, photo: photo_preview }));

        toast.success(t("Saved successfully."));
        resolve();
        setPhotoProgress("0%");
      } catch (err) {
        toast.error("Something went wrong.");
        reject(err);
      }
    });

  const value = useMemo(() => {
    return {
      data: user,
      photo_progress,
      updateSettings,
      changePassword,
      updatePhoto,
      personal_data_modal,
      change_password_modal,
      profile_photo_modal,
      language_modal,
      setPersonalDataModal,
      setChangePasswordModal,
      setProfilePhotoModal,
      setLanguageModal,
    };
    // eslint-disable-next-line
  }, [
    user,
    photo_progress,
    personal_data_modal,
    change_password_modal,
    profile_photo_modal,
    language_modal,
  ]);

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
};

const useSettings = () => useContext(SettingsContext);
export { SettingsContext, useSettings };
export default SettingsProvider;
