import "./styles.scss";

import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";

import Button from "components/Button";
import FormikInput from "components/formik/FormikInput";
import FormikSelect from "components/formik/FormikSelect";
import FormikTaxInput from "components/formik/FormikTaxInput";
import FormikPhoneInput from "components/formik/FormikPhoneInput";

import { useNewWebsite } from "context/client/new_website";

import countries from "libs/countries.json";
import yourDataSchema from "libs/validations/new_website_form/yourDataSchema";
import { useApp } from "context/app";
import useResponsive from "libs/helpers/useResponsive";
import YourDataMobile from "./components/MobileContent";

const mainClass = "new-website-content__your-data";

const YourData = () => {
  const { t, i18n } = useTranslation();
  const { websites } = useApp();
  const { isMobile } = useResponsive();

  const {
    website,
    form_data,
    change_plan_mode,
    nextStep,
    prevStep,
    form_data_error,
  } = useNewWebsite();

  const onSubmit = (values) => {
    nextStep(values);
  };

  const select_options = countries?.map(({ name, name_pl, code }) => ({
    label: i18n?.language === "pl" ? name_pl || name : name,
    value: code,
  }));

  const first_website = websites?.[0];

  return (
    <Formik
      initialValues={{
        first_name: !!change_plan_mode
          ? website?.contact_data?.first_name
          : form_data?.first_name ||
            first_website?.contact_data?.first_name ||
            "",
        last_name: !!change_plan_mode
          ? website?.contact_data?.last_name
          : form_data?.last_name ||
            first_website?.contact_data?.last_name ||
            "",
        email: !!change_plan_mode
          ? website?.contact_data?.email
          : form_data?.email || first_website?.contact_data?.email || "",
        phone: {
          code: !!change_plan_mode
            ? website?.contact_data?.phone?.code
            : form_data?.phone?.code ||
              first_website?.contact_data?.phone?.code ||
              "",
          number: !!change_plan_mode
            ? website?.contact_data?.phone?.number
            : form_data?.phone?.number ||
              first_website?.contact_data?.phone?.number ||
              "",
        },
        billing: {
          name: !!change_plan_mode
            ? website?.billing_data?.name
            : form_data?.billing?.name ||
              first_website?.billing_data?.name ||
              "",
          tax: {
            code: !!change_plan_mode
              ? website?.billing_data?.tax?.code
              : form_data?.billing?.tax?.code ||
                first_website?.billing_data?.tax?.code ||
                "",
            number: !!change_plan_mode
              ? website?.billing_data?.tax?.clean_number
              : form_data?.billing?.tax?.number ||
                first_website?.billing_data?.tax?.clean_number ||
                "",
          },
          street: !!change_plan_mode
            ? website?.billing_data?.street
            : form_data?.billing?.street ||
              first_website?.billing_data?.street ||
              "",
          city: !!change_plan_mode
            ? website?.billing_data?.city
            : form_data?.billing?.city ||
              first_website?.billing_data?.city ||
              "",
          post_code: !!change_plan_mode
            ? website?.billing_data?.post_code
            : form_data?.billing?.post_code ||
              first_website?.billing_data?.post_code ||
              "",
          country: !!change_plan_mode
            ? website?.billing_data?.country
            : form_data?.billing?.country ||
              first_website?.billing_data?.country ||
              "",
        },
      }}
      validationSchema={yourDataSchema}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({ values, isSubmitting }) => (
        <Form className={mainClass} autoComplete="off">
          {!!isMobile ? (
            <YourDataMobile />
          ) : (
            <>
              <div className={`${mainClass}__wrapper`}>
                <div className={`${mainClass}__form form`}>
                  <div className={`${mainClass}__form__header`}>
                    <h3>{t("Contact data")}</h3>
                  </div>
                  <FormikInput
                    name="first_name"
                    label={t("Name")}
                    placeholder={t("John")}
                    disabled={isSubmitting || !!change_plan_mode}
                    required
                  />
                  <FormikInput
                    name="last_name"
                    label={t("Surname")}
                    placeholder={t("Doe")}
                    disabled={isSubmitting || !!change_plan_mode}
                    required
                  />
                  <FormikInput
                    name="email"
                    type="email"
                    label={t("Email")}
                    placeholder={t("john.doe@adream.com")}
                    disabled={isSubmitting || !!change_plan_mode}
                    required
                  />
                  <FormikPhoneInput
                    label={t("Phone")}
                    name="phone"
                    placeholder="123 456 789"
                    required
                    disabled={isSubmitting || !!change_plan_mode}
                  />
                  <div className="form__required-info">
                    <small>
                      <span>*</span> {t("required field")}
                    </small>
                  </div>
                </div>
                <div className={`${mainClass}__form form`}>
                  <div className={`${mainClass}__form__header`}>
                    <h3>{t("Billing data")}</h3>
                  </div>
                  <FormikInput
                    name="billing.name"
                    label={t("Company name")}
                    disabled={isSubmitting || !!change_plan_mode}
                    placeholder={t("Acme Corporation")}
                    required
                  />
                  <FormikTaxInput
                    label={t("Tax identification number")}
                    name="billing.tax"
                    disabled={isSubmitting || !!change_plan_mode}
                    invalid_tax={
                      form_data_error === "tax_id_invalid" ? true : false
                    }
                    required
                  />
                  <FormikInput
                    name="billing.street"
                    label={t("Street")}
                    disabled={isSubmitting || !!change_plan_mode}
                    placeholder={t("Main Street 13A")}
                    required
                  />
                  <FormikInput
                    name="billing.city"
                    label={t("City")}
                    placeholder={t("London")}
                    disabled={isSubmitting || !!change_plan_mode}
                    required
                  />
                </div>
                <div className={`${mainClass}__form form`}>
                  <div className={`${mainClass}__form__header`} />
                  <FormikInput
                    name="billing.post_code"
                    label={t("Post code")}
                    disabled={isSubmitting || !!change_plan_mode}
                    placeholder="30-123"
                    required
                  />
                  <FormikSelect
                    name="billing.country"
                    label={t("Country")}
                    options={select_options}
                    selected={values?.billing?.country}
                    disabled={isSubmitting || !!change_plan_mode}
                    searcher={true}
                    placeholder={t("United Kingdom")}
                    required
                  />
                </div>
              </div>
              <div className={`${mainClass}__able-top-change-info`}>
                <small>
                  {t(
                    `You will always be able to change the above data in the administration panel after successfully purchasing a plan for your website`
                  )}
                </small>
              </div>
              <div className={`${mainClass}__bottom`}>
                <Button variant="transparent" onClick={() => prevStep(values)}>
                  {t("Previous step")}
                </Button>
                <Button type="submit">{t("Next step")}</Button>
              </div>
            </>
          )}
          {isMobile && (
            <div className={`${mainClass}__bottom--mobile`}>
              <Button
                type="button"
                variant="transparent"
                onClick={() => prevStep(values)}
              >
                {t("Back")}
              </Button>
              <Button type={"submit"}>{t("Next step")}</Button>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default YourData;
