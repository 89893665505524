import "./styles.scss";

import { useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "components/Button";
import CreateTaskModal from "page_components/admin/tasks/Content/components/CreateTaskModal";

import TasksProvider from "context/admin/tasks";

const CreateTask = () => {
  const { t } = useTranslation();

  const [modal_open, setModalOpen] = useState(false);

  return (
    <TasksProvider>
      <div>
        <Button onClick={() => setModalOpen((prev) => !prev)}>
          {t("Create new task")}
        </Button>
      </div>
      {!!modal_open && <CreateTaskModal onClose={() => setModalOpen(false)} />}
    </TasksProvider>
  );
};

export default CreateTask;
