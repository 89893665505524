import "./styles.scss";

import { useState } from "react";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import Button from "components/Button";
import CreateTask from "components/CreateTask";
import TaskOnboarding from "components/TaskOnboarding";
import SendNotification from "components/SendNotification";
import CreateClientTask from "components/CreateClientTask";

const mainClass = "dashboard-layout__actions";

const button_render = (item, onboarding) => {
  switch (item?.type) {
    case "send_notification":
      return <SendNotification />;
    case "create_task":
      return <CreateTask />;
    case "create_client_task":
      return <CreateClientTask onboarding={onboarding} />;

    default:
      return (
        <Button
          variant={item?.variant}
          href={item?.href || null}
          onClick={
            item?.onclick
              ? () => {
                  item?.onclick();
                  !!item?.event && item?.event();
                }
              : null
          }
        >
          {item?.label}
        </Button>
      );
  }
};

const Actions = ({ data }) => {
  const { t } = useTranslation();
  const { pathname, search } = useLocation();
  const query = queryString.parse(search);

  const [onboarding, setOnboarding] = useState(query?.onboarding === "true");

  const onOnboardingClose = () => {
    const init_element =
      document?.getElementsByClassName("onboarding_button")?.[0];
    init_element.style.zIndex = 1;

    window.history.replaceState({}, null, pathname);
    setOnboarding(false);
  };

  return (
    <>
      <div className={mainClass}>
        <span>{`${t("Actions")}:`}</span>
        <div className={`${mainClass}__items`}>
          {data?.map((item, index) => (
            <div
              key={index}
              className={item?.onboarding_button ? "onboarding_button" : ""}
            >
              {button_render(item, onboarding)}
              {item?.onboarding_button && !!!onboarding && (
                <button
                  onClick={(e) => setOnboarding(e)}
                  className={`${mainClass}__helper-button`}
                >
                  <div>?</div>
                  <span>{t("How it works")}?</span>
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
      {!!onboarding && <TaskOnboarding onClose={onOnboardingClose} />}
    </>
  );
};

export default Actions;
