import "./styles.scss";

const mainClass = "task-conversation__details__info-message";

const InfoMessage = ({ text }) => {
  return (
    <div className={mainClass}>
      <p>{text}</p>
    </div>
  );
};

export default InfoMessage;
