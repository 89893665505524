import "./styles.scss";

import { useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import Table from "components/Table";
import Input from "components/form/Input";
import Calendar from "components/Calendar";
import PillGrid from "components/PillGrid";
import CreateTaskModal from "./components/CreateTaskModal";
import WebsiteInfoModal from "components/WebsiteInfoModal";
import DashboardContentLayout from "components/layouts/DashboardContentLayout";

import { useTasks } from "context/webmaster/tasks";
import { useTimeTracking } from "context/webmaster/time-tracking";

import task_status_data from "libs/task_status_data.json";
import task_priority_data from "libs/task_priority_data.json";
import toHoursAndMinutes from "libs/helpers/toHoursAndMinutes";
import Select from "components/form/Select";

const mainClass = "invoices-content";

const TasksContent = () => {
  const { t } = useTranslation();

  const {
    data,
    loading,
    init_loading,
    filters,
    websites,
    filterHandler,
    refetchTasks,
  } = useTasks();
  const { current, timeTracking } = useTimeTracking();

  const [task_create_modal, setTaskCreateModal] = useState(false);
  const [website_info_modal, setWebsiteInfoModal] = useState(null);

  const pills_data = [
    {
      label: t("Show all"),
      value: "all",
      onclick: () => filterHandler("type", ""),
      active: !!!filters?.type,
    },
    ...task_priority_data?.map(({ key, label, value }) => ({
      label: t(label),
      value,
      onclick: () =>
        filterHandler(
          "type",
          task_priority_data?.find((item) => item?.value === value)?.key
        ),
      active: filters?.type === key,
    })),
  ];

  const pills_task_status = [
    {
      label: t("Show all"),
      value: "all",
      onclick: () => filterHandler("status", ""),
      active: !!!filters?.status,
    },
    ...task_status_data
      ?.filter(
        (item) => item?.value !== 2 && item?.value !== 6 && item?.value !== 7
      )
      ?.map(({ key, label, value }) => ({
        label: t(label),
        value,
        onclick: () =>
          filterHandler(
            "status",
            task_status_data?.find((item) => item?.value === value)?.key
          ),
        active: filters?.status === key,
      })),
  ];

  const pills_task_extra_status = [
    {
      label: t("Cancelled"),
      value: 6,
      onclick: () => filterHandler("status", "cancelled"),
      active: filters?.status === "cancelled",
    },
    {
      label: t("Closed"),
      value: 7,
      onclick: () => filterHandler("status", "closed"),
      active: filters?.status === "closed",
    },
  ];

  const tasks_out_of_time = [
    {
      label: t("Show tasks"),
      value: "show_tasks",
      onclick: () =>
        filterHandler("time_over", filters?.time_over === "true" ? "" : "true"),
      active: filters?.time_over === "true",
    },
  ];

  const table_data = {
    keys: [
      {
        key: "priority",
        value: t("Priority"),
      },
      {
        sort_key: "status",
        key: "status-pill",
        value: t("Status"),
      },
      {
        sort_key: "title",
        key: "title-full",
        value: t("Title"),
      },
      {
        sort_key: "website",
        key: "website-full",
        value: t("Website"),
      },
      {
        key: "estimated_time",
        value: t("Estimated time"),
      },
      {
        sort_key: "total_time",
        key: "time_tracked",
        value: t("Time tracked"),
      },
      {
        key: !!filters?.time_over ? "time_over" : "due_date",
        value: !!filters?.time_over ? t("Time over plan") : t("Due date"),
      },
    ],
    items: data?.map(
      ({
        _id,
        priority,
        title,
        website,
        estimated_time,
        total_time,
        time_over,
        due_date,
        status,
      }) => [
        {
          key: "priority",
          type: "priority",
          value: priority,
        },
        {
          key: "status-pill",
          type: "status-pill",
          value: task_status_data?.find((item) => item?.value === status)?.key,
        },
        {
          key: "title-full",
          type: "link",
          value: {
            label: title,
            href: !!filters?.time_over
              ? `/tasks/${_id}/time-over`
              : `/tasks/${_id}`,
          },
        },
        {
          key: "website-full",
          type: "clickable_text",
          value: {
            label: website?.name,
            onclick: () => setWebsiteInfoModal(website?._id),
          },
        },
        {
          key: "estimated_time",
          value: !!estimated_time
            ? toHoursAndMinutes(estimated_time?.$numberDecimal)?.formatted
            : "-",
        },
        {
          key: "time_tracked",
          type: "webmaster_tracker",
          value: {
            label: total_time,
            onclick: () =>
              timeTracking(_id, !!!current?.start ? refetchTasks : null),
            start_time_tracked:
              current?.tasks?.[0]?._id === _id ? current?.start : null,
          },
        },
        {
          key: !!filters?.time_over ? "time_over" : "due_date",
          value: !!filters?.time_over
            ? toHoursAndMinutes(time_over)?.formatted
            : !!due_date
            ? moment(due_date)?.format("DD MMM, YYYY")
            : "-",
        },
      ]
    ),
  };

  const websites_options = [
    {
      label: t("All websites"),
      value: null,
    },
    ...websites?.map(({ _id, website }) => ({
      label: website,
      value: _id,
    })),
  ];

  return (
    <DashboardContentLayout header_label={t("Tasks")} loading={init_loading}>
      <div className={mainClass}>
        <Table
          data={table_data}
          loading={!!loading}
          header={
            <div style={{ display: "flex", gap: 64 }}>
              <PillGrid
                header={t("Task priority")}
                items={pills_data}
                size="small"
              />
              <PillGrid
                header={t("Task status")}
                items={pills_task_status}
                size="small"
              />
            </div>
          }
          filter_default_open={Object?.keys(filters)?.length > 0}
          sort={filters?.sort}
          setSort={(value) => filterHandler("sort", value)}
          filters={
            <>
              <div className={`${mainClass}__filter-wrap`}>
                <div className={`${mainClass}__filter-row`}>
                  <Select
                    label={t("Website")}
                    options={websites_options}
                    value={
                      !!filters?.website
                        ? filters?.website
                        : websites?.length === 1
                        ? websites?.[0]?._id
                        : null
                    }
                    disabled={websites?.length === 1}
                    onChange={(e) => filterHandler("website", e.target.value)}
                  />
                  <Input
                    label={t("Search")}
                    placeholder={t("Task title")}
                    value={filters?.search}
                    onChange={(e) => filterHandler("search", e.target.value)}
                  />
                  <PillGrid
                    header={t("Show")}
                    items={pills_task_extra_status}
                    size="small"
                  />
                </div>
                <div className={`${mainClass}__filter-row`}>
                  <PillGrid
                    header={t("Tasks with time exceeding monthly plan")}
                    items={tasks_out_of_time}
                    size="small"
                  />
                  {!!filters?.time_over && (
                    <>
                      <Calendar
                        label={t("Due date start")}
                        default_value={filters?.start_due_date}
                        onChange={(value) =>
                          filterHandler("start_due_date", value)
                        }
                      />
                      <Calendar
                        label={t("Due date end")}
                        default_value={filters?.end_due_date}
                        onChange={(value) =>
                          filterHandler("end_due_date", value)
                        }
                      />
                    </>
                  )}
                </div>
              </div>
            </>
          }
        />
      </div>
      {!!task_create_modal && (
        <CreateTaskModal onClose={() => setTaskCreateModal(false)} />
      )}
      {!!website_info_modal && (
        <WebsiteInfoModal
          id={website_info_modal}
          onClose={() => setWebsiteInfoModal(null)}
        />
      )}
    </DashboardContentLayout>
  );
};

export default TasksContent;
