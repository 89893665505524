import { Route } from "react-router-dom";

import AuthLayout from "components/layouts/AuthLayout";

import Login from "pages/auth/login";
import Register from "pages/auth/register";
import ForgetPassword from "pages/auth/forget-password";
import ResetPassword from "pages/auth/forget-password/reset";
import VerifyAccount from "pages/auth/verify";

const authRoutes = () => (
  <Route element={<AuthLayout />}>
    <Route index path="/login" element={<Login />} />
    <Route path="/register" element={<Register />} />
    <Route path="/forget-password">
      <Route index element={<ForgetPassword />} />
      <Route path="reset" element={<ResetPassword />} />
    </Route>
    <Route path="/verify" element={<VerifyAccount />} />
  </Route>
);

export default authRoutes;
