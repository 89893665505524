import "./styles.scss";

import { useState } from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import Modal from "components/Modal";

import { useWebmasterSettings } from "context/admin/webmaster-settings";

import { ReactComponent as EnglishIcon } from "assets/icons/flags/en.svg";
import { ReactComponent as PolishIcon } from "assets/icons/flags/pl.svg";

const mainClass = "language-modal";

const LanguageModal = ({ onClose }) => {
  const { t } = useTranslation();

  const { data, updateSettings } = useWebmasterSettings();

  const [selected, setSelected] = useState(data?.language);
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    try {
      setLoading(true);
      await updateSettings({ language: selected });
      onClose();
    } catch (err) {
      console.error(err);
    }
  };

  const language_data = [
    {
      icon: <EnglishIcon />,
      label: t("English"),
      value: "en",
    },
    {
      icon: <PolishIcon />,
      label: t("Polish"),
      value: "pl",
    },
  ];

  return (
    <Modal
      title={t("Change language")}
      onClose={onClose}
      onSubmit={onSubmit}
      loading={loading}
      variant="narrow"
    >
      <div className={mainClass}>
        {language_data?.map(({ icon, label, value }, index) => (
          <button
            key={index}
            onClick={() => setSelected(value)}
            className={classnames(`${mainClass}__item`, {
              [`${mainClass}__item--active`]: value === selected,
            })}
          >
            <div className={`${mainClass}__item__icon`}>{icon}</div>
            <span>{label}</span>
          </button>
        ))}
      </div>
    </Modal>
  );
};

export default LanguageModal;
