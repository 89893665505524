import { Route } from "react-router-dom";

import DashboardLayout from "components/layouts/DashboardLayout";

import Dashboard from "pages/webmaster";
import Notifications from "pages/shared/notifications";
import Settings from "pages/webmaster/settings";
import Tasks from "pages/webmaster/tasks";
import TaskDetails from "pages/webmaster/tasks/details";
import ConversationTasks from "pages/webmaster/tasks/conversation";
import WebmastersDetails from "pages/webmaster/webmasters";
import TimeOut from "pages/shared/time-out";
import Faq from "pages/shared/faq";

const webmasterRoutes = () => {
  return (
    <Route element={<DashboardLayout />}>
      <Route index element={<Dashboard />} />
      <Route path="/tasks">
        <Route index element={<Tasks />} />
        <Route path=":id" element={<TaskDetails />} />
        <Route path=":id/conversation" element={<ConversationTasks />} />
        <Route path=":id/time-over" element={<TimeOut />} />
      </Route>
      <Route path="/webmasters/:id" element={<WebmastersDetails />} />
      <Route path="/notifications" element={<Notifications />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/faq" element={<Faq />} />
    </Route>
  );
};

export default webmasterRoutes;
