import "./styles.scss";

import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";

import Button from "components/Button";
import FormikInput from "components/formik/FormikInput";
import FormikSelect from "components/formik/FormikSelect";
import FormikTextarea from "components/formik/FormikTextarea";

import api from "libs/api";
import contactSchema from "libs/validations/contactSchema";

import { useApp } from "context/app";

const mainClass = "contact-form";

const ContactForm = ({ setFormSent }) => {
  const { t } = useTranslation();

  const { websites } = useApp();

  const formatted_websites = websites?.map(({ website }) => ({
    label: website,
    value: website,
  }));

  const onSubmit = async (values, setSubmitting) => {
    try {
      await api.post("/client/websites/contact-form", values);
      setFormSent(true);
    } catch (err) {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        website: "",
        title: "",
        message: "",
      }}
      validationSchema={contactSchema}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({ isSubmitting }) => (
        <Form className={`${mainClass}__form form`} autoComplete="off">
          <FormikSelect
            name="website"
            label={t("Website")}
            placeholder="www.adream.pl"
            options={formatted_websites}
            disabled={isSubmitting}
          />
          <FormikInput
            name="title"
            label={t("Title")}
            placeholder={t("Example title")}
            disabled={isSubmitting}
            required
          />
          <FormikTextarea
            name="message"
            label={t("Message")}
            placeholder={`${t("Type your message here")}...`}
            disabled={isSubmitting}
            required
          />
          <div className="form__required-info">
            <small>
              <span>*</span> {t("required field")}
            </small>
          </div>
          <div className={`${mainClass}__actions`}>
            <Button type="submit" loading={isSubmitting}>
              {t("Send message")}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ContactForm;
