import "./styles.scss";

import { useTranslation } from "react-i18next";

import ContactForm from "./components/Form";
import Calendly from "./components/Calendly";
import DetailsCard from "components/DetailsCard";
import useResponsive from "libs/helpers/useResponsive";
import MobileModalOptions from "components/MobileModalOptions";

const mainClass = "contact-content";

const ContactContent = ({ setFormSent }) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  const DetailsCards = () => (
    <>
      <DetailsCard title={t("Schedule a call / online meeting")}>
        <Calendly />
      </DetailsCard>
      <DetailsCard title={t("Contact form")}>
        <ContactForm setFormSent={setFormSent} />
      </DetailsCard>
    </>
  );

  return (
    <div className={mainClass}>
      {!!!isMobile ? (
        <DetailsCards />
      ) : (
        <MobileModalOptions components={DetailsCards} />
      )}
    </div>
  );
};

export default ContactContent;
