import "./styles.scss";
import classnames from "classnames";
import { Link } from "react-router-dom";

const mainClass = "breadcrumps";

const Breadcrumps = ({ data, extra_padding }) => {
  return (
    <div
      className={classnames(mainClass, {
        extra_padding: !!extra_padding,
      })}
    >
      {data?.map(({ label, href }, index) => {
        return (
          <div key={index}>
            {!!label ? (
              !!href ? (
                <Link to={href}>{label}</Link>
              ) : (
                <span>{label}</span>
              )
            ) : (
              <div key={index} className={`${mainClass}__skeleton`}></div>
            )}
            <small>/</small>
          </div>
        );
      })}
    </div>
  );
};

export default Breadcrumps;
