import "./styles.scss";

import classnames from "classnames";

import TrackerButtonTimer from "./components/Timer";

import toHoursAndMinutes from "libs/helpers/toHoursAndMinutes";

import { ReactComponent as PlayIcon } from "assets/icons/time-tracker-play.svg";
import { ReactComponent as PauseIcon } from "assets/icons/time-tracker-pause.svg";

const mainClass = "tracker-button";

const TrackerButton = ({ time, onClick, start_time_tracked, only_button }) => {
  return (
    <div
      className={classnames(mainClass, {
        [`${mainClass}--active`]: !!start_time_tracked,
      })}
    >
      <button onClick={onClick}>
        {!!start_time_tracked ? <PauseIcon /> : <PlayIcon />}
      </button>
      {!!!only_button && (
        <>
          {!!start_time_tracked ? (
            <span>
              <TrackerButtonTimer start_time_tracked={start_time_tracked} />
            </span>
          ) : (
            <span>
              {toHoursAndMinutes(time?.$numberDecimal || 0)?.formatted}
            </span>
          )}
        </>
      )}
    </div>
  );
};

export default TrackerButton;
