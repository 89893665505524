import "./styles.scss";

import { useState } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import Button from "components/Button";
import FormikTextarea from "components/formik/FormikTextarea";

import { ReactComponent as CrossIcon } from "assets/icons/cross.svg";

const mainClass = "new-project-content__summary";

const SummaryProject = ({ setStep }) => {
  const { t } = useTranslation();
  const { values, setFieldValue, isSubmitting } = useFormikContext();

  const [description_open, setDescriptionOpen] = useState(null);

  const onItemRemove = (value) => {
    const index = values?.options?.findIndex((item) => item === value);
    if (index > -1) {
      let temp = [...values?.options];
      temp.splice(index, 1);

      setFieldValue("options", [...temp]);
    }
  };

  return (
    <div className={mainClass}>
      <div className={`${mainClass}__content`}>
        <div className={`${mainClass}__left`}>
          <h3>{t(`Selected projects`)}</h3>
          <div className={`${mainClass}__left__items`}>
            {values?.options?.map((item, index) => (
              <div key={index} className={`${mainClass}__left__items__item`}>
                <span>{item}</span>
                {values?.options?.length > 1 && (
                  <button onClick={() => onItemRemove(item)}>
                    <CrossIcon />
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className={`${mainClass}__right`}>
          <h3>{t("Describe what you need")}</h3>
          <FormikTextarea
            name="message"
            placeholder={t(
              "Provide as many details as possible about the projects you would like to implement with us"
            )}
          />
        </div>
      </div>
      <div className={`${mainClass}__bottom`}>
        <Button variant="transparent" onClick={() => setStep(0)}>
          {t("Back")}
        </Button>
        <Button loading={isSubmitting} type="submit">
          {t("Send message")}
        </Button>
      </div>
      {!!description_open && (
        <div
          className={`${mainClass}__backdrop`}
          onClick={() => setDescriptionOpen(null)}
        />
      )}
    </div>
  );
};

export default SummaryProject;
