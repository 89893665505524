import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.response.use((res) => {
  return res.data;
});

export default api;
