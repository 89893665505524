import "react-toastify/dist/ReactToastify.css";
import "./styles.scss";

import { useState } from "react";
import { Outlet } from "react-router-dom";

import Sidebar from "./components/Sidebar";
import NotifyContainer from "components/NotifyContainer";
import WebmasterFloatTracker from "../DashboardContentLayout/components/WebmasterFloatTracker";

import { useApp } from "context/app";
import ScrollToTop from "libs/helpers/ScrollToTop";

const DashboardLayout = () => {
  const { user } = useApp();

  const [mobile_sidebar_open, setMobileSidebarOpen] = useState(false);

  return (
    <>
      <div className="dashboard-layout">
        <Sidebar
          mobile_open={mobile_sidebar_open}
          setMobileSidebarOpen={setMobileSidebarOpen}
        />
        <Outlet />
      </div>
      {user?.type === "webmaster" && <WebmasterFloatTracker />}
      <NotifyContainer />
      <ScrollToTop />
    </>
  );
};

export default DashboardLayout;
