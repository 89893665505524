import "./styles.scss";

import classnames from "classnames";
import { useTranslation } from "react-i18next";

import { ReactComponent as EyeIcon } from "assets/icons/eye.svg";
import { ReactComponent as EyeOffIcon } from "assets/icons/eye-off.svg";
import IconButton from "components/IconButton";
import { useState } from "react";

const mainClass = "input";

const Input = ({
  type = "text",
  label,
  placeholder,
  name,
  touched,
  error,
  value,
  onChange,
  disabled,
  uppercaseLabel = false,
  maxLength,
  required,
  id,
}) => {
  const { t } = useTranslation();
  const [show_password, setShowPassword] = useState(false);

  const is_error = touched && error !== undefined;

  return (
    <div
      id={id}
      className={classnames(mainClass, {
        [`${mainClass}--error`]: !!is_error,
        [`${mainClass}--uppercase-label`]: !!uppercaseLabel,
      })}
    >
      {!!label && (
        <label>
          {label}
          {!!required && <span>*</span>}
        </label>
      )}
      <div className={`${mainClass}__content`}>
        <input
          className={`${mainClass}__field`}
          type={!!show_password ? "text" : type}
          placeholder={placeholder}
          value={value}
          name={name}
          onChange={onChange}
          disabled={disabled}
          maxLength={maxLength}
        />
        {type === "password" && (
          <div className={`${mainClass}__content__show-password`}>
            <IconButton
              onClick={() => setShowPassword((prev) => !prev)}
              // size="small"
              icon={!!show_password ? <EyeOffIcon /> : <EyeIcon />}
            />
          </div>
        )}
      </div>
      {!!is_error && (
        <small className={`${mainClass}__error`}>{t(error)}</small>
      )}
    </div>
  );
};

export default Input;
