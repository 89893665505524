import { createContext, useContext, useMemo, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { loadStripe } from "@stripe/stripe-js";
import { useParams } from "react-router-dom";

import api from "libs/api";
import tax_data from "libs/tax_data.json";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const NewWebsiteContext = createContext();

const NewWebsiteProvider = ({ children, change_plan_mode }) => {
  const { id } = useParams();
  const queryClient = useQueryClient();

  const [step, setStep] = useState(0);
  const [step_touched, setStepTouched] = useState(0);
  const [mobile_step, setMobileStep] = useState(0);
  const [form_data, setFormData] = useState(null);
  const [form_data_error, setFormDataError] = useState("");

  const { isLoading: loading, data: website } = useQuery(
    ["websites", id],
    () => api.get(`/client/websites/${id}`),
    {
      enabled: !!id && !!change_plan_mode,
    }
  );

  const saveData = (values) => {
    setFormData((prev) => ({ ...prev, ...values }));
  };

  const nextStep = (values) => {
    setStep((prev) => prev + 1);
    if (step === step_touched) {
      setStepTouched((prev) => prev + 1);
    }
    saveData(values);
  };

  const prevStep = (values) => {
    setStep((prev) => prev - 1);
    saveData(values);
  };

  const createPayment = () =>
    new Promise(async (resolve, reject) => {
      try {
        let data_to_send = { ...form_data };

        const tax = tax_data?.find(
          (item) => data_to_send?.billing?.tax?.code === item?.code
        );

        const createWebsite = await api.post(`/client/websites`, {
          ...data_to_send,
          billing: {
            ...data_to_send?.billing,
            tax: {
              ...data_to_send?.billing?.tax,
              prefix: tax?.prefix,
              clean_number: data_to_send.billing.tax?.number,
              number: `${tax?.prefix || ""}${data_to_send.billing.tax?.number}`,
              id: tax?.tax_id,
            },
          },
        });
        if (createWebsite) {
          stripePromise.then((c) => {
            const { error } = c.redirectToCheckout({
              sessionId: createWebsite?.id,
            });
            console.error(error);
          });
        }
      } catch (err) {
        if (err?.response?.data?.error === "tax_id_invalid") {
          setStep(1);
          setFormDataError(err?.response?.data?.error);
        }
        reject(err);
      }
    });

  const updateSubscription = () =>
    new Promise(async (resolve, reject) => {
      try {
        if (!!website?.subscription?.subscription_id) {
          const subscriptionUpdate = await api.put(
            `/client/websites/${id}/subscription`,
            form_data
          );

          queryClient.setQueryData(["websites", id], (prev) => ({
            ...prev,
            subscription: {
              ...prev?.subscription,
              price_id: subscriptionUpdate?.price_id,
            },
          }));

          setStep(4);
        } else {
          const continuePayment = await api.post(
            `/client/websites/${website?._id}/continue-payment`,
            {
              price_id: form_data?.plan,
            }
          );
          if (continuePayment) {
            stripePromise.then((c) => {
              const { error } = c.redirectToCheckout({
                sessionId: continuePayment?.id,
              });
              console.error(error);
            });
          }
        }

        resolve();
      } catch (err) {
        console.error(err);
        reject();
      }
    });

  const submitForm = async () => {
    if (!!change_plan_mode) {
      await updateSubscription();
    } else {
      await createPayment();
    }
  };

  const value = useMemo(() => {
    return {
      step,
      step_touched,
      mobile_step,
      form_data,
      website,
      change_plan_mode,
      loading: !!loading && !!change_plan_mode,
      nextStep,
      prevStep,
      setStep,
      form_data_error,
      submitForm,
      setMobileStep,
    };
    // eslint-disable-next-line
  }, [
    step,
    step_touched,
    form_data,
    website,
    change_plan_mode,
    form_data_error,
    loading,
    mobile_step,
  ]);

  return (
    <NewWebsiteContext.Provider value={value}>
      {children}
    </NewWebsiteContext.Provider>
  );
};

const useNewWebsite = () => useContext(NewWebsiteContext);
export { NewWebsiteContext, useNewWebsite };
export default NewWebsiteProvider;
