import "./styles.scss";

import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";
import classnames from "classnames";
import moment from "moment";

import DropdownCard from "components/DropdownCard";
import CalendarMonths from "./components/Months";
import CalendarDays from "./components/Days";

import useClickOutside from "libs/helpers/useClickOutside";
import { ReactComponent as ExitIcon } from "assets/icons/cross.svg";

const mainClass = "calendar";

const Calendar = ({
  label,
  onChange,
  required,
  default_value,
  error,
  anchor,
  touched,
  prev_date,
}) => {
  const ref = useRef();
  const { t } = useTranslation();

  const [date, setDate] = useState(moment());
  const [calendar_open, setCalendarOpen] = useState(false);
  const [selected_month, setSelectedMonth] = useState(false);
  const [selected_date, setSelectedDate] = useState(
    !!default_value ? moment(default_value) : null
  );

  // useEffect(() => {
  //   if (!!prev_date) {
  //     onClear();
  //   }
  //   // eslint-disable-next-line
  // }, [prev_date]);

  useClickOutside(ref, () => {
    setCalendarOpen(false);
    setSelectedMonth(null);
  });

  const onMonthChange = (month) => {
    setDate((prev) => moment(prev).set("month", month));
    setSelectedMonth(true);
  };

  const onSubmit = (day) => {
    const selected_date = moment(date).set("date", day);

    setCalendarOpen(false);
    setSelectedDate(selected_date);
    setSelectedMonth(null);
    onChange(moment(selected_date).toISOString());
  };

  const onClear = (e) => {
    e?.stopPropagation();
    setSelectedDate(null);
    onChange(null);
  };

  return (
    <div ref={ref} className={mainClass}>
      <label>
        {label}
        {!!required && <span>*</span>}
      </label>
      <button
        type="button"
        onClick={() => setCalendarOpen((prev) => !prev)}
        className={classnames(`${mainClass}__button`, {
          [`${mainClass}__button--active`]: !!selected_date,
          [`${mainClass}__button--focus`]: !!calendar_open,
          [`${mainClass}__button--error`]:
            !!error && Object.keys(touched).length !== 0,
        })}
      >
        {selected_date && !!selected_date?._isValid ? (
          <>
            {moment(selected_date).format("ll")}
            <button
              type="button"
              className={`${mainClass}__button__aside-button`}
              onClick={onClear}
            >
              <ExitIcon />
            </button>
          </>
        ) : (
          t("Select date")
        )}
      </button>
      {!!calendar_open && (
        <div
          className={classnames(
            `${mainClass}__content`,
            `${mainClass}__content--${!!!selected_month ? "months" : "days"}`,
            {
              [`${mainClass}__content--${anchor}`]: !!anchor,
            }
          )}
        >
          <DropdownCard position="left">
            {!!!selected_month ? (
              <CalendarMonths
                date={date}
                setDate={setDate}
                selected_date={selected_date}
                onSelect={onMonthChange}
                prev_date={prev_date}
              />
            ) : (
              <CalendarDays
                date={date}
                setDate={setDate}
                selected_date={selected_date}
                onSelect={(day) => onSubmit(day)}
                prev_date={prev_date}
              />
            )}
          </DropdownCard>
        </div>
      )}
    </div>
  );
};

export default Calendar;
