import { createContext, useContext, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import queryString from "query-string";

import api from "libs/api";
import task_status_data from "libs/task_status_data.json";
import task_priority_data from "libs/task_priority_data.json";

const ClientTasksContext = createContext();

const ClientTasksProvider = ({ children }) => {
  const { id } = useParams();
  const { search } = useLocation();
  const navigate = useNavigate();

  const filters = queryString.parse(search);
  const default_sort = "updated_at:desc";

  const [init_loading, setInitLoading] = useState(true);

  const { isLoading: loading, data } = useQuery(
    ["client_tasks", id, filters],
    () =>
      api.get(
        `/admin/clients/${id}/tasks?${queryString.stringify({
          ...filters,
          type:
            task_priority_data?.find((item) => item?.key === filters?.type)
              ?.value || undefined,
          status:
            task_status_data?.find((item) => item?.key === filters?.status)
              ?.value || undefined,
          sort: filters?.sort || default_sort,
        })}`
      ),
    {
      onSuccess: () => {
        setInitLoading(false);
      },
    }
  );

  const filterHandler = (name, value) => {
    navigate(
      `/clients/${id}/tasks?${queryString.stringify({
        ...filters,
        [name]: value || undefined,
      })}`
    );
  };

  const value = useMemo(() => {
    return {
      id,
      data,
      loading,
      init_loading,
      filters,
      filterHandler,
    };
    // eslint-disable-next-line
  }, [id, data, loading, init_loading, filters]);

  return (
    <ClientTasksContext.Provider value={value}>
      {children}
    </ClientTasksContext.Provider>
  );
};

const useClientTasks = () => useContext(ClientTasksContext);
export { ClientTasksContext, useClientTasks };
export default ClientTasksProvider;
