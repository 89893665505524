import "./styles.scss";

import { useState } from "react";
import { useTranslation } from "react-i18next";

import Pill from "components/Pill";
import Tooltip from "components/ToolTip";
import Dropdown from "components/Dropdown";
import DropdownCard from "components/DropdownCard";
import ChangeStatusModal from "./components/ChangeStatusModal";

import task_status_data from "libs/task_status_data.json";
import { useApp } from "context/app";

const mainClass = "task-conversation__details__status-select";

const StatusSelect = ({ status, updateTask }) => {
  const { t } = useTranslation();

  const { user } = useApp();

  const [dropdown_key, setDropdownKey] = useState(0);
  const [confirmation_modal, setConfirmationModal] = useState(false);

  const status_key = task_status_data?.find(
    (item) => item?.value === status
  )?.key;

  const onClickHandler = (new_status) => {
    if (status < new_status && new_status === 4) {
      if (user?.type === "admin") {
        setConfirmationModal(true);
        return;
      }
    }

    updateTask({ status: new_status });
    setDropdownKey((prev) => prev + 1);
  };

  return (
    <>
      <Dropdown
        button={
          <Tooltip text={t("Change Status")} variant="info">
            <Pill variant={status_key} size="small" />
          </Tooltip>
        }
        onclose={dropdown_key}
      >
        <DropdownCard>
          <div className={mainClass}>
            {task_status_data?.map(({ key, value }) => {
              if (key === status_key) return null;

              return (
                <Pill
                  key={key}
                  variant={
                    task_status_data?.find((item) => item?.value === value)?.key
                  }
                  size="small"
                  onclick={() => onClickHandler(value)}
                />
              );
            })}
          </div>
        </DropdownCard>
      </Dropdown>
      {!!confirmation_modal && (
        <ChangeStatusModal onClose={() => setConfirmationModal(false)} />
      )}
    </>
  );
};

export default StatusSelect;
