const toHoursAndMinutes = (totalMinutes = 60) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = (totalMinutes % 60)?.toFixed(0);

  const formatted =
    parseFloat(totalMinutes) < 1
      ? `${(totalMinutes * 60)?.toFixed(0)}s`
      : `${!!hours ? `${hours}h ` : ""}${minutes}m`;

  return { hours, minutes, formatted };
};

export default toHoursAndMinutes;
