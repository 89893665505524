import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Button from "components/Button";
import PlaceholderPage from "components/PlaceholderPage";
import DashboardContentLayout from "components/layouts/DashboardContentLayout";

import { useApp } from "context/app";

const WebsitesPlaceholder = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { user, websites } = useApp();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (websites?.length > 0) {
      return navigate(`/websites/${websites?.[0]?._id}`);
    }

    setLoading(false);
    // eslint-disable-next-line
  }, [user]);

  if (!!loading) return null;

  return (
    <DashboardContentLayout
      header_label={t("Websites")}
      buttons={
        <>
          <Button
            href="/websites/new"
            onClick={() =>
              window.gtag("event", "new_website", {
                click_text: t("Add new website"),
                click_url: `/websites/new`,
              })
            }
          >
            {t("Add new website")}
          </Button>
        </>
      }
    >
      <PlaceholderPage
        title={t("You have not added any websites yet")}
        text={t("To purchase a plan for your website click the button below.")}
        button={{
          label: t("Choose a plan"),
          href: "/websites/new",
        }}
      />
    </DashboardContentLayout>
  );
};

export default WebsitesPlaceholder;
