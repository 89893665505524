import { useEffect, useState } from "react";
import moment from "moment";

const TrackerButtonTimer = ({ start_time_tracked }) => {
  const [countdown, setCountdown] = useState("00:00:00");

  const countdownHandler = () => {
    if (!!!start_time_tracked) return;

    const msLeft = moment().diff(moment(start_time_tracked));
    const duration = moment.duration(msLeft);

    const hours = Math.floor(duration.asMinutes() / 60);
    const minutes = Math.floor(duration.asMinutes() % 60);
    const seconds = Math.floor((duration.asMinutes() * 60) % 60);

    let formattedHours = String(hours).padStart(2, "0");
    let formattedMinutes = String(minutes).padStart(2, "0");
    let formattedSeconds = String(seconds).padStart(2, "0");

    setCountdown(`${formattedHours}:${formattedMinutes}:${formattedSeconds}`);
  };

  useEffect(() => {
    // countdownHandler();

    var interval = setInterval(() => {
      countdownHandler();
    }, 1000);
    return () => {
      clearInterval(interval);
      setCountdown("00:00:00");
    };
    // eslint-disable-next-line
  }, [start_time_tracked]);

  return countdown;
};

export default TrackerButtonTimer;
