import "./styles.scss";

import moment from "moment";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Modal from "components/Modal";
import PillGrid from "components/PillGrid";

import { useTask } from "context/webmaster/task";
import toHoursAndMinutes from "libs/helpers/toHoursAndMinutes";

const mainClass = "all-entries-modal";

const AllEntriesModal = ({ onClose }) => {
  const { t } = useTranslation();

  const { data } = useTask();
  const [webmaster_filter, setWebmasterFilter] = useState(null);

  let webmasters = [
    {
      label: t("Show all"),
      onclick: () => setWebmasterFilter(null),
      active: !!!webmaster_filter,
    },
  ];

  data?.time_entries?.forEach((item) => {
    const index = webmasters?.findIndex(
      (element) => item?.webmaster?._id === element?.webmaster_id
    );

    if (index === -1) {
      webmasters?.push({
        webmaster_id: item?.webmaster?._id,
        label: `${item?.webmaster?.first_name} ${item?.webmaster?.last_name}`,
        onclick: () => setWebmasterFilter(item?.webmaster?._id),
        active: webmaster_filter === item?.webmaster?._id,
      });
    }
  });

  const filtered_data = !!webmaster_filter
    ? data?.time_entries?.filter(
        (item) => item?.webmaster?._id === webmaster_filter
      )
    : data?.time_entries;

  return (
    <Modal
      title={t("All entries")}
      onClose={onClose}
      hide_submit_button
      variant="standard"
    >
      <PillGrid header="Webmaster" items={webmasters} size="small" />
      <div className={mainClass}>
        {filtered_data
          ?.map((item, index) => (
            <div key={index} className={`${mainClass}__item`}>
              <span>
                {`${
                  toHoursAndMinutes(item?.time?.$numberDecimal)?.formatted
                } | ${moment(item?.started_at)?.format("HH:mm")} - ${moment(
                  item?.created_at
                )?.format("HH:mm")} ${t("on")}
            ${moment(item?.created_at)?.format("ll")}`}
                <span> {t("by")} </span>
                <Link
                  to={`/webmasters/${item?.webmaster?._id}`}
                >{`${item?.webmaster?.first_name} ${item?.webmaster?.last_name}`}</Link>
              </span>
            </div>
          ))
          ?.reverse()}
      </div>
    </Modal>
  );
};

export default AllEntriesModal;
