import "./styles.scss";

import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import LazyLoad from "react-lazy-load";
import classnames from "classnames";

import useOnScreen from "libs/helpers/useOnScreen";
import useClickOutside from "libs/helpers/useClickOutside";

import { ReactComponent as ArrowIcon } from "assets/icons/list-arrow.svg";

const mainClass = "select";

const Select = ({
  name,
  label,
  options = [],
  placeholder,
  required,
  selected,
  onChange,
  searcher,
  disabled,
  value,
  touched,
  error,
  hideError,
  id,
}) => {
  const { t } = useTranslation();

  const is_error = !!!hideError && touched && !!error;

  const [select_open, setSelectOpen] = useState(false);
  const [search, setSearch] = useState(null);
  const [search_result, setSearchResult] = useState([]);

  const ref = useRef();
  const button_ref = useRef();
  const searcher_ref = useRef();

  useClickOutside(ref, () => {
    setSelectOpen(false);
    setSearch(null);
    setSearchResult([]);
  });

  const can_bottom = useOnScreen(button_ref);
  const current_value = options?.find((item) => item?.value === value);

  const onSearch = () => {
    if (!!!search) return setSearchResult([]);
    const filtered_options = options?.filter(
      (item) => item?.label !== -1 || item?.value !== -1
    );
    const find_options = filtered_options?.filter(
      (item) =>
        item?.label?.toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        item?.value?.toLowerCase().indexOf(search?.toLowerCase()) !== -1
    );
    setSearchResult(find_options);
  };

  useEffect(() => {
    if (!!select_open) {
      searcher_ref?.current?.focus();
    }
    // eslint-disable-next-line
  }, [select_open]);

  useEffect(() => {
    onSearch(search);
    // eslint-disable-next-line
  }, [search]);

  const options_to_show = search?.length > 1 ? search_result : options;

  return (
    <div
      ref={ref}
      id={id}
      className={classnames(mainClass, {
        [`${mainClass}--active`]: !!select_open,
        [`${mainClass}--selected`]: !!selected,
        [`${mainClass}--top`]: !!!can_bottom,
        [`${mainClass}--disabled`]: !!disabled,
        [`${mainClass}--error`]: !!is_error,
        [`${mainClass}--empty`]: !!!value,
      })}
    >
      {!!label && (
        <label>
          {label}
          {!!required && <span>*</span>}
        </label>
      )}
      <button
        ref={button_ref}
        type="button"
        onClick={() => setSelectOpen((prev) => !prev)}
      >
        <span>
          {current_value?.icon}
          {current_value?.label || placeholder}
        </span>
        <ArrowIcon />
      </button>
      {select_open && (
        <div className={`${mainClass}__options`}>
          {!!searcher && (
            <div className={`${mainClass}__options__searcher`}>
              <input
                ref={searcher_ref}
                type="text"
                placeholder={t("Search")}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          )}
          <div className={`${mainClass}__options__wrapper`}>
            {options_to_show?.map((item, index) => (
              <button
                type="button"
                key={index}
                className={classnames(`${mainClass}__options__item`, {
                  [`${mainClass}__options__item--selected`]:
                    selected?.value === item?.value,
                })}
                onClick={() => {
                  onChange({ target: { name, value: item?.value } });
                  setSearch(null);
                  setSearchResult([]);
                  setSelectOpen((prev) => !prev);
                }}
              >
                {!!item?.icon && <LazyLoad>{item?.icon}</LazyLoad>}
                {item?.label}
              </button>
            ))}
          </div>
        </div>
      )}
      {!!is_error && (
        <small className={`${mainClass}__error`}>{t(error)}</small>
      )}
    </div>
  );
};

export default Select;
