import "./styles.scss";

import { useState } from "react";

import Modal from "components/Modal";

import { useTask } from "context/webmaster/task";

const WebmasterCompletedModal = ({ onClose }) => {
  const { updateTask } = useTask();
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    try {
      setLoading(true);
      await updateTask({ status: 5 });
      onClose();
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Mark task as completed"
      onClose={onClose}
      onSubmit={onSubmit}
      submit_button_label="Yes, all done"
      loading={!!loading}
      variant="little-narrow"
    >
      <p>
        Have you checked if all the requirements for the task have been
        completed?
        <br />
        <br />
        Are you sure you would like to assign the task to the Client for
        verification? Please note that this action can only be reversed by the
        administrator.
      </p>
    </Modal>
  );
};

export default WebmasterCompletedModal;
