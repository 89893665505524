import "./styles.scss";

import { useTranslation } from "react-i18next";

import PriorityFlag from "components/PriorityFlag";

const mainClass = "dashboard-content__tasks-overview";

const TasksOverview = ({ data }) => {
  const { t } = useTranslation();

  const tasks_overview_data = [
    {
      label: t("Tasks to do this week"),
      value: data?.this_week_count,
    },
    {
      icon: <PriorityFlag priority={3} />,
      label: t("Urgent tasks"),
      value: data?.urgent_count,
    },
    {
      icon: <PriorityFlag priority={2} />,
      label: t("High priority tasks"),
      value: data?.high_count,
    },
    {
      icon: <PriorityFlag priority={1} />,
      label: t("Normal priority tasks"),
      value: data?.normal_count,
    },
  ];

  return (
    <div className={mainClass}>
      {tasks_overview_data?.map(({ icon, label, value }, index) => (
        <div key={index} className={`${mainClass}__item`}>
          <div className={`${mainClass}__item__label`}>
            <span>{label}</span>
            {icon}
          </div>
          <span>{value}</span>
        </div>
      ))}
    </div>
  );
};

export default TasksOverview;
