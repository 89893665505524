import "./styles.scss";

import { useEffect } from "react";
import classnames from "classnames";

import PriorityFlag from "components/PriorityFlag";
import TrackerButton from "components/TrackerButton";
import TrackerButtonTimer from "components/TrackerButton/components/Timer";

import { useTimeTracking } from "context/webmaster/time-tracking";

import { ReactComponent as TimerIcon } from "assets/icons/timer.svg";

const mainClass = "webmaster-float-tracker";

const WebmasterFloatTracker = () => {
  const { current, timeTracking } = useTimeTracking();

  useEffect(() => {
    if (!!current?.active) {
      document.body.style.paddingBottom = "64px";
    } else {
      document.body.style.paddingBottom = "0px";
    }
  }, [current]);

  return (
    <div
      className={classnames(mainClass, {
        [`${mainClass}--active`]: !!current?.start,
      })}
    >
      <div className={`${mainClass}__top`}>
        <PriorityFlag priority={current?.tasks?.[0]?.priority} />
        <span>{current?.website}</span>
      </div>
      <p>{current?.tasks?.[0]?.title}</p>
      <div className={`${mainClass}__tracker`}>
        <span>
          <TimerIcon />{" "}
          <span>
            Time tracked:
            <br />
            <TrackerButtonTimer start_time_tracked={current?.start} />
          </span>
        </span>
        <TrackerButton
          onClick={() => timeTracking(current?.tasks?.[0]?._id)}
          start_time_tracked={current?.start}
        />
      </div>
    </div>
  );
};

export default WebmasterFloatTracker;
