import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import Button from "components/Button";
import FormikInput from "components/formik/FormikInput";
import MobileModalOptions from "components/MobileModalOptions";

const AccessDataMobile = ({ index }) => {
  const { t } = useTranslation();
  const { values, submitForm, isSubmitting } = useFormikContext();

  const cms_completed =
    values.wordpress.url && values.wordpress.login && values.wordpress.password;
  const server_completed =
    values.server.host && values.server.username && values.server.password;
  const database_completed =
    values.database.name &&
    values.database.username &&
    values.database.password &&
    values.database.host;

  const FormMobileComponent = () => (
    <>
      <div title={t("CMS WordPress")} variant="radio" checked={cms_completed}>
        <div className="form">
          <FormikInput name="wordpress.url" label={t("Login URL")} />
          <FormikInput name="wordpress.login" label={t("Login")} />
          <FormikInput name="wordpress.password" label={t("Password")} />
          <Button fullWidth onClick={submitForm} loading={isSubmitting}>
            {t("Save")}
          </Button>
        </div>
      </div>
      <div title={t("Server / FTP")} variant="radio" checked={server_completed}>
        <div className="form">
          <FormikInput name="server.host" label={t("Host")} />
          <FormikInput name="server.username" label={t("Username")} />
          <FormikInput name="server.password" label={t("Password")} />
          <Button fullWidth onClick={submitForm} loading={isSubmitting}>
            {t("Save")}
          </Button>
        </div>
      </div>
      <div title={t("Database")} variant="radio" checked={database_completed}>
        <div className="form">
          <FormikInput name="database.name" label={t("Database name")} />
          <FormikInput name="database.username" label={t("Username")} />
          <FormikInput name="database.password" label={t("Password")} />
          <FormikInput name="database.host" label={t("Host/URL")} />
          <Button fullWidth onClick={submitForm} loading={isSubmitting}>
            {t("Save")}
          </Button>
        </div>
      </div>
    </>
  );

  return <MobileModalOptions index={index} components={FormMobileComponent} />;
};

export default AccessDataMobile;
