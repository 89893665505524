import { Route } from "react-router-dom";

import DashboardLayout from "components/layouts/DashboardLayout";

import Dashboard from "pages/admin";
import Tasks from "pages/admin/tasks";
import TaskDetails from "pages/admin/tasks/details";
import TaskConversation from "pages/admin/tasks/conversation";
import Clients from "pages/admin/clients";
import Webmasters from "pages/admin/webmasters";
import ReferralPrograms from "pages/shared/referral-programs";
import ReferralProgramDetails from "pages/shared/referral-programs/details";
import Notifications from "pages/shared/notifications";
import Settings from "pages/admin/settings";
import Client from "pages/admin/clients/client";
import ClientSettings from "pages/admin/clients/settings";
import WebmasterDetails from "pages/admin/webmasters/details";
import WebmasterSettings from "pages/admin/webmasters/settings";
import ClientTasks from "pages/admin/clients/tasks";
import TimeOut from "pages/shared/time-out";
import Faq from "pages/shared/faq";
import Users from "pages/admin/users";

const adminRoutes = () => {
  return (
    <Route element={<DashboardLayout />}>
      <Route index element={<Dashboard />} />
      <Route path="/tasks">
        <Route index element={<Tasks />} />
        <Route path=":id" element={<TaskDetails />} />
        <Route path=":id/conversation" element={<TaskConversation />} />
        <Route path=":id/time-over" element={<TimeOut />} />
      </Route>
      <Route path="/clients">
        <Route index element={<Clients />} />
        <Route path=":id">
          <Route index element={<Client />} />
          <Route path="tasks" element={<ClientTasks />} />
          <Route path="account-settings" element={<ClientSettings />} />
        </Route>
      </Route>
      <Route path="/webmasters">
        <Route index element={<Webmasters />} />
        <Route path=":id">
          <Route index element={<WebmasterDetails />} />
          <Route path="account-settings" element={<WebmasterSettings />} />
        </Route>
      </Route>
      <Route path="/referral-program">
        <Route index element={<ReferralPrograms />} />
        <Route path=":id" element={<ReferralProgramDetails />} />
      </Route>
      <Route path="/users" element={<Users />} />
      <Route path="/notifications" element={<Notifications />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/faq" element={<Faq />} />
    </Route>
  );
};

export default adminRoutes;
