import { useTranslation } from "react-i18next";

import DashboardContentLayout from "components/layouts/DashboardContentLayout";

import NewProjectContent from "page_components/client/new-project/Content";

const NewProject = () => {
  const { t } = useTranslation();

  const breadcrumps = [
    {
      label: t("Need a new project?"),
    },
  ];

  return (
    <DashboardContentLayout
      header_label={t("Need a new project?")}
      breadcrumps={breadcrumps}
    >
      <NewProjectContent />
    </DashboardContentLayout>
  );
};

export default NewProject;
