import "./styles.scss";
import Pill from "components/Pill";

const mainClass = "pill-grid";

const PillGrid = ({ header, items, size }) => {
  return (
    <div className={mainClass}>
      {!!header && <span>{header}</span>}
      <div className={`${mainClass}__items`}>
        {items?.map((item, index) => (
          <Pill key={index} {...item} size={size} />
        ))}
      </div>
    </div>
  );
};

export default PillGrid;
