const currency_render = (currency) => {
  switch (currency) {
    case "usd":
      return "$";
    case "eur":
      return "€";
    case "pln":
      return "zł";
    default:
      return currency;
  }
};

const priceRender = (amount, currency) => {
  if (currency === "pln") {
    return `${amount}${currency_render(currency)}`;
  } else {
    return `${currency_render(currency)}${amount}`;
  }
};

module.exports = priceRender;
