import "./styles.scss";

import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import Table from "components/Table";
import Button from "components/Button";
import Switch from "components/form/Switch";
import PlaceholderPage from "components/PlaceholderPage";
import WebsiteInfoModal from "components/WebsiteInfoModal";
import CustomNotificationModal from "../components/CustomNotificationModal";
import DashboardContentLayout from "components/layouts/DashboardContentLayout";

import useResponsive from "libs/helpers/useResponsive";
import notificationRender from "libs/notification_render";

import { useApp } from "context/app";
import { useNotifications } from "context/notifications";

const mainClass = "notifications-content";

const actions_admin = [
  {
    type: "send_notification",
  },
];

const NotificationsContent = () => {
  const { user, websites } = useApp();
  const { isMobile } = useResponsive();
  const { t } = useTranslation();

  const breadcrumps = [
    {
      label: t("Notifications"),
    },
  ];

  const {
    data,
    readHandler,
    loading,
    init_loading,
    filters,
    filterHandler,
    show_read,
    setShowRead,
  } = useNotifications();

  const [website_info_modal, setWebsiteInfoModal] = useState(null);
  const [custom_text_modal, setCustomTextModal] = useState(null);

  const actions_client = [
    {
      label: t("Add new website"),
      href: "/websites/new",
      event: () =>
        window.gtag("event", "new_website", {
          click_text: t("Add new website"),
          click_url: `/websites/new`,
        }),
    },
    ...(websites?.length > 0
      ? [
          {
            type: "create_client_task",
            onboarding_button: true,
          },
        ]
      : []),
  ];

  const website_render = (website, href) => {
    switch (user?.type) {
      case "client":
        return {
          type: "link",
          value: {
            label: website?.website,
            href: href,
          },
        };
      case "webmaster":
        return {
          type: "clickable_text",
          value: {
            label: website?.website,
            onclick: () => setWebsiteInfoModal(website?._id),
          },
        };
      case "admin":
        return {
          type: "link",
          value: {
            label: website?.website,
            href: `/clients/${website?._id}`,
          },
        };
      default:
        return null;
    }
  };

  const table_data = {
    keys: [
      {
        key: "notification_status",
        value: "",
      },
      {
        sort_key: "website",
        key: "website-full",
        value: t("Website"),
      },
      {
        key: "created_at",
        value: t("Date"),
      },
      {
        key: "template",
        value: t("Message"),
      },
    ],
    items: data?.map(
      ({
        _id,
        template,
        created_at,
        website,
        status,
        custom_text,
        context,
        href,
      }) => [
        {
          key: "notification_status",
          type: "status",
          value: !!!status,
        },
        {
          key: "website-full",
          mobile_title: true,
          ...website_render(website, href),
        },
        {
          key: "created_at",
          value: moment(created_at)?.format("ll"),
        },
        {
          key: "template",
          type: "clickable_text",
          value: {
            label: custom_text || notificationRender(template, context, t),
            onclick: () => {
              readHandler(_id, 1);
              setCustomTextModal(
                custom_text || notificationRender(template, context, t)
              );
            },
          },
        },
        ...(isMobile
          ? [
              {
                key: "button",
                type: "button",
                value: {
                  label: t("Show"),
                  href: href,
                  // onclick: () => {
                  //   readHandler(_id, 1);
                  //   setCustomTextModal(
                  //     custom_text || notificationRender(template, context, t)
                  //   );
                  // },
                },
              },
            ]
          : []),
      ]
    ),
  };

  return (
    <DashboardContentLayout
      header_label={t("Notifications")}
      loading={init_loading}
      breadcrumps={breadcrumps}
      actions={user?.type === "client" ? actions_client : actions_admin}
      // buttons={
      //   user?.type === "client" ? (
      //     <>
      //       <Button href="/websites/new">Add new website</Button>
      //       {websites?.length > 0 && (
      //         <Button variant="transparent" href="/tasks/new">
      //           Create new task
      //         </Button>
      //       )}
      //     </>
      //   ) : null
      // }
    >
      <div className={mainClass}>
        {websites?.length === 0 && user?.type === "client" ? (
          <PlaceholderPage
            title={t("You have not added any websites yet")}
            text={t(
              "To purchase a plan for your website click the button below."
            )}
            button={{
              label: t("Choose a plan"),
              href: "/websites/new",
            }}
          />
        ) : (
          <Table
            data={table_data}
            loading={!!loading}
            sort={filters?.sort}
            setSort={(value) => filterHandler("sort", value)}
            header={
              <Switch
                label={t("Show read")}
                checked={!!show_read}
                onChange={(e) => setShowRead(e?.target?.checked)}
              />
            }
            header_aside={
              data?.length > 0 ? (
                <Button
                  size="small"
                  variant="transparent"
                  onClick={() => readHandler("all")}
                >
                  {t("Mark all as read")}
                </Button>
              ) : null
            }
          />
        )}
      </div>
      {!!website_info_modal && (
        <WebsiteInfoModal
          id={website_info_modal}
          onClose={() => setWebsiteInfoModal(null)}
        />
      )}
      {!!custom_text_modal && (
        <CustomNotificationModal
          text={custom_text_modal}
          onClose={() => setCustomTextModal(null)}
        />
      )}
    </DashboardContentLayout>
  );
};

export default NotificationsContent;
