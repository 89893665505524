import { createContext, useContext, useMemo, useState } from "react";
import { toast } from "react-toastify";

import api from "libs/api";
import { useApp } from "context/app";

const TimeTrackingContext = createContext();

const TimeTrackingProvider = ({ children }) => {
  const { task_tracking } = useApp();
  const [current_time_tracking, setCurrentTimeTracking] =
    useState(task_tracking);

  const timeTracking = (id, refetch) =>
    new Promise(async (resolve, reject) => {
      try {
        const update = await api.post(`/webmaster/tasks/${id}/time-tracking`);
        setCurrentTimeTracking({ ...update, refetch });

        if (!!current_time_tracking?.refetch) {
          current_time_tracking?.refetch();
        }

        resolve();
      } catch (err) {
        console.error(err);
        toast.error("Something went wrong.");
        reject(err);
      }
    });

  const value = useMemo(() => {
    return {
      current: current_time_tracking,
      setCurrentTimeTracking,
      timeTracking,
    };
    // eslint-disable-next-line
  }, [current_time_tracking]);

  return (
    <TimeTrackingContext.Provider value={value}>
      {children}
    </TimeTrackingContext.Provider>
  );
};

const useTimeTracking = () => useContext(TimeTrackingContext);
export { TimeTrackingContext, useTimeTracking };
export default TimeTrackingProvider;
