import "./styles.scss";

import classnames from "classnames";
import { useTranslation } from "react-i18next";

const mainClass = "password-validator";

const PasswordValidator = ({ password }) => {
  const { t } = useTranslation();

  const validator_data = [
    {
      label: t("At least 10 characters"),
      status: password.length >= 10,
    },
    {
      label: t("One number"),
      status: /[0-9]/.test(password),
    },
    {
      label: t("One upper case letter"),
      status: /[A-Z]/.test(password),
    },
    {
      label: t("One lower case letter"),
      status: /[a-z]/.test(password),
    },
  ];

  return (
    <div className={mainClass}>
      {validator_data?.map(({ label, status }, index) => (
        <div
          key={index}
          className={classnames(`${mainClass}__item`, [
            `${mainClass}__item--${!!status ? "success" : "failed"}`,
          ])}
        >
          <span>{label}</span>
        </div>
      ))}
    </div>
  );
};

export default PasswordValidator;
