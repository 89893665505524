import "./styles.scss";

import { useTranslation } from "react-i18next";

import { UpcomingTasks } from "./components";
import DetailsCard from "components/DetailsCard";
import TasksOverview from "./components/TasksOverview";
import DashboardContentLayout from "components/layouts/DashboardContentLayout";

import { useApp } from "context/app";
import { useDashboard } from "context/webmaster/dashboard";

const mainClass = "dashboard-content";

const actions = [
  {
    type: "send_notification",
  },
];

const DashboardContent = () => {
  const { t } = useTranslation();

  const { user } = useApp();
  const { data, loading } = useDashboard();

  const breadcrumps = [
    {
      label: t("Dashboard"),
    },
  ];

  return (
    <DashboardContentLayout
      header_label={`${t("Welcome back")}, ${user?.first_name}`}
      breadcrumps={breadcrumps}
      actions={actions}
      loading={loading}
    >
      <div className={mainClass}>
        <div className={`${mainClass}__row`}>
          <DetailsCard title={t("Tasks overview")}>
            <TasksOverview data={data?.task_stats} />
          </DetailsCard>
          <UpcomingTasks />
        </div>
      </div>
    </DashboardContentLayout>
  );
};

export default DashboardContent;
